import { Sidemenu } from '@veracity/vui'
import { Link } from 'react-router-dom'

interface Props {
  to: string
  title: string
  icon: string
  isExternal?: boolean
}
export const SidebarChildItem = ({ to, title, icon, isExternal }: Props) => (
  <Sidemenu.Item
    as={Link}
    to={to}
    target={isExternal ? '_blank' : undefined}
    iconSize="sm"
    bg="sandstone.97"
    title={title}
    icon={icon}
  />
)
