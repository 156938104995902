import { useEffect, useState } from 'react'

export const useDebouncedValue = <T extends string | number | Date>(
  value?: T,
  delay: number = 500
): T | undefined => {
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(() => {
    const handler = setTimeout(() => setDebouncedValue(value), delay)

    return () => clearTimeout(handler)
  }, [value, delay])

  return debouncedValue
}
