import { PortfolioSimple } from '@/interfaces'
import { Card, Heading, P, T } from '@veracity/vui'

interface Props {
  portfolio: PortfolioSimple
}

export const PortfoliosListPreviewCard = ({ portfolio }: Props) => (
  <Card isInteractive column gap={2} p={2} h="100%" justify="space-between">
    <Heading isTruncated size="h6" title={portfolio.name}>
      {portfolio.name}
    </Heading>
    <P size="sm" maxLines={3}>
      {portfolio.description}
    </P>
    <T
      color="darkBlue.18"
      size="sm">{`${portfolio.projects?.length} project(s)`}</T>
  </Card>
)
