import { useQuery } from 'react-query'
import { SelectOptionData } from '@veracity/vui'

import {
  financesClient,
  incomeAdjustmentClient,
  lossFactorsClient,
  otherBessInputsLibraryClient,
  productionProfilesClient,
  wholesaleMarketClient
} from '@/api'
import { DatasetSimple, SourceTypes } from '@/interfaces'

export const useScenarioLibrariesDictionaries = () => {
  const wholesaleQuery = useQuery(
    [...wholesaleMarketClient.QUERY_KEY, { lockedOnly: true }],
    () => wholesaleMarketClient.getAll(true)
  )

  const financesQuery = useQuery(
    [...financesClient.QUERY_KEY, { lockedOnly: true }],
    () => financesClient.getAll(true)
  )

  const incomeAdjustmentQuery = useQuery(
    [...incomeAdjustmentClient.QUERY_KEY, { lockedOnly: true }],
    () => incomeAdjustmentClient.getAll(true)
  )

  const lossFactorsQuery = useQuery(
    [...lossFactorsClient.QUERY_KEY, { lockedOnly: true }],
    () => lossFactorsClient.getAll(true)
  )

  const productionProfilesPv = useQuery(
    [
      ...productionProfilesClient.QUERY_KEY,
      { lockedOnly: true, type: SourceTypes.Solar }
    ],
    () => productionProfilesClient.getAll(true, SourceTypes.Solar)
  )

  const productionProfilesWind = useQuery(
    [
      ...productionProfilesClient.QUERY_KEY,
      { lockedOnly: true, type: SourceTypes.Wind }
    ],
    () => productionProfilesClient.getAll(true, SourceTypes.Wind)
  )

  const otherBessInputs = useQuery(
    [...otherBessInputsLibraryClient.QUERY_KEY, { lockedOnly: true }],
    () => otherBessInputsLibraryClient.getAll(true)
  )

  const convertToOptions = (datasets: DatasetSimple[]) =>
    datasets.map(x => ({ text: x.name, value: x.id } as SelectOptionData))

  return {
    wholesaleMarket: convertToOptions(wholesaleQuery.data || []),
    finances: convertToOptions(financesQuery.data || []),
    incomeAdjustment: convertToOptions(incomeAdjustmentQuery.data || []),
    lossFactors: convertToOptions(lossFactorsQuery.data || []),
    productionProfilesPv: convertToOptions(productionProfilesPv.data || []),
    productionProfilesWind: convertToOptions(productionProfilesWind.data || []),
    otherBessInputs: convertToOptions(otherBessInputs.data || [])
  }
}
