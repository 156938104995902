import { DatasetSimple } from '@/interfaces'
import { useMemo, useState } from 'react'
import { formatDate } from '@veracity/vui'

export const useFilterDatasets = (allDatasets: DatasetSimple[]) => {
  const [searchValue, setSearchValue] = useState('')

  const filteredDatasets = useMemo(() => {
    const optimizedSearchValue = searchValue.toLocaleLowerCase()

    return allDatasets.filter(
      x =>
        x.name?.toLocaleLowerCase().includes(optimizedSearchValue) ||
        x.createdBy?.toLocaleLowerCase().includes(optimizedSearchValue) ||
        formatDate(x.createdAt, 'DD MM YYYY').includes(optimizedSearchValue) ||
        formatDate(x.modifiedAt, 'DD MM YYYY').includes(optimizedSearchValue)
    )
  }, [allDatasets, searchValue])

  return {
    filteredDatasets,
    onFilterChange: (val: string) => setSearchValue(val)
  }
}
