import styled from 'styled-components'
import ReactMarkdown from 'react-markdown'

export const MarkdownContainer = styled.div`
  font-family: 'Nunito Sans', -apple-system, '.SFNSText-Regular',
    'San Francisco', BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', Helvetica,
    Arial, sans-serif;
  ul,
  ol {
    list-style: disc;
    padding: 0.5rem;
    margin-left: 0.5rem;
  }
  a {
    text-decoration: underline;
  }

  h3 {
    font-size: 24px;
    font-weight: 400;
  }
`

interface Props {
  content: string
}

export const ReactMarkdownWrapper = ({ content }: Props) => {
  return (
    <MarkdownContainer>
      <ReactMarkdown
        children={content}
        linkTarget={target =>
          target.startsWith('http') ? '_blank' : undefined
        }
      />
    </MarkdownContainer>
  )
}
