import { useState } from 'react'
import { Box } from '@veracity/vui'

import { filterBy } from '@/utils'
import { PageContainer, PageSearchInput } from '@/components'

import { usePortfoliosList } from './hooks'
import {
  PortfoliosListAddPortfolioDialog,
  PortfoliosListPreview
} from './components'

export const PortfoliosListPage = () => {
  const [searchValue, setSearchValue] = useState('')

  const { portfolios, isFetching } = usePortfoliosList()

  const filteredPortfolios =
    portfolios?.filter(x => filterBy(searchValue, [x.name, x.description])) ||
    []

  return (
    <PageContainer
      loading={isFetching}
      header={<PageContainer.Breadcrumbs items={[{ title: 'Portfolios' }]} />}
      menuBar={
        <Box w="100%" justify="space-between">
          <PageSearchInput onChange={setSearchValue} />
          <PortfoliosListAddPortfolioDialog />
        </Box>
      }>
      <PortfoliosListPreview portfolios={filteredPortfolios} />
    </PageContainer>
  )
}
