import { useEffect, useState } from 'react'
import { Box, formatDate } from '@veracity/vui'

import { PricePlanSimple } from '@/interfaces'

import { OrganizationsFilterValues } from '../../../types'
import { OrganizationsFilterPanelInputs } from './OrganizationsFilterPanelInputs'
import { OrganizationsFiltersPanelPreview } from './OrganizationsFiltersPanelPreview'

interface Props {
  isFilterActive: boolean
  onFilterCloseClicked: () => void
  onFilterChange: (filterValues?: OrganizationsFilterValues) => void
  availablePricingPlans: PricePlanSimple[]
}

export const OrganizationsFilterPanel = ({
  isFilterActive,
  onFilterCloseClicked,
  onFilterChange,
  availablePricingPlans
}: Props) => {
  const [selectedPricePlanId, setSelectedPricePlanId] = useState<number>()
  const [selectedCreationDate, setSelectedCreationDate] = useState<Date>()
  const [
    selectedSubscriptionExpirationDate,
    setSelectedSubscriptionExpirationDate
  ] = useState<Date>()

  useEffect(() => {
    if (
      selectedPricePlanId === undefined &&
      selectedCreationDate === undefined &&
      selectedSubscriptionExpirationDate === undefined
    )
      onFilterChange(undefined)
    else
      onFilterChange({
        selectedPricePlanId,
        selectedCreationDate,
        selectedSubscriptionExpirationDate
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedPricePlanId,
    selectedCreationDate,
    selectedSubscriptionExpirationDate
  ])

  const selectedPricingPlanText = availablePricingPlans.find(
    x => x.id === selectedPricePlanId
  )?.name

  const isFilterPreviewVisible =
    isFilterActive ||
    selectedPricePlanId !== undefined ||
    selectedCreationDate !== undefined ||
    selectedSubscriptionExpirationDate !== undefined

  const handleClearAllFilters = () => {
    setSelectedPricePlanId(undefined)
    setSelectedCreationDate(undefined)
    setSelectedSubscriptionExpirationDate(undefined)
  }

  return (
    <Box column>
      {isFilterActive && (
        <OrganizationsFilterPanelInputs
          onCloseClicked={onFilterCloseClicked}
          availablePricingPlans={availablePricingPlans}
          onPricePlanFilterChanged={setSelectedPricePlanId}
          onCreateDateFilterChanged={setSelectedCreationDate}
          onSubscriptionExpirationDateFilterChanged={
            setSelectedSubscriptionExpirationDate
          }
          onClearAllFiltersClicked={handleClearAllFilters}
        />
      )}
      {isFilterPreviewVisible && (
        <OrganizationsFiltersPanelPreview
          selectedPricingPlanText={selectedPricingPlanText}
          onPricingPlanClearClicked={() => setSelectedPricePlanId(undefined)}
          selectedCreationDateText={formatDate(
            selectedCreationDate,
            'DD MM YYYY'
          )}
          onCreationDateClearClicked={() => setSelectedCreationDate(undefined)}
          selectedSubscriptionExpirationDateText={formatDate(
            selectedSubscriptionExpirationDate,
            'DD MM YYYY'
          )}
          onSelectedSubscriptionDateClearClicked={() =>
            setSelectedSubscriptionExpirationDate(undefined)
          }
        />
      )}
    </Box>
  )
}
