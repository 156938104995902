import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { useToast } from '@veracity/vui'

import { config } from '@/config'
import { productionProfilesClient } from '@/api'
import { UpdateProductionProfileDatasetRequest } from '@/interfaces'

const BASE_ROUTE = config.links.library.productionProfiles
const BASE_QUERY_KEY = productionProfilesClient.QUERY_KEY

export const useProductionProfileDetails = (datasetId: number) => {
  const QUERY_KEY = [...BASE_QUERY_KEY, datasetId]

  const navigate = useNavigate()
  const { showSuccess } = useToast()
  const queryClient = useQueryClient()

  const allDatasets = useQuery([...BASE_QUERY_KEY, { lockedOnly: false }], () =>
    productionProfilesClient.getAll()
  )

  const allDatasetNames =
    allDatasets.data?.map(x => x.name || '').filter(Boolean) || []

  const datasetInfoQuery = useQuery(
    QUERY_KEY,
    () => productionProfilesClient.getById(datasetId),
    { enabled: !!datasetId }
  )

  const updateDatasetMutation = useMutation({
    mutationFn: (req: UpdateProductionProfileDatasetRequest) =>
      productionProfilesClient.updateDataset(datasetId, req),
    onSuccess: async () => {
      showSuccess('Dataset successfully updated')
      await queryClient.invalidateQueries(QUERY_KEY)
    }
  })

  const cloneDatasetMutation = useMutation({
    mutationFn: (datasetName: string) =>
      productionProfilesClient.cloneDataset({ datasetId, name: datasetName }),
    onSuccess: async clonedDatasetId => {
      showSuccess('Dataset successfully cloned')
      navigate(`${BASE_ROUTE}/${clonedDatasetId}`)
      await queryClient.invalidateQueries(BASE_QUERY_KEY, {
        refetchInactive: true
      })
    }
  })

  const removeDatasetMutation = useMutation({
    mutationFn: () => productionProfilesClient.removeDataset(datasetId),
    onSuccess: async () => {
      showSuccess('Dataset successfully removed')
      navigate(BASE_ROUTE)
      await queryClient.invalidateQueries(BASE_QUERY_KEY, {
        refetchInactive: true
      })
    }
  })

  const lockDatasetMutation = useMutation({
    mutationFn: () => productionProfilesClient.lockDataset(datasetId),
    onSuccess: async () => {
      showSuccess('Dataset successfully locked')
      await queryClient.invalidateQueries(BASE_QUERY_KEY, {
        refetchInactive: true
      })
    }
  })

  const archiveDatasetMutation = useMutation({
    mutationFn: () => productionProfilesClient.archiveDataset(datasetId),
    onSuccess: async () => {
      showSuccess('Dataset successfully archived')
      await queryClient.invalidateQueries(BASE_QUERY_KEY, {
        refetchInactive: true
      })
    }
  })

  const unArchiveDatasetMutation = useMutation({
    mutationFn: () => productionProfilesClient.unArchiveDataset(datasetId),
    onSuccess: async () => {
      showSuccess('Dataset successfully unarchived')
      await queryClient.invalidateQueries(BASE_QUERY_KEY, {
        refetchInactive: true
      })
    }
  })

  return {
    datasetInfo: datasetInfoQuery.data,
    allDatasetNames: allDatasetNames,
    isFetching: datasetInfoQuery.isFetching,
    updateDataset: updateDatasetMutation.mutate,
    isUpdateDatasetInProgress: updateDatasetMutation.isLoading,
    cloneDataset: (datasetName: string, onSuccess?: () => void) =>
      cloneDatasetMutation.mutate(datasetName, { onSuccess }),
    isCloneDatasetInProgress: cloneDatasetMutation.isLoading,
    removeDataset: removeDatasetMutation.mutate,
    lockDataset: lockDatasetMutation.mutate,
    archiveDataset: archiveDatasetMutation.mutate,
    unArchiveDataset: unArchiveDatasetMutation.mutate,
    getDownloadFileUrl: productionProfilesClient.getDownloadFileUrl
  }
}
