import axios from 'axios'
import { PredictionLogRequest, PredictionLogResponseItem } from '@/interfaces'

const baseUrl = '/api/scenarios/predictionLog'
export const scenariosCalculationLogsClient = {
  QUERY_KEY: ['scenarios', 'predictionLogs'],
  getLogs: (page: number = 0, pageSize: number, filterText?: string) =>
    axios
      .post<PredictionLogResponseItem[]>(baseUrl, {
        pageNumber: page,
        pageCount: pageSize,
        filterText: filterText
      } as PredictionLogRequest)
      .then(x => x.data)
}
