import axios from 'axios'

import {
  AddDatasetRequest,
  CloneDatasetRequest,
  DatasetSimple,
  OtherBessInputsSimple,
  UpdateOtherBessInputsDatasetRequest
} from '@/interfaces'

const baseUrl = '/api/library/otherBessInputs'
export const otherBessInputsLibraryClient = {
  QUERY_KEY: ['library', 'bessInputs'],
  getAll: (lockedOnly?: boolean) =>
    axios
      .get<DatasetSimple[]>(baseUrl, { params: { locked: lockedOnly } })
      .then(x => x.data),
  getDetails: (datasetId: number) =>
    axios
      .get<OtherBessInputsSimple>(`${baseUrl}/${datasetId}`)
      .then(x => x.data),
  addNewDataset: (req: AddDatasetRequest) =>
    axios.post<number>(baseUrl, req).then(x => x.data),
  removeDataset: (datasetId: number) =>
    axios.delete<number>(`${baseUrl}/${datasetId}`).then(x => x.data),
  lockDataset: (datasetId: number) =>
    axios.post<void>(`${baseUrl}/lockDataset/${datasetId}`).then(x => x.data),
  archiveDataset: (datasetId: number) =>
    axios
      .post<void>(`${baseUrl}/archiveDataset/${datasetId}`)
      .then(x => x.data),
  unArchiveDataset: (datasetId: number) =>
    axios
      .post<void>(`${baseUrl}/unArchiveDataset/${datasetId}`)
      .then(x => x.data),
  cloneDataset: ({
    datasetId,
    ...rest
  }: { datasetId: number } & CloneDatasetRequest) =>
    axios
      .post<number>(`${baseUrl}/cloneDataset/${datasetId}`, rest)
      .then(x => x.data),
  updateDataset: (
    datasetId: number,
    req: UpdateOtherBessInputsDatasetRequest
  ) => axios.putForm<void>(`${baseUrl}/${datasetId}`, req),
  removeDatasetFile: (datasetFileId: number) =>
    axios.delete<void>(`${baseUrl}/file/${datasetFileId}`),
  getDownloadFileUrl: (fileId: number) => `${baseUrl}/File/${fileId}`
}
