import { useState } from 'react'
import { Box, Heading, Notification, Switch } from '@veracity/vui'

import { PageLoader } from '@/components'
import { SystemConfigurations } from '@/interfaces'

import { ScenarioResultsAggregatesTabPreview } from './ScenarioResultsAggregatesTabPreview'
import { useAggregatedSummary } from '../../hooks'

interface Props {
  scenarioId: number
  scenarioConfiguration: SystemConfigurations
}

export const ScenarioResultsAggregatesTab = ({
  scenarioId,
  scenarioConfiguration
}: Props) => {
  const [isAnnuallySelected, setIsAnnuallySelected] = useState(true)

  const { sections, dateSection, isFetching, areResultsEmpty } =
    useAggregatedSummary(scenarioId, scenarioConfiguration, isAnnuallySelected)

  if (areResultsEmpty)
    return (
      <Notification
        status="warning"
        title="Data Unavailable"
        text="Apologies, results are unavailable due to backward incompatibility."
        w="100%"
      />
    )

  return (
    <Box column p={2} gap={3} bg="white">
      <Box w="100%" justify="center">
        <Switch
          labelLeft="Monthly"
          labelRight="Annually"
          checked={isAnnuallySelected}
          onChange={ev => setIsAnnuallySelected(ev.target.checked)}
        />
      </Box>

      {isFetching ? (
        <PageLoader />
      ) : (
        <>
          <Heading size="h5">
            Dispatch results summary (
            {isAnnuallySelected ? 'Annually' : 'Monthly'})
          </Heading>

          <ScenarioResultsAggregatesTabPreview
            sections={sections}
            dateSection={dateSection}
          />
        </>
      )}
    </Box>
  )
}
