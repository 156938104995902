import { lazy, Suspense, useEffect, useMemo, useState } from 'react'
import {
  Box,
  Button,
  DatePicker,
  formatDate,
  Heading,
  Select
} from '@veracity/vui'

import { convertDateToLocal, convertDateToUTC } from '@/utils'
import { PageLoader, RadioButtons, Slider } from '@/components'
import {
  ScenarioTimeseriesChartItemSimple,
  SystemConfigurations
} from '@/interfaces'

const ScenarioResultsTimeSeriesTabCharts = lazy(
  () => import('./ScenarioResultsTimeSeriesTabCharts')
)

interface Props {
  allowedYears: number[]
  selectedDate?: Date
  onSelectedDateChanged: (date?: Date) => void
  onSelectedYearChanged: (year: number) => void
  isCollapsedView: boolean
  onToggleIsCollapsedView: () => void
  isFetchingData: boolean
  timeSeriesData: ScenarioTimeseriesChartItemSimple[]
  scenarioConfiguration?: SystemConfigurations
}

export const ScenarioResultsTimeSeriesContent = ({
  allowedYears,
  selectedDate,
  onSelectedDateChanged,
  onSelectedYearChanged,
  isCollapsedView,
  onToggleIsCollapsedView,
  isFetchingData,
  timeSeriesData,
  scenarioConfiguration
}: Props) => {
  const [isGraphActive, setIsGraphActive] = useState(true)

  const selectedYear = selectedDate?.getFullYear()

  const datepickerBoundaries = useMemo(() => {
    const startYear = allowedYears[0]
    const endYear = allowedYears[allowedYears.length - 1]

    return {
      startDate: convertDateToLocal(new Date(startYear, 0, 1)),
      endDate: convertDateToLocal(new Date(endYear, 11, 31))
    }
  }, [allowedYears])

  return (
    <Box column gap={2} maxH="90vh">
      <Box justify="space-between" p={2} bg="white">
        <Box gap={1} align="center">
          <Heading size="h6">Start date</Heading>
          <DatePicker
            selectedDate={convertDateToLocal(selectedDate)}
            onSelected={ev => onSelectedDateChanged(convertDateToUTC(ev))}
            boundaries={datepickerBoundaries}>
            <Button isDropDown variant="tertiaryDark">
              {selectedDate
                ? formatDate(selectedDate, 'DD MMM YYYY')
                : 'Select date'}
            </Button>
          </DatePicker>
        </Box>

        <RadioButtons<boolean>
          value={isGraphActive}
          onChange={setIsGraphActive}
          items={[
            { label: 'Graph', value: true },
            { label: 'Table', value: false }
          ]}
        />

        <Button
          variant="secondaryDark"
          onClick={onToggleIsCollapsedView}
          iconLeft={isCollapsedView ? 'falExpandAlt' : 'falCompressAlt'}>
          {isCollapsedView ? 'Expand' : 'Collapse'}
        </Button>
      </Box>
      <Box bg="white" p={1} gap={1} maxH="100%">
        <Box column gap={1} pl={1} w={100}>
          <Heading size="h6">Year</Heading>
          <Select
            value={selectedYear}
            onChange={ev => onSelectedYearChanged(+ev.target.value)}
            options={allowedYears.map(year => ({
              text: `${year}`,
              value: year
            }))}
          />
          <Heading size="h6">{formatDate(selectedDate, 'DD MMM')}</Heading>
          {!!selectedYear && (
            <Slider
              value={selectedDate}
              onChange={onSelectedDateChanged}
              year={selectedYear}
            />
          )}
        </Box>
        {isFetchingData ? (
          <PageLoader />
        ) : (
          <Suspense fallback={<PageLoader />}>
            <Box column w="100%">
              <ScenarioResultsTimeSeriesTabCharts
                data={timeSeriesData}
                isGraphActive={isGraphActive}
                systemConfiguration={scenarioConfiguration}
              />
            </Box>
          </Suspense>
        )}
      </Box>
    </Box>
  )
}
