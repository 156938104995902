import { useRef } from 'react'
import { Box, Icon, T, v } from '@veracity/vui'
import styled from 'styled-components'

const FileUploaderContent = styled(Box)<{ disabled?: boolean }>`
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};

  :hover {
    background: ${props => (props.disabled ? 'inherit' : '#d3eef8')};
  }
`

interface Props {
  label: string
  accept: string
  disabled?: boolean
  onFilesAdded: (files: File[]) => void
}

export const FileUploader = ({
  label,
  accept,
  disabled,
  onFilesAdded
}: Props) => {
  const fileInputRef = useRef<HTMLInputElement>(null)

  const handleClick = () => !disabled && fileInputRef.current?.click()

  return (
    <Box bg="inherit" onClick={handleClick}>
      <FileUploaderContent disabled={disabled} py="5px" gap={2}>
        <Icon name="falCloudUpload" size="sm" pathFill="seaBlue.28" />
        <T color="seaBlue.28"> {label}</T>
      </FileUploaderContent>
      <v.input
        type="file"
        accept={accept}
        display="none"
        ref={fileInputRef}
        onChange={ev => onFilesAdded((ev.target as any).files)}
      />
    </Box>
  )
}
