import { useQuery, useQueryClient } from 'react-query'

import { config } from '@/config'
import { projectsClient, scenariosClient } from '@/api'

export const useScenarioCalculationProgress = (scenarioId: number) => {
  const BASE_QUERY_KEY = scenariosClient.QUERY_KEY(scenarioId)
  const QUERY_KEY = [...BASE_QUERY_KEY, 'progress']

  const queryClient = useQueryClient()

  const query = useQuery(
    QUERY_KEY,
    () => scenariosClient.getCalculationProgress(scenarioId),
    {
      refetchInterval: config.requestIntervals.scenarioCalculations,
      refetchOnMount: true,
      onSuccess: async data => {
        if (!data.isCalculating) {
          queryClient.removeQueries(QUERY_KEY)
          await queryClient.invalidateQueries(projectsClient.QUERY_KEY, {
            refetchInactive: true
          })
          await queryClient.invalidateQueries(BASE_QUERY_KEY, {
            refetchInactive: true
          })
        }
      }
    }
  )

  return {
    data: query.data
  }
}
