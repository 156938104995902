import { Box, T, Tag } from '@veracity/vui'

interface Props {
  selectedPricingPlanText?: string
  onPricingPlanClearClicked: () => void

  selectedCreationDateText: string
  onCreationDateClearClicked: () => void

  selectedSubscriptionExpirationDateText: string
  onSelectedSubscriptionDateClearClicked: () => void
}

export const OrganizationsFiltersPanelPreview = ({
  selectedPricingPlanText,
  onPricingPlanClearClicked,
  selectedCreationDateText,
  onCreationDateClearClicked,
  selectedSubscriptionExpirationDateText,
  onSelectedSubscriptionDateClearClicked
}: Props) => {
  return (
    <Box py={2} gap={2}>
      <T variant="label">Applied filters:</T>
      {selectedPricingPlanText ? (
        <Tag
          text={`Pricing plan: ${selectedPricingPlanText}`}
          onDelete={onPricingPlanClearClicked}
        />
      ) : null}
      {selectedCreationDateText ? (
        <Tag
          text={`Date created: ${selectedCreationDateText}`}
          onDelete={onCreationDateClearClicked}
        />
      ) : null}
      {selectedSubscriptionExpirationDateText ? (
        <Tag
          text={`Sub exp date: ${selectedSubscriptionExpirationDateText}`}
          onDelete={onSelectedSubscriptionDateClearClicked}
        />
      ) : null}
    </Box>
  )
}
