import { useMemo } from 'react'
import { Box } from '@veracity/vui'
import { Control } from 'react-hook-form'

import { LibraryDatasetConfigurationAlignment } from '@/components'
import { OtherBessInputsSimple, SystemConfigurations } from '@/interfaces'

import { OtherBessInputsDetailsForm } from './OtherBessInputsDetailsForm'

interface Props {
  formControl: Control
  isFormDisabled: boolean
  getDownloadFileUrl: (datasetFileId?: number) => string | undefined
  datasetInfo: OtherBessInputsSimple
  isPercentageChainingFileSelected: boolean
  isFormValid: boolean
}

export const OtherBessInputsDetailsPreview = ({
  formControl,
  isFormDisabled,
  getDownloadFileUrl,
  datasetInfo,
  isPercentageChainingFileSelected,
  isFormValid
}: Props) => {
  const allowedSystemConfigurations = useMemo(() => {
    if (isFormValid && !isPercentageChainingFileSelected)
      return [SystemConfigurations.Standalone]
    else if (isFormValid && isPercentageChainingFileSelected)
      return [
        SystemConfigurations.Standalone,
        SystemConfigurations.SolarPV,
        SystemConfigurations.SolarPVOnSharedBus,
        SystemConfigurations.WindOnShareBus,
        SystemConfigurations.SolarPVAndAcCoupledWithWind,
        SystemConfigurations.SolarPVAndWind
      ]
    else return []
  }, [isPercentageChainingFileSelected, isFormValid])

  return (
    <Box column gap={5}>
      <OtherBessInputsDetailsForm
        formControl={formControl}
        isFormDisabled={isFormDisabled}
        getDownloadFileUrl={getDownloadFileUrl}
        datasetInfo={datasetInfo}
      />

      <LibraryDatasetConfigurationAlignment
        allowedSystemConfigurations={allowedSystemConfigurations}
      />
    </Box>
  )
}
