import { Link } from 'react-router-dom'
import {
  Box,
  Button,
  Card,
  Definition,
  Divider,
  formatDate,
  Grid,
  Heading
} from '@veracity/vui'

import { CalculationStates, PredictionLogResponseItem } from '@/interfaces'

import { LogItemStatusBadge } from './LogItemStatusBadge'

interface Props {
  logData: PredictionLogResponseItem
}

export const LogItemCard = ({ logData }: Props) => (
  <Card p={1} column w="100%" isInteractive>
    <Grid gridTemplateColumns="10fr 1fr 1fr" gap={2}>
      <Heading w="70%" isTruncated size="h5" title={logData.scenarioName}>
        {logData.scenarioName}
      </Heading>

      <Link to={`/projects/${logData.projectId}`}>
        <Button variant="tertiaryDark">Open Project</Button>
      </Link>

      {logData.calculationState === CalculationStates.Finished && (
        <Link
          to={`/projects/${logData.projectId}/scenarios/${logData.scenarioId}/results`}>
          <Button variant="primaryDark">View Results</Button>
        </Link>
      )}
    </Grid>

    <Box py={1}>
      <LogItemStatusBadge status={logData.calculationState} />
    </Box>

    <Divider />

    <Definition
      isLabelBold
      orientation="vertical"
      items={[
        { key: 'Project', value: logData.projectName || '' },
        { key: 'Run Date', value: formatDate(logData.runDate, 'DD MM YYYY') }
      ]}
    />
  </Card>
)
