import axios from 'axios'

import { ScenarioCompareDetails, ScenarioCompareSimple } from '@/interfaces'

const baseUrl = '/api/Scenarios'
export const scenarioComparisonClient = {
  QUERY_KEY: ['projects', 'scenarios', 'comparison'],
  getComparison: (scenarioIds: number[]) =>
    axios
      .get<ScenarioCompareSimple[]>(`${baseUrl}/Compare`, {
        params: { scenarioIds },
        paramsSerializer: () =>
          scenarioIds.map(x => `scenarioIds=${x}`).join('&')
      })
      .then(x => x.data),
  getDetailedComparison: (scenarioIds: number[]) =>
    axios
      .get<ScenarioCompareDetails>(`${baseUrl}/CompareDetails`, {
        params: { scenarioIds },
        paramsSerializer: () =>
          scenarioIds.map(x => `scenarioIds=${x}`).join('&')
      })
      .then(x => x.data),
  downloadExportFile: (scenarioIds: number[]) =>
    axios
      .get<Blob>(`${baseUrl}/CompareExport`, {
        responseType: 'blob',
        params: { scenarioIds },
        paramsSerializer: () =>
          scenarioIds.map(x => `scenarioIds=${x}`).join('&')
      })
      .then(x => x.data)
}
