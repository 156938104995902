import { useNavigate } from 'react-router-dom'
import { useToast } from '@veracity/vui'
import { useMutation, useQuery, useQueryClient } from 'react-query'

import { config } from '@/config'
import { lossFactorsClient } from '@/api'
import { UpdateLossFactorsDatasetRequest } from '@/interfaces'

const BASE_ROUTE = config.links.library.lossFactors
const BASE_QUERY_KEY = lossFactorsClient.QUERY_KEY

export const useLossFactorsDetails = (datasetId: number) => {
  const QUERY_KEY = [...BASE_QUERY_KEY, datasetId]

  const navigate = useNavigate()
  const { showSuccess } = useToast()
  const queryClient = useQueryClient()

  const allDatasets = useQuery([...BASE_QUERY_KEY, { lockedOnly: false }], () =>
    lossFactorsClient.getAll()
  )

  const allDatasetNames =
    allDatasets.data?.map(x => x.name || '').filter(Boolean) || []

  const datasetInfoQuery = useQuery(
    QUERY_KEY,
    () => lossFactorsClient.getById(datasetId),
    { enabled: !!datasetId }
  )

  const updateDatasetMutation = useMutation({
    mutationFn: (req: UpdateLossFactorsDatasetRequest) =>
      lossFactorsClient.updateDataset(datasetId, req),
    onSuccess: async () => {
      showSuccess('Dataset successfully updated')
      await queryClient.invalidateQueries(QUERY_KEY)
    }
  })

  const cloneDatasetMutation = useMutation({
    mutationFn: (datasetName: string) =>
      lossFactorsClient.cloneDataset({ datasetId, name: datasetName }),
    onSuccess: async clonedDatasetId => {
      showSuccess('Dataset successfully cloned')
      navigate(`${BASE_ROUTE}/${clonedDatasetId}`)
      await queryClient.invalidateQueries(BASE_QUERY_KEY, {
        refetchInactive: true
      })
    }
  })

  const cloneDataset = (datasetName: string, onSuccess?: () => void) =>
    cloneDatasetMutation.mutate(datasetName, { onSuccess })

  const removeDatasetMutation = useMutation({
    mutationFn: () => lossFactorsClient.removeDataset(datasetId),
    onSuccess: async () => {
      showSuccess('Dataset successfully removed')
      navigate(BASE_ROUTE)
      await queryClient.invalidateQueries(BASE_QUERY_KEY, {
        refetchInactive: true
      })
    }
  })

  const lockDatasetMutation = useMutation({
    mutationFn: () => lossFactorsClient.lockDataset(datasetId),
    onSuccess: async () => {
      showSuccess('Dataset successfully locked')
      await queryClient.invalidateQueries(BASE_QUERY_KEY, {
        refetchInactive: true
      })
    }
  })

  const archiveDatasetMutation = useMutation({
    mutationFn: () => lossFactorsClient.archiveDataset(datasetId),
    onSuccess: async () => {
      showSuccess('Dataset successfully archived')
      await queryClient.invalidateQueries(BASE_QUERY_KEY, {
        refetchInactive: true
      })
    }
  })

  const unArchiveDatasetMutation = useMutation({
    mutationFn: () => lossFactorsClient.unArchiveDataset(datasetId),
    onSuccess: async () => {
      showSuccess('Dataset successfully unarchived')
      await queryClient.invalidateQueries(BASE_QUERY_KEY, {
        refetchInactive: true
      })
    }
  })

  return {
    datasetInfo: datasetInfoQuery.data,
    allDatasetNames: allDatasetNames,
    isFetching: datasetInfoQuery.isFetching,
    updateDataset: updateDatasetMutation.mutate,
    isUpdateDatasetInProgress: updateDatasetMutation.isLoading,
    cloneDataset: cloneDataset,
    isCloneDatasetInProgress: cloneDatasetMutation.isLoading,
    removeDataset: removeDatasetMutation.mutate,
    lockDataset: lockDatasetMutation.mutate,
    archiveDataset: archiveDatasetMutation.mutate,
    unArchiveDataset: unArchiveDatasetMutation.mutate
  }
}
