import { useToast } from '@veracity/vui'
import { useNavigate } from 'react-router-dom'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { projectsClient } from '@/api'
import { config } from '@/config'

const BASE_ROUTE = '/projects'
const BASE_QUERY_KEY = projectsClient.QUERY_KEY

export const useProjectsList = () => {
  const { showSuccess } = useToast()
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const projectsQuery = useQuery(BASE_QUERY_KEY, projectsClient.getAll, {
    refetchInterval: config.requestIntervals.scenarioCalculations
  })

  const addProjectsMutation = useMutation({
    mutationFn: projectsClient.addNewProject,
    onSuccess: async (projectId: number) => {
      await queryClient.invalidateQueries(BASE_QUERY_KEY)
      showSuccess('Project successfully created')
      navigate(`${BASE_ROUTE}/${projectId}`)
    }
  })

  return {
    projects: projectsQuery.data || [],
    isFetching: projectsQuery.isFetching && !projectsQuery.isRefetching,
    addProject: addProjectsMutation.mutate,
    isAddProjectInProgress: addProjectsMutation.isLoading
  }
}
