import axios from 'axios'

import { ApiKeySimple } from '@/interfaces'

const baseUrl = '/api/apiKey'
export const apiKeyClient = {
  QUERY_KEY: ['apiKey', 'internal'],
  getApiKeyInfo: () => axios.get<ApiKeySimple>(baseUrl).then(x => x.data),
  reactivateApiKey: () =>
    axios.post<void>(`${baseUrl}/generate`).then(x => x.data)
}
