import { useMemo } from 'react'
import moment from 'moment/moment'

import { ScenarioSimple } from '@/interfaces'

import { ProjectScenarioFilterValues } from '../types'

export const useProjectScenarioFilter = (
  allScenarios: ScenarioSimple[],
  queryText?: string,
  filterProps?: ProjectScenarioFilterValues
) => {
  const filterByText = (scenarios: ScenarioSimple[], query: string) =>
    scenarios.filter(
      f =>
        f?.name?.toLocaleLowerCase()?.includes(query) ||
        f?.createdBy?.toLocaleLowerCase()?.includes(query)
    )

  const filterByAdvancedFilters = (
    scenarios: ScenarioSimple[],
    projectFilterValues: ProjectScenarioFilterValues
  ) =>
    scenarios
      ?.filter(
        scenario =>
          projectFilterValues.selectedCalculationState === undefined ||
          scenario.state === projectFilterValues.selectedCalculationState
      )
      .filter(
        scenario =>
          projectFilterValues.selectedSystemConfiguration === undefined ||
          scenario.systemConfiguration ===
            projectFilterValues.selectedSystemConfiguration
      )
      .filter(
        scenario =>
          !projectFilterValues.selectedCalculationStartDate ||
          moment(scenario.calculationStartDate).isSameOrAfter(
            projectFilterValues.selectedCalculationStartDate
          )
      )

  return useMemo(() => {
    let results = allScenarios || []

    if (!!queryText)
      results = filterByText(results, queryText.toLocaleLowerCase())
    if (!!filterProps) results = filterByAdvancedFilters(results, filterProps)

    return results
  }, [allScenarios, filterProps, queryText])
}
