import { useParams } from 'react-router-dom'

import { config } from '@/config'
import { DatasetDetailsOperationBar, PageContainer } from '@/components'

import { OtherBessInputsDetailsPreview } from './components'
import {
  useOtherBessInputDatasetDetails,
  useOtherBessInputDatasetForm,
  useOtherBessInputsDatasetNames
} from './hooks'

export const OtherBessInputsDetailsPage = () => {
  const { id: datasetIdString } = useParams()
  const datasetId = +(datasetIdString || '')
  const allDatasetNames = useOtherBessInputsDatasetNames()
  const {
    datasetInfo,
    isLoadingDatasetInfo,
    getDownloadFileUrl,
    updateDataset,
    archiveDataset,
    unArchiveDataset,
    removeDataset,
    lockDataset,
    cloneDataset,
    isUpdateDatasetInProgress,
    isCloneDatasetInProgress
  } = useOtherBessInputDatasetDetails(datasetId)

  const {
    isFormValid,
    formControl,
    isFormDisabled,
    isFormTouched,
    handleSubmitForm,
    isPercentageChainingFileSelected
  } = useOtherBessInputDatasetForm(updateDataset, datasetInfo)

  return (
    <PageContainer
      loading={isLoadingDatasetInfo}
      header={
        <PageContainer.Breadcrumbs
          items={[
            { title: 'Library' },
            {
              title: 'Other BESS Inputs',
              path: config.links.library.otherBessInputs
            },
            { title: datasetInfo?.name || '' }
          ]}
        />
      }
      menuBar={
        !datasetInfo ? null : (
          <DatasetDetailsOperationBar
            datasetInfo={datasetInfo}
            onArchiveDataset={archiveDataset}
            onUnArchiveDataset={unArchiveDataset}
            onLockDataset={lockDataset}
            onRemoveDataset={removeDataset}
            onCloneDataset={cloneDataset}
            isCloneDatasetInProgress={isCloneDatasetInProgress}
            allDatasetNames={allDatasetNames}
            isLockDisabled={!isFormValid || isFormTouched}
            saveButton={{
              onClick: handleSubmitForm,
              isDisabled:
                !isFormTouched || !isFormValid || isUpdateDatasetInProgress,
              isLoading: isUpdateDatasetInProgress
            }}
          />
        )
      }>
      {!!datasetInfo && (
        <OtherBessInputsDetailsPreview
          formControl={formControl}
          isFormValid={isFormValid}
          datasetInfo={datasetInfo}
          isFormDisabled={isFormDisabled}
          getDownloadFileUrl={getDownloadFileUrl}
          isPercentageChainingFileSelected={isPercentageChainingFileSelected}
        />
      )}
    </PageContainer>
  )
}
