import { useState } from 'react'
import { Accordion } from '@veracity/vui'

import { ReleaseNoteModel } from '@/interfaces'
import { ReactMarkdownWrapper } from '@/components'

interface Props {
  items?: ReleaseNoteModel[]
}

export const ReleaseNotesAccordion = ({ items }: Props) => {
  const [expandedItemId, setExpandedItemId] = useState(0)

  return (
    <Accordion w="100%">
      {items?.map((item, index) => (
        <Accordion.Item
          key={index}
          id={index}
          title={`v${item.major}.${item.minor}.${item.bugFix}`}
          collapsed={index !== expandedItemId}
          onToggle={() => setExpandedItemId(index)}>
          <ReactMarkdownWrapper content={item.content || ''} />
        </Accordion.Item>
      ))}
    </Accordion>
  )
}
