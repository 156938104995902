import { useQuery } from 'react-query'
import { Box, formatDate } from '@veracity/vui'

import {
  PageContainer,
  UserAdministrationPageMenu,
  UserAdministrationSection
} from '@/components'
import { useCurrentUser } from '@/hooks'
import { apiKeyClient } from '@/api'

import { ProfilePageInfoSection } from './components'

export const ProfilePage = () => {
  const { currentUser, isAdmin } = useCurrentUser()
  const { data: apiKeyInfo } = useQuery(
    apiKeyClient.QUERY_KEY,
    apiKeyClient.getApiKeyInfo
  )

  return (
    <PageContainer
      header={<PageContainer.Breadcrumbs items={[{ title: 'Profile' }]} />}
      menuBar={
        <UserAdministrationPageMenu
          isAdmin={isAdmin}
          activeSection={UserAdministrationSection.USER_PROFILE}
        />
      }>
      <Box column gap={2} bg="white" w="45rem" p={2}>
        <ProfilePageInfoSection
          title="User"
          items={[
            {
              label: 'Name',
              value: `${currentUser?.firstName} ${currentUser?.lastName}`
            },
            { label: 'Email', value: currentUser?.email || '-' },
            { label: 'Role', value: currentUser?.roles?.[0]?.name || '-' }
          ]}
        />

        <ProfilePageInfoSection
          title="Company"
          items={[
            { label: 'Name', value: currentUser?.organization?.name || '' },
            {
              label: 'Pricing plan',
              value: currentUser?.organization?.pricePlanName || ''
            },
            {
              label: 'Subscription Expires',
              value: formatDate(
                currentUser?.organization?.subscriptionExpirationDate,
                'DD MMM YYYY',
                { locales: 'en-US' }
              )
            },
            {
              label: 'Monthly Calculations',
              value: `${currentUser?.organization?.actuallyCalculationRequests}/${currentUser?.organization?.maxCalculationRequests}`
            },
            {
              label: 'Parallel Calculations',
              value: `${currentUser?.organization?.actuallyParallelCalculations}/${currentUser?.organization?.maxParallelCalculations}`
            }
          ]}
        />

        {!currentUser?.organization?.hasAccessToApi || !apiKeyInfo ? null : (
          <ProfilePageInfoSection
            title="API"
            items={[
              { label: 'Key', value: apiKeyInfo.key, isSecret: true },
              { label: 'IsActive', value: apiKeyInfo.isActive ? 'Yes' : 'No' },
              {
                label: 'Expires',
                value: formatDate(apiKeyInfo.expirationDate, 'DD MMM YYYY')
              }
            ]}
          />
        )}
      </Box>
    </PageContainer>
  )
}
