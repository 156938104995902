import { Box, Grid, T } from '@veracity/vui'
import { Control, useWatch } from 'react-hook-form'

import { OtherBessInputsSimple } from '@/interfaces'
import {
  FileUploadFormControl,
  SwitchFormControl,
  TooltipIcon
} from '@/components'

interface Props {
  formControl: Control
  isFormDisabled: boolean
  getDownloadFileUrl: (datasetFileId?: number) => string | undefined
  datasetInfo: OtherBessInputsSimple
}

export const OtherBessInputsDetailsForm = ({
  formControl,
  isFormDisabled,
  getDownloadFileUrl,
  datasetInfo
}: Props) => {
  const isShadowPriceChecked = useWatch({
    control: formControl,
    name: 'isShadowPriceChecked'
  })
  return (
    <Box column gap={2} maxW={800}>
      <Grid gridTemplateColumns="4fr 3fr" gap={2}>
        <Box gap={1} align="center">
          <SwitchFormControl
            control={formControl}
            formControlName="isShadowPriceChecked"
            disabled={isFormDisabled}
            size="sm"
          />

          <T>
            {isShadowPriceChecked
              ? 'Composite Shadow Price'
              : 'Annual Cycle Cap'}
          </T>

          <TooltipIcon.Help
            tooltipText={
              isShadowPriceChecked
                ? 'You can use this Composite Shadow Price to control storage cycles per year. Large composite shadow price means less cycles per year. Default value is $15/MWh.'
                : 'You can use this Annual Cycle Cap to control storage cycles.'
            }
          />
        </Box>

        {isShadowPriceChecked ? (
          <FileUploadFormControl
            control={formControl}
            formControlName="shadowPriceFile"
            disabled={isFormDisabled}
            downloadFileUrl={getDownloadFileUrl(datasetInfo.shadowPriceFileId)}
            uploadedFileName={datasetInfo.shadowPriceFileName}
            rules={{ required: !datasetInfo.shadowPriceFileId }}
          />
        ) : (
          <FileUploadFormControl
            control={formControl}
            formControlName="annualCycleCapFile"
            disabled={isFormDisabled}
            downloadFileUrl={getDownloadFileUrl(
              datasetInfo.annualCycleCapFileId
            )}
            uploadedFileName={datasetInfo.annualCycleCapFileName}
            rules={{ required: !datasetInfo.annualCycleCapFileId }}
          />
        )}

        <Box gap={2}>
          <T>Augmentation/Replacement Capacity</T>
          <TooltipIcon.Help tooltipText="You can define augmentation and replacement capacity schedule in MWh." />
        </Box>

        <FileUploadFormControl
          control={formControl}
          formControlName="augmentationCapacityFile"
          disabled={isFormDisabled}
          downloadFileUrl={getDownloadFileUrl(
            datasetInfo.augmentationCapacityFileId
          )}
          uploadedFileName={datasetInfo.augmentationCapacityFileName}
          rules={{ required: !datasetInfo.augmentationCapacityFileId }}
        />

        <Box gap={2}>
          <T>Capacity Retention</T>
          <TooltipIcon.Help tooltipText="You can upload the storage warranty degradation from the manufacturer. The value is a floating number 0–1. “0” means no energy capacity can be used to participate in the merchant market. “1” means 100% of energy capacity can be used to participate in the merchant market." />
        </Box>

        <FileUploadFormControl
          control={formControl}
          formControlName="capacityRetentionFile"
          disabled={isFormDisabled}
          downloadFileUrl={getDownloadFileUrl(
            datasetInfo.capacityRetentionFileId
          )}
          uploadedFileName={datasetInfo.capacityRetentionFileName}
          rules={{ required: !datasetInfo.capacityRetentionFileId }}
        />

        <Box gap={2}>
          <T>Percentage Charging from Renewable</T>
          <TooltipIcon.Help tooltipText="If storage wants to be qualified for ITC incentive, storage needs to be 100% charged from renewables for the first 5 years. In this file, you can define the percentage charge from renewables. The value is floating numbers 0–1. “0” means no need to charge from renewables. “1” means 100% charge from renewables." />
        </Box>

        <FileUploadFormControl
          control={formControl}
          formControlName="percentageChargingFromRenewableFile"
          disabled={isFormDisabled}
          downloadFileUrl={getDownloadFileUrl(
            datasetInfo.percentageChargingFromRenewableFileId
          )}
          uploadedFileName={datasetInfo.percentageChargingFromRenewableFileName}
          rules={{ required: false }}
        />
      </Grid>
    </Box>
  )
}
