import { useParams } from 'react-router-dom'

import { PageContainer } from '@/components'

import { ScenarioDetailsContent, ScenarioDetailsMenuBar } from './components'
import { useScenarioDetails, useScenarioDetailsForm } from './hooks'

export const ScenarioDetailsPage = () => {
  const { projectId: projectIdString, scenarioId: scenarioIdString } =
    useParams()
  const projectId = +(projectIdString || '')
  const scenarioId = +(scenarioIdString || '')

  const { scenarioInfo, isFetching, updateScenarioField } = useScenarioDetails(
    projectId,
    scenarioId
  )

  const { formControl, isFormDisabled, isFormValid } = useScenarioDetailsForm(
    scenarioInfo,
    updateScenarioField
  )

  return (
    <PageContainer
      loading={isFetching}
      header={
        <PageContainer.Breadcrumbs
          items={[
            { path: '/projects', title: 'Projects' },
            {
              path: `/projects/${projectId}`,
              title: scenarioInfo?.projectName || ''
            },
            { path: `/projects/${projectId}`, title: 'Scenarios' },
            { title: scenarioInfo?.name || '' }
          ]}
        />
      }
      menuBar={
        !!scenarioInfo && (
          <ScenarioDetailsMenuBar
            projectId={projectId}
            scenarioId={scenarioInfo.id}
            isScenarioValid={isFormValid}
          />
        )
      }>
      <ScenarioDetailsContent
        formControl={formControl}
        scenarioInfo={scenarioInfo}
        isFormDisabled={isFormDisabled}
      />
    </PageContainer>
  )
}
