import { Control } from 'react-hook-form'

import { SystemConfigurations } from '@/interfaces'

import { ScenarioDetailsContentDiagramFormStandalone } from './ScenarioDetailsContentDiagramFormStandalone'
import { ScenarioDetailsContentDiagramFormDcCoupledWithPV } from './ScenarioDetailsContentDiagramFormDcCoupledWithPV'
import { ScenarioDetailsContentDiagramFormAcCoupledWithPV } from './ScenarioDetailsContentDiagramFormAcCoupledWithPV'
import { ScenarioDetailsContentDiagramFormAcCoupledWithWind } from './ScenarioDetailsContentDiagramFormAcCoupledWithWind'
import { ScenarioDetailsFormValues } from '../../../types'

interface Props {
  scenarioId: number
  systemConfiguration?: SystemConfigurations
  formControl: Control<ScenarioDetailsFormValues>
  isFormDisabled: boolean
}

export const ScenarioDetailsContentDiagramForm = ({
  scenarioId,
  systemConfiguration,
  formControl,
  isFormDisabled
}: Props) => {
  switch (systemConfiguration) {
    case SystemConfigurations.Standalone:
      return (
        <ScenarioDetailsContentDiagramFormStandalone
          scenarioId={scenarioId}
          formControl={formControl}
          isFormDisabled={isFormDisabled}
        />
      )
    case SystemConfigurations.SolarPV:
      return (
        <ScenarioDetailsContentDiagramFormDcCoupledWithPV
          scenarioId={scenarioId}
          formControl={formControl}
          isFormDisabled={isFormDisabled}
        />
      )
    case SystemConfigurations.SolarPVOnSharedBus:
      return (
        <ScenarioDetailsContentDiagramFormAcCoupledWithPV
          scenarioId={scenarioId}
          formControl={formControl}
          isFormDisabled={isFormDisabled}
        />
      )
    case SystemConfigurations.WindOnShareBus:
      return (
        <ScenarioDetailsContentDiagramFormAcCoupledWithWind
          scenarioId={scenarioId}
          formControl={formControl}
          isFormDisabled={isFormDisabled}
        />
      )
    default:
      return null
  }
}
