import { useParams } from 'react-router-dom'

import { config } from '@/config'
import { DatasetDetailsOperationBar, PageContainer } from '@/components'

import { WholesaleMarketDetailsContent } from './components'
import {
  useMarketParticipationFilters,
  useWholesaleDataset,
  useWholesaleMarketDatasetNames
} from './hooks'

export const WholesaleMarketDetailsPage = () => {
  const { id } = useParams()
  const allDatasetNames = useWholesaleMarketDatasetNames()
  const {
    datasetInfo,
    isFetching,
    archiveDataset,
    unArchiveDataset,
    removeDataset,
    lockDataset,
    cloneDataset,
    isCloneDatasetInProgress
  } = useWholesaleDataset(+(id || ''))

  const { filters, treeSelectItems, isDatasetValid } =
    useMarketParticipationFilters(datasetInfo)

  const isDatasetDisabled =
    datasetInfo?.isLocked ||
    datasetInfo?.isDefault ||
    datasetInfo?.isArchive ||
    false

  return (
    <PageContainer
      loading={isFetching}
      header={
        <PageContainer.Breadcrumbs
          items={[
            { title: 'Library' },
            {
              title: 'Wholesale Market',
              path: config.links.library.wholesaleMarket
            },
            { title: datasetInfo?.name || '' }
          ]}
        />
      }
      menuBar={
        !datasetInfo ? null : (
          <DatasetDetailsOperationBar
            datasetInfo={datasetInfo}
            onArchiveDataset={archiveDataset}
            onUnArchiveDataset={unArchiveDataset}
            onLockDataset={lockDataset}
            onRemoveDataset={removeDataset}
            onCloneDataset={cloneDataset}
            isCloneDatasetInProgress={isCloneDatasetInProgress}
            allDatasetNames={allDatasetNames}
            isLockDisabled={!isDatasetValid}
          />
        )
      }>
      <WholesaleMarketDetailsContent
        filters={filters}
        datasetInfo={datasetInfo}
        isDatasetDisabled={isDatasetDisabled}
        treeSelectItems={treeSelectItems}
      />
    </PageContainer>
  )
}
