import { useInfiniteQuery } from 'react-query'

import { useDebouncedValue } from '@/hooks'
import { scenariosCalculationLogsClient } from '@/api'

const PAGE_SIZE = 10

export const useFetchScenarioCalculationLogs = (searchQuery: string) => {
  const searchQueryDebounced = useDebouncedValue(searchQuery, 500)

  const calculationLogsQuery = useInfiniteQuery(
    [
      scenariosCalculationLogsClient.QUERY_KEY,
      { searchQuery: searchQueryDebounced }
    ],
    ({ pageParam }) =>
      scenariosCalculationLogsClient.getLogs(
        pageParam,
        PAGE_SIZE,
        searchQueryDebounced
      ),
    {
      getNextPageParam: (lastPage, allPages) => allPages.length
    }
  )

  const allPagesItems =
    calculationLogsQuery.data?.pages.reduce((prev, next) => [
      ...prev,
      ...next
    ]) || []

  const hasMorePages = allPagesItems.length % PAGE_SIZE === 0

  return {
    hasMorePages:
      hasMorePages &&
      !calculationLogsQuery.isFetching &&
      !!allPagesItems.length,
    fetchNextPage: () => calculationLogsQuery.fetchNextPage(),
    isFetching: calculationLogsQuery.isFetching,
    allPagesItems
  }
}
