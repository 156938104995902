import { useState } from 'react'
import { Box, Button, Spinner } from '@veracity/vui'

import { PageContainer, PageSearchInput } from '@/components'

import { LogItemList } from './components'
import { useFetchScenarioCalculationLogs } from './hooks'

export const LogsPage = () => {
  const [searchValue, setSearchValue] = useState<string>('')

  const { fetchNextPage, hasMorePages, isFetching, allPagesItems } =
    useFetchScenarioCalculationLogs(searchValue)

  return (
    <PageContainer
      header={<PageContainer.Breadcrumbs items={[{ title: 'Log' }]} />}
      menuBar={<PageSearchInput onChange={setSearchValue} />}>
      <Box column gap={2} w="100%">
        <LogItemList logItems={allPagesItems} />
        {hasMorePages && (
          <Button w="100%" onClick={fetchNextPage}>
            Load more
          </Button>
        )}
      </Box>
      {isFetching && (
        <Box center w="100%" h={300}>
          <Spinner />
        </Box>
      )}
    </PageContainer>
  )
}
