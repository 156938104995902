import { useState } from 'react'
import { Box, Button, Dialog, Menu } from '@veracity/vui'
import { FieldValues, useForm } from 'react-hook-form'

import { AddScenarioRequest } from '@/interfaces'
import { InputFormControl } from '@/components'
import { useCurrentUser } from '@/hooks'

interface Props {
  onAddScenario: (
    req: Omit<AddScenarioRequest, 'projectId'>,
    onSuccess: () => void
  ) => void
  isAddScenarioInProgress: boolean
  validateScenarioName: (val: string) => boolean
  onBatchDesignerClicked: () => void
}

export const ProjectDetailsAddScenarioDialog = ({
  isAddScenarioInProgress,
  onAddScenario,
  validateScenarioName,
  onBatchDesignerClicked
}: Props) => {
  const [isVisible, setIsVisible] = useState(false)
  const { isInternal } = useCurrentUser()
  const { reset, handleSubmit, control } = useForm({
    defaultValues: { name: '' }
  })

  const handleSubmitClick = (val: FieldValues) =>
    onAddScenario({ name: val.name }, handleClose)

  const handleClose = () => {
    setIsVisible(false)
    reset()
  }

  const handleOpenDialog = () => setIsVisible(true)

  return (
    <>
      {isInternal ? (
        <Menu size="lg">
          <Menu.Button
            isDropDown
            iconLeft="falPlus"
            size="lg"
            variant="primaryDark">
            Add Scenario
          </Menu.Button>
          <Menu.List>
            <Menu.Item onClick={handleOpenDialog}>Detailed Designer</Menu.Item>
            <Menu.Item onClick={onBatchDesignerClicked}>
              Batch Designer
            </Menu.Item>
          </Menu.List>
        </Menu>
      ) : (
        <Button iconLeft="falPlus" size="lg" onClick={handleOpenDialog}>
          Add Scenario
        </Button>
      )}

      <Dialog
        isOpen={isVisible}
        title="Add Scenario"
        size="lg"
        onClose={handleClose}
        cancelButton={{ text: 'Cancel', disabled: isAddScenarioInProgress }}
        submitButton={{
          text: 'Create',
          onClick: handleSubmit(handleSubmitClick),
          isLoading: isAddScenarioInProgress,
          disabled: isAddScenarioInProgress
        }}
        body={
          <Box column p={2} gap={2}>
            <InputFormControl
              control={control}
              formControlName="name"
              label="Name"
              disabled={isAddScenarioInProgress}
              rules={{ required: true, validate: validateScenarioName }}
            />
          </Box>
        }
      />
    </>
  )
}
