import { useEffect, useState } from 'react'
import { FieldValues, useForm } from 'react-hook-form'
import { Box, Button, Dialog } from '@veracity/vui'

import { InputFormControl } from '@/components'

interface Props {
  currentName: string
  onUpdateProject: (name: string, onSuccess: () => void) => void
  isUpdateProjectInProgress: boolean
}

export const ProjectDetailsUpdateDialog = ({
  currentName,
  onUpdateProject,
  isUpdateProjectInProgress
}: Props) => {
  const [isVisible, setIsVisible] = useState(false)
  const { reset, handleSubmit, control } = useForm({
    defaultValues: { name: '' }
  })

  useEffect(() => reset({ name: currentName }), [currentName, reset])

  const handleSubmitClick = (val: FieldValues) =>
    onUpdateProject(val.name, handleClose)

  const handleClose = () => {
    setIsVisible(false)
    reset({ name: currentName })
  }

  return (
    <>
      <Button
        icon="falPen"
        size="lg"
        variant="tertiaryDark"
        onClick={() => setIsVisible(true)}
      />

      <Dialog
        isOpen={isVisible}
        title="Update Project"
        size="lg"
        onClose={handleClose}
        cancelButton={{ text: 'Cancel', disabled: isUpdateProjectInProgress }}
        submitButton={{
          text: 'Update',
          onClick: handleSubmit(handleSubmitClick),
          isLoading: isUpdateProjectInProgress,
          disabled: isUpdateProjectInProgress
        }}
        body={
          <Box column p={2}>
            <InputFormControl
              control={control}
              formControlName="name"
              label="Name"
              disabled={isUpdateProjectInProgress}
              rules={{ required: true }}
            />
          </Box>
        }
      />
    </>
  )
}
