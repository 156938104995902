import { Link } from 'react-router-dom'
import { Grid } from '@veracity/vui'

import { ProjectSimple } from '@/interfaces'

import { PortfolioDetailsCollectionPreviewGridCard } from './PortfolioDetailsCollectionPreviewGridCard'

interface Props {
  projects?: ProjectSimple[]
}

export const PortfolioDetailsCollectionPreviewGrid = ({
  projects = []
}: Props) => (
  <Grid w="100%" gap={3} gridTemplateColumns={{ xl: 4, lg: 3, md: 2, sm: 1 }}>
    {projects?.map(project => (
      <Link
        key={project.id}
        to={`/projects/${project.id}`}
        className="text-decoration-none">
        <PortfolioDetailsCollectionPreviewGridCard project={project} />
      </Link>
    ))}
  </Grid>
)
