import { Controller } from 'react-hook-form'
import { DragAndDropProps } from '@veracity/vui'

import { CsvFileUploader } from '@/components'

import { BaseFormItemProps } from './BaseFormItem'

export interface FileUploadFormControlProps
  extends BaseFormItemProps,
    DragAndDropProps {
  tooltipText?: string
  downloadFileUrl?: string
  uploadedFileName?: string
}

export const FileUploadFormControl = ({
  label,
  formControlName,
  control,
  disabled,
  rules,
  tooltipText,
  downloadFileUrl,
  uploadedFileName
}: FileUploadFormControlProps) => {
  const getEventObject = ([file]: File[]) => ({ target: { value: file } })

  return (
    <Controller
      name={formControlName}
      control={control}
      rules={rules}
      render={({ field, fieldState }) => (
        <CsvFileUploader
          title={label}
          tooltipText={tooltipText}
          uploadedFileName={
            fieldState.isTouched ? field.value?.name : uploadedFileName
          }
          downloadFileUrl={
            fieldState.isTouched
              ? field.value
                ? URL.createObjectURL(field.value)
                : undefined
              : downloadFileUrl
          }
          isDisabled={disabled}
          onFileAdded={file => {
            field.onChange(getEventObject([file]))
            field.onBlur()
          }}
          onRemoveFileClicked={() => {
            field.onChange(getEventObject([]))
            field.onBlur()
          }}
        />
      )}
    />
  )
}
