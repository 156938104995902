import { useState } from 'react'
import { Box, Button, Dialog } from '@veracity/vui'

import { TreeSelect } from '@/components'

import { useDownloadResults } from '../hooks'
import { DownloadResultsSectionNames } from '../types'

interface Props {
  scenarioId: number
}

export const ScenarioResultsDownloadResultsDialog = ({ scenarioId }: Props) => {
  const [isVisible, setIsVisible] = useState(false)

  const [selectedSections, setSelectedSections] = useState<
    DownloadResultsSectionNames[]
  >([])

  const { isDownloading, downloadFile } = useDownloadResults(
    scenarioId,
    selectedSections
  )

  const areAllOptionsSelected =
    selectedSections.length === Object.keys(DownloadResultsSectionNames).length

  const handleClose = () => {
    if (isDownloading) return
    setIsVisible(false)
    resetAllOptions(false)
  }

  const handleChange = (
    isChecked: boolean,
    type: DownloadResultsSectionNames
  ) =>
    setSelectedSections(state => {
      const filteredState = state.filter(x => x !== type)
      return isChecked ? [...filteredState, type] : filteredState
    })

  const setAllOperationsOptions = (isChecked: boolean) => {
    handleChange(isChecked, DownloadResultsSectionNames.CASH_FLOWS)
    handleChange(isChecked, DownloadResultsSectionNames.INCOMES)
    handleChange(isChecked, DownloadResultsSectionNames.COSTS)
    handleChange(isChecked, DownloadResultsSectionNames.OPERATIONS)
  }

  const resetAllOptions = (isChecked: boolean) => {
    setAllOperationsOptions(isChecked)
    handleChange(isChecked, DownloadResultsSectionNames.EXCEL_SUMMARY_REPORT)
    handleChange(isChecked, DownloadResultsSectionNames.PDF_SUMMARY_REPORT)
  }

  const handleDownload = () =>
    downloadFile(undefined, { onSuccess: handleClose })

  return (
    <>
      <Button size="lg" onClick={() => setIsVisible(true)}>
        Download Results
      </Button>

      <Dialog
        isOpen={isVisible}
        title="Download Results"
        onClose={handleClose}
        cancelButton={{ text: 'Cancel', disabled: isDownloading }}
        submitButton={{
          text: 'Download',
          onClick: handleDownload,
          isLoading: isDownloading,
          disabled: !selectedSections.length || isDownloading
        }}
        body={
          <Box column p={2} pl={4}>
            <Box justify="right">
              <Button
                size="lg"
                variant="tertiaryDark"
                disabled={isDownloading}
                iconLeft={areAllOptionsSelected ? 'falTimes' : 'falCheck'}
                onClick={() => resetAllOptions(!areAllOptionsSelected)}>
                Select all
              </Button>
            </Box>

            <TreeSelect
              isDisabled={isDownloading}
              items={[
                {
                  label: 'Dispatch results (.zip)',
                  onChange: setAllOperationsOptions,
                  children: [
                    {
                      label: 'Cashflow',
                      checked: selectedSections.includes(
                        DownloadResultsSectionNames.CASH_FLOWS
                      ),
                      onChange: isChecked =>
                        handleChange(
                          isChecked,
                          DownloadResultsSectionNames.CASH_FLOWS
                        )
                    },
                    {
                      label: 'Income',
                      checked: selectedSections.includes(
                        DownloadResultsSectionNames.INCOMES
                      ),
                      onChange: isChecked =>
                        handleChange(
                          isChecked,
                          DownloadResultsSectionNames.INCOMES
                        )
                    },
                    {
                      label: 'Cost',
                      checked: selectedSections.includes(
                        DownloadResultsSectionNames.COSTS
                      ),
                      onChange: isChecked =>
                        handleChange(
                          isChecked,
                          DownloadResultsSectionNames.COSTS
                        )
                    },
                    {
                      label: 'Operation',
                      checked: selectedSections.includes(
                        DownloadResultsSectionNames.OPERATIONS
                      ),
                      onChange: isChecked =>
                        handleChange(
                          isChecked,
                          DownloadResultsSectionNames.OPERATIONS
                        )
                    }
                  ]
                },
                {
                  label: 'Word report (.docx)',
                  checked: selectedSections.includes(
                    DownloadResultsSectionNames.PDF_SUMMARY_REPORT
                  ),
                  onChange: isChecked =>
                    handleChange(
                      isChecked,
                      DownloadResultsSectionNames.PDF_SUMMARY_REPORT
                    )
                },
                {
                  label: 'Operating summary (.xlsx)',
                  checked: selectedSections.includes(
                    DownloadResultsSectionNames.EXCEL_SUMMARY_REPORT
                  ),
                  onChange: isChecked =>
                    handleChange(
                      isChecked,
                      DownloadResultsSectionNames.EXCEL_SUMMARY_REPORT
                    )
                }
              ]}
            />
          </Box>
        }
      />
    </>
  )
}
