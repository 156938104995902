import { Box, Icon, T } from '@veracity/vui'

import { CalculationStates } from '@/interfaces'

interface Props {
  status: CalculationStates
}
export const ProjectDetailsScenarioCardStatus = ({ status }: Props) => {
  let result

  switch (status) {
    case CalculationStates.Error:
      result = { status: 'Failed', iconName: 'falExclamationCircle' }
      break
    case CalculationStates.Finished:
      result = { status: 'Completed', iconName: 'falCheckCircle' }
      break
    case CalculationStates.Preprocessing:
    case CalculationStates.InProgress:
      result = { status: 'Processing', iconName: 'falHourglass' }
      break
    default:
      result = { status: 'New', iconName: 'falFile' }
      break
  }

  return (
    <Box gap={1}>
      <Icon name={result.iconName} pathFill="seaBlue.28" />
      <T>{result.status}</T>
    </Box>
  )
}
