import { Box, Heading } from '@veracity/vui'

import { ScenarioSimple } from '@/interfaces'

import { ProjectDetailsScenarioCard } from './ProjectDetailsScenarioCard'

interface Props {
  scenarios: ScenarioSimple[]
  validateNameDuplication: (val: string) => boolean
}
export const ProjectListScenariosCollection = ({
  scenarios,
  validateNameDuplication
}: Props) => {
  return (
    <Box column>
      <Heading size="h4" py={3}>
        Scenarios
      </Heading>

      <Box column gap={2}>
        {scenarios.map(scenario => (
          <ProjectDetailsScenarioCard
            key={scenario.id}
            scenarioInfo={scenario}
            validateNameDuplication={validateNameDuplication}
          />
        ))}
      </Box>
    </Box>
  )
}
