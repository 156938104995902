import { Box, Heading, T } from '@veracity/vui'

import { SecretText } from '@/components'

interface Props {
  title: string
  items: { label: string; value: string; isSecret?: boolean }[]
}
export const ProfilePageInfoSection = ({ title, items }: Props) => (
  <Box column gap={1} p={2}>
    <Heading size="h3">{title}</Heading>
    {items.map(item => (
      <Box key={item.label} justify="space-between">
        <T weight="medium" variant="caption">
          {item.label}
        </T>
        {item.isSecret ? (
          <SecretText secret={item.value} />
        ) : (
          <T variant="caption">{item.value}</T>
        )}
      </Box>
    ))}
  </Box>
)
