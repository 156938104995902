import { Box, Grid, T } from '@veracity/vui'

import {
  DatasetFileTypes,
  WholesaleMarketDatasetWithFilesSimple
} from '@/interfaces'

import { MarketParticipationFilters } from '../types'
import { WholesaleMarketDetailsUploadSectionUploader } from './WholesaleMarketDetailsUploadSectionUploader'
import { useIsSpainDataset } from '../hooks'

interface Props {
  isDatasetLocked: boolean
  filters: MarketParticipationFilters
  datasetInfo?: WholesaleMarketDatasetWithFilesSimple
}

export const WholesaleMarketDetailsUploadSection = ({
  isDatasetLocked,
  datasetInfo,
  filters
}: Props) => {
  const isSpainDataset = useIsSpainDataset(datasetInfo)

  return isSpainDataset ? (
    <Box column gap={2}>
      <T variant="label">Energy Market Prices</T>
      <WholesaleMarketDetailsUploadSectionUploader
        datasetId={datasetInfo?.id}
        fileType={DatasetFileTypes.DayAheadEnergyPrices}
        title="Day-Ahead Energy Market Prices"
        uploadedFile={datasetInfo?.dayAheadEnergyPricesFile}
        tooltipText="$/MWh hourly Locational Marginal Prices forecast on project electrical node from the Day-Ahead Market over the project life."
        isDisabled={!filters.hasDayAheadEnergy || isDatasetLocked}
      />

      <WholesaleMarketDetailsUploadSectionUploader
        datasetId={datasetInfo?.id}
        fileType={DatasetFileTypes.AFRREnergyDown}
        title="Energy Down (aFRR)"
        uploadedFile={datasetInfo?.afrrEnergyDownFile}
        tooltipText="Placeholder text"
        isDisabled={!filters.hasEnergyDownAFRR || isDatasetLocked}
      />

      <WholesaleMarketDetailsUploadSectionUploader
        datasetId={datasetInfo?.id}
        fileType={DatasetFileTypes.AFRREnergyUp}
        title="Energy Up (aFRR)"
        uploadedFile={datasetInfo?.afrrEnergyUpFile}
        tooltipText="Placeholder text"
        isDisabled={!filters.hasEnergyUpAFRR || isDatasetLocked}
      />

      <WholesaleMarketDetailsUploadSectionUploader
        datasetId={datasetInfo?.id}
        fileType={DatasetFileTypes.AFRRPower}
        title="Power (aFRR)"
        uploadedFile={datasetInfo?.afrrPowerFile}
        tooltipText="Placeholder text"
        isDisabled={!filters.hasPowerAFRR || isDatasetLocked}
      />

      <WholesaleMarketDetailsUploadSectionUploader
        datasetId={datasetInfo?.id}
        fileType={DatasetFileTypes.MFRREnergyDown}
        title="Energy Down (mFRR)"
        uploadedFile={datasetInfo?.mfrrEnergyDownFile}
        tooltipText="Placeholder text"
        isDisabled={!filters.hasEnergyDownMFRR || isDatasetLocked}
      />

      <WholesaleMarketDetailsUploadSectionUploader
        datasetId={datasetInfo?.id}
        fileType={DatasetFileTypes.MFRREnergyUp}
        title="Energy Up (mFRR)"
        uploadedFile={datasetInfo?.mfrrEnergyUpFile}
        tooltipText="Placeholder text"
        isDisabled={!filters.hasEnergyUpMFRR || isDatasetLocked}
      />
    </Box>
  ) : (
    <Grid gap={5} gridTemplateColumns={{ md: 3 }} w="100%">
      <Box column gap={2}>
        <T variant="label">Energy Market Prices</T>

        <WholesaleMarketDetailsUploadSectionUploader
          datasetId={datasetInfo?.id}
          fileType={DatasetFileTypes.DayAheadEnergyPrices}
          title="Day-Ahead Energy Market Prices"
          uploadedFile={datasetInfo?.dayAheadEnergyPricesFile}
          tooltipText="$/MWh hourly Locational Marginal Prices forecast on project electrical node from the Day-Ahead Market over the project life."
          isDisabled={!filters.hasDayAheadEnergy || isDatasetLocked}
        />

        <WholesaleMarketDetailsUploadSectionUploader
          datasetId={datasetInfo?.id}
          fileType={DatasetFileTypes.RealTimeEnergyPrice}
          title="Real-Time Energy Market Prices"
          uploadedFile={datasetInfo?.realTimeEnergyPriceFile}
          tooltipText="$/MWh hourly Locational Marginal Prices forecast on project electrical node from the Day-Ahead Market over the project life."
          isDisabled={!filters.hasRealTimeEnergy || isDatasetLocked}
        />
      </Box>

      <Box column gap={2}>
        <T variant="label">Ancillary Service Price</T>

        <WholesaleMarketDetailsUploadSectionUploader
          datasetId={datasetInfo?.id}
          fileType={DatasetFileTypes.RegUpPrices}
          title="Regulation Up Prices"
          uploadedFile={datasetInfo?.regUpPricesFile}
          tooltipText="$/MW zonal price for resource owner to reserve MWs for Regulation Up."
          isDisabled={!filters.hasRegulationUp || isDatasetLocked}
        />

        <WholesaleMarketDetailsUploadSectionUploader
          datasetId={datasetInfo?.id}
          fileType={DatasetFileTypes.RegDownPrices}
          title="Regulation Down Prices"
          uploadedFile={datasetInfo?.regDownPricesFile}
          tooltipText="$/MW zonal price for resource owner to reserve MWs for regulation down."
          isDisabled={!filters.hasRegulationDown || isDatasetLocked}
        />

        <WholesaleMarketDetailsUploadSectionUploader
          datasetId={datasetInfo?.id}
          fileType={DatasetFileTypes.SpinningReservePrices}
          title="Spinning Reserve Prices"
          uploadedFile={datasetInfo?.spinningReservePricesFile}
          tooltipText="$/MW zonal price for resource owner to reserve MWs for spinning reserve."
          isDisabled={!filters.hasSpinningReserve || isDatasetLocked}
        />

        <WholesaleMarketDetailsUploadSectionUploader
          datasetId={datasetInfo?.id}
          fileType={DatasetFileTypes.RegulationMileagePrice}
          title="Regulation Mileage Prices"
          uploadedFile={datasetInfo?.regulationMileagePriceFile}
          tooltipText="$/MW zonal price for resource owner to provide regulation movement."
          isDisabled={!filters.hasRegulationMileage || isDatasetLocked}
        />
      </Box>

      <Box column gap={2}>
        <T variant="label">Capacity Market Price</T>

        <WholesaleMarketDetailsUploadSectionUploader
          datasetId={datasetInfo?.id}
          fileType={DatasetFileTypes.CapacityMarketPrice}
          title="Capacity Market Price"
          uploadedFile={datasetInfo?.capacityMarketPriceFile}
          tooltipText="$/MW zonal price for resource owner to secure their resources participate in the ISO merchant market."
          isDisabled={!filters.hasCapacityMarketPrice || isDatasetLocked}
        />
      </Box>
    </Grid>
  )
}
