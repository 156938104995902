import { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import {
  Box,
  Button,
  formatDate,
  Pagination,
  Table,
  Tbody,
  Td,
  Tfoot,
  Thead,
  Tr,
  useDialog
} from '@veracity/vui'

import { useTableSorting } from '@/hooks'
import { DatasetSimple } from '@/interfaces'
import { orderByKey } from '@/utils'

import { LibraryDatasetTableTypeIcon } from './LibraryDatasetTableTypeIcon'
import { CloneLibraryDatasetDialog } from '../CloneLibraryDatasetDialog'

interface Props {
  pageSize?: number
  datasets: DatasetSimple[]
  isCloneInProgress: boolean
  onCloneClicked: (
    datasetId: number,
    datasetName: string,
    onSuccess: () => void
  ) => void
  onRemoveClicked: (datasetId: number) => void
  getDetailsLink: (datasetId: number) => string
  allDatasetNames: string[]
}

export const LibraryDatasetTable = ({
  datasets,
  pageSize = 10,
  isCloneInProgress,
  onCloneClicked,
  onRemoveClicked,
  getDetailsLink,
  allDatasetNames
}: Props) => {
  const { showConfirm } = useDialog()
  const [pageNumber, setPageNumber] = useState(1)

  const { sort, handleSortChange } = useTableSorting<DatasetSimple>({
    key: 'name',
    order: 'asc'
  })

  useEffect(() => setPageNumber(1), [datasets])

  const datasetsPage = useMemo(() => {
    const defaultDatasets = datasets.filter(x => x.isDefault)

    const restDatasets = datasets
      .filter(x => !x.isDefault)
      .sort(orderByKey(sort.key, sort.order))

    return [...defaultDatasets, ...restDatasets]
      .filter(Boolean)
      .slice((pageNumber - 1) * pageSize, pageNumber * pageSize)
  }, [pageNumber, datasets, sort, pageSize])

  const handleRemoveDataset = async (datasetId: number) => {
    const result = await showConfirm(
      'Remove dataset',
      'This operation is irreversible. Are you sure?'
    )
    if (result) onRemoveClicked(datasetId)
  }

  return (
    <Table
      sort={sort}
      onSortChange={handleSortChange}
      columns={[
        { field: 'info', title: '', w: 20 },
        { field: 'name', title: 'Dataset name', isSortable: true },
        { field: 'createdBy', title: 'Owner', isSortable: true },
        { field: 'createdAt', title: 'Created At', isSortable: true },
        { field: 'modifiedBy', title: 'Updated At', isSortable: true },
        { field: 'action', title: 'Actions' }
      ]}>
      <Thead bg="white" />
      <Tbody bg="white">
        {datasetsPage.map(dataset => (
          <Tr key={dataset.id}>
            <Td>
              <LibraryDatasetTableTypeIcon dataset={dataset} />
            </Td>
            <Td>{dataset.name}</Td>
            <Td>{dataset.createdBy}</Td>
            <Td>{formatDate(dataset.createdAt, 'DD MM YYYY')}</Td>
            <Td>{formatDate(dataset.modifiedAt, 'DD MM YYYY')}</Td>
            <Td>
              <Box gap={2}>
                <CloneLibraryDatasetDialog
                  isSmall
                  sourceDataset={dataset}
                  allDatasetNames={allDatasetNames}
                  isCloneInProgress={isCloneInProgress}
                  onClone={(datasetName, onSuccess) =>
                    onCloneClicked(dataset.id, datasetName, onSuccess)
                  }
                />

                <Button
                  as={Link}
                  to={getDetailsLink(dataset.id)}
                  variant="tertiaryDark"
                  icon="falArrowRight"
                />

                {!dataset.isDefault && !dataset.isLocked && (
                  <Button
                    variant="tertiaryDark"
                    icon="falTrashAlt"
                    onClick={() => handleRemoveDataset(dataset.id)}
                  />
                )}
              </Box>
            </Td>
          </Tr>
        ))}
      </Tbody>
      <Tfoot>
        <Tr>
          <Td colSpan={6}>
            <Pagination
              pageSize={pageSize}
              count={datasets.length}
              page={pageNumber}
              onPageChange={setPageNumber}
            />
          </Td>
        </Tr>
      </Tfoot>
    </Table>
  )
}
