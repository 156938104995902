import { useState } from 'react'
import { Sort } from '@veracity/vui'

interface DefaultSort<T> {
  key: keyof T
  order: Sort['order']
}

export const useTableSorting = <T>(defaultSort: DefaultSort<T>) => {
  const [sort, setSort] = useState<Sort<keyof T>>(defaultSort)

  const handleSortChange = (sort: Sort) =>
    setSort({ key: sort.key as keyof T, order: sort.order })

  return { sort, handleSortChange }
}
