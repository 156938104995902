import { Datum } from 'plotly.js'

export enum ScenarioResultsTabSectionType {
  SUMMARY,
  TIME_SERIES,
  AGGREGATES
}

export interface ChartData {
  x: Datum[]
  y: Datum[]
}

export interface EnergyPriceData {
  priceDae: ChartData
  priceRte: ChartData
}

export interface RegulationAndSrPriceData {
  regUp: ChartData
  regDown: ChartData
  spinningReserve: ChartData
}

export interface EnergyBreakdown2Data {
  title: string
  esCharge: ChartData
  esDischarge: ChartData
  windPoi: ChartData
  windCurtail: ChartData
  solarPoi: ChartData
  solarCurtail: ChartData
}

export interface SrAndRegulationCommitmentData {
  regDownWhileDischarge: ChartData
  regDownWhileCharge: ChartData
  regUpWhileDischarge: ChartData
  regUpWhileCharge: ChartData
  srWhileDischarge: ChartData
  srWhileCharge: ChartData
}

export interface EnergyBreakdownData {
  rte: ChartData
  dae: ChartData
  netRegEnergy: ChartData
  wind: ChartData
  solar: ChartData
  esOutputPoi: ChartData
  maxOutput: ChartData
  minOutput: ChartData
}

export interface SocData {
  esSocEndOfInterval: ChartData
  maxSoc: ChartData
}

export enum DownloadResultsSectionNames {
  CASH_FLOWS = 'Cashflow',
  INCOMES = 'Income',
  COSTS = 'Cost',
  OPERATIONS = 'Operation',
  EXCEL_SUMMARY_REPORT = 'AnnualSummaryReport',
  PDF_SUMMARY_REPORT = 'WordSummaryReport'
}
