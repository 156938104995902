import { Box, Card, Divider, T } from '@veracity/vui'
import { ScenarioComparisonPreviewDataRow } from './ScenarioComparisonPreviewDataRow'
import { Fragment, ReactNode, useEffect, useState } from 'react'
import { ExpandToggleButton } from '@/components'

export interface ScenarioComparisonPreviewCardItem {
  label: string
  items?: ReactNode[]
  isBoldedRow?: boolean
  isCollapsableRow?: boolean
}

export interface ScenarioComparisonPreviewCardProps {
  title?: string
  areAllRowsExpanded: boolean
  items: ScenarioComparisonPreviewCardItem[]
}

export const ScenarioComparisonPreviewCard = ({
  title,
  areAllRowsExpanded,
  items
}: ScenarioComparisonPreviewCardProps) => {
  const [isExpanded, setIsExpanded] = useState(false)

  useEffect(() => setIsExpanded(areAllRowsExpanded), [areAllRowsExpanded])

  const filteredItems = items.filter(x => isExpanded || !x.isCollapsableRow)

  return (
    <Card column p={1}>
      {!!title && (
        <Box justify="space-between">
          <T p={1} variant="label">
            {title}
          </T>

          <ExpandToggleButton
            isExpanded={isExpanded}
            onToggle={setIsExpanded}
          />
        </Box>
      )}

      {filteredItems.map((item, index) => (
        <Fragment key={index}>
          {!!index && <Divider />}

          <ScenarioComparisonPreviewDataRow
            label={item.label}
            items={item.items || []}
            isBolded={item.isBoldedRow}
          />
        </Fragment>
      ))}
    </Card>
  )
}
