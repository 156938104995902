import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useToast } from '@veracity/vui'

import { externalApiKeyClient } from '@/api'

const BASE_QUERY_KEY = externalApiKeyClient.QUERY_KEY
export const useOtherDnvApiKeys = () => {
  const queryClient = useQueryClient()
  const { showSuccess } = useToast()

  const otherDnvApiKeysQuery = useQuery(
    BASE_QUERY_KEY,
    externalApiKeyClient.getAll
  )

  const addExternalApiKeyMutation = useMutation({
    mutationFn: externalApiKeyClient.addNewApiKey,
    onSuccess: async () => {
      await queryClient.invalidateQueries(BASE_QUERY_KEY)
      showSuccess('API Key successfully added')
    }
  })

  const updateExternalApiKeyMutation = useMutation({
    mutationFn: externalApiKeyClient.updateApiKey,
    onSuccess: async () => {
      await queryClient.invalidateQueries(BASE_QUERY_KEY)
      showSuccess('API Key successfully updated')
    }
  })

  return {
    otherDnvApiKeys: otherDnvApiKeysQuery.data || [],
    isOtherDnvApiKeysLoading: otherDnvApiKeysQuery.isLoading,
    addExternalApiKey: addExternalApiKeyMutation.mutate,
    updateExternalApiKey: updateExternalApiKeyMutation.mutate
  }
}
