import { useMemo } from 'react'
import { FieldValues } from 'react-hook-form'

import { SystemConfigurations } from '@/interfaces'
import { LibraryDatasetConfigurationAlignment } from '@/components'

interface Props {
  formValues: FieldValues
}
export const LossFactorsDetailsPreviewCard = ({ formValues }: Props) => {
  const isValid = (val: any) =>
    !isNaN(parseFloat(val)) && +val >= 0 && +val <= 100

  const isSystemLevelLossesSectionValid = [
    'acLineEfficiency',
    'acMainTransformerEfficiency'
  ].every(x => isValid(formValues[x]))

  const isBessSectionValid = [
    'storageChargeDischargeEfficiency',
    'dcLineEfficiency',
    'dcDcConverterEfficiency',
    'dcAcInverterEfficiency',
    'auxiliaryPowerLoss',
    'initialStateOfCharge',
    'minimumStateOfCharge',
    'maximumStateOfCharge'
  ].every(x => isValid(formValues[x]))

  const isPVSectionValid = [
    'pvDCACInverterEfficiency',
    'pvAnnualDerating'
  ].every(x => isValid(formValues[x]))

  const isWindSectionValid = [
    'windTotalEfficiency',
    'windAnnualDerating'
  ].every(x => isValid(formValues[x]))

  const alignedSystemConfigurationTypes = useMemo(() => {
    if (!isSystemLevelLossesSectionValid || !isBessSectionValid) return []

    let results = [SystemConfigurations.Standalone]

    if (isPVSectionValid)
      results.push(
        SystemConfigurations.SolarPV,
        SystemConfigurations.SolarPVOnSharedBus
      )

    if (isWindSectionValid) results.push(SystemConfigurations.WindOnShareBus)

    if (isPVSectionValid && isWindSectionValid)
      results.push(
        SystemConfigurations.SolarPVAndAcCoupledWithWind,
        SystemConfigurations.SolarPVAndAcCoupledWithWind
      )

    return results
  }, [
    isBessSectionValid,
    isPVSectionValid,
    isSystemLevelLossesSectionValid,
    isWindSectionValid
  ])

  return (
    <LibraryDatasetConfigurationAlignment
      allowedSystemConfigurations={alignedSystemConfigurationTypes}
    />
  )
}
