import { createContext } from 'react'
import { Config, Layout } from 'plotly.js'

export type SharedPlotLayout = Partial<Layout>
export type SharedPlotConfig = Partial<Config>

interface SharedPlotLayoutContextState {
  onLayoutChange: (changes: SharedPlotLayout) => void
  sharedLayout: SharedPlotLayout
  sharedConfig?: SharedPlotConfig
}

export const SharedPlotLayoutContext =
  createContext<SharedPlotLayoutContextState>({
    sharedLayout: {},
    onLayoutChange: changes => {}
  })
