import { Box, T } from '@veracity/vui'

import { SecretText } from '@/components'

interface Props {
  label: string
  value: string
  isSecret?: boolean
}

export const ApiManagementKeyValuePair = ({
  label,
  value,
  isSecret
}: Props) => (
  <Box gap={2} column justify="baseline">
    <T variant="label">{label}</T>
    {isSecret ? (
      <SecretText secret={value} />
    ) : (
      <T variant="caption">{value}</T>
    )}
  </Box>
)
