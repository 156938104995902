import { useEffect, useMemo, useState } from 'react'

import { TreeSelectItemOption } from '@/components'
import { WholesaleMarketDatasetWithFilesSimple } from '@/interfaces'

import { MarketParticipationFilters } from '../types'
import { useIsSpainDataset } from './useIsSpainDataset'

export const useMarketParticipationFilters = (
  datasetInfo?: WholesaleMarketDatasetWithFilesSimple
) => {
  const [marketParticipationFilters, setMarketParticipationFilters] =
    useState<MarketParticipationFilters>({
      hasCapacityMarketPrice: false,
      hasRegulationMileage: false,
      hasSpinningReserve: false,
      hasRegulationDown: false,
      hasRegulationUp: false,
      hasRealTimeEnergy: false,
      hasDayAheadEnergy: false,
      hasEnergyDownAFRR: false,
      hasEnergyDownMFRR: false,
      hasEnergyUpAFRR: false,
      hasEnergyUpMFRR: false,
      hasPowerAFRR: false
    })

  const isSpainMarket = useIsSpainDataset(datasetInfo)

  useEffect(() => {
    setMarketParticipationFilters(currentValue => ({
      hasCapacityMarketPrice:
        currentValue.hasCapacityMarketPrice ||
        !!datasetInfo?.capacityMarketPriceFile?.id,
      hasRegulationMileage:
        currentValue.hasRegulationMileage ||
        !!datasetInfo?.regulationMileagePriceFile?.id,
      hasSpinningReserve:
        currentValue.hasSpinningReserve ||
        !!datasetInfo?.spinningReservePricesFile?.id,
      hasRegulationDown:
        currentValue.hasRegulationDown || !!datasetInfo?.regDownPricesFile?.id,
      hasRegulationUp:
        currentValue.hasRegulationUp || !!datasetInfo?.regUpPricesFile?.id,
      hasRealTimeEnergy:
        currentValue.hasRealTimeEnergy ||
        !!datasetInfo?.realTimeEnergyPriceFile?.id,
      hasDayAheadEnergy:
        currentValue.hasDayAheadEnergy ||
        !!datasetInfo?.dayAheadEnergyPricesFile?.id,
      hasEnergyDownAFRR:
        currentValue.hasEnergyDownAFRR || !!datasetInfo?.afrrEnergyDownFile?.id,
      hasEnergyDownMFRR:
        currentValue.hasEnergyDownMFRR || !!datasetInfo?.mfrrEnergyDownFile?.id,
      hasEnergyUpAFRR:
        currentValue.hasEnergyUpAFRR || !!datasetInfo?.afrrEnergyUpFile?.id,
      hasEnergyUpMFRR:
        currentValue.hasEnergyUpMFRR || !!datasetInfo?.mfrrEnergyUpFile?.id,
      hasPowerAFRR:
        currentValue.hasPowerAFRR || !!datasetInfo?.afrrPowerFile?.id
    }))
  }, [datasetInfo])

  const handleChange = (val: Partial<MarketParticipationFilters>) => {
    setMarketParticipationFilters(currentValue => ({ ...currentValue, ...val }))
  }

  const items = useMemo(() => {
    return isSpainMarket
      ? ([
          {
            label: 'Energy',
            isDisabled:
              !!datasetInfo?.dayAheadEnergyPricesFile?.id ||
              !!datasetInfo?.afrrEnergyDownFile?.id ||
              !!datasetInfo?.afrrEnergyUpFile?.id ||
              !!datasetInfo?.afrrPowerFile?.id ||
              !!datasetInfo?.mfrrEnergyDownFile?.id ||
              !!datasetInfo?.mfrrEnergyUpFile?.id,
            onChange: val => {
              handleChange({
                hasDayAheadEnergy: val,
                hasEnergyDownAFRR: val,
                hasEnergyUpAFRR: val,
                hasPowerAFRR: val,
                hasEnergyDownMFRR: val,
                hasEnergyUpMFRR: val
              })
            },
            children: [
              {
                label: 'Day-Ahead Energy',
                checked: marketParticipationFilters?.hasDayAheadEnergy,
                isDisabled: !!datasetInfo?.dayAheadEnergyPricesFile?.id,
                onChange: val => handleChange({ hasDayAheadEnergy: val })
              },
              {
                label: 'Energy Down (aFRR)',
                checked: marketParticipationFilters?.hasEnergyDownAFRR,
                isDisabled: !!datasetInfo?.afrrEnergyDownFile?.id,
                onChange: val => handleChange({ hasEnergyDownAFRR: val })
              },
              {
                label: 'Energy Up (aFRR)',
                checked: marketParticipationFilters?.hasEnergyUpAFRR,
                isDisabled: !!datasetInfo?.afrrEnergyUpFile?.id,
                onChange: val => handleChange({ hasEnergyUpAFRR: val })
              },
              {
                label: 'Power (aFRR)',
                checked: marketParticipationFilters?.hasPowerAFRR,
                isDisabled: !!datasetInfo?.afrrPowerFile?.id,
                onChange: val => handleChange({ hasPowerAFRR: val })
              },
              {
                label: 'Energy Down (mFRR)',
                checked: marketParticipationFilters?.hasEnergyDownMFRR,
                isDisabled: !!datasetInfo?.mfrrEnergyDownFile?.id,
                onChange: val => handleChange({ hasEnergyDownMFRR: val })
              },
              {
                label: 'Energy Up (mFRR)',
                checked: marketParticipationFilters?.hasEnergyUpMFRR,
                isDisabled: !!datasetInfo?.mfrrEnergyUpFile?.id,
                onChange: val => handleChange({ hasEnergyUpMFRR: val })
              }
            ]
          }
        ] as TreeSelectItemOption[])
      : ([
          {
            label: 'Energy',
            isDisabled:
              !!datasetInfo?.dayAheadEnergyPricesFile?.id ||
              !!datasetInfo?.realTimeEnergyPriceFile?.id,
            onChange: val =>
              handleChange({
                hasDayAheadEnergy: val,
                hasRealTimeEnergy: val
              }),
            children: [
              {
                label: 'Day-Ahead Energy',
                checked: marketParticipationFilters?.hasDayAheadEnergy,
                isDisabled: !!datasetInfo?.dayAheadEnergyPricesFile?.id,
                onChange: val => handleChange({ hasDayAheadEnergy: val })
              },
              {
                label: 'Real Time Energy',
                checked: marketParticipationFilters?.hasRealTimeEnergy,
                isDisabled: !!datasetInfo?.realTimeEnergyPriceFile?.id,
                onChange: val => handleChange({ hasRealTimeEnergy: val })
              }
            ]
          },
          {
            label: 'Ancillary Service',
            isDisabled:
              !!datasetInfo?.regUpPricesFile?.id ||
              !!datasetInfo?.regDownPricesFile?.id ||
              !!datasetInfo?.spinningReservePricesFile?.id ||
              !!datasetInfo?.regulationMileagePriceFile?.id,
            onChange: val =>
              handleChange({
                hasRegulationUp: val,
                hasRegulationDown: val,
                hasSpinningReserve: val,
                hasRegulationMileage: val
              }),
            children: [
              {
                label: 'Regulation Up',
                checked: marketParticipationFilters?.hasRegulationUp,
                isDisabled: !!datasetInfo?.regUpPricesFile?.id,
                onChange: val => handleChange({ hasRegulationUp: val })
              },
              {
                label: 'Regulation Down',
                checked: marketParticipationFilters?.hasRegulationDown,
                isDisabled: !!datasetInfo?.regDownPricesFile?.id,
                onChange: val => handleChange({ hasRegulationDown: val })
              },
              {
                label: 'Spinning Reserve',
                checked: marketParticipationFilters?.hasSpinningReserve,
                isDisabled: !!datasetInfo?.spinningReservePricesFile?.id,
                onChange: val => handleChange({ hasSpinningReserve: val })
              },
              {
                label: 'Regulation Mileage',
                checked: marketParticipationFilters?.hasRegulationMileage,
                isDisabled: !!datasetInfo?.regulationMileagePriceFile?.id,
                onChange: val => handleChange({ hasRegulationMileage: val })
              }
            ]
          },
          {
            label: 'Capacity Market Price',
            checked: marketParticipationFilters?.hasCapacityMarketPrice,
            isDisabled: !!datasetInfo?.capacityMarketPriceFile?.id,
            onChange: val => handleChange({ hasCapacityMarketPrice: val })
          }
        ] as TreeSelectItemOption[])
  }, [datasetInfo, isSpainMarket, marketParticipationFilters])

  const isDatasetValid = useMemo(() => {
    const paris = [
      {
        isChecked: marketParticipationFilters.hasDayAheadEnergy,
        isFileUploaded: !!datasetInfo?.dayAheadEnergyPricesFile?.id
      },
      {
        isChecked: marketParticipationFilters.hasRealTimeEnergy,
        isFileUploaded: !!datasetInfo?.realTimeEnergyPriceFile?.id
      },
      {
        isChecked: marketParticipationFilters.hasRegulationUp,
        isFileUploaded: !!datasetInfo?.regUpPricesFile?.id
      },
      {
        isChecked: marketParticipationFilters.hasRegulationDown,
        isFileUploaded: !!datasetInfo?.regDownPricesFile?.id
      },
      {
        isChecked: marketParticipationFilters.hasSpinningReserve,
        isFileUploaded: !!datasetInfo?.spinningReservePricesFile?.id
      },
      {
        isChecked: marketParticipationFilters.hasRegulationMileage,
        isFileUploaded: !!datasetInfo?.regulationMileagePriceFile?.id
      },
      {
        isChecked: marketParticipationFilters.hasCapacityMarketPrice,
        isFileUploaded: !!datasetInfo?.capacityMarketPriceFile?.id
      },
      {
        isChecked: marketParticipationFilters.hasEnergyDownAFRR,
        isFileUploaded: !!datasetInfo?.afrrEnergyDownFile?.id
      },
      {
        isChecked: marketParticipationFilters.hasPowerAFRR,
        isFileUploaded: !!datasetInfo?.afrrPowerFile?.id
      },
      {
        isChecked: marketParticipationFilters.hasEnergyDownMFRR,
        isFileUploaded: !!datasetInfo?.mfrrEnergyDownFile?.id
      },
      {
        isChecked: marketParticipationFilters.hasEnergyUpMFRR,
        isFileUploaded: !!datasetInfo?.mfrrEnergyUpFile?.id
      }
    ]

    if (paris.some(x => x.isChecked && !x.isFileUploaded)) return false

    return paris.some(x => x.isChecked)
  }, [datasetInfo, marketParticipationFilters])

  return {
    treeSelectItems: items,
    filters: marketParticipationFilters,
    isDatasetValid
  }
}
