import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Box, Button, Dialog, Spinner } from '@veracity/vui'

import { DatasetSimple } from '@/interfaces'

import { InputFormControl } from './form'

interface Props {
  isSmall?: boolean
  sourceDataset: DatasetSimple
  allDatasetNames: string[]
  isCloneInProgress: boolean
  onClone: (datasetName: string, onSuccess: () => void) => void
}

export const CloneLibraryDatasetDialog = ({
  isSmall,
  sourceDataset,
  allDatasetNames,
  isCloneInProgress,
  onClone
}: Props) => {
  const [isVisible, setIsVisible] = useState(false)
  const { control, handleSubmit, reset, setValue, formState } = useForm()

  const defaultName = `${sourceDataset.name} - Clone`

  useEffect(
    () => setValue('name', defaultName),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sourceDataset.name]
  )

  const handleClose = () => {
    if (isCloneInProgress) return
    reset({ name: defaultName })
    setIsVisible(false)
  }

  const handleSubmitClick = (formFields: any) =>
    onClone(formFields.name, handleClose)

  const validateNameDuplication = (value?: string) =>
    !allDatasetNames.some(
      p => p.toLocaleLowerCase() === value?.toLocaleLowerCase()
    )

  return (
    <>
      {isSmall ? (
        <Button
          variant="tertiaryDark"
          icon="falCopy"
          onClick={() => setIsVisible(true)}
        />
      ) : (
        <Button
          variant="tertiaryDark"
          iconLeft="falCopy"
          size="lg"
          onClick={() => setIsVisible(true)}>
          Clone
        </Button>
      )}

      <Dialog
        isOpen={isVisible}
        title="Clone Dataset"
        size="lg"
        onClose={handleClose}
        onSubmit={handleSubmit(handleSubmitClick)}
        cancelButton={{ text: 'Cancel', disabled: isCloneInProgress }}
        submitButton={{
          text: 'Clone',
          onClick: handleSubmit(handleSubmitClick),
          isLoading: isCloneInProgress,
          disabled: isCloneInProgress
        }}
        body={
          <Box column p={2} gap={2}>
            {isCloneInProgress ? (
              <Spinner />
            ) : (
              <InputFormControl
                control={control}
                formControlName="name"
                label="Name"
                rules={{ required: true, validate: validateNameDuplication }}
                errorText={
                  formState.errors.name?.type === 'validate'
                    ? 'Provide unique name for dataset'
                    : undefined
                }
              />
            )}
          </Box>
        }
      />
    </>
  )
}
