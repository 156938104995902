import { Box, Button, Link, Spinner, T, useDialog } from '@veracity/vui'

import { FileUploader } from './FileUploader'
import { TooltipIcon } from './TooltipIcon'

interface Props {
  title?: string
  tooltipText?: string
  isDisabled?: boolean
  uploadedFileName?: string
  downloadFileUrl?: string
  onFileAdded?: (file: File) => void
  isUploading?: boolean
  onRemoveFileClicked?: () => void
}
export const CsvFileUploader = ({
  title,
  isDisabled,
  tooltipText,
  uploadedFileName,
  downloadFileUrl,
  onFileAdded,
  isUploading,
  onRemoveFileClicked
}: Props) => {
  const { showConfirm } = useDialog()

  const handleRemoveFile = async () => {
    const result = await showConfirm(
      'Remove dataset file',
      'This operation is irreversible. Are you sure?'
    )
    if (result) onRemoveFileClicked?.()
  }

  return (
    <Box column gap={2} w="100%" borderBottom="1px solid gray">
      {!!title && (
        <Box gap={1}>
          <T variant="caption">{title}</T>
          <TooltipIcon.Help tooltipText={tooltipText || ''} />
        </Box>
      )}

      {!!downloadFileUrl && (
        <Box justify="space-between">
          <Link
            py="6px"
            size="lg"
            href={downloadFileUrl}
            iconLeft="falFileCsv"
            title={uploadedFileName}
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            download={uploadedFileName}>
            <T overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
              {uploadedFileName}
            </T>
          </Link>
          {!isDisabled && (
            <Button
              icon="falTrashAlt"
              variant="tertiaryDark"
              onClick={handleRemoveFile}
            />
          )}
        </Box>
      )}

      {!downloadFileUrl && !!isUploading && (
        <Box>
          <Spinner size="xs" text="Uploading file ..." textPosition="right" />
        </Box>
      )}

      {!downloadFileUrl && !isUploading && (
        <FileUploader
          label="Upload CSV File"
          accept="text/csv"
          disabled={isDisabled}
          onFilesAdded={([file]) => onFileAdded?.(file)}
        />
      )}
    </Box>
  )
}
