import axios from 'axios'

import { GetAllSelectionOptionsResponse, PricePlanSimple } from '@/interfaces'

const baseUrl = '/api/configurationDictionary'

export const configurationClient = {
  QUERY_KEY: ['configuration'],
  getAllDictionaries: () =>
    axios
      .get<GetAllSelectionOptionsResponse>(`${baseUrl}/getAllSelectionOptions`)
      .then(x => x.data),
  getAvailablePricingPlans: () =>
    axios.get<PricePlanSimple[]>(`${baseUrl}/getPricePlans`).then(x => x.data)
}
