import { Box, Button, useDialog } from '@veracity/vui'

import { DatasetSimple } from '@/interfaces'
import { CloneLibraryDatasetDialog } from './CloneLibraryDatasetDialog'

interface Props {
  datasetInfo: DatasetSimple
  onArchiveDataset: () => void
  onUnArchiveDataset: () => void
  onRemoveDataset: () => void
  onLockDataset: () => void
  onCloneDataset: (datasetName: string, onSuccess?: () => void) => void
  isCloneDatasetInProgress: boolean
  allDatasetNames: string[]
  isLockDisabled?: boolean
  saveButton?: {
    onClick: () => void
    isLoading?: boolean
    isDisabled?: boolean
  }
}
export const DatasetDetailsOperationBar = ({
  datasetInfo,
  onArchiveDataset,
  onUnArchiveDataset,
  onRemoveDataset,
  onLockDataset,
  onCloneDataset,
  isCloneDatasetInProgress,
  isLockDisabled,
  saveButton,
  allDatasetNames
}: Props) => {
  const { showConfirm } = useDialog()

  const handleRemoveDataset = async () => {
    const result = await showConfirm(
      'Remove dataset',
      'This operation is irreversible. Are you sure?'
    )
    if (result) onRemoveDataset()
  }

  const handleLockDataset = async () => {
    const result = await showConfirm(
      'Lock dataset',
      'This operation is irreversible. Are you sure?'
    )
    if (result) onLockDataset()
  }

  return (
    <Box justify="right" gap={2} w="100%">
      <CloneLibraryDatasetDialog
        sourceDataset={datasetInfo}
        allDatasetNames={allDatasetNames}
        isCloneInProgress={isCloneDatasetInProgress}
        onClone={onCloneDataset}
      />

      {!datasetInfo.isDefault && !datasetInfo.isLocked && (
        <Button
          iconLeft="falTrashAlt"
          size="lg"
          variant="tertiaryDark"
          onClick={handleRemoveDataset}>
          Remove
        </Button>
      )}

      {!datasetInfo.isDefault &&
        !datasetInfo.isLocked &&
        !datasetInfo.isArchive && (
          <Button
            iconLeft="falLockAlt"
            size="lg"
            variant="tertiaryDark"
            disabled={isLockDisabled}
            onClick={handleLockDataset}>
            Lock
          </Button>
        )}

      {!datasetInfo.isDefault && datasetInfo.isArchive && (
        <Button
          iconLeft="falFolderOpen"
          size="lg"
          variant="tertiaryDark"
          onClick={onUnArchiveDataset}>
          Unarchive
        </Button>
      )}

      {!datasetInfo.isDefault && !datasetInfo.isArchive && (
        <Button
          iconLeft="falFileArchive"
          size="lg"
          variant="tertiaryDark"
          onClick={onArchiveDataset}>
          Archive
        </Button>
      )}

      {!datasetInfo.isDefault &&
        !datasetInfo.isLocked &&
        !datasetInfo.isArchive &&
        !!saveButton && (
          <Button
            iconLeft="falSave"
            size="lg"
            variant="tertiaryDark"
            disabled={saveButton.isDisabled}
            isLoading={saveButton.isLoading}
            onClick={saveButton.onClick}>
            Save
          </Button>
        )}
    </Box>
  )
}
