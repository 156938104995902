import { useMemo } from 'react'
import moment from 'moment'

import { ProjectSimple } from '@/interfaces'

import { ProjectFilterValues } from '../types'

export const useProjectsListFilter = (
  allProjects?: ProjectSimple[],
  queryText?: string,
  filterProps?: ProjectFilterValues
) => {
  const filterByText = (projects: ProjectSimple[], query: string) =>
    projects.filter(
      f =>
        f?.name?.toLocaleLowerCase()?.includes(query) ||
        f?.isoBaName?.toLocaleLowerCase()?.includes(query) ||
        f?.createdBy?.toLocaleLowerCase()?.includes(query) ||
        f?.company?.toLocaleLowerCase()?.includes(query)
    )

  const filterByAdvancedFilters = (
    projects: ProjectSimple[],
    projectFilterValues: ProjectFilterValues
  ) =>
    projects
      .map(
        project =>
          ({
            ...project,
            scenarios: project.scenarios
              ?.filter(
                scenario =>
                  projectFilterValues.selectedCalculationState === undefined ||
                  scenario.state ===
                    projectFilterValues.selectedCalculationState
              )
              .filter(
                scenario =>
                  projectFilterValues.selectedSystemConfiguration ===
                    undefined ||
                  scenario.systemConfiguration ===
                    projectFilterValues.selectedSystemConfiguration
              )
              .filter(
                scenario =>
                  !projectFilterValues.selectedCalculationStartDate ||
                  moment(scenario.calculationStartDate).isSameOrAfter(
                    projectFilterValues.selectedCalculationStartDate
                  )
              )
          } as ProjectSimple)
      )
      .filter(x => !!x.scenarios?.length)

  return useMemo(() => {
    let results = allProjects || []

    if (!!queryText)
      results = filterByText(results, queryText.toLocaleLowerCase())
    if (!!filterProps) results = filterByAdvancedFilters(results, filterProps)

    return results
  }, [allProjects, filterProps, queryText])
}
