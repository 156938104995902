import { PropsWithChildren, ReactNode, useMemo } from 'react'
import { Box, T } from '@veracity/vui'
import { useQuery } from 'react-query'
import { Control, useWatch } from 'react-hook-form'

import { scenariosClient } from '@/api'
import { TooltipIcon } from '@/components'
import { ScenarioDetailed } from '@/interfaces'
import { convertToPercentage } from '@/utils'

import { ScenarioDetailsFormValues } from '../../../types'

interface Props {
  scenarioId: number
  currentValueKey: keyof ScenarioDetailsFormValues
  originalValueKey: keyof ScenarioDetailed
  formControl: Control<ScenarioDetailsFormValues>
  isPercentage?: boolean
}

export const ScenarioDetailsContentDiagramFormLabel = ({
  scenarioId,
  currentValueKey,
  originalValueKey,
  formControl,
  isPercentage,
  children
}: PropsWithChildren<Props>) => {
  const { data: scenarioInfo } = useQuery(
    scenariosClient.QUERY_KEY(scenarioId),
    () => scenariosClient.getById(scenarioId),
    { refetchOnMount: false }
  )

  const currentValue = useWatch({ control: formControl, name: currentValueKey })

  const originalValue = useMemo(() => {
    if (!scenarioInfo) return ''
    const val = scenarioInfo[originalValueKey]
    if (typeof val !== 'number') return ''

    return isPercentage ? convertToPercentage(val) : val
  }, [isPercentage, originalValueKey, scenarioInfo])

  return (
    <Box gap={1}>
      {children}
      {currentValue !== originalValue && !!originalValue && (
        <TooltipIcon.Warning
          tooltipText={`Notice: Dataset value changed from the original. (${
            isPercentage ? `${originalValue}%` : originalValue
          }).`}
        />
      )}
    </Box>
  )
}
