import { Box, Button, T } from '@veracity/vui'
import { useToggle } from '@veracity/ui-utils'

interface Props {
  secret: string
}
export const SecretText = ({ secret }: Props) => {
  const [isVisible, toggle] = useToggle()

  const secretText = isVisible ? secret : secret.replace(/./g, '*')

  return (
    <Box gap={2} alignItems="center">
      <T variant="caption">{secretText}</T>
      <Button
        title={isVisible ? 'Hide secret' : 'Show secret'}
        size="sm"
        icon={isVisible ? 'falEyeSlash' : 'falEye'}
        onClick={toggle}
      />
    </Box>
  )
}
