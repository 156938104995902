import { Box, Spinner, useLoadLinks } from '@veracity/vui'

import { AppRouting } from './AppRouting'
import { CurrentUserContext } from './contexts'
import { useUserSession } from '@/hooks'

export const App = () => {
  const { currentUser, isFetching, isReFetching } = useUserSession()

  useLoadLinks('Prod', !!currentUser)

  if (isFetching && !isReFetching)
    return (
      <Box mt={4} w="100%" center>
        <Spinner text="Loading..." />
      </Box>
    )

  return (
    <CurrentUserContext.Provider value={{ currentUser, isLoading: isFetching }}>
      <AppRouting />
    </CurrentUserContext.Provider>
  )
}
