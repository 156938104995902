import { Box, Button, Table } from '@veracity/vui'

import { OrganizationSimple, UserSimple } from '@/interfaces'
import { PageLoader } from '@/components'

import { GuestUsersTableActionColumn } from './GuestUsersTableActionColumn'

interface Props {
  users?: UserSimple[]
  allOrganizations: OrganizationSimple[]
  isSyncButtonVisible: boolean
  isInProgress: boolean
  onSyncWithVeracityClicked: () => void
  onAddToOrganizationClicked: (userId: string, organizationId: number) => void
  onRemoveUserClicked: (userId: string) => void
}

export const GuestUsersContainer = ({
  users,
  allOrganizations,
  onSyncWithVeracityClicked,
  isSyncButtonVisible,
  isInProgress,
  onRemoveUserClicked,
  onAddToOrganizationClicked
}: Props) => {
  const rows = users?.map(user => ({
    ...user,
    actions: (
      <GuestUsersTableActionColumn
        userId={user.id}
        allOrganizations={allOrganizations}
        onRemoveUserClicked={onRemoveUserClicked}
        onAddToOrganizationClicked={onAddToOrganizationClicked}
      />
    )
  }))

  return (
    <Box p={2} column bg="white">
      {!isSyncButtonVisible ? null : (
        <Box justifyContent="flex-end">
          <Button
            iconLeft="falSync"
            disabled={isInProgress}
            size="lg"
            onClick={onSyncWithVeracityClicked}>
            Sync with Veracity
          </Button>
        </Box>
      )}

      {isInProgress ? (
        <PageLoader />
      ) : (
        <Table
          columns={[
            { field: 'firstName', title: 'First Name', isSortable: true },
            { field: 'lastName', title: 'Last name', isSortable: true },
            { field: 'email', title: 'Email', isSortable: true },
            { field: 'actions', title: 'Actions' }
          ]}
          rows={rows}
        />
      )}
    </Box>
  )
}
