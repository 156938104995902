import { ColorGetter, Icon, Tooltip } from '@veracity/vui'

interface BaseProps {
  tooltipText: string
  iconName?: string
  iconColor?: ColorGetter
}

type Props = Omit<BaseProps, 'iconName' | 'iconColor'>

const TooltipIconBase = ({ tooltipText, iconName, iconColor }: BaseProps) => (
  <Tooltip text={tooltipText}>
    <Icon name={iconName} role="button" size="sm" pathFill={iconColor} />
  </Tooltip>
)

const InfoTooltipIcon = ({ tooltipText }: Omit<Props, 'iconName'>) => (
  <TooltipIconBase iconName="falInfoCircle" tooltipText={tooltipText} />
)

const HelpTooltipIcon = ({ tooltipText }: Omit<Props, 'iconName'>) => (
  <TooltipIconBase iconName="falQuestionCircle" tooltipText={tooltipText} />
)

const WarningTooltipIcon = ({ tooltipText }: Omit<Props, 'iconName'>) => (
  <TooltipIconBase
    iconName="falExclamationCircle"
    tooltipText={tooltipText}
    iconColor="terracotta.main"
  />
)

const ErrorTooltipIcon = ({ tooltipText }: Omit<Props, 'iconName'>) => (
  <TooltipIconBase
    iconName="falExclamationCircle"
    tooltipText={tooltipText}
    iconColor="energyRed.main"
  />
)

export const TooltipIcon = TooltipIconBase as typeof TooltipIconBase & {
  Info: typeof InfoTooltipIcon
  Help: typeof HelpTooltipIcon
  Warning: typeof WarningTooltipIcon
  Error: typeof ErrorTooltipIcon
}

TooltipIcon.Info = InfoTooltipIcon
TooltipIcon.Help = HelpTooltipIcon
TooltipIcon.Warning = WarningTooltipIcon
TooltipIcon.Error = ErrorTooltipIcon
