import { ScenarioDetailed } from '@/interfaces'

import { ScenarioResultsSummaryTab } from './ScenarioResultsSummaryTab'
import { ScenarioResultsAggregatesTab } from './ScenarioResultsAggregatesTab'
import { ScenarioResultsTimeSeriesTab } from './ScenarioResultsTimeSeriesTab'
import { ScenarioResultsTabSectionType } from '../types'

interface Props {
  activeSection: ScenarioResultsTabSectionType
  scenarioInfo?: ScenarioDetailed
}
export const ScenarioResultsPageSectionContainer = ({
  activeSection,
  scenarioInfo
}: Props) => {
  if (!scenarioInfo) return null

  switch (activeSection) {
    case ScenarioResultsTabSectionType.SUMMARY:
      return <ScenarioResultsSummaryTab scenarioId={scenarioInfo.id} />
    case ScenarioResultsTabSectionType.AGGREGATES:
      return (
        <ScenarioResultsAggregatesTab
          scenarioId={scenarioInfo.id}
          scenarioConfiguration={scenarioInfo.systemConfiguration}
        />
      )
    case ScenarioResultsTabSectionType.TIME_SERIES:
      return (
        <ScenarioResultsTimeSeriesTab
          scenarioId={scenarioInfo.id}
          selectedYears={scenarioInfo.selectedRunYears || []}
        />
      )
  }
}
