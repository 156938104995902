import { useToast } from '@veracity/vui'
import { useMutation, useQuery, useQueryClient } from 'react-query'

import { portfoliosClient } from '@/api'
import { useNavigate } from 'react-router-dom'

const BASE_ROUTE = '/portfolios'
const BASE_QUERY_KEY = portfoliosClient.QUERY_KEY
export const usePortfoliosList = () => {
  const { showSuccess } = useToast()
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const { data, isFetching } = useQuery(BASE_QUERY_KEY, portfoliosClient.getAll)

  const addPortfoliosMutation = useMutation({
    mutationFn: portfoliosClient.createPortfolio,
    onSuccess: async (portfolioId: number) => {
      showSuccess('Portfolio successfully created')
      await queryClient.invalidateQueries(BASE_QUERY_KEY)
      navigate(`${BASE_ROUTE}/${portfolioId}`)
    }
  })

  return {
    isFetching: isFetching,
    portfolios: data || [],
    addPortfolio: addPortfoliosMutation.mutate,
    isAddPortfolioInProgress: addPortfoliosMutation.isLoading
  }
}
