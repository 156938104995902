import { CalculationStates } from '@/interfaces'

export const TABLE_PAGE_SIZE = 10

export const stateTitles = [
  { title: 'Pending', state: CalculationStates.NotSet },
  { title: 'Processing', state: CalculationStates.Preprocessing },
  { title: 'Calculating', state: CalculationStates.InProgress },
  { title: 'Failed', state: CalculationStates.Error },
  { title: 'Completed', state: CalculationStates.Finished }
]
