import { useToast } from '@veracity/vui'
import { useNavigate } from 'react-router-dom'
import { useMutation, useQuery, useQueryClient } from 'react-query'

import { config } from '@/config'
import { otherBessInputsLibraryClient } from '@/api'

const BASE_ROUTE = config.links.library.otherBessInputs
const BASE_QUERY_KEY = otherBessInputsLibraryClient.QUERY_KEY

export const useOtherBessInputs = () => {
  const { showSuccess } = useToast()
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const query = useQuery([...BASE_QUERY_KEY, { lockedOnly: false }], () =>
    otherBessInputsLibraryClient.getAll()
  )

  const addDatasetMutation = useMutation({
    mutationFn: otherBessInputsLibraryClient.addNewDataset,
    onSuccess: async (datasetId: number) => {
      await queryClient.invalidateQueries(BASE_QUERY_KEY, {
        refetchInactive: true
      })
      showSuccess('Dataset successfully added')
      navigate(`${BASE_ROUTE}/${datasetId}`)
    }
  })

  const cloneDatasetMutation = useMutation({
    mutationFn: otherBessInputsLibraryClient.cloneDataset,
    onSuccess: async (datasetId: number) => {
      await queryClient.invalidateQueries(BASE_QUERY_KEY, {
        refetchInactive: true
      })
      showSuccess('Dataset successfully cloned')
      navigate(`${BASE_ROUTE}/${datasetId}`)
    }
  })

  const removeDatasetMutation = useMutation({
    mutationFn: otherBessInputsLibraryClient.removeDataset,
    onSuccess: async () => {
      await queryClient.invalidateQueries(BASE_QUERY_KEY, {
        refetchInactive: true
      })
      showSuccess('Dataset successfully removed')
    }
  })

  return {
    datasets: query.data || [],
    isFetching: query.isFetching,
    addDataset: addDatasetMutation.mutate,
    isAddDatasetInProgress: addDatasetMutation.isLoading,
    removeDataset: removeDatasetMutation.mutate,
    cloneDataset: cloneDatasetMutation.mutate,
    isCloneDatasetInProgress: cloneDatasetMutation.isLoading
  }
}
