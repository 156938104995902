import { Input } from '@veracity/vui'

interface Props {
  onChange: (value: string) => void
}
export const PageSearchInput = ({ onChange }: Props) => (
  <Input
    size="lg"
    iconLeft="falSearch"
    placeholder="Search"
    type="search"
    w={250}
    onChange={ev => onChange(ev.target.value)}
  />
)
