import { InputFormControl, InputFormControlProps } from './InputFormControl'

export type InputPercentageFormControlProps = InputFormControlProps

export const InputPercentageFormControl = ({
  label,
  control,
  formControlName,
  ...props
}: InputPercentageFormControlProps) => (
  <InputFormControl
    control={control}
    formControlName={formControlName}
    type="number"
    min={0}
    max={100}
    maxLength={3}
    suffix="%"
    {...props}
  />
)
