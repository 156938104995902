import { Heading, Link, P } from '@veracity/vui'

import { config } from '@/config'

export const ContactSupport = () => (
  <>
    <Heading pt={1} size="h3">
      Need help ?
    </Heading>

    <P>
      You can contact our support team at{' '}
      <Link href={`mailto:${config.emails.heroSupport}`}>
        {config.emails.heroSupport}
      </Link>{' '}
      for technical and usage questions.
    </P>

    <P>
      For contracting questions, or questions related to billing and invoicing,
      please contact us at{' '}
      <Link href={`mailto:${config.emails.heroContracts}`}>
        {config.emails.heroContracts}
      </Link>
      .
    </P>
  </>
)
