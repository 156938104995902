import { useToast } from '@veracity/vui'
import { useNavigate } from 'react-router-dom'
import { useMutation, useQuery, useQueryClient } from 'react-query'

import { incomeAdjustmentClient, wholesaleMarketClient } from '@/api'

const BASE_ROUTE = '/library/income-adjustments'
const BASE_QUERY_KEY = wholesaleMarketClient.QUERY_KEY
export const useIncomeAdjustments = () => {
  const { showSuccess } = useToast()
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const incomeAdjustmentQuery = useQuery(
    [...BASE_QUERY_KEY, { lockedOnly: false }],
    () => incomeAdjustmentClient.getAll()
  )

  const addDatasetMutation = useMutation({
    mutationFn: incomeAdjustmentClient.addDataset,
    onSuccess: async (datasetId: number) => {
      showSuccess('Dataset successfully added')
      await queryClient.invalidateQueries(BASE_QUERY_KEY)
      navigate(`${BASE_ROUTE}/${datasetId}`)
    }
  })

  const cloneDatasetMutation = useMutation({
    mutationFn: incomeAdjustmentClient.cloneDataset,
    onSuccess: async (datasetId: number) => {
      showSuccess('Dataset successfully cloned')
      await queryClient.invalidateQueries(BASE_QUERY_KEY)
      navigate(`${BASE_ROUTE}/${datasetId}`)
    }
  })

  const removeDatasetMutation = useMutation({
    mutationFn: incomeAdjustmentClient.removeDataset,
    onSuccess: async () => {
      showSuccess('Dataset successfully removed')
      await queryClient.invalidateQueries(BASE_QUERY_KEY)
    }
  })

  return {
    incomeAdjustmentDatasets: incomeAdjustmentQuery.data || [],
    isFetching:
      incomeAdjustmentQuery.isFetching && !incomeAdjustmentQuery.isRefetching,
    addDataset: addDatasetMutation.mutate,
    isAddDatasetInProgress: addDatasetMutation.isLoading,
    cloneDataset: cloneDatasetMutation.mutate,
    isCloneDatasetInProgress: cloneDatasetMutation.isLoading,
    removeDataset: removeDatasetMutation.mutate
  }
}
