import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { Box, Button, Grid, Heading } from '@veracity/vui'

import { config } from '@/config'
import { ExternalApiKeySimple, KeyTypes } from '@/interfaces'

import { ApiManagementKeyValuePair } from '../ApiManagementKeyValuePair'

interface Props {
  keyType: KeyTypes
  externalApiKey?: ExternalApiKeySimple
  onCopyToClipboard: (apiKey?: string) => void
  onAddClicked: () => void
  onEditClicked: (apiKey: ExternalApiKeySimple) => void
}
export const ApiManagementOtherDnvSectionItem = ({
  keyType,
  externalApiKey,
  onCopyToClipboard,
  onAddClicked,
  onEditClicked
}: Props) => {
  const keyTypeHeader = useMemo(() => {
    switch (keyType) {
      case KeyTypes.SolarFarmer:
        return 'SOLAR FARMER'
      case KeyTypes.Src:
        return 'SOLAR RESOURCE COMPASS'
      default:
        return ''
    }
  }, [keyType])

  const supportMail = useMemo(() => {
    switch (keyType) {
      case KeyTypes.Src:
        return `mailto:${config.emails.srcSupport}`
      case KeyTypes.SolarFarmer:
        return `mailto:${config.emails.solarFarmerSupport}`
      default:
        return ''
    }
  }, [keyType])

  if (!externalApiKey?.key)
    return (
      <Box justify="space-between" w="100%">
        <Heading size="h3">{keyTypeHeader}</Heading>
        <Button iconLeft="falPlus" onClick={onAddClicked}>
          Add
        </Button>
      </Box>
    )

  return (
    <Box column>
      <Box justify="space-between" w="100%">
        <Heading size="h5">{keyTypeHeader}</Heading>

        <Box gap={2}>
          <Button
            variant="secondaryDark"
            iconLeft="falPen"
            onClick={() => onEditClicked(externalApiKey)}>
            Edit
          </Button>
          <Button
            variant="secondaryDark"
            iconLeft="falCopy"
            onClick={() => onCopyToClipboard(externalApiKey.key)}>
            Copy
          </Button>

          <Button
            as={Link}
            to={supportMail}
            variant="secondaryDark"
            iconLeft="falEnvelope">
            Contact
          </Button>
        </Box>
      </Box>
      <Grid gap={2} gridTemplateColumns={4}>
        <ApiManagementKeyValuePair
          label="Key"
          value={externalApiKey?.key}
          isSecret
        />
      </Grid>
    </Box>
  )
}
