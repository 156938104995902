import { useState } from 'react'
import { Box, Heading, Spinner, Switch } from '@veracity/vui'

import { PageLoader } from '@/components'

import { ScenarioResultsSummaryTabAdjustmentTable } from './ScenarioResultsSummaryTabAdjustmentTable'
import { ScenarioResultsSummaryTabInfoSection } from './ScenarioResultsSummaryTabInfoSection'
import { useScenarioResultsSummary } from '../../hooks'

interface Props {
  scenarioId: number
}
export const ScenarioResultsSummaryTab = ({ scenarioId }: Props) => {
  const [applyIncomeAdjustments, setApplyIncomeAdjustments] = useState(true)

  const { summaryInfo, isFetching, isRefetching } = useScenarioResultsSummary(
    scenarioId,
    applyIncomeAdjustments
  )

  return isFetching ? (
    <PageLoader />
  ) : (
    <Box column p={2} gap={3} bg="white">
      {!!summaryInfo && (
        <ScenarioResultsSummaryTabInfoSection summaryInfo={summaryInfo} />
      )}

      <Box justify="space-between">
        <Heading size="h5">
          {applyIncomeAdjustments
            ? 'Annual income prior to adjustment'
            : 'Annual income post adjustment'}
        </Heading>

        <Switch
          checked={applyIncomeAdjustments}
          onChange={ev => setApplyIncomeAdjustments(ev.target.checked)}
          labelRight="Apply income adjustment factor"
        />
      </Box>

      {isRefetching ? (
        <Spinner size="lg" />
      ) : (
        <ScenarioResultsSummaryTabAdjustmentTable
          systemConfiguration={summaryInfo?.systemConfiguration}
          summaryResults={summaryInfo?.bessOperatingIncomeResults || []}
        />
      )}
    </Box>
  )
}
