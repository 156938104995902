import { AxiosError } from 'axios'
import { useQuery } from 'react-query'
import { accountClient } from '@/api'
import { config } from '@/config'

export const useUserSession = () => {
  const handleAutoSignIn = (error: AxiosError) => {
    if (error.response?.status !== 401) throw error

    if (!['/about', '/'].includes(window.location.pathname)) {
      window.location.replace(
        `/account/signIn?redirectUrl=${window.location.pathname}`
      )
    }
    return undefined
  }

  const currentUserQuery = useQuery(
    ['userSession'],
    () => accountClient.getUserSession().catch(handleAutoSignIn),
    {
      refetchInterval: config.requestIntervals.userSession,
      retry: 0,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: true,
      staleTime: config.requestIntervals.userSession
    }
  )

  return {
    currentUser: currentUserQuery.data,
    isFetching: currentUserQuery.isFetching,
    isReFetching: currentUserQuery.isRefetching
  }
}
