import { useState } from 'react'
import { FieldValues, useForm } from 'react-hook-form'
import { Box, Button, Dialog, Spinner } from '@veracity/vui'

import { InputFormControl } from '@/components'

import { useOtherBessInputs } from '../hooks'

export const AddOtherBessInputsDatasetDialog = () => {
  const [isVisible, setIsVisible] = useState(false)
  const { control, handleSubmit, reset } = useForm()
  const { datasets, addDataset, isAddDatasetInProgress } = useOtherBessInputs()

  const handleClose = () => {
    if (isAddDatasetInProgress) return
    reset()
    setIsVisible(false)
  }

  const handleSubmitClick = (formFields: FieldValues) =>
    addDataset({ name: formFields.name }, { onSuccess: handleClose })

  const validateNameDuplication = (value?: string) =>
    !datasets.some(
      p => p.name?.toLocaleLowerCase() === value?.toLocaleLowerCase()
    )

  return (
    <>
      <Button size="lg" iconLeft="falPlus" onClick={() => setIsVisible(true)}>
        Add Dataset
      </Button>

      <Dialog
        isOpen={isVisible}
        title="Add Dataset"
        size="lg"
        onClose={handleClose}
        onSubmit={handleSubmit(handleSubmitClick)}
        cancelButton={{ text: 'Cancel', disabled: isAddDatasetInProgress }}
        submitButton={{
          text: 'Add',
          onClick: handleSubmit(handleSubmitClick),
          isLoading: isAddDatasetInProgress,
          disabled: isAddDatasetInProgress
        }}
        body={
          <Box column p={2} gap={2}>
            {isAddDatasetInProgress ? (
              <Spinner />
            ) : (
              <InputFormControl
                control={control}
                formControlName="name"
                label="Name"
                rules={{ required: true, validate: validateNameDuplication }}
              />
            )}
          </Box>
        }
      />
    </>
  )
}
