import { Button } from '@veracity/vui'

interface Props {
  isExpanded: boolean
  onToggle: (isExpanded: boolean) => void
}

export const ExpandToggleButton = ({ isExpanded, onToggle }: Props) => (
  <Button
    variant="tertiaryDark"
    size="lg"
    icon={isExpanded ? 'falChevronUp' : 'falChevronDown'}
    onClick={() => onToggle(!isExpanded)}
  />
)
