import { Box } from '@veracity/vui'

import { PredictionLogResponseItem } from '@/interfaces'

import { LogItemCard } from './LogItemCard'

interface Props {
  logItems?: PredictionLogResponseItem[]
}
export const LogItemList = ({ logItems }: Props) => (
  <Box column w="100%" gap={2}>
    {logItems?.map(logItem => (
      <LogItemCard key={logItem.scenarioId} logData={logItem} />
    ))}
  </Box>
)
