import { useQuery } from 'react-query'

import { wholesaleMarketClient } from '@/api'

export const useWholesaleMarketDatasetNames = () => {
  const { data } = useQuery(
    [...wholesaleMarketClient.QUERY_KEY, { lockedOnly: false }],
    () => wholesaleMarketClient.getAll()
  )

  return data?.map(x => x.name || '').filter(Boolean) || []
}
