import { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import {
  Box,
  Button,
  formatDate,
  Pagination,
  Table,
  Tbody,
  Td,
  Tfoot,
  Thead,
  Tr
} from '@veracity/vui'

import { orderByKey } from '@/utils'
import { useTableSorting } from '@/hooks'
import { ProjectSimple } from '@/interfaces'

import { TABLE_PAGE_SIZE } from '../../consts'

interface Props {
  projects: ProjectSimple[] | undefined
}

export const ProjectsListCollectionTable = ({ projects }: Props) => {
  const [pageIndex, setPageIndex] = useState(1)
  const { sort, handleSortChange } = useTableSorting<ProjectSimple>({
    key: 'name',
    order: 'asc'
  })

  useEffect(() => setPageIndex(1), [projects])

  const pageData = useMemo(
    () =>
      (projects || [])
        .slice((pageIndex - 1) * TABLE_PAGE_SIZE, pageIndex * TABLE_PAGE_SIZE)
        .sort(orderByKey(sort.key, sort.order)),
    [pageIndex, projects, sort]
  )

  return (
    <Table
      onSortChange={handleSortChange}
      sort={sort}
      columns={[
        { field: 'name', title: 'Project name', isSortable: true },
        { field: 'companyName', title: 'Company' },
        { field: 'isoBaName', title: 'ISO', isSortable: true },
        { field: 'createdBy', title: 'Created by', isSortable: true },
        { field: 'createdAt', title: 'Created date', isSortable: true },
        { field: 'id', title: ' ' }
      ]}>
      <Thead bg="white" />
      <Tbody bg="white">
        {pageData.map(project => (
          <Tr key={project.id}>
            <Td>
              <Box display="block" w="25vw" isTruncated>
                {project.name}
              </Box>
            </Td>
            <Td>{project.company}</Td>
            <Td>{project.isoBaName}</Td>
            <Td>{project.createdBy}</Td>
            <Td>{formatDate(project.createdAt, 'DD MM YYYY')}</Td>
            <Td>
              <Link to={`/projects/${project.id}`}>
                <Button variant="tertiaryDark" icon="falArrowRight" />
              </Link>
            </Td>
          </Tr>
        ))}
      </Tbody>
      <Tfoot>
        {!!projects?.length && (
          <Tr>
            <Td colSpan={6}>
              <Pagination
                count={projects?.length || 0}
                onPageChange={setPageIndex}
                page={pageIndex}
                pageSize={TABLE_PAGE_SIZE}
              />
            </Td>
          </Tr>
        )}
      </Tfoot>
    </Table>
  )
}
