import { useQuery } from 'react-query'

import { scenariosClient } from '@/api'

export const useScenarioResults = (scenarioId: number) => {
  const scenarioInfoQuery = useQuery(
    scenariosClient.QUERY_KEY(scenarioId),
    () => scenariosClient.getById(scenarioId)
  )

  return {
    scenarioInfo: scenarioInfoQuery.data,
    isFetching: scenarioInfoQuery.isFetching
  }
}
