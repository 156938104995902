import { useNavigate } from 'react-router-dom'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useToast } from '@veracity/vui'

import { organizationClient } from '@/api'
import { UpdateOrganizationRequest } from '@/interfaces'

const BASE_QUERY_KEY = organizationClient.QUERY_KEY

export const useOrganizationInfo = (organizationId: number) => {
  const QUERY_KEY = [...BASE_QUERY_KEY, organizationId]
  const { showSuccess } = useToast()
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const organizationInfoQuery = useQuery(QUERY_KEY, () =>
    organizationClient.getOrganizationInfo(organizationId)
  )

  const removeOrganizationMutation = useMutation({
    mutationFn: () => organizationClient.removeOrganization(organizationId),
    onSuccess: async () => {
      showSuccess('Organization successfully removed')
      navigate(`/administration`)
      queryClient.removeQueries(QUERY_KEY)
      await queryClient.invalidateQueries(BASE_QUERY_KEY, {
        refetchInactive: true
      })
    }
  })

  const updateOrganizationMutation = useMutation({
    mutationFn: (request: UpdateOrganizationRequest) =>
      organizationClient.updateOrganization(organizationId, request),
    onSuccess: async () => {
      showSuccess('Organization successfully updated')
      await queryClient.invalidateQueries(BASE_QUERY_KEY, {
        refetchInactive: true
      })
    }
  })

  return {
    organizationInfo: organizationInfoQuery.data,
    isFetchingOrganizationInfo: organizationInfoQuery.isFetching,
    removeOrganization: removeOrganizationMutation.mutate,
    updateOrganization: updateOrganizationMutation.mutate,
    isUpdateInProgress: updateOrganizationMutation.isLoading
  }
}
