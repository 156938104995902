import { Tag } from '@veracity/vui'

import { ScenarioTags } from '@/interfaces'

interface Props {
  scenarioTag: ScenarioTags
}

export const ProjectDetailsScenarioCardScenarioTag = ({
  scenarioTag
}: Props) => {
  switch (scenarioTag) {
    case ScenarioTags.Draft:
      return <Tag text="Draft" isRound variant="subtleYellow" />
    case ScenarioTags.Analysis:
      return <Tag text="Analysis" isRound variant="subtleBlue" />
    case ScenarioTags.Final:
      return <Tag text="Final" isRound variant="subtleGrey" />
    default:
      return null
  }
}
