import { useQuery } from 'react-query'
import { configurationClient } from '@/api'

export const usePricePlans = () => {
  const availablePricePlansQuery = useQuery(
    [...configurationClient.QUERY_KEY, 'pricePlans'],
    configurationClient.getAvailablePricingPlans
  )

  return availablePricePlansQuery.data || []
}
