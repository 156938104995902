import { useToast } from '@veracity/vui'
import { useNavigate } from 'react-router-dom'
import { useMutation, useQueryClient } from 'react-query'

import { projectsClient, scenariosClient } from '@/api'
import { CloneScenarioRequest, ScenarioTags } from '@/interfaces'

export const useProjectScenario = (projectId: number, scenarioId: number) => {
  const ROOT_QUERY_KEY = [...projectsClient.QUERY_KEY, projectId]

  const { showSuccess } = useToast()
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const removeScenarioMutation = useMutation({
    mutationFn: () => scenariosClient.removeById(scenarioId),
    onSuccess: async () => {
      showSuccess('Scenario successfully removed')
      await queryClient.invalidateQueries(ROOT_QUERY_KEY)
    }
  })

  const cloneScenarioMutation = useMutation({
    mutationFn: (req: CloneScenarioRequest) =>
      scenariosClient.clone(scenarioId, req),
    onSuccess: async (clonedScenarioId: number) => {
      showSuccess('Scenario successfully cloned')
      await queryClient.invalidateQueries(ROOT_QUERY_KEY)
      navigate(`/projects/${projectId}/scenarios/${clonedScenarioId}`)
    }
  })

  const updateScenarioTagMutation = useMutation({
    mutationFn: (tag: ScenarioTags) =>
      scenariosClient.updateTag(scenarioId, tag),
    onSuccess: async () => {
      showSuccess('Scenario successfully updated')
      await queryClient.invalidateQueries(ROOT_QUERY_KEY)
    }
  })

  return {
    removeScenario: removeScenarioMutation.mutate,
    isRemoveScenarioInProgress: removeScenarioMutation.isLoading,
    cloneScenario: cloneScenarioMutation.mutate,
    isCloneScenarioInProgress: cloneScenarioMutation.isLoading,
    updateScenarioTag: (tag: ScenarioTags, onSuccess: () => void) =>
      updateScenarioTagMutation.mutate(tag, { onSuccess: onSuccess }),
    isUpdateScenarioTagInProgress: updateScenarioTagMutation.isLoading
  }
}
