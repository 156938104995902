import { Input, InputProps, T } from '@veracity/vui'
import { BaseFormItem, BaseFormItemProps } from './BaseFormItem'

export interface InputFormControlProps extends BaseFormItemProps, InputProps {
  suffix?: string
}

export const InputFormControl = ({
  label,
  control,
  formControlName,
  rules,
  suffix,
  useOnBlurAsChange,
  ...props
}: InputFormControlProps) => (
  <BaseFormItem
    label={label}
    control={control}
    rules={rules}
    formControlName={formControlName}
    useOnBlurAsChange={useOnBlurAsChange}>
    <Input
      itemRight={!suffix ? undefined : <T px={1}>{suffix}</T>}
      {...props}
    />
  </BaseFormItem>
)
