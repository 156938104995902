import { useState } from 'react'
import { Link } from 'react-router-dom'
import { Box, Button, Grid, Heading, Menu, useDialog } from '@veracity/vui'

import { CalculationStates } from '@/interfaces'

import { ScenarioDetailsMenuBarEditDialog } from './ScenarioDetailsMenuBarEditDialog'
import { ScenarioDetailsMenuBarCloneDialog } from './ScenarioDetailsMenuBarCloneDialog'
import { ScenarioDetailsMenuBarUpdateTagDialog } from './ScenarioDetailsMenuBarUpdateTagDialog'
import { ScenarioConstraintDesignerDialog } from './ScenarioConstraintDesignerDialog'
import { ScenarioDetailsMenuBarCalculationProgress } from './ScenarioDetailsMenuBarCalculationProgress'
import { useScenarioDetails } from '../../hooks'

interface Props {
  projectId: number
  scenarioId: number
  isScenarioValid: boolean
}

export const ScenarioDetailsMenuBar = ({
  projectId,
  scenarioId,
  isScenarioValid
}: Props) => {
  const { showConfirm } = useDialog()
  const [isConstraintDesignerVisible, setIsConstraintDesignerVisible] =
    useState(false)
  const {
    scenarioInfo,
    updateScenarioName,
    isUpdateScenarioNameInProgress,
    removeScenario,
    isRemoveScenarioInProgress,
    cloneScenario,
    isCloneScenarioInProgress,
    updateScenarioTag,
    isUpdateScenarioTagInProgress,
    runCalculation,
    runCalculationInProgress,
    cancelCalculation,
    cancelCalculationInProgress
  } = useScenarioDetails(projectId, scenarioId)

  const handleRemoveScenario = async () => {
    const result = await showConfirm(
      'Remove scenario',
      'This operation is irreversible. Are you sure?'
    )
    if (result) removeScenario()
  }

  return (
    <Box column w="100%">
      <Grid
        gridTemplateColumns={{
          xxl: '1fr 1fr',
          xl: '1fr 2fr',
          lg: '1fr 3fr'
        }}
        w="100%">
        <Heading size="h3" isTruncated>
          {scenarioInfo?.name}
        </Heading>

        <Box gap={1} justifyContent="right">
          <ScenarioDetailsMenuBarEditDialog
            isDisabled={scenarioInfo?.state !== CalculationStates.NotSet && scenarioInfo?.state !== CalculationStates.Canceled}
            baseScenarioName={scenarioInfo?.name}
            onEdit={updateScenarioName}
            isEditInProgress={isUpdateScenarioNameInProgress}
          />
          <Button
            icon="falTrashAlt"
            size="lg"
            variant="tertiaryDark"
            onClick={handleRemoveScenario}
            disabled={isRemoveScenarioInProgress}
            isLoading={isRemoveScenarioInProgress}
          />

          <ScenarioDetailsMenuBarCloneDialog
            baseScenarioName={scenarioInfo?.name}
            onClone={cloneScenario}
            isCloneInProgress={isCloneScenarioInProgress}
          />

          <ScenarioDetailsMenuBarUpdateTagDialog
            currentValue={scenarioInfo?.tag}
            onTagUpdate={updateScenarioTag}
            isTagUpdateInProgress={isUpdateScenarioTagInProgress}
          />

          {(scenarioInfo?.state === CalculationStates.NotSet ||
            scenarioInfo?.state === CalculationStates.Canceled ||
            scenarioInfo?.state === CalculationStates.Error) && (
            <Button
              size="lg"
              onClick={runCalculation}
              disabled={runCalculationInProgress || !isScenarioValid}
              isLoading={runCalculationInProgress}>
              Run
            </Button>
          )}

          {(scenarioInfo?.state === CalculationStates.Preprocessing ||
            scenarioInfo?.state === CalculationStates.InProgress) && (
            <Button
              size="lg"
              variant="secondaryDark"
              onClick={cancelCalculation}
              disabled={cancelCalculationInProgress}
              isLoading={cancelCalculationInProgress}>
              Stop
            </Button>
          )}

          <Box>
            <Menu size="lg">
              <Menu.Button
                size="lg"
                variant="secondaryDark"
                isDropDown
                disabled={
                  scenarioInfo?.state !== CalculationStates.NotSet &&
                  scenarioInfo?.state !== CalculationStates.Canceled &&
                  scenarioInfo?.state !== CalculationStates.Error
                }>
                Constraints
              </Menu.Button>
              <Menu.List>
                <Menu.Item disabled>Upload constraint</Menu.Item>
                <Menu.Item onClick={() => setIsConstraintDesignerVisible(true)}>
                  Constraint designer
                </Menu.Item>
              </Menu.List>
            </Menu>

            {isConstraintDesignerVisible && (
              <ScenarioConstraintDesignerDialog
                scenarioId={scenarioId}
                calculationYears={scenarioInfo?.selectedRunYears || []}
                onClose={() => setIsConstraintDesignerVisible(false)}
              />
            )}
          </Box>

          {scenarioInfo?.state === CalculationStates.Finished && (
            <Button
              as={Link}
              to={`/projects/${scenarioInfo?.projectId}/scenarios/${scenarioId}/results`}
              size="lg"
              variant="secondaryDark">
              View Results
            </Button>
          )}
        </Box>
      </Grid>
      {(scenarioInfo?.state === CalculationStates.Preprocessing ||
        scenarioInfo?.state === CalculationStates.InProgress) && (
        <ScenarioDetailsMenuBarCalculationProgress scenarioId={scenarioId} />
      )}
    </Box>
  )
}
