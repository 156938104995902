import { useEffect } from 'react'
import { useForm } from 'react-hook-form'

import {
  OtherBessInputsSimple,
  UpdateOtherBessInputsDatasetRequest
} from '@/interfaces'

export const useOtherBessInputDatasetForm = (
  updateDataset: (req: UpdateOtherBessInputsDatasetRequest) => void,
  datasetInfo?: OtherBessInputsSimple
) => {
  const { control, formState, resetField, handleSubmit, watch, reset } =
    useForm()

  useEffect(
    () => reset({ isShadowPriceChecked: datasetInfo?.isShadowPriceChecked }),
    [datasetInfo, reset]
  )

  const isShadowPriceChecked = watch('isShadowPriceChecked')

  useEffect(() => {
    if (!isShadowPriceChecked) {
      resetField('shadowPriceFile', { defaultValue: undefined })
    } else {
      resetField('annualCycleCapFile', { defaultValue: undefined })
    }
  }, [resetField, isShadowPriceChecked])

  const isPercentageChainingFileSelected = watch(
    'percentageChargingFromRenewableFile'
  )

  const handleSubmitForm = handleSubmit(data =>
    updateDataset({
      isShadowPriceChecked: data?.isShadowPriceChecked,
      annualCycleCapFile: data?.annualCycleCapFile,
      shadowPriceFile: data?.shadowPriceFile,
      augmentationCapacityFile: data?.augmentationCapacityFile,
      percentageChargingFromRenewableFile:
        data?.percentageChargingFromRenewableFile,
      capacityRetentionFile: data?.capacityRetentionFile
    })
  )

  const isDisabled =
    datasetInfo?.isLocked ||
    datasetInfo?.isDefault ||
    datasetInfo?.isArchive ||
    false

  return {
    formControl: control,
    isFormValid: formState.isValid,
    isFormTouched: formState.isDirty,
    isFormDisabled: isDisabled,
    handleSubmitForm,
    isPercentageChainingFileSelected:
      isPercentageChainingFileSelected ||
      !!datasetInfo?.percentageChargingFromRenewableFileId
  }
}
