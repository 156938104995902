import axios from 'axios'
import {
  AddProjectRequest,
  ProjectSimple,
  ProjectWithScenarioSimple,
  UpdatePropertyRequest
} from '@/interfaces'

const baseUrl = '/api/projects'

export const projectsClient = {
  QUERY_KEY: ['projects'],
  getAll: () => axios.get<ProjectSimple[]>(baseUrl).then(x => x.data),
  getAllWithCompletedScenarios: () =>
    axios
      .get<ProjectWithScenarioSimple[]>(
        `${baseUrl}/GetProjectsWithCompletedScenarios`
      )
      .then(x => x.data),
  getById: (projectId: number) =>
    axios.get<ProjectSimple>(`${baseUrl}/${projectId}`).then(x => x.data),
  addNewProject: (req: AddProjectRequest) =>
    axios.post<number>(baseUrl, req).then(x => x.data),
  removeById: (projectId: number) =>
    axios.delete<void>(`${baseUrl}/${projectId}`).then(x => x.data),
  updateProjectName: (projectId: number, updatedName: string) =>
    axios.patch(`${baseUrl}/${projectId}`, {
      propertyName: 'name',
      propertyValue: updatedName
    } as UpdatePropertyRequest)
}
