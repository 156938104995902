import { ReactNode } from 'react'
import { Grid, T } from '@veracity/vui'

interface Props {
  label: string
  items: ReactNode[]
  isBolded?: boolean
}

export const ScenarioComparisonPreviewDataRow = ({
  label,
  items,
  isBolded
}: Props) => {
  const variant = isBolded ? 'label' : undefined
  return (
    <Grid gridTemplateColumns={`1fr repeat(${items.length}, minmax(0, 1fr))`}>
      <T p={1} variant={variant}>
        {label}
      </T>
      {items.map((item, index) => (
        <T key={index} p={1} variant={variant} align="right">
          {item === null || item === undefined || item === false ? '-' : item}
        </T>
      ))}
    </Grid>
  )
}
