import { Box, Checkbox } from '@veracity/vui'
import { TreeSelectItemOption } from './types'

interface Props {
  isDisabled?: boolean
  item: TreeSelectItemOption
}

export const TreeSelectItem = ({ item, isDisabled }: Props) => {
  const isIntermediate =
    item.children?.some(x => x.checked) && !item.children?.every(x => x.checked)

  const isChecked =
    item.checked !== undefined
      ? item.checked
      : !!item.children?.every(x => x.checked)

  return (
    <>
      <Checkbox
        label={item.label}
        checked={isChecked}
        disabled={isDisabled || item.isDisabled}
        isIndeterminate={isIntermediate}
        onChange={ev => item.onChange?.(ev.target.checked)}
      />
      {!!item.children && (
        <Box column ml={2} gap={2}>
          {item.children?.map((child, index) => (
            <Checkbox
              key={index}
              checked={!!child.checked}
              disabled={isDisabled || child.isDisabled}
              label={child.label}
              onChange={ev => child.onChange?.(ev.target.checked)}
            />
          ))}
        </Box>
      )}
    </>
  )
}
