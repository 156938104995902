import moment from 'moment'
import { useQuery } from 'react-query'

import { formatters } from '@/utils'
import { scenarioResultsClient } from '@/api'
import { SystemConfigurations } from '@/interfaces'

export const useAggregatedSummary = (
  scenarioId: number,
  systemConfiguration: SystemConfigurations,
  isAnnually: boolean
) => {
  const QUERY_KEY = [
    ...scenarioResultsClient.QUERY_KEY(scenarioId),
    'aggregatedResults',
    { isAnnually }
  ]

  const { data, isFetching } = useQuery(QUERY_KEY, () =>
    scenarioResultsClient.getAggregatedResults(scenarioId, isAnnually)
  )

  const sections = [
    {
      sectionTitle: 'PV Results',
      sectionItems: [
        {
          label: 'Total PV production (DC)',
          values: data?.map(x =>
            x.pvResult?.totalDcPvProduction === undefined
              ? undefined
              : formatters.number(x.pvResult?.totalDcPvProduction)
          )
        },
        {
          label: 'ES charge from PV - clipped (DC)',
          values: data?.map(x =>
            x.pvResult?.esDcChargeFromPvClipped === undefined
              ? undefined
              : formatters.number(x.pvResult?.esDcChargeFromPvClipped)
          )
        },
        {
          label: 'ES charge from PV - non-clipped (DC)',
          values: data?.map(x =>
            x.pvResult?.esChargeFromPvNonClipped === undefined
              ? undefined
              : formatters.number(x.pvResult?.esChargeFromPvNonClipped)
          )
        },
        {
          label: 'Inverter clipping (DC)',
          values: data?.map(x =>
            x.pvResult?.inverterClippingDc === undefined
              ? undefined
              : formatters.number(x.pvResult?.inverterClippingDc)
          )
        },
        {
          label: 'Economic curtailment (DC)',
          values: data?.map(x =>
            x.pvResult?.economicCurtailmentDc === undefined
              ? undefined
              : formatters.number(x.pvResult?.economicCurtailmentDc)
          )
        },
        {
          label: 'Grid export (DC)',
          values: data?.map(x =>
            x.pvResult?.gridExportDc === undefined
              ? undefined
              : formatters.number(x.pvResult?.gridExportDc)
          )
        },
        {
          label: 'Grid export (POI)',
          values: data?.map(x =>
            x.pvResult?.gridExportPoi === undefined
              ? undefined
              : formatters.number(x.pvResult?.gridExportPoi)
          )
        },
        {
          label: 'PV Revenue of grid export',
          values: data?.map(x =>
            x.pvResult?.pvRevenueOfGridExport === undefined
              ? undefined
              : formatters.currency(x.pvResult?.pvRevenueOfGridExport)
          )
        },
        {
          label: 'PV revenue loss due to ES operation',
          values: data?.map(x =>
            x.pvResult?.pvRevenueLossDueToEsOperation === undefined
              ? undefined
              : formatters.currency(x.pvResult?.pvRevenueLossDueToEsOperation)
          )
        },
        {
          label: 'Total PV income - without ES',
          values: data?.map(x =>
            x.pvResult?.totalPvIncomeWithoutEs === undefined
              ? undefined
              : formatters.currency(x.pvResult?.totalPvIncomeWithoutEs)
          )
        }
      ]
    },
    {
      sectionTitle: 'BESS results',
      sectionItems: [
        {
          label: 'Cycle',
          values: data?.map(x =>
            x.bessResults?.cycle === undefined
              ? undefined
              : formatters.number(x.bessResults?.cycle)
          )
        },
        {
          label: 'Average SOC',
          values: data?.map(x =>
            x.bessResults?.averageSoc === undefined
              ? undefined
              : formatters.percentage(x.bessResults?.averageSoc)
          )
        },
        {
          label: 'Total charge (DC)',
          values: data?.map(x =>
            x.bessResults?.totalChargeDc === undefined
              ? undefined
              : formatters.number(x.bessResults?.totalChargeDc)
          )
        },
        {
          label: 'Total discharge (DC)',
          values: data?.map(x =>
            x.bessResults?.totalDischargeDc === undefined
              ? undefined
              : formatters.number(x.bessResults?.totalDischargeDc)
          )
        },
        {
          label: 'Round-trip efficiency (DC)',
          values: data?.map(x =>
            x.bessResults?.roundTripEfficiencyDc === undefined
              ? undefined
              : formatters.percentage(x.bessResults?.roundTripEfficiencyDc)
          )
        },
        {
          label: 'Revenue of DA energy',
          values: data?.map(x =>
            x.bessResults?.revenueOfDaEnergy === undefined
              ? undefined
              : formatters.currency(x.bessResults?.revenueOfDaEnergy)
          )
        },
        {
          label: 'Revenue of RT energy',
          values: data?.map(x =>
            x.bessResults?.revenueOfRtEnergy === undefined
              ? undefined
              : formatters.currency(x.bessResults?.revenueOfRtEnergy)
          )
        },
        {
          label: 'Revenue of Reg up',
          values: data?.map(x =>
            x.bessResults?.revenueOfRegUp === undefined
              ? undefined
              : formatters.currency(x.bessResults?.revenueOfRegUp)
          )
        },
        {
          label: 'Revenue of Reg down',
          values: data?.map(x =>
            x.bessResults?.revenueOfRegDown === undefined
              ? undefined
              : formatters.currency(x.bessResults?.revenueOfRegDown)
          )
        },
        {
          label: 'Revenue of SR',
          values: data?.map(x =>
            x.bessResults?.revenueOfSr === undefined
              ? undefined
              : formatters.currency(x.bessResults?.revenueOfSr)
          )
        },
        {
          label: 'PV revenue loss due to ES operation',
          values: data?.map(x =>
            x.bessResults?.pvRevenueLossDueToEsOperation === undefined
              ? undefined
              : formatters.number(x.bessResults?.pvRevenueLossDueToEsOperation)
          )
        },
        {
          label: 'Total BESS income',
          values: data?.map(x =>
            x.bessResults?.totalBessIncome === undefined
              ? undefined
              : formatters.number(x.bessResults?.totalBessIncome)
          )
        }
      ]
    },
    {
      sectionTitle: 'DAM energy',
      sectionItems: [
        {
          label: 'Average LMP price',
          values: data?.map(x =>
            x.damEnergy?.averageLmpPrice === undefined
              ? undefined
              : formatters.currency(x.damEnergy?.averageLmpPrice)
          )
        },
        {
          label: 'Average sale price',
          values: data?.map(x =>
            x.damEnergy?.averageSalePrice === undefined
              ? undefined
              : formatters.currency(x.damEnergy?.averageSalePrice)
          )
        },
        {
          label: 'Average purchase price',
          values: data?.map(x =>
            x.damEnergy?.averagePurchasePrice === undefined
              ? undefined
              : formatters.currency(x.damEnergy?.averagePurchasePrice)
          )
        },
        {
          label: 'Total energy sale',
          values: data?.map(x =>
            x.damEnergy?.totalEnergySale === undefined
              ? undefined
              : formatters.number(x.damEnergy?.totalEnergySale)
          )
        },
        {
          label: 'Total energy purchase',
          values: data?.map(x =>
            x.damEnergy?.totalEnergyPurchase === undefined
              ? undefined
              : formatters.number(x.damEnergy?.totalEnergyPurchase)
          )
        },
        {
          label: 'Revenue of energy sale',
          values: data?.map(x =>
            x.damEnergy?.revenueOfEnergySale === undefined
              ? undefined
              : formatters.currency(x.damEnergy?.revenueOfEnergySale)
          )
        },
        {
          label: 'Cost of energy purchase',
          values: data?.map(x =>
            x.damEnergy?.costOfEnergyPurchase === undefined
              ? undefined
              : formatters.currency(x.damEnergy?.costOfEnergyPurchase)
          )
        }
      ]
    },
    {
      sectionTitle: 'RTM energy',
      sectionItems: [
        {
          label: 'Average LMP price',
          values: data?.map(x =>
            x.rtmEnergy?.averageLmpPrice === undefined
              ? undefined
              : formatters.currency(x.rtmEnergy?.averageLmpPrice)
          )
        },
        {
          label: 'Average sale price',
          values: data?.map(x =>
            x.rtmEnergy?.averageSalePrice === undefined
              ? undefined
              : formatters.currency(x.rtmEnergy?.averageSalePrice)
          )
        },
        {
          label: 'Average purchase price',
          values: data?.map(x =>
            x.rtmEnergy?.averagePurchasePrice === undefined
              ? undefined
              : formatters.currency(x.rtmEnergy?.averagePurchasePrice)
          )
        },
        {
          label: 'Total energy sale',
          values: data?.map(x =>
            x.rtmEnergy?.totalEnergySale === undefined
              ? undefined
              : formatters.number(x.rtmEnergy?.totalEnergySale)
          )
        },
        {
          label: 'Total energy purchase',
          values: data?.map(x =>
            x.rtmEnergy?.totalEnergyPurchase === undefined
              ? undefined
              : formatters.number(x.rtmEnergy?.totalEnergyPurchase)
          )
        },
        {
          label: 'Revenue of energy sale',
          values: data?.map(x =>
            x.rtmEnergy?.revenueOfEnergySale === undefined
              ? undefined
              : formatters.currency(x.rtmEnergy?.revenueOfEnergySale)
          )
        },
        {
          label: 'Cost of energy purchase',
          values: data?.map(x =>
            x.rtmEnergy?.costOfEnergyPurchase === undefined
              ? undefined
              : formatters.currency(x.rtmEnergy?.costOfEnergyPurchase)
          )
        }
      ]
    },
    {
      sectionTitle: 'Regulation up',
      sectionItems: [
        {
          label: 'Average price',
          values: data?.map(x =>
            x.regulationUp?.averagePrice === undefined
              ? undefined
              : formatters.currency(x.regulationUp?.averagePrice)
          )
        },
        {
          label: 'Average sale price',
          values: data?.map(x =>
            x.regulationUp?.averageSalePrice === undefined
              ? undefined
              : formatters.currency(x.regulationUp?.averageSalePrice)
          )
        },
        {
          label: 'Total capacity commitment',
          values: data?.map(x =>
            x.regulationUp?.totalCapacityCommitment === undefined
              ? undefined
              : formatters.number(x.regulationUp?.totalCapacityCommitment)
          )
        },
        {
          label: 'Total energy deployment',
          values: data?.map(x =>
            x.regulationUp?.totalEnergyDeployment === undefined
              ? undefined
              : formatters.number(x.regulationUp?.totalEnergyDeployment)
          )
        },
        {
          label: 'Revenue of reg capacity',
          values: data?.map(x =>
            x.regulationUp?.revenueOfRegCapacity === undefined
              ? undefined
              : formatters.currency(x.regulationUp?.revenueOfRegCapacity)
          )
        },
        {
          label: 'Revenue of reg energy',
          values: data?.map(x =>
            x.regulationUp?.revenueOfRegEnergy === undefined
              ? undefined
              : formatters.currency(x.regulationUp?.revenueOfRegEnergy)
          )
        },
        {
          label: 'Revenue of reg movement',
          values: data?.map(x =>
            x.regulationUp?.revenueOfRegMovement === undefined
              ? undefined
              : formatters.currency(x.regulationUp?.revenueOfRegMovement)
          )
        }
      ]
    },
    {
      sectionTitle: 'Regulation down',
      sectionItems: [
        {
          label: 'Average price',
          values: data?.map(x =>
            x.regulationDown?.averagePrice === undefined
              ? undefined
              : formatters.currency(x.regulationDown?.averagePrice)
          )
        },
        {
          label: 'Average sale price',
          values: data?.map(x =>
            x.regulationDown?.averageSalePrice === undefined
              ? undefined
              : formatters.currency(x.regulationDown?.averageSalePrice)
          )
        },
        {
          label: 'Total capacity commitment',
          values: data?.map(x =>
            x.regulationDown?.totalCapacityCommitment === undefined
              ? undefined
              : formatters.number(x.regulationDown?.totalCapacityCommitment)
          )
        },
        {
          label: 'Total energy deployment',
          values: data?.map(x =>
            x.regulationDown?.totalEnergyDeployment === undefined
              ? undefined
              : formatters.number(x.regulationDown?.totalEnergyDeployment)
          )
        },
        {
          label: 'Revenue of reg capacity',
          values: data?.map(x =>
            x.regulationDown?.revenueOfRegCapacity === undefined
              ? undefined
              : formatters.currency(x.regulationDown?.revenueOfRegCapacity)
          )
        },
        {
          label: 'Revenue of reg energy',
          values: data?.map(x =>
            x.regulationDown?.revenueOfRegEnergy === undefined
              ? undefined
              : formatters.currency(x.regulationDown?.revenueOfRegEnergy)
          )
        },
        {
          label: 'Revenue of reg movement',
          values: data?.map(x =>
            x.regulationDown?.revenueOfRegMovement === undefined
              ? undefined
              : formatters.currency(x.regulationDown?.revenueOfRegMovement)
          )
        }
      ]
    },
    {
      sectionTitle: 'Spinning reserve',
      sectionItems: [
        {
          label: 'Average price',
          values: data?.map(x =>
            x.spinningReserve?.averagePrice === undefined
              ? undefined
              : formatters.currency(x.spinningReserve?.averagePrice)
          )
        },
        {
          label: 'Average sale price',
          values: data?.map(x =>
            x.spinningReserve?.averageSalePrice === undefined
              ? undefined
              : formatters.currency(x.spinningReserve?.averageSalePrice)
          )
        },
        {
          label: 'Total capacity commitment',
          values: data?.map(x =>
            x.spinningReserve?.totalCapacityCommitment === undefined
              ? undefined
              : formatters.number(x.spinningReserve?.totalCapacityCommitment)
          )
        },
        {
          label: 'Revenue of SR capacity',
          values: data?.map(x =>
            x.spinningReserve?.revenueOfSrCapacity === undefined
              ? undefined
              : formatters.currency(x.spinningReserve?.revenueOfSrCapacity)
          )
        }
      ]
    }
  ]

  const dateSection = isAnnually
    ? {
        label: 'Year',
        values: data?.map(x => moment(x.date).format('YYYY'))
      }
    : {
        label: 'Month',
        values: data?.map(x => moment(x.date).format('M/YYYY'))
      }

  const returnedSections = [
    SystemConfigurations.Standalone,
    SystemConfigurations.WindOnShareBus
  ].includes(systemConfiguration)
    ? sections.filter(x => x.sectionTitle !== 'PV Results')
    : sections

  return {
    sections: returnedSections,
    dateSection,
    isFetching,
    areResultsEmpty: !data?.length && !isFetching
  }
}
