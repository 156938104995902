import { useParams } from 'react-router-dom'
import { isNumber } from '@veracity/vui'

export const useRouteIdParam = (paramName: string) => {
  const params = useParams()

  const paramValue = params[paramName]

  return !!paramValue && isNumber(+paramValue) ? +paramValue : undefined
}
