import { Box, Icon } from '@veracity/vui'
import styled from 'styled-components'

import { formatters } from '@/utils'
import { ScenarioCompareDetails, ScenarioCompareSimple } from '@/interfaces'

import { ScenarioComparisonPreviewCard } from './ScenarioComparisonPreviewCard'
import { ScenarioComparisonPreviewCardContainer } from './ScenarioComparisonPreviewCardContainer'

const FullScreenPrintableContainer = styled(Box)`
  @media print {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    padding: 5px;
    margin-left: -5px;
    background: white;
  }
`

interface Props {
  areAllRowsExpanded: boolean
  comparisonInfo: ScenarioCompareSimple[]
  detailedComparisonInfo?: ScenarioCompareDetails
}

export const ScenarioComparisonPreview = ({
  areAllRowsExpanded,
  comparisonInfo,
  detailedComparisonInfo
}: Props) => {
  return (
    <FullScreenPrintableContainer column gap={1}>
      <ScenarioComparisonPreviewCard
        areAllRowsExpanded={areAllRowsExpanded}
        items={[
          {
            label: 'Scenario',
            items: comparisonInfo.map(x => x.name),
            isBoldedRow: true
          },
          { label: 'Project', items: comparisonInfo.map(x => x.projectName) }
        ]}
      />

      <ScenarioComparisonPreviewCard
        title="Project Summary"
        areAllRowsExpanded={areAllRowsExpanded}
        items={[
          {
            label: 'System Configuration',
            items: comparisonInfo.map(x => x.systemConfigurationString),
            isCollapsableRow: true
          },
          {
            label: 'Term',
            items: comparisonInfo.map(x => x.term && `${x.term} year(s)`),
            isCollapsableRow: true
          },
          {
            label: 'IRR',
            items: comparisonInfo.map(
              x => x.irr && formatters.percentage(x.irr)
            ),
            isCollapsableRow: true
          },
          {
            label: 'Present Value of Total Expenses',
            items: comparisonInfo.map(
              x => x.totalExpenses && formatters.currency(x.totalExpenses)
            ),
            isCollapsableRow: true
          },
          {
            label: 'Present Value of Total Earnings',
            items: comparisonInfo.map(
              x => x.totalEarnings && formatters.currency(x.totalEarnings)
            ),
            isCollapsableRow: true
          },
          {
            label: 'Present Value of Total Profit',
            items: comparisonInfo.map(
              x => x.totalProfit && formatters.currency(x.totalProfit)
            ),
            isCollapsableRow: true
          },
          {
            label: 'ES Average Cycles',
            items: comparisonInfo.map(
              x => x.esAverageCycles && formatters.number(x.esAverageCycles)
            ),
            isCollapsableRow: true
          },
          {
            label: 'ES Average SOC',
            items: comparisonInfo.map(
              x => x.esAverageSOC && formatters.percentage(x.esAverageSOC)
            ),
            isCollapsableRow: true
          },
          {
            label: 'Interconnection Limit',
            items: comparisonInfo.map(
              x =>
                x.interconnectionLimit &&
                `${formatters.number(x.interconnectionLimit)} MW`
            ),
            isCollapsableRow: true
          },
          {
            label: 'Run Options',
            items: comparisonInfo.map(x => x.yearsToRunString),
            isCollapsableRow: true
          }
        ]}
      />

      <ScenarioComparisonPreviewCard
        title="Input Dataset Name"
        areAllRowsExpanded={areAllRowsExpanded}
        items={[
          {
            label: 'Wholesale Market Price',
            items: comparisonInfo.map(x => x.whosaleMarketDatasetName),
            isCollapsableRow: true
          },
          {
            label: 'Finances',
            items: comparisonInfo.map(x => x.financesDatasetName),
            isCollapsableRow: true
          },
          {
            label: 'Income Adjustment',
            items: comparisonInfo.map(x => x.incomeAdjustmentDatasetName),
            isCollapsableRow: true
          },
          {
            label: 'Loss Factors',
            items: comparisonInfo.map(x => x.lossFactorsDatasetName),
            isCollapsableRow: true
          },
          {
            label: 'Production Profiles',
            items: comparisonInfo.map(x => x.productionProfileDatasetName),
            isCollapsableRow: true
          },
          {
            label: 'Other BESS Inputs',
            items: comparisonInfo.map(x => x.otherBessInputDatasetName),
            isCollapsableRow: true
          }
        ]}
      />

      <ScenarioComparisonPreviewCard
        title="ES"
        areAllRowsExpanded={areAllRowsExpanded}
        items={[
          {
            label: 'Power Capacity',
            items: comparisonInfo.map(
              x =>
                x.esPowerCapacity &&
                `${formatters.number(x.esPowerCapacity)} MW`
            )
          },
          {
            label: 'Energy Capacity',
            items: comparisonInfo.map(
              x =>
                x.esEnergyCapacity &&
                `${formatters.number(x.esEnergyCapacity)} MWh`
            )
          },
          {
            label: 'Maximum Discharge Power',
            items: comparisonInfo.map(
              x =>
                x.maximumDischargePower &&
                `${formatters.number(x.maximumDischargePower)} MW`
            ),
            isCollapsableRow: true
          }
        ]}
      />

      <ScenarioComparisonPreviewCard
        title="PV"
        areAllRowsExpanded={areAllRowsExpanded}
        items={[
          {
            label: 'DC Capacity',
            items: comparisonInfo.map(
              x => x.pvDcCapacity && `${formatters.number(x.pvDcCapacity)} MW`
            )
          },
          {
            label: 'AC Capacity',
            items: comparisonInfo.map(
              x => x.pvAcCapacity && `${formatters.number(x.pvAcCapacity)} MW`
            )
          },
          {
            label: 'DC-AC Inverter Efficiency',
            items: comparisonInfo.map(
              x =>
                x.maximumDischargePower &&
                formatters.percentage(x.maximumDischargePower)
            ),
            isCollapsableRow: true
          },
          {
            label: 'Annual Derating',
            items: comparisonInfo.map(
              x =>
                x.pvAnnualDerating && formatters.percentage(x.pvAnnualDerating)
            ),
            isCollapsableRow: true
          }
        ]}
      />

      <ScenarioComparisonPreviewCard
        title="Wind"
        areAllRowsExpanded={areAllRowsExpanded}
        items={[
          {
            label: 'AC Capacity',
            items: comparisonInfo.map(
              x =>
                x.windAcCapacity && `${formatters.number(x.windAcCapacity)} MW`
            )
          },
          {
            label: 'Total Efficiency',
            items: comparisonInfo.map(
              x =>
                x.windTotalEfficiency &&
                formatters.percentage(x.windTotalEfficiency)
            ),
            isCollapsableRow: true
          }
        ]}
      />

      <ScenarioComparisonPreviewCard
        title="Market & Finance"
        areAllRowsExpanded={areAllRowsExpanded}
        items={[
          {
            label: 'Real-Time Energy Participation Limit',
            items: comparisonInfo.map(x =>
              x.realTimeEnergyParticipationLimit ? (
                <Icon name="falCheck" pathFill="landGreen.41" />
              ) : (
                <Icon name="falTimes" pathFill="energyRed.54" />
              )
            ),
            isCollapsableRow: true
          },
          {
            label: 'Regulation Participation Limit',
            items: comparisonInfo.map(x =>
              x.regulationParticipationLimit ? (
                <Icon name="falCheck" pathFill="landGreen.41" />
              ) : (
                <Icon name="falTimes" pathFill="energyRed.54" />
              )
            ),
            isCollapsableRow: true
          },
          {
            label: 'Spinning Reserve Participation Limit',
            items: comparisonInfo.map(x =>
              x.spinningReserveParticipationLimit ? (
                <Icon name="falCheck" pathFill="landGreen.41" />
              ) : (
                <Icon name="falTimes" pathFill="energyRed.54" />
              )
            ),
            isCollapsableRow: true
          }
        ]}
      />

      <ScenarioComparisonPreviewCardContainer
        title="Cashflow"
        items={detailedComparisonInfo?.yearlyData?.map(yearlyData => ({
          title: `${yearlyData.year}`,
          areAllRowsExpanded,
          items: [
            {
              label: 'ES Operating Income',
              items: yearlyData.cashflow?.map(
                cashflow =>
                  !!cashflow && formatters.currency(cashflow['ES Operating'])
              ),
              isCollapsableRow: true
            },
            {
              label: 'PV Operating Income',
              items: yearlyData.cashflow?.map(
                cashflow =>
                  !!cashflow && formatters.currency(cashflow['PV Operating'])
              ),
              isCollapsableRow: true
            },
            {
              label: 'Capacity Market Income',
              items: yearlyData.cashflow?.map(
                cashflow =>
                  !!cashflow && formatters.currency(cashflow['Capacity Market'])
              ),
              isCollapsableRow: true
            },
            {
              label: 'Operating Expenditure',
              items: yearlyData.cashflow?.map(
                cashflow =>
                  !!cashflow &&
                  formatters.currency(cashflow['Operating Expenditure'])
              ),
              isCollapsableRow: true
            },
            {
              label: 'Equity Payment',
              items: yearlyData.cashflow?.map(
                cashflow =>
                  !!cashflow && formatters.currency(cashflow['Equity Payment'])
              ),
              isCollapsableRow: true
            },
            {
              label: 'Federal ITC Rebate',
              items: yearlyData.cashflow?.map(
                cashflow =>
                  !!cashflow &&
                  formatters.currency(cashflow['Federal ITC Rebate'])
              ),
              isCollapsableRow: true
            },
            {
              label: 'Tax Depreciation',
              items: yearlyData.cashflow?.map(
                cashflow =>
                  !!cashflow &&
                  formatters.currency(cashflow['Tax Depreciation'])
              ),
              isCollapsableRow: true
            },
            {
              label: 'Pre-Tax Cashflow',
              items: yearlyData.cashflow?.map(
                cashflow =>
                  !!cashflow &&
                  formatters.currency(cashflow['ES Pre-Tax Cashflow'])
              ),
              isCollapsableRow: true
            },
            {
              label: 'Cumulative Cashflow',
              items: yearlyData.cashflow?.map(
                cashflow =>
                  !!cashflow &&
                  formatters.currency(cashflow['ES Cumulative Cashflow'])
              ),
              isCollapsableRow: true
            },
            {
              label: 'ES Average Cycles',
              items: yearlyData.cashflow?.map(
                cashflow =>
                  cashflow?.['ES Average Cycles'] &&
                  formatters.number(cashflow['ES Average Cycles'])
              ),
              isCollapsableRow: true
            },
            {
              label: 'ES Average SOC',
              items: yearlyData.cashflow?.map(
                cashflow =>
                  cashflow?.['ES Average SOC'] &&
                  formatters.percentage(cashflow['ES Average SOC'])
              ),
              isCollapsableRow: true
            }
          ]
        }))}
      />

      <ScenarioComparisonPreviewCardContainer
        title="ES Operating Revenue"
        items={detailedComparisonInfo?.yearlyData?.map(yearlyData => ({
          title: `${yearlyData.year}`,
          areAllRowsExpanded,
          items: [
            {
              label: 'DAE ',
              items: yearlyData.esOperatingRevenue?.map(
                operatingRevenue =>
                  !!operatingRevenue &&
                  formatters.currency(operatingRevenue.DAE)
              ),
              isCollapsableRow: true
            },
            {
              label: 'RTE  ',
              items: yearlyData.esOperatingRevenue?.map(
                operatingRevenue =>
                  !!operatingRevenue &&
                  formatters.currency(operatingRevenue.RTE)
              ),
              isCollapsableRow: true
            },
            {
              label: 'Regulation Up',
              items: yearlyData.esOperatingRevenue?.map(
                operatingRevenue =>
                  !!operatingRevenue &&
                  formatters.currency(operatingRevenue['Regulation Up'])
              ),
              isCollapsableRow: true
            },
            {
              label: 'Regulation Down',
              items: yearlyData.esOperatingRevenue?.map(
                operatingRevenue =>
                  !!operatingRevenue &&
                  formatters.currency(operatingRevenue['Regulation Down'])
              ),
              isCollapsableRow: true
            },
            {
              label: 'Spinning Reserve ',
              items: yearlyData.esOperatingRevenue?.map(
                operatingRevenue =>
                  !!operatingRevenue &&
                  formatters.currency(operatingRevenue['Spinning Reserve'])
              ),
              isCollapsableRow: true
            },
            {
              label: 'Total ',
              items: yearlyData.esOperatingRevenue?.map(
                operatingRevenue =>
                  !!operatingRevenue &&
                  formatters.currency(operatingRevenue.Total)
              )
            }
          ]
        }))}
      />

      <ScenarioComparisonPreviewCardContainer
        title="ES Operating Cost"
        items={detailedComparisonInfo?.yearlyData?.map(yearlyData => ({
          title: `${yearlyData.year}`,
          areAllRowsExpanded,
          items: [
            {
              label: 'DAE ',
              items: yearlyData.esOperatingCost?.map(
                operatingCost =>
                  !!operatingCost && formatters.currency(operatingCost.DAE)
              ),
              isCollapsableRow: true
            },
            {
              label: 'RTE ',
              items: yearlyData.esOperatingCost?.map(
                operatingCost =>
                  !!operatingCost && formatters.currency(operatingCost.RTE)
              ),
              isCollapsableRow: true
            },
            {
              label: 'PV ',
              items: yearlyData.esOperatingCost?.map(
                operatingCost =>
                  !!operatingCost && formatters.currency(operatingCost.PV)
              ),
              isCollapsableRow: true
            },
            {
              label: 'Wind  ',
              items: yearlyData.esOperatingCost?.map(
                operatingCost =>
                  !!operatingCost && formatters.currency(operatingCost.Wind)
              ),
              isCollapsableRow: true
            },
            {
              label: 'Total',
              items: yearlyData.esOperatingCost?.map(
                operatingCost =>
                  !!operatingCost && formatters.currency(operatingCost.Total)
              )
            }
          ]
        }))}
      />

      <ScenarioComparisonPreviewCardContainer
        title="Resource Income"
        items={detailedComparisonInfo?.yearlyData?.map(yearlyData => ({
          title: `${yearlyData.year}`,
          areAllRowsExpanded,
          items: [
            {
              label: 'ES Operating Income ',
              items: yearlyData.resourceIncome?.map(
                resourceIncome =>
                  !!resourceIncome &&
                  formatters.currency(resourceIncome['ES Operating Income'])
              ),
              isCollapsableRow: true
            },
            {
              label: 'PV Operating Income',
              items: yearlyData.resourceIncome?.map(
                resourceIncome =>
                  !!resourceIncome &&
                  formatters.currency(resourceIncome['PV Operating Income'])
              ),
              isCollapsableRow: true
            },
            {
              label: 'Wind Operating Income',
              items: yearlyData.resourceIncome?.map(
                resourceIncome =>
                  !!resourceIncome &&
                  formatters.currency(resourceIncome['Wind Operating Income'])
              ),
              isCollapsableRow: true
            },

            {
              label: 'Total',
              items: yearlyData.resourceIncome?.map(
                resourceIncome =>
                  !!resourceIncome &&
                  formatters.currency(
                    resourceIncome['ES Operating Income'] +
                      resourceIncome['PV Operating Income'] +
                      resourceIncome['Wind Operating Income']
                  ) // TODO: Add Total at backend
              )
            }
          ]
        }))}
      />

      <ScenarioComparisonPreviewCard
        title="Project Cost"
        areAllRowsExpanded={areAllRowsExpanded}
        items={[
          {
            label: 'Total PV Cost',
            items: detailedComparisonInfo?.details?.map(
              x =>
                x.projectCost &&
                formatters.currency(x.projectCost['Total PV Cost'])
            ),
            isCollapsableRow: true
          },
          {
            label: 'Total ES Cost',
            items: detailedComparisonInfo?.details?.map(
              x =>
                x.projectCost &&
                formatters.currency(x.projectCost['Total ES Cost'])
            ),
            isCollapsableRow: true
          },
          {
            label: 'Total Development Cost',
            items: detailedComparisonInfo?.details?.map(
              x =>
                x.projectCost &&
                formatters.currency(x.projectCost['Total Development Cost'])
            ),
            isCollapsableRow: true
          },
          {
            label: 'Total Wind Cost',
            items: detailedComparisonInfo?.details?.map(
              x =>
                x.projectCost &&
                formatters.currency(x.projectCost['Total Wind Cost'])
            ),
            isCollapsableRow: true
          },
          {
            label: 'Total Equipment Sales Tax',
            items: detailedComparisonInfo?.details?.map(
              x =>
                x.projectCost &&
                formatters.currency(x.projectCost['Total Equip Sales Tax'])
            ),
            isCollapsableRow: true
          },
          {
            label: 'Total',
            items: detailedComparisonInfo?.details?.map(x =>
              formatters.currency(x.projectCostTotal)
            )
          }
        ]}
      />

      <ScenarioComparisonPreviewCard
        title="ES Cost"
        areAllRowsExpanded={areAllRowsExpanded}
        items={[
          {
            label: 'DC Side',
            items: detailedComparisonInfo?.details?.map(
              x =>
                x.esCost?.['DC Side'] &&
                formatters.currency(x.esCost['DC Side'])
            ),
            isCollapsableRow: true
          },
          {
            label: 'Power Conversion System',
            items: detailedComparisonInfo?.details?.map(
              x =>
                x.esCost?.['Power Conversion System'] &&
                formatters.currency(x.esCost['Power Conversion System'])
            ),
            isCollapsableRow: true
          },
          {
            label: 'Grid Management System',
            items: detailedComparisonInfo?.details?.map(
              x =>
                x.esCost?.['Grid Management System'] &&
                formatters.currency(x.esCost['Grid Management System'])
            ),
            isCollapsableRow: true
          },
          {
            label: 'LV Xmer and Switchgear',
            items: detailedComparisonInfo?.details?.map(
              x =>
                x.esCost?.['LV Xmer and Switchgear'] &&
                formatters.currency(x.esCost['LV Xmer and Switchgear'])
            ),
            isCollapsableRow: true
          },
          {
            label: 'Total',
            items: detailedComparisonInfo?.details?.map(x =>
              formatters.currency(x.esCostTotal)
            )
          }
        ]}
      />

      <ScenarioComparisonPreviewCard
        title="Development Cost"
        areAllRowsExpanded={areAllRowsExpanded}
        items={[
          {
            label: 'Site Infrastructure',
            items: detailedComparisonInfo?.details?.map(
              x =>
                x.developmentCost?.['Site Infrastructure'] &&
                formatters.currency(x.developmentCost['Site Infrastructure'])
            ),
            isCollapsableRow: true
          },
          {
            label: 'Interconnection and Network Upgrade',
            items: detailedComparisonInfo?.details?.map(
              x =>
                x.developmentCost?.['Interconnection and Network Upgrade'] &&
                formatters.currency(
                  x.developmentCost?.['Interconnection and Network Upgrade']
                )
            ),
            isCollapsableRow: true
          },
          {
            label: 'Owner Cost',
            items: detailedComparisonInfo?.details?.map(
              x =>
                x.developmentCost?.['Owner Cost'] &&
                formatters.currency(x.developmentCost['Owner Cost'])
            ),
            isCollapsableRow: true
          },
          {
            label: 'Total',
            items: detailedComparisonInfo?.details?.map(x =>
              formatters.currency(x.developmentCostTotal)
            )
          }
        ]}
      />

      <ScenarioComparisonPreviewCard
        title="PV Cost"
        areAllRowsExpanded={areAllRowsExpanded}
        items={[
          {
            label: 'Module Equipment',
            items: detailedComparisonInfo?.details?.map(
              x => x.pvCost && formatters.currency(x.pvCost['Module Equipment'])
            ),
            isCollapsableRow: true
          },
          {
            label: 'Inverter and Control',
            items: detailedComparisonInfo?.details?.map(
              x =>
                x.pvCost &&
                formatters.currency(x.pvCost['Inverter and Control'])
            ),
            isCollapsableRow: true
          },
          {
            label: 'Engineering and Construction',
            items: detailedComparisonInfo?.details?.map(
              x =>
                x.pvCost &&
                formatters.currency(x.pvCost['Engg and Construction'])
            ),
            isCollapsableRow: true
          },
          {
            label: 'LV Xmer and Switchgear',
            items: detailedComparisonInfo?.details?.map(
              x =>
                x.pvCost &&
                formatters.currency(x.pvCost['LV Xmer and Switchgear'])
            ),
            isCollapsableRow: true
          },
          {
            label: 'Total',
            items: detailedComparisonInfo?.details?.map(x =>
              formatters.currency(x.pvCostTotal)
            )
          }
        ]}
      />
    </FullScreenPrintableContainer>
  )
}
