import { config } from '@/config'
import { DatasetSimple } from '@/interfaces'
import { LibraryDatasetTable } from '@/components'

import { useWholesaleMarketDatasets } from '../hooks'

interface Props {
  datasets: DatasetSimple[]
}

export const WholesaleMarketDatasetsTable = ({ datasets }: Props) => {
  const { cloneDataset, isCloneDatasetInProgress, removeDataset } =
    useWholesaleMarketDatasets()

  const allDatasetNames = datasets.map(x => x.name || '')

  const handleCloneDataset = (
    datasetId: number,
    datasetName: string,
    onSuccess: () => void
  ) => {
    cloneDataset({ datasetId, name: datasetName }, { onSuccess })
  }

  return (
    <LibraryDatasetTable
      datasets={datasets}
      getDetailsLink={datasetId =>
        `${config.links.library.wholesaleMarket}/${datasetId}`
      }
      isCloneInProgress={isCloneDatasetInProgress}
      onRemoveClicked={datasetId => removeDataset(datasetId)}
      onCloneClicked={handleCloneDataset}
      allDatasetNames={allDatasetNames}
    />
  )
}
