import { Fragment } from 'react'
import { Box, Grid, Heading, T } from '@veracity/vui'

import { formatters, getDurationInYears } from '@/utils'
import { SummaryResult, SystemConfigurations } from '@/interfaces'

interface Props {
  summaryInfo: SummaryResult
}

export const ScenarioResultsSummaryTabInfoSection = ({
  summaryInfo
}: Props) => {
  const durationInYears = getDurationInYears(
    summaryInfo.startYear,
    summaryInfo.endYear
  )

  const items = [
    {
      key: 'Project Term',
      value: `${durationInYears} year(s) (${summaryInfo.startYear} - ${summaryInfo.endYear})`
    },
    {
      key: 'System Configuration',
      value: summaryInfo.systemConfigurationName
    },
    {
      key: 'Market Participation',
      value: '(DAM) Energy, (DAM) Reg up, Reg Down, SR, (RTM) Energy'
    },
    {
      key: 'Interconnection Capacity',
      value:
        summaryInfo.interconnectionCapacity === undefined
          ? '-'
          : `${formatters.number(summaryInfo.interconnectionCapacity)} MW`
    },
    {
      key: 'Solar PV capacity (DC)',
      value:
        summaryInfo.solarPvCapacityDc === undefined
          ? '-'
          : `${formatters.number(summaryInfo.solarPvCapacityDc)} MW`,
      disabled:
        summaryInfo.systemConfiguration === SystemConfigurations.Standalone
    },
    {
      key: 'Inverter Capacity (AC)',
      value:
        summaryInfo.inverterCapacityAc === undefined
          ? '-'
          : `${formatters.number(summaryInfo.inverterCapacityAc)} MW`,
      disabled:
        summaryInfo.systemConfiguration === SystemConfigurations.Standalone
    },
    {
      key: 'Inverter Loading Ratio',
      value:
        summaryInfo.inverterLoadingRatio === undefined
          ? '-'
          : formatters.number(summaryInfo.inverterLoadingRatio),
      disabled:
        summaryInfo.systemConfiguration === SystemConfigurations.Standalone
    },
    {
      key: 'BESS Energy Capacity (DC)',
      value:
        summaryInfo.bessEnergyCapacityDc === undefined
          ? '-'
          : `${formatters.number(summaryInfo.bessEnergyCapacityDc)} MWh`
    },
    {
      key: 'BESS Energy Capacity (POI)',
      value:
        summaryInfo.bessEnergyCapacityPoi === undefined
          ? '-'
          : `${formatters.number(summaryInfo.bessEnergyCapacityPoi)} MWh`
    },
    {
      key: 'BESS Duration',
      value:
        summaryInfo.bessDuration === undefined
          ? '-'
          : `${formatters.number(summaryInfo.bessDuration)} hr`
    },
    {
      key: 'Minimum SOC limit',
      value:
        summaryInfo.minimumSocLimit === undefined
          ? '-'
          : formatters.percentage(summaryInfo.minimumSocLimit)
    },
    {
      key: 'Maximum SOC limit',
      value:
        summaryInfo.maximumSocLimit === undefined
          ? '-'
          : formatters.percentage(summaryInfo.maximumSocLimit)
    },
    {
      key: 'DC-AC inverter efficiency',
      value:
        summaryInfo.dcAcInverterEfficiency === undefined
          ? '-'
          : formatters.percentage(summaryInfo.dcAcInverterEfficiency)
    },
    {
      key: 'AC losses between PCC and POI',
      value:
        summaryInfo.acLossesBetweenPccAndPoi === undefined
          ? '-'
          : formatters.percentage(summaryInfo.acLossesBetweenPccAndPoi)
    }
  ].filter(x => !x.disabled)

  return (
    <Box column gap={2}>
      <Heading size="h5">Scenario inputs</Heading>

      <Grid gridTemplateColumns={2} gap={2}>
        {items.map(item => (
          <Fragment key={item.key}>
            <T>{item.key}</T>
            <T>{item.value}</T>
          </Fragment>
        ))}
      </Grid>
    </Box>
  )
}
