const locales = 'en-US'

export const formatters = {
  currency: (val: number) =>
    val === null
      ? undefined
      : new Intl.NumberFormat(locales, {
          style: 'currency',
          currency: 'USD'
        }).format(+val.toFixed(2)),
  percentage: (val: number) =>
    val === null
      ? null
      : new Intl.NumberFormat(locales, {
          style: 'percent',
          minimumFractionDigits: 0,
          maximumFractionDigits: 2
        }).format(val),
  number: (val: number) =>
    val === null
      ? null
      : new Intl.NumberFormat(locales, {
          minimumFractionDigits: 0,
          maximumFractionDigits: 2
        }).format(+val.toFixed(2))
}
