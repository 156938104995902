import { CsvFileUploader } from '@/components'
import { useWholesaleDataset } from '../hooks'
import { DatasetFileTypes, WholesaleMarketDatasetFileInfo } from '@/interfaces'

interface Props {
  datasetId?: number
  title: string
  tooltipText: string
  isDisabled: boolean
  fileType: DatasetFileTypes
  uploadedFile?: WholesaleMarketDatasetFileInfo
}

export const WholesaleMarketDetailsUploadSectionUploader = ({
  datasetId,
  isDisabled,
  title,
  tooltipText,
  fileType,
  uploadedFile
}: Props) => {
  const {
    uploadDatasetFile,
    isDatasetFileUploading,
    removeDatasetFile,
    getDownloadFileUrl
  } = useWholesaleDataset(datasetId || 0)

  const handleFileUpload = (fileType: string, file: File) => {
    uploadDatasetFile({ file: file, fileType: fileType })
  }
  const handleRemoveDatasetFile = () => {
    if (uploadedFile?.id) removeDatasetFile(uploadedFile.id)
  }

  return (
    <CsvFileUploader
      title={title}
      tooltipText={tooltipText}
      isDisabled={isDisabled}
      uploadedFileName={uploadedFile?.name}
      downloadFileUrl={getDownloadFileUrl(uploadedFile?.id)}
      onFileAdded={file => handleFileUpload(DatasetFileTypes[fileType], file)}
      isUploading={isDatasetFileUploading}
      onRemoveFileClicked={handleRemoveDatasetFile}
    />
  )
}
