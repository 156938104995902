import { useMutation } from 'react-query'

import { scenarioResultsClient } from '@/api'

import { DownloadResultsSectionNames } from '../types'
import { downloadFromBlob } from '@/utils'

export const useDownloadResults = (
  scenarioId: number,
  sections: DownloadResultsSectionNames[]
) => {
  const downloadFileMutation = useMutation({
    mutationFn: () =>
      scenarioResultsClient.downloadResultsFile(scenarioId, sections),
    onSuccess: result =>
      downloadFromBlob(`CalculationResults_${scenarioId}.zip`, result)
  })

  return {
    downloadFile: downloadFileMutation.mutate,
    isDownloading: downloadFileMutation.isLoading
  }
}
