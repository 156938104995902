import { useEffect, useState } from 'react'
import { Box, Modal } from '@veracity/vui'
import { useToggle } from '@veracity/ui-utils'

import { config } from '@/config'

import { useTimeSeriesChartData } from '../../hooks'
import { ScenarioResultsTimeSeriesContent } from './ScenarioResultsTimeSeriesContent'

interface Props {
  scenarioId: number
  selectedYears: number[]
}

export const ScenarioResultsTimeSeriesTab = ({
  scenarioId,
  selectedYears
}: Props) => {
  const [selectedDate, setSelectedDate] = useState<Date>()
  const [isCollapsedView, toggleIsCollapsedView] = useToggle(true)

  const { isFetching, timeSeriesData, scenarioConfiguration } =
    useTimeSeriesChartData(scenarioId, selectedDate)

  useEffect(() => {
    handleChangeYear(selectedYears[0])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedYears])

  const handleChangeYear = (year: number) =>
    handleChangeDate(new Date(`1/1/${year}`))

  const handleChangeDate = (date?: Date) => setSelectedDate(date)

  return isCollapsedView ? (
    <ScenarioResultsTimeSeriesContent
      selectedDate={selectedDate}
      onSelectedDateChanged={handleChangeDate}
      onSelectedYearChanged={handleChangeYear}
      allowedYears={selectedYears}
      isCollapsedView={isCollapsedView}
      onToggleIsCollapsedView={toggleIsCollapsedView}
      isFetchingData={isFetching}
      timeSeriesData={timeSeriesData}
      scenarioConfiguration={scenarioConfiguration}
    />
  ) : (
    <Modal disableBackdropClose isOpen>
      <Box column bg={config.colors.grayBg} w="100%">
        <ScenarioResultsTimeSeriesContent
          selectedDate={selectedDate}
          onSelectedDateChanged={handleChangeDate}
          onSelectedYearChanged={handleChangeYear}
          allowedYears={selectedYears}
          isCollapsedView={isCollapsedView}
          onToggleIsCollapsedView={toggleIsCollapsedView}
          isFetchingData={isFetching}
          timeSeriesData={timeSeriesData}
          scenarioConfiguration={scenarioConfiguration}
        />
      </Box>
    </Modal>
  )
}
