import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Box, Button, Dialog } from '@veracity/vui'

import { SelectFormControl } from '@/components'
import { ScenarioTags } from '@/interfaces'

interface Props {
  currentValue: ScenarioTags
  onTagUpdate: (tag: ScenarioTags, onSuccess: () => void) => void
  isTagUpdateInProgress: boolean
}
export const ProjectDetailsScenarioCardEditTagDialog = ({
  currentValue,
  onTagUpdate,
  isTagUpdateInProgress
}: Props) => {
  const [isVisible, setIsVisible] = useState(false)
  const { control, reset, handleSubmit } = useForm({
    defaultValues: { tag: ScenarioTags.Draft }
  })

  useEffect(() => reset({ tag: currentValue }), [currentValue, reset])

  const handleSubmitClick = (val: any) => onTagUpdate(val.tag, handleClose)

  const handleClose = () => {
    setIsVisible(false)
    reset()
  }

  return (
    <>
      <Button
        icon="falFlag"
        variant="tertiaryDark"
        onClick={() => setIsVisible(true)}
      />

      <Dialog
        isOpen={isVisible}
        title="Change Scenario Tag"
        size="lg"
        onClose={handleClose}
        cancelButton={{ text: 'Cancel', disabled: isTagUpdateInProgress }}
        submitButton={{
          text: 'Save',
          onClick: handleSubmit(handleSubmitClick),
          isLoading: isTagUpdateInProgress,
          disabled: isTagUpdateInProgress
        }}
        body={
          <Box column p={2}>
            <SelectFormControl
              control={control}
              disabled={isTagUpdateInProgress}
              formControlName="tag"
              rules={{ required: true }}
              options={[
                { text: 'Draft', value: ScenarioTags.Draft },
                { text: 'Analysis', value: ScenarioTags.Analysis },
                { text: 'Final', value: ScenarioTags.Final }
              ]}
            />
          </Box>
        }
      />
    </>
  )
}
