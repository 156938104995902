import { useEffect, useMemo, useState } from 'react'
import {
  Button,
  Icon,
  Menu,
  Pagination,
  Sort,
  Switch,
  Table,
  Tbody,
  Td,
  Tfoot,
  Thead,
  Tr
} from '@veracity/vui'

import { UserRoleSimple, UserSimple } from '@/interfaces'
import { orderByKey } from '@/utils'

const PAGE_SIZE = 10

interface Props {
  users: UserSimple[]
  allowedRoles?: UserRoleSimple[]
  onDetachUserClicked: (userId: string) => void
  onUserStatusChanged: (userId: string, isEnabled: boolean) => void
  onUserRoleChanged: (userId: string, roleId: number) => void
}

export const OrganizationManagementUsersTable = ({
  users,
  allowedRoles,
  onDetachUserClicked,
  onUserStatusChanged,
  onUserRoleChanged
}: Props) => {
  const [pageIndex, setPageIndex] = useState(1)
  const [sort, setSort] = useState<Sort<keyof UserSimple>>({
    key: 'employerId',
    order: null
  })

  useEffect(() => setPageIndex(1), [users])

  const pageData = useMemo(
    () =>
      (users || [])
        .sort(orderByKey(sort.key, sort.order))
        .slice((pageIndex - 1) * PAGE_SIZE, pageIndex * PAGE_SIZE),
    [pageIndex, sort.key, sort.order, users]
  )

  const handleSortChange = (sort: Sort) =>
    setSort({ key: sort.key as keyof UserSimple, order: sort.order })

  return (
    <Table
      sort={sort}
      onSortChange={handleSortChange}
      columns={[
        { field: 'firstName', title: 'First name', isSortable: true },
        { field: 'lastName', title: 'Last name', isSortable: true },
        { field: 'email', title: 'Email', isSortable: true },
        { field: 'roleId', title: 'Role', isSortable: true },
        { field: 'isEnabled', title: 'Active' },
        { field: 'actions', title: 'Actions' }
      ]}>
      <Thead bg="white" />
      <Tbody bg="white">
        {pageData.map(user => (
          <Tr key={user.id}>
            <Td>{user.firstName}</Td>
            <Td>{user.lastName}</Td>
            <Td>{user.email}</Td>
            <Td>
              <Menu>
                <Menu.Button isDropDown>{user.roleName}</Menu.Button>
                <Menu.List>
                  {allowedRoles?.map(role => (
                    <Menu.Item
                      key={role.id}
                      onClick={() => onUserRoleChanged(user.id, role.id)}>
                      {role.name}
                    </Menu.Item>
                  ))}
                </Menu.List>
              </Menu>
            </Td>
            <Td>
              <Switch
                checked={user.isEnabled}
                onChange={() => onUserStatusChanged(user.id, !user.isEnabled)}
              />
            </Td>
            <Td>
              <Button
                variant="tertiaryDark"
                onClick={() => onDetachUserClicked(user.id)}>
                <Icon name="falUserTimes" pathFill="energyRed.main" />
              </Button>
            </Td>
          </Tr>
        ))}
      </Tbody>
      <Tfoot>
        <Tr>
          <Td colSpan={6}>
            <Pagination
              pageSize={PAGE_SIZE}
              count={users.length}
              page={pageIndex}
              onPageChange={setPageIndex}
            />
          </Td>
        </Tr>
      </Tfoot>
    </Table>
  )
}
