import { Box, Heading } from '@veracity/vui'

import { ScenarioResultsAggregatesTabSection } from './ScenarioResultsAggregatesTabSection'

interface ScenarioResultsAggregatesTabSectionDataItem {
  label: string
  values?: (string | number | undefined | null)[]
}

interface ScenarioResultsAggregatesTabSectionData {
  sectionTitle: string
  sectionItems: ScenarioResultsAggregatesTabSectionDataItem[]
}

interface Props {
  dateSection: ScenarioResultsAggregatesTabSectionDataItem
  sections: ScenarioResultsAggregatesTabSectionData[]
}
export const ScenarioResultsAggregatesTabPreview = ({
  dateSection,
  sections
}: Props) => (
  <Box column gap={2} overflow="auto">
    <ScenarioResultsAggregatesTabSection
      label={dateSection.label}
      values={dateSection.values}
    />

    {sections.map(section => (
      <Box column key={section.sectionTitle}>
        <Heading size="h6">{section.sectionTitle}</Heading>
        <Box column>
          {section.sectionItems?.map(x => (
            <ScenarioResultsAggregatesTabSection
              key={x.label}
              label={x.label}
              values={x.values}
            />
          ))}
        </Box>
      </Box>
    ))}
  </Box>
)
