import moment from 'moment'
import { Sort } from '@veracity/vui'

export function orderByKey<T extends object, K extends keyof T>(
  key: K,
  order: Sort['order'] = 'asc'
) {
  const ord = order === 'asc' ? 1 : -1

  return (item1: T, item2: T) => {
    if (order === null) return 0
    const a = item1[key]
    const b = item2[key]
    if (typeof a === 'string' && typeof b === 'string') {
      return a.localeCompare(b) * ord
    } else if (typeof a === 'number' && typeof b === 'number') {
      return a > b ? ord : a < b ? -ord : 0
    } else if (moment.isDate(a) && moment.isDate(b))
      return moment(a).isAfter(b) ? ord : moment(b).isBefore(a) ? -ord : 0
    return 0
  }
}
