import { Control } from 'react-hook-form'
import {
  Box,
  Divider,
  T,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tooltip,
  Tr,
  v
} from '@veracity/vui'

import { InputFormControl } from '@/components'
import { generateRange } from '@/utils'

const getMonthName = (monthId: number) =>
  new Date(2000, monthId).toLocaleDateString('en-Us', { month: 'long' })

const schema = generateRange(0, 11).map(monthId => ({
  monthId: monthId,
  month: getMonthName(monthId),
  hours: generateRange(0, 23)
}))

interface Props {
  formControl: Control<any>
  isFormDisabled: boolean
}

export const ScenarioConstraintDesignerDialogTableForm = ({
  formControl,
  isFormDisabled
}: Props) => {
  return (
    <Box column overflowY="auto" overflowX="hidden">
      <Divider />

      <Box w="100%" bg="white" gap={1} p={1}>
        <v.div w={120} />
        <T variant="caption">Hours</T>
      </Box>

      <Divider />

      <Box w="100%" overflow="auto">
        <Table bg="white" maxW="100%" maxH="100%">
          <Thead>
            <Tr>
              <Th w={120}>Month</Th>
              {generateRange(0, 23).map(x => (
                <Th key={x} w={100}>{`${x}:00`}</Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {schema.map(x => (
              <Tr key={x.monthId}>
                <Td w={60}>{x.month}</Td>
                {x.hours.map(hour => (
                  <Td key={hour} w={100}>
                    <Tooltip text="Allowed values from 0 to 1.">
                      <Box>
                        <InputFormControl
                          size="sm"
                          control={formControl}
                          formControlName={`constraints.${x.monthId}.${hour}`}
                          rules={{ min: 0, max: 1 }}
                          type="number"
                          min={0}
                          max={1}
                          step={0.1}
                          disabled={isFormDisabled}
                        />
                      </Box>
                    </Tooltip>
                  </Td>
                ))}
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
    </Box>
  )
}
