import { Box, Card, T } from '@veracity/vui'

import {
  ScenarioComparisonPreviewCard,
  ScenarioComparisonPreviewCardProps
} from './ScenarioComparisonPreviewCard'

interface Props {
  title: string
  items?: ScenarioComparisonPreviewCardProps[]
}

export const ScenarioComparisonPreviewCardContainer = ({
  title,
  items
}: Props) => (
  <Card column p={1}>
    <T p={1} variant="label">
      {title}
    </T>

    <Box column gap={2}>
      {items?.map((x, index) => (
        <ScenarioComparisonPreviewCard
          key={index}
          title={x.title}
          items={x.items}
          areAllRowsExpanded={x.areAllRowsExpanded}
        />
      ))}
    </Box>
  </Card>
)
