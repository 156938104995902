import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Box, Button, Dialog, Spinner } from '@veracity/vui'

import { PricePlanSimple } from '@/interfaces'
import { InputFormControl, SelectFormControl } from '@/components'

import { useOrganizations } from '../../hooks'

interface Props {
  availablePricePlans: PricePlanSimple[]
}

export const AddOrganizationDialog = ({ availablePricePlans }: Props) => {
  const [isOpen, setIsOpen] = useState(false)
  const { addOrganization, isAddOrganizationInProgress } = useOrganizations()

  const { control, handleSubmit, reset } = useForm()

  const handleSubmitClick = (formValues: any) => {
    addOrganization(
      {
        name: formValues.name,
        description: formValues.description,
        pricePlanId: formValues.pricePlanId,
        expirationDate: formValues.expirationDate
      },
      { onSuccess: handleCloseClick }
    )
  }

  const handleCloseClick = () => {
    if (!isAddOrganizationInProgress) {
      reset()
      setIsOpen(false)
    }
  }

  return (
    <>
      <Button size="lg" iconLeft="falPlus" onClick={() => setIsOpen(true)}>
        Add Organization
      </Button>

      <Dialog
        isOpen={isOpen}
        size="lg"
        title="Add Organization"
        onClose={handleCloseClick}
        cancelButton={{ text: 'Cancel', disabled: isAddOrganizationInProgress }}
        submitButton={{
          text: 'Add',
          onClick: handleSubmit(handleSubmitClick),
          isLoading: isAddOrganizationInProgress,
          disabled: isAddOrganizationInProgress
        }}
        body={
          isAddOrganizationInProgress ? (
            <Box column p={2}>
              <Spinner />
            </Box>
          ) : (
            <Box column p={2} gap={2}>
              <InputFormControl
                control={control}
                formControlName="name"
                label="Name"
                rules={{ required: true }}
              />
              <InputFormControl
                control={control}
                formControlName="description"
                label="Description"
              />

              <SelectFormControl
                control={control}
                formControlName="pricePlanId"
                label="Price Plan"
                rules={{ required: true }}
                options={availablePricePlans.map(x => ({
                  text: x.name!,
                  value: x.id
                }))}
              />

              <InputFormControl
                control={control}
                formControlName="expirationDate"
                type="date"
                label="Expiration Date"
                rules={{ required: true }}
              />
            </Box>
          )
        }
      />
    </>
  )
}
