import { useToast } from '@veracity/vui'
import { useMutation, useQuery, useQueryClient } from 'react-query'

import { scenarioConstraintsClient } from '@/api'
import { DatasetFileTypes } from '@/interfaces'
import { generateRange } from '@/utils'

interface SetScenarioConstraintRequest {
  years?: number[] | undefined
  constraints: number[][]
}

const getBaseQueryKey = scenarioConstraintsClient.QUERY_KEY

export const useConstraintDesigner = (
  scenarioId: number,
  datasetFileType: DatasetFileTypes,
  isCharging: boolean,
  isBusinessDay: boolean
) => {
  const QUERY_KEY = [
    ...getBaseQueryKey(scenarioId),
    { datasetFileType, isCharging, isBusinessDay }
  ]
  const { showSuccess } = useToast()
  const queryClient = useQueryClient()

  const constraintsQuery = useQuery(QUERY_KEY, () =>
    scenarioConstraintsClient.getConstraint(
      scenarioId,
      datasetFileType,
      isCharging,
      isBusinessDay
    )
  )

  const setConstraintMutation = useMutation({
    mutationFn: (req: SetScenarioConstraintRequest) =>
      scenarioConstraintsClient.setConstraint(scenarioId, {
        marketProductType: datasetFileType,
        isBusinessDay: isBusinessDay,
        isCharging: isCharging,
        years: req.years,
        constraints: req.constraints
      }),
    onSuccess: async () => {
      showSuccess('Constraint successfully updated')
      await queryClient.invalidateQueries(QUERY_KEY)
    }
  })

  return {
    constraintInfo: constraintsQuery.data,
    isFetching: constraintsQuery.isFetching,
    setConstraint: setConstraintMutation.mutate,
    setConstraintInProgress: setConstraintMutation.isLoading,
    clearConstraint: (years: number[]) =>
      setConstraintMutation.mutate({
        years: years,
        constraints: generateRange(0, 11).map(() => new Array(24).fill(null))
      })
  }
}
