import axios from 'axios'

import {
  ConstraintDefinitionSimple,
  DatasetFileTypes,
  SetConstraintDefinitionRequest
} from '@/interfaces'

const baseUrl = (scenarioId: number) =>
  `/api/scenarios/${scenarioId}/constraints`
export const scenarioConstraintsClient = {
  QUERY_KEY: (scenarioId: number) => ['scenarios', scenarioId, 'constraints'],
  getAll: (scenarioId: number) =>
    axios
      .get<ConstraintDefinitionSimple[]>(baseUrl(scenarioId))
      .then(x => x.data),
  getConstraint: (
    scenarioId: number,
    marketProductType: DatasetFileTypes,
    isCharging: boolean,
    isBusinessDay: boolean
  ) =>
    axios
      .get<ConstraintDefinitionSimple>(
        `${baseUrl(scenarioId)}/marketProductType/${marketProductType}`,
        { params: { isCharging, isBusinessDay } }
      )
      .then(x => x.data),
  setConstraint: (scenarioId: number, req: SetConstraintDefinitionRequest) =>
    axios.put<void>(baseUrl(scenarioId), req).then(x => x.data)
}
