import moment from 'moment'
import { Link } from 'react-router-dom'
import {
  Box,
  formatDate,
  HeaderAccount,
  HeaderDivider,
  List,
  LoggedInHeader,
  LoggedOutHeader,
  Logo
} from '@veracity/vui'

import { config } from '@/config'
import { UserResponse } from '@/interfaces'
import { TextLogo } from './TextLogo'

interface Props {
  currentUser?: UserResponse
}

export const Header = ({ currentUser }: Props) => {
  const isSubscriptionExpired = currentUser?.organization
    ?.subscriptionExpirationDate
    ? moment(
        currentUser.organization.subscriptionExpirationDate
      ).isSameOrBefore(new Date(), 'days')
    : null

  const isMonthlyLimitExceeded = currentUser?.organization
    ? currentUser?.organization.actuallyCalculationRequests >=
      currentUser?.organization.maxCalculationRequests
    : null

  const isParallelCalculationsLimitExceeded = currentUser?.organization
    ? currentUser?.organization?.actuallyParallelCalculations >=
      currentUser?.organization?.maxParallelCalculations
    : null

  return currentUser ? (
    <LoggedInHeader
      logo={
        <Link to="/">
          <Logo brand="dnv" />
        </Link>
      }
      notifications={{ count: currentUser?.veracityMessageCount }}
      isApplication
      applicationName={config.appFullName}
      showAltUserInfo={false}
      account={
        <HeaderAccount
          userInfo={{
            displayName: `${currentUser.firstName} ${currentUser.lastName}`,
            companyName: currentUser.organization?.name,
            role: currentUser.roles?.[0]?.name
          }}
          sections={
            <List isInteractive>
              {!currentUser.organization ? null : (
                <>
                  <List.Divider />
                  <List.Item
                    color={isSubscriptionExpired ? 'energyRed.54' : undefined}>
                    Subscription expiration date:
                    <br />
                    {formatDate(
                      currentUser.organization?.subscriptionExpirationDate,
                      'DD MMM YYYY'
                    )}
                  </List.Item>
                  <List.Item
                    color={isMonthlyLimitExceeded ? 'energyRed.54' : undefined}>
                    Monthly calculations:{' '}
                    {`${currentUser.organization?.actuallyCalculationRequests}/${currentUser.organization?.maxCalculationRequests}`}
                  </List.Item>
                  <List.Item
                    color={
                      isParallelCalculationsLimitExceeded
                        ? 'energyRed.54'
                        : undefined
                    }>
                    Parallel calculations:{' '}
                    {`${currentUser.organization?.actuallyParallelCalculations}/${currentUser.organization?.maxParallelCalculations}`}
                  </List.Item>
                </>
              )}

              <List.Divider />
              <a href="/account/signOut">
                <List.Item iconLeft="falSignOut" text="Sign out" />
              </a>
            </List>
          }
        />
      }
    />
  ) : (
    <LoggedOutHeader
      title={config.appFullName}
      logo={
        <Box gap={1}>
          <Logo brand="dnv" />
          <HeaderDivider />
          <TextLogo size="h2" fontWeight="demi">
            {config.appFullName}
          </TextLogo>
        </Box>
      }
      signIn={{ url: '/account/signIn' }}
    />
  )
}
