import axios from 'axios'
import {
  AddPortfolioRequest,
  PortfolioSimple,
  UpdateLinkedProjectsRequest
} from '@/interfaces'

const baseUrl = '/api/portfolios'

export const portfoliosClient = {
  QUERY_KEY: ['portfolios'],
  getAll: () => axios.get<PortfolioSimple[]>(baseUrl).then(x => x.data),
  createPortfolio: (req: AddPortfolioRequest) =>
    axios.post<number>(`${baseUrl}`, req).then(x => x.data),
  getById: (portfolioId: number) =>
    axios.get<PortfolioSimple>(`${baseUrl}/${portfolioId}`).then(x => x.data),
  removeById: (portfolioId: number) =>
    axios.delete<void>(`${baseUrl}/${portfolioId}`).then(x => x.data),
  updateLinkedProjects: (
    portfolioId: number,
    req: UpdateLinkedProjectsRequest
  ) =>
    axios.put<void>(`${baseUrl}/${portfolioId}/projects`, req).then(x => x.data)
}
