export const filterBy = (
  searchText: string,
  elements: (string | undefined)[]
) => {
  const normalizedSearchText = searchText.toLocaleLowerCase()

  if (elements.length === 0 || !searchText) return true

  return elements
    .filter(Boolean)
    .some(x => x?.toLocaleLowerCase().includes(normalizedSearchText))
}
