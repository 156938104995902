import axios from 'axios'

import {
  AddDatasetRequest,
  CloneDatasetRequest,
  DatasetSimple
} from '@/interfaces'

const baseUrl = '/api/library/IncomeAdjustmentFactors'
export const incomeAdjustmentClient = {
  QUERY_KEY: ['library', 'incomeAdjustment'],
  getAll: (lockedOnly?: boolean) =>
    axios
      .get<DatasetSimple[]>(baseUrl, { params: { locked: lockedOnly } })
      .then(x => x.data),
  addDataset: (req: AddDatasetRequest) =>
    axios.post<number>(baseUrl, req).then(x => x.data),
  cloneDataset: ({
    datasetId,
    name
  }: { datasetId: number } & CloneDatasetRequest) =>
    axios
      .post<number>(`${baseUrl}/cloneDataset/${datasetId}`, { name })
      .then(x => x.data),
  removeDataset: (datasetId: number) =>
    axios.delete<void>(`${baseUrl}/${datasetId}`).then(x => x.data)
}
