import { useState } from 'react'
import { Box } from '@veracity/vui'

import { useSystemDictionaries } from '@/hooks'
import {
  PageContainer,
  PageSearchInput,
  ToggleFilterButton
} from '@/components'

import { useProjectsList, useProjectsListFilter } from './hooks'
import { ProjectFilterValues, ProjectsPreviewType } from './types'
import {
  ProjectsListAddProjectDialog,
  ProjectsListCollectionPreview,
  ProjectsListFilterPanel,
  ProjectsListPreviewSelector
} from './components'

export const ProjectsListPage = () => {
  const [searchValue, setSearchValue] = useState('')
  const [isFilterActive, setIsFilterActive] = useState(false)
  const [projectsSelectedFilters, setProjectsSelectedFilters] =
    useState<ProjectFilterValues>()
  const [selectedPreviewType, setSelectedPreviewType] =
    useState<ProjectsPreviewType>(ProjectsPreviewType.GRID)

  const { projects, isFetching } = useProjectsList()

  const { systemConfigurations } = useSystemDictionaries()
  const filteredProjects = useProjectsListFilter(
    projects,
    searchValue,
    projectsSelectedFilters
  )

  return (
    <PageContainer
      loading={isFetching}
      header={<PageContainer.Breadcrumbs items={[{ title: 'Projects' }]} />}
      menuBar={
        <Box justify="space-between" w="100%" wrap gap={1}>
          <Box gap={1}>
            <PageSearchInput onChange={setSearchValue} />
            <ToggleFilterButton
              isActive={isFilterActive}
              onClick={() => setIsFilterActive(!isFilterActive)}
            />
          </Box>
          <Box gap={1}>
            <ProjectsListPreviewSelector
              value={selectedPreviewType}
              onChange={setSelectedPreviewType}
            />
            <ProjectsListAddProjectDialog />
          </Box>
        </Box>
      }>
      <ProjectsListFilterPanel
        isFilterActive={isFilterActive}
        onFilterChange={setProjectsSelectedFilters}
        availableSystemConfigurations={systemConfigurations}
        onFilterCloseClicked={() => setIsFilterActive(false)}
      />

      <ProjectsListCollectionPreview
        filteredProjects={filteredProjects}
        selectedPreviewType={selectedPreviewType}
      />
    </PageContainer>
  )
}
