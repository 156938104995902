import { useEffect, useRef } from 'react'
import { Map, MapRef } from 'react-map-gl'
import { IsoBa } from '@/interfaces'

// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from '!mapbox-gl'
import { config } from '@/config'

const ISO_COORDS = {
  [IsoBa.None]: { lon: -97, lat: 42, zoom: 2.7 },
  [IsoBa.US_NYISO]: { lon: -77, lat: 43, zoom: 6 },
  [IsoBa.US_CAISO]: { lon: -117.065, lat: 38, zoom: 5 },
  [IsoBa.US_PJM]: { lon: -80.699, lat: 38.919, zoom: 5.4 },
  [IsoBa.US_MISO]: { lon: -84.802, lat: 36.488, zoom: 4 },
  [IsoBa.US_SPP]: { lon: -97.931, lat: 39.229, zoom: 4.3 },
  [IsoBa.US_ERCOT]: { lon: -100.385, lat: 31, zoom: 5.5 },
  [IsoBa.US_ISONE]: { lon: -69.762, lat: 44, zoom: 6.5 },
  [IsoBa.CAN_IESO]: { lon: -83.778, lat: 50.209, zoom: 5 },
  [IsoBa.CAN_AESO]: { lon: -83.361, lat: 49.639, zoom: 4.5 },
  [IsoBa.ES_PENINSULA]: { lon: -3.608, lat: 39.779, zoom: 4.5 }
}

interface Props {
  selectedISO?: IsoBa
}

export const IsoRegionsMap = ({ selectedISO }: Props) => {
  const mapContainerRef = useRef<MapRef>(null)

  useEffect(() => {
    const { lon, lat, zoom } = ISO_COORDS[selectedISO || IsoBa.None]

    mapContainerRef.current?.flyTo({
      center: { lat, lon },
      essential: true,
      zoom: zoom
    })
  }, [selectedISO])

  return (
    <Map
      ref={mapContainerRef}
      style={{ height: 350 }}
      mapLib={mapboxgl}
      mapboxAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
      mapStyle={config.mapboxStyle}
      initialViewState={{
        longitude: -97,
        latitude: 42,
        zoom: 2.7
      }}
      attributionControl={false}
    />
  )
}
