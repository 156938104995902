import { Textarea, TextareaProps } from '@veracity/vui'
import { BaseFormItem, BaseFormItemProps } from './BaseFormItem'

export type TextareaBaseFormProps = BaseFormItemProps & TextareaProps

export interface TextareaFormControlProps extends TextareaBaseFormProps {}

export const TextareaFormControl = ({
  control,
  formControlName,
  label,
  rules,
  ...props
}: TextareaFormControlProps) => (
  <BaseFormItem
    label={label}
    control={control}
    rules={rules}
    formControlName={formControlName}>
    <Textarea {...props} />
  </BaseFormItem>
)
