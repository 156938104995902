import { Box, Link, List, P } from '@veracity/vui'

import { ContactSupport, PageContainer } from '@/components'
import { config } from '@/config'

export const TermsOfUsePage = () => (
  <PageContainer
    header={<PageContainer.Breadcrumbs items={[{ title: 'Terms of Use' }]} />}>
    <Box column bg="white" p={2}>
      <P>
        Hybrid Energy Resource Optimizer is provided on the DNV Veracity data
        platform.
      </P>
      <P>Your use of Hybrid Energy Resource Optimizer is subject to:</P>

      <List py={2} variant="unordered">
        <List.Item>
          The DNV Veracity platform terms of use{' '}
          <Link isExternal href={config.externalLinks.veracityTermsOfUse}>
            here
          </Link>
          .
        </List.Item>
        <List.Item>
          The DNV Hybrid Energy Resource Optimizer terms of use{' '}
          <Link
            isExternal
            href={config.externalLinks.veracityTermsOfUseForHero}>
            here
          </Link>
          .
        </List.Item>
        <List.Item>
          If you have purchased a subscription to Hybrid Energy Resource
          Optimizer, your subscription contract will include terms and
          conditions associated with payment terms, warranty and liability.
          <br />
          If a conflict exists between these terms, your subscription contract
          will take precedence.
        </List.Item>
      </List>

      <ContactSupport />
    </Box>
  </PageContainer>
)
