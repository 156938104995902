import { Grid, Heading } from '@veracity/vui'
import { PropsWithChildren } from 'react'

interface Props {
  title: string
}

export const FinancesDetailsFormSection = ({
  title,
  children
}: PropsWithChildren<Props>) => {
  return (
    <>
      <Heading size="h3" weight="medium">
        {title}
      </Heading>

      <Grid
        gap={5}
        py={4}
        gridTemplateColumns={{ xs: '4fr 3fr', sm: '4fr 3fr 4fr 3fr' }}>
        {children}
      </Grid>
    </>
  )
}
