import { useEffect } from 'react'
import { FieldValues, useForm } from 'react-hook-form'
import { Box, Button, Dialog } from '@veracity/vui'

import { config } from '@/config'
import { generateRange } from '@/utils'
import { DatasetFileTypes } from '@/interfaces'

import { ScenarioConstraintDesignerDialogBarForm } from './ScenarioConstraintDesignerDialogBarForm'
import { ScenarioConstraintDesignerDialogTableForm } from './ScenarioConstraintDesignerDialogTableForm'
import { useConstraintDesigner } from '../../../hooks'

interface Props {
  scenarioId: number
  calculationYears: number[]
  onClose: () => void
}

export const ScenarioConstraintDesignerDialog = ({
  scenarioId,
  calculationYears,
  onClose
}: Props) => {
  const { control, watch, handleSubmit, resetField, setValue } = useForm({
    defaultValues: {
      marketProductType: DatasetFileTypes.DayAheadEnergyPrices,
      years: [] as number[],
      isNonBusinessDay: false,
      isDischarging: false,
      constraints: generateRange(0, 11).map(() => new Array(24).fill(null))
    }
  })

  const isCharging = !watch('isDischarging')
  const isBusinessDay = !watch('isNonBusinessDay')
  const marketProductType = watch('marketProductType')
  const years = watch('years')

  const {
    constraintInfo,
    isFetching,
    setConstraint,
    setConstraintInProgress,
    clearConstraint
  } = useConstraintDesigner(
    scenarioId,
    marketProductType,
    isCharging,
    isBusinessDay
  )

  useEffect(() => {
    if (constraintInfo)
      setValue('constraints', constraintInfo.constraints || [])
  }, [setValue, constraintInfo])

  const handleValidSubmit = (values: FieldValues) => {
    setConstraint({
      years: values.years,
      constraints: values.constraints.map((month: (string | number | null)[]) =>
        month.map(hour =>
          hour === '' || hour === null || isNaN(+hour) ? null : +hour
        )
      )
    })
  }

  const handleClearConstraint = () => {
    resetField('constraints')
    clearConstraint(years)
  }

  const handleSelectAllYears = () => setValue('years', calculationYears)

  return (
    <Dialog
      m={2}
      isOpen
      title="Constraint Designer"
      onClose={onClose}
      size="fullWidth"
      w="95vw"
      modalProps={{
        disableBackdropClose: true,
        disableEscClose: true,
        children: <></>
      }}
      body={
        <Box column gap={3} p={2} h="80vh" overflow="auto">
          <Box gap={2} py={1} bg={config.colors.grayBg} justify="right">
            <Button
              variant="blueOutlined"
              onClick={handleClearConstraint}
              disabled={isFetching || setConstraintInProgress}>
              Clear Table
            </Button>

            <Button
              onClick={handleSubmit(handleValidSubmit)}
              disabled={isFetching || setConstraintInProgress}
              isLoading={setConstraintInProgress}>
              Save changes
            </Button>
          </Box>

          <Box column gap={2}>
            <ScenarioConstraintDesignerDialogBarForm
              formControl={control}
              isFormDisabled={isFetching || setConstraintInProgress}
              allowedYears={calculationYears}
              onSelectAllYearsClick={handleSelectAllYears}
            />
            <ScenarioConstraintDesignerDialogTableForm
              formControl={control}
              isFormDisabled={isFetching || setConstraintInProgress}
            />
          </Box>
        </Box>
      }
    />
  )
}
