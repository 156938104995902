import axios from 'axios'

import {
  DispatchResultsSummaryResult,
  ScenarioTimeseriesChartItemSimple,
  SummaryResult
} from '@/interfaces'

const baseUrl = `/api/results`

export const scenarioResultsClient = {
  QUERY_KEY: (scenarioId: number) => ['scenarios', scenarioId, 'results'],
  getChartData: (scenarioId: number, startDate: Date) =>
    axios
      .get<ScenarioTimeseriesChartItemSimple[]>(
        `${baseUrl}/GetChartData/${scenarioId}`,
        {
          params: { startDate }
        }
      )
      .then(x => x.data),
  downloadResultsFile: (scenarioId: number, sectionsNames: string[]) =>
    axios
      .get<Blob>(`${baseUrl}/DownloadSelectedFiles/${scenarioId}`, {
        responseType: 'blob',
        params: { sections: sectionsNames },
        paramsSerializer: _ => sectionsNames.map(x => `sections=${x}`).join('&')
      })
      .then(x => x.data),
  getAggregatedResults: (scenarioId: number, forAnnually?: boolean) =>
    axios
      .get<DispatchResultsSummaryResult[]>(
        `${baseUrl}/DispatchResultsSummary/${scenarioId}`,
        { params: { forAnnually } }
      )
      .then(x => x.data),
  getResultsSummary: (
    scenarioId: number,
    applyIncomeAdjustmentFactor: boolean
  ) =>
    axios
      .get<SummaryResult>(`${baseUrl}/${scenarioId}`, {
        params: { applyIncomeAdjustmentFactor }
      })
      .then(x => x.data)
}
