import { Box, Button, DatePicker, Menu, SelectOptionData } from '@veracity/vui'

import { CalculationStates, SystemConfigurations } from '@/interfaces'

import { stateTitles } from '../../consts'

interface Props {
  availableSystemConfigurations: SelectOptionData[]
  onCalculationStateChanged: (calculationState?: CalculationStates) => void
  onSystemConfigurationChanged: (
    systemConfiguration?: SystemConfigurations
  ) => void
  onCalculationStartDateRangeChanged: (date?: Date) => void
}

export const PortfolioDetailsFilterPanelInputs = ({
  availableSystemConfigurations,
  onCalculationStateChanged,
  onSystemConfigurationChanged,
  onCalculationStartDateRangeChanged
}: Props) => (
  <Box gap={2}>
    <Menu>
      <Menu.Button isDropDown>Status</Menu.Button>
      <Menu.List>
        {stateTitles.map(x => (
          <Menu.Item
            key={x.state}
            onClick={() => onCalculationStateChanged(x.state)}>
            {x.title}
          </Menu.Item>
        ))}
      </Menu.List>
    </Menu>

    <Menu>
      <Menu.Button isDropDown>System configuration</Menu.Button>
      <Menu.List>
        {availableSystemConfigurations.map(x => (
          <Menu.Item
            key={x.value}
            disabled={x.disabled}
            onClick={() =>
              onSystemConfigurationChanged(
                +(x.value || '') as SystemConfigurations
              )
            }>
            {x.text}
          </Menu.Item>
        ))}
      </Menu.List>
    </Menu>

    <DatePicker onSelected={onCalculationStartDateRangeChanged}>
      <Button variant="tertiaryDark" iconRight="falAngleDown">
        Date Started
      </Button>
    </DatePicker>
  </Box>
)
