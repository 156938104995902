import { FieldValues, useForm } from 'react-hook-form'
import { useToggle } from '@veracity/ui-utils'
import { Box, Button, Dialog } from '@veracity/vui'

import { InputFormControl, TextareaFormControl } from '@/components'
import { usePortfoliosList } from '../hooks'

export const PortfoliosListAddPortfolioDialog = () => {
  const [isVisible, toggleIsVisible] = useToggle()
  const { control, handleSubmit, reset } = useForm({
    defaultValues: { name: '', description: '' }
  })

  const { portfolios, isAddPortfolioInProgress, addPortfolio } =
    usePortfoliosList()

  const validateNameDuplication = (name: string) =>
    !portfolios.some(
      x => x.name?.toLocaleLowerCase() === name.toLocaleLowerCase()
    )

  const handleClose = () => {
    if (isAddPortfolioInProgress) return
    reset()
    toggleIsVisible.off()
  }

  const handleSubmitClick = (values: FieldValues) =>
    addPortfolio(
      {
        portfolioName: values.name,
        portfolioDescription: values.description
      },
      { onSuccess: handleClose }
    )

  return (
    <>
      <Button size="lg" iconLeft="falPlus" onClick={toggleIsVisible.on}>
        Add portfolio
      </Button>

      <Dialog
        isOpen={isVisible}
        title="Add Portfolio"
        onClose={handleClose}
        onSubmit={handleSubmit(handleSubmitClick)}
        cancelButton={{ text: 'Cancel', disabled: isAddPortfolioInProgress }}
        submitButton={{
          text: 'Create',
          onClick: handleSubmit(handleSubmitClick),
          isLoading: isAddPortfolioInProgress,
          disabled: isAddPortfolioInProgress
        }}
        body={
          <Box column gap={2} p={2}>
            <InputFormControl
              control={control}
              formControlName="name"
              label="Name"
              size="md"
              rules={{ required: true, validate: validateNameDuplication }}
            />

            <TextareaFormControl
              control={control}
              formControlName="description"
              label="Description"
              maxLength={250}
              showCount
              rules={{ maxLength: 250 }}
            />
          </Box>
        }
      />
    </>
  )
}
