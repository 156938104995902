import { useEffect } from 'react'
import { useForm } from 'react-hook-form'

import { FinancesSimple, UpdateFinancesDatatasetRequest } from '@/interfaces'

export const useFinancesDetailsForm = (
  updateDataset: (req: UpdateFinancesDatatasetRequest) => void,
  datasetInfo?: FinancesSimple
) => {
  const { control, reset, handleSubmit, formState, watch } = useForm()
  const formValues = watch()

  useEffect(
    () =>
      reset({
        taxDepreciationType: datasetInfo?.taxDepreciationType,
        dcSide: datasetInfo?.dcSide,
        powerConversionSystem: datasetInfo?.powerConversionSystem,
        lvXmerAndSwitchgear: datasetInfo?.lvXmerAndSwitchgear,
        gridManagementSystem: datasetInfo?.gridManagementSystem,
        preventativeMaintenanceAndDispatchCost:
          datasetInfo?.preventativeMaintenanceAndDispatchCost,
        capacityLongTermServiceAgreement:
          datasetInfo?.capacityLongTermServiceAgreement,
        eSOMCostEscalationRate: datasetInfo?.esomCostEscalationRate,
        pVOMCostEscalationRate: datasetInfo?.pvomCostEscalationRate,
        windOMCostEscalationRates: datasetInfo?.windOMCostEscalationRates,
        pVModuleEquipment: datasetInfo?.pvModuleEquipment,
        pCSInvertersAndAssociatedControls:
          datasetInfo?.pcsInvertersAndAssociatedControls,
        pVEquipmentEnggAndConstruction:
          datasetInfo?.pvEquipmentEnggAndConstruction,
        pVLVTransformer: datasetInfo?.pvlvTransformer,
        pVFixedOMCost: datasetInfo?.pvFixedOMCost,
        wTGFoundations: datasetInfo?.wtgFoundations,
        wTGSupplyAndOptions: datasetInfo?.wtgSupplyAndOptions,
        wTGInstallation: datasetInfo?.wtgInstallation,
        collectionSystem: datasetInfo?.collectionSystem,
        controlSystem: datasetInfo?.controlSystem,
        windLVXmers: datasetInfo?.windLVXmers,
        windFixedOMCost: datasetInfo?.windFixedOMCost,
        landLeaseAndAcquisition: datasetInfo?.landLeaseAndAcquisition,
        siteAndPublicRoads: datasetInfo?.siteAndPublicRoads,
        buildings: datasetInfo?.buildings,
        directInterconnectionCost: datasetInfo?.directInterconnectionCost,
        systemUpgrades: datasetInfo?.systemUpgrades,
        projectDevelopment: datasetInfo?.projectDevelopment,
        projectManagement: datasetInfo?.projectManagement,
        contingencyAndOverhead: datasetInfo?.contingencyAndOverhead,
        constructionManagementAndEngg:
          datasetInfo?.constructionManagementAndEngg,
        federalITCRateOfInstalledCost:
          datasetInfo?.federalITCRateOfInstalledCost,
        taxRate: datasetInfo?.taxRate,
        salesTaxRate: datasetInfo?.salesTaxRate,
        debtForBESSFinancing: datasetInfo?.debtForBESSFinancing,
        debtFinancingRate: datasetInfo?.debtFinancingRate,
        debtFinancingTerm: datasetInfo?.debtFinancingTerm,
        discountRate: datasetInfo?.discountRate,
        equipmentInsuranceFee: datasetInfo?.equipmentInsuranceFee
      }),
    [datasetInfo, reset]
  )

  const handleSubmitForm = handleSubmit(data =>
    updateDataset({
      capacityAugmentationFile: data.capacityAugmentationFile,
      taxDepreciationType: data.taxDepreciationType,
      dcSide: data.dcSide,
      powerConversionSystem: data.powerConversionSystem,
      lvXmerAndSwitchgear: data.lvXmerAndSwitchgear,
      gridManagementSystem: data.gridManagementSystem,
      preventativeMaintenanceAndDispatchCost:
        data.preventativeMaintenanceAndDispatchCost,
      capacityLongTermServiceAgreement: data.capacityLongTermServiceAgreement,
      eSOMCostEscalationRate: data.eSOMCostEscalationRate,
      pVOMCostEscalationRate: data.pVOMCostEscalationRate,
      windOMCostEscalationRates: data.windOMCostEscalationRates,
      pVModuleEquipment: data.pVModuleEquipment,
      pCSInvertersAndAssociatedControls: data.pCSInvertersAndAssociatedControls,
      pVEquipmentEnggAndConstruction: data.pVEquipmentEnggAndConstruction,
      pVLVTransformer: data.pVLVTransformer,
      pVFixedOMCost: data.pVFixedOMCost,
      wTGFoundations: data.wTGFoundations,
      wTGSupplyAndOptions: data.wTGSupplyAndOptions,
      wTGInstallation: data.wTGInstallation,
      collectionSystem: data.collectionSystem,
      controlSystem: data.controlSystem,
      windLVXmers: data.windLVXmers,
      windFixedOMCost: data.windFixedOMCost,
      landLeaseAndAcquisition: data.landLeaseAndAcquisition,
      siteAndPublicRoads: data.siteAndPublicRoads,
      buildings: data.buildings,
      directInterconnectionCost: data.directInterconnectionCost,
      systemUpgrades: data.systemUpgrades,
      projectDevelopment: data.projectDevelopment,
      projectManagement: data.projectManagement,
      contingencyAndOverhead: data.contingencyAndOverhead,
      constructionManagementAndEngg: data.constructionManagementAndEngg,
      federalITCRateOfInstalledCost: data.federalITCRateOfInstalledCost,
      taxRate: data.taxRate,
      salesTaxRate: data.salesTaxRate,
      debtForBESSFinancing: data.debtForBESSFinancing,
      debtFinancingRate: data.debtFinancingRate,
      debtFinancingTerm: data.debtFinancingTerm,
      discountRate: data.discountRate,
      equipmentInsuranceFee: data.equipmentInsuranceFee
    })
  )

  return {
    formControl: control,
    isFormValid: formState.isValid,
    isFormTouched: formState.isDirty,
    handleSubmitForm,
    formValues
  }
}
