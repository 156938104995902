export const SolarPanelIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    className="st3"
    colorInterpolationFilters="sRGB"
    height={80}
    viewBox="0 0 45 45">
    <path
      fill="none"
      d="M.51294-.00429h44.1666v44.1666H.51294z"
      className="st1"
    />
    <path
      d="M9.50182 20.6451c-1.38-1.18-2.25-2.93-2.25-4.89 0-3.54 2.88-6.42 6.42-6.42 1.99 0 3.77.91 4.95 2.34.25.29.68.33.97.09.3-.24.34-.68.1-.97-1.43-1.73-3.6-2.84-6.02-2.84-4.3 0-7.8 3.5-7.8 7.8 0 2.38 1.06 4.5 2.74 5.94.29.24.72.21.97-.08s.21-.73-.08-.97zm3.48082-17.1257v2.18c0 .38.31.69.69.69.38 0 .69-.31.69-.69v-2.18c0-.38-.31-.69-.69-.69-.38 0-.69.31-.69.69zm-8.45198 4.073 1.54 1.54c.27.27.71.27.98 0s.27-.7 0-.97l-1.54-1.55c-.27-.27-.71-.27-.98 0s-.27.71 0 .98zm17.30608-.98-1.54 1.55c-.27.27-.27.7 0 .97s.71.27.97 0l1.55-1.54c.27-.27.27-.71 0-.98s-.71-.27-.98 0zM6.07066 22.378l-1.54 1.55c-.27.27-.27.7 0 .97s.71.27.98 0l1.54-1.54c.27-.27.27-.71 0-.98-.27-.26-.71-.26-.98 0zm-4.63768-5.932h2.18c.38 0 .69-.31.69-.69 0-.38-.31-.69-.69-.69h-2.18c-.38 0-.69.31-.69.69 0 .38.31.69.69.69zm9.39836 19.0696v3.72c0 .55.22 1.09.61 1.48.4.39.93.61 1.49.61h.69c.56 0 1.09-.22 1.49-.61.39-.39.61-.93.61-1.48v-6.48c0-.38-.31-.69-.69-.69-.38 0-.69.31-.69.69v6.48c0 .19-.08.37-.21.5-.13.14-.32.21-.51.21h-.69c-.19 0-.38-.07-.51-.21-.13-.13-.21-.31-.21-.5v-3.72c0-.38-.31-.69-.69-.69-.38 0-.69.31-.69.69zm23.8712-7v10.72c0 .55.22 1.09.61 1.48.4.39.93.61 1.49.61h.69c.56 0 1.09-.22 1.49-.61.39-.39.61-.93.61-1.48v-19.01c0-.38-.31-.69-.69-.69-.38 0-.69.31-.69.69v19.01c0 .19-.08.37-.21.5-.13.14-.32.21-.51.21h-.69c-.19 0-.38-.07-.51-.21-.13-.13-.21-.31-.21-.5v-10.72c0-.38-.31-.69-.69-.69-.38 0-.69.31-.69.69z"
      className="st2"
    />
    <path
      d="m6.87531 26.2947-3.13 3.7c-.53.62-.65 1.5-.31 2.24.35.74 1.09 1.21 1.91 1.21h21.65c1.03 0 2-.45 2.67-1.24 2.76-3.25 11.51-13.59 14.45-17.07.35-.41.43-.99.2-1.49-.22-.49-.72-.8-1.26-.8h-23.82c-.61 0-1.2.27-1.6.74l-9.11 10.77c-.25.29-.21.72.08.97.29.24.72.21.97-.08l9.11-10.77c.14-.16.34-.25.55-.25h23.83v.02l-14.45 17.07c-.41.48-1 .75-1.62.75h-21.65c-.28 0-.54-.16-.65-.41-.12-.26-.08-.55.1-.77l3.13-3.7c.25-.29.21-.72-.08-.97s-.72-.21-.97.08z"
      className="st2"
    />
    <path
      d="m19.64154 33.2075 16.28-19.23c.24-.29.21-.73-.08-.97-.29-.25-.73-.21-.98.08l-16.27 19.22c-.25.3-.21.73.08.98.29.24.72.21.97-.08zm-9.45258 0 16.28-19.23c.24-.29.21-.73-.08-.97-.29-.25-.73-.21-.98.08l-16.27 19.22c-.25.3-.21.73.08.98.29.24.72.21.97-.08z"
      className="st2"
    />
    <path
      d="M11.76564 26.2004h22.32c.38 0 .69-.31.69-.69 0-.38-.31-.69-.69-.69h-22.32c-.38 0-.69.31-.69.69 0 .38.31.69.69.69zm4.8077-5.7881h22.74c.38 0 .69-.31.69-.69 0-.38-.31-.69-.69-.69h-22.74c-.38 0-.69.31-.69.69 0 .38.31.69.69.69z"
      className="st2"
    />
  </svg>
)
