import { config } from '@/config'
import { PageContainer } from '@/components'

export const IncomeAdjustmentDetailsPage = () => {
  return (
    <PageContainer
      header={
        <PageContainer.Breadcrumbs
          items={[
            { title: 'Library' },
            {
              title: 'Income Adjustment',
              path: config.links.library.incomeAdjustment
            },
            { title: '<<Dataset Name>>' }
          ]}
        />
      }>
      Coming Soon
    </PageContainer>
  )
}
