import { Button } from '@veracity/vui'

interface Props {
  isActive: boolean
  onClick?: () => void
}

export const ToggleFilterButton = ({ isActive, onClick }: Props) => (
  <Button
    variant="tertiaryDark"
    size="lg"
    icon={isActive ? 'falFilter' : 'fasFilter'}
    onClick={onClick}
  />
)
