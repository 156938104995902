import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { Box, Heading } from '@veracity/vui'

import { PageContainer } from '@/components'

import { useScenarioResults } from './hooks'
import { ScenarioResultsTabSectionType } from './types'
import {
  ScenarioResultsDownloadResultsDialog,
  ScenarioResultsPageSectionContainer,
  ScenarioResultsTabSection
} from './components'

export const ScenarioResultsPage = () => {
  const { projectId, scenarioId: scenarioIdString } = useParams()
  const scenarioId = +(scenarioIdString || '')

  const [activeSectionType, setActiveSectionType] =
    useState<ScenarioResultsTabSectionType>(
      ScenarioResultsTabSectionType.SUMMARY
    )

  const { scenarioInfo, isFetching } = useScenarioResults(scenarioId)

  return (
    <PageContainer
      loading={isFetching}
      header={
        <PageContainer.Breadcrumbs
          items={[
            { path: '/projects', title: 'Projects' },
            {
              path: `/projects/${projectId}`,
              title: scenarioInfo?.projectName || ''
            },
            { path: `/projects/${projectId}`, title: 'Scenarios' },
            {
              path: `/projects/${projectId}/scenarios/${scenarioIdString}`,
              title: scenarioInfo?.name || ''
            },
            { title: 'Results' }
          ]}
        />
      }
      menuBar={
        <Box w="100%" justify="space-between">
          <Heading size="h3">{scenarioInfo?.name}</Heading>
          <ScenarioResultsDownloadResultsDialog scenarioId={scenarioId} />
        </Box>
      }
      secondMenuBar={
        <ScenarioResultsTabSection
          activeSection={activeSectionType}
          onSectionChange={setActiveSectionType}
        />
      }>
      <Box column gap={4}>
        <ScenarioResultsPageSectionContainer
          activeSection={activeSectionType}
          scenarioInfo={scenarioInfo}
        />
      </Box>
    </PageContainer>
  )
}
