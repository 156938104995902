import axios from 'axios'
import {
  AddDatasetRequest,
  CloneDatasetRequest,
  DatasetSimple,
  LossFactorsSimple,
  UpdateLossFactorsDatasetRequest
} from '@/interfaces'

const baseUrl = '/api/library/LossFactors'

export const lossFactorsClient = {
  QUERY_KEY: ['library', 'lossFactors'],
  getAll: (lockedOnly?: boolean) =>
    axios
      .get<DatasetSimple[]>(baseUrl, { params: { locked: lockedOnly } })
      .then(x => x.data),
  getById: (datasetId: number) =>
    axios.get<LossFactorsSimple>(`${baseUrl}/${datasetId}`).then(x => x.data),
  addDataset: (req: AddDatasetRequest) =>
    axios.post<number>(baseUrl, req).then(x => x.data),
  cloneDataset: ({
    datasetId,
    name
  }: { datasetId: number } & CloneDatasetRequest) =>
    axios
      .post<number>(`${baseUrl}/cloneDataset/${datasetId}`, { name })
      .then(x => x.data),
  updateDataset: (datasetId: number, req: UpdateLossFactorsDatasetRequest) =>
    axios.put<void>(`${baseUrl}/${datasetId}`, req).then(x => x.data),
  removeDataset: (datasetId: number) =>
    axios.delete<number>(`${baseUrl}/${datasetId}`).then(x => x.data),
  lockDataset: (datasetId: number) =>
    axios.post<void>(`${baseUrl}/lockDataset/${datasetId}`).then(x => x.data),
  archiveDataset: (datasetId: number) =>
    axios
      .post<void>(`${baseUrl}/archiveDataset/${datasetId}`)
      .then(x => x.data),
  unArchiveDataset: (datasetId: number) =>
    axios
      .post<void>(`${baseUrl}/unArchiveDataset/${datasetId}`)
      .then(x => x.data)
}
