import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useToast } from '@veracity/vui'

import { userAdministrationClient } from '@/api'

const BASE_QUERY_KEY = userAdministrationClient.QUERY_KEY
export const useOrganizationUsers = (organizationId: number) => {
  const QUERY_KEY = [...BASE_QUERY_KEY, 'organizations', organizationId]

  const { showSuccess } = useToast()
  const queryClient = useQueryClient()

  const getOrganizationUsers = useQuery(QUERY_KEY, () =>
    userAdministrationClient.getOrganizationUsers(organizationId)
  )

  const updateUserStatusMutation = useMutation({
    mutationFn: userAdministrationClient.setUserStatus,
    onSuccess: async () => {
      await queryClient.invalidateQueries(BASE_QUERY_KEY, {
        refetchInactive: true
      })
      showSuccess('User status successfully updated')
    }
  })

  const updateUserRoleMutation = useMutation({
    mutationFn: userAdministrationClient.setUserRole,
    onSuccess: async () => {
      await queryClient.invalidateQueries(BASE_QUERY_KEY, {
        refetchInactive: true
      })
      showSuccess('User role successfully updated')
    }
  })

  const detachUserFromOrganizationMutation = useMutation({
    mutationFn: userAdministrationClient.detachUserFromOrganization,
    onSuccess: async () => {
      await queryClient.invalidateQueries(BASE_QUERY_KEY, {
        refetchInactive: true
      })
      showSuccess('User successfully detached from organization')
    }
  })

  return {
    users: getOrganizationUsers.data || [],
    isFetching: getOrganizationUsers.isFetching,
    updateUserStatus: (userId: string, isEnabled: boolean) =>
      updateUserStatusMutation.mutate({ userId, isEnabled }),
    updateUserRole: (userId: string, roleId: number) =>
      updateUserRoleMutation.mutate({ userId, roleId }),
    detachUserFromOrganization: detachUserFromOrganizationMutation.mutate
  }
}
