import { Box, Button, T } from '@veracity/vui'
import { Control } from 'react-hook-form'

import {
  MultiSelectFormControl,
  SelectFormControl,
  SwitchFormControl
} from '@/components'
import { useSystemDictionaries } from '@/hooks'

interface ConstraintDesignerBarFormProps {
  formControl: Control<any>
  isFormDisabled: boolean
  allowedYears: number[]
  onSelectAllYearsClick: () => void
}

export const ScenarioConstraintDesignerDialogBarForm = ({
  formControl,
  isFormDisabled,
  allowedYears,
  onSelectAllYearsClick
}: ConstraintDesignerBarFormProps) => {
  const { datasetFileTypes } = useSystemDictionaries()

  return (
    <Box gap={3} alignItems="baseline" justify="center">
      <T variant="caption">Market Product</T>

      <SelectFormControl
        control={formControl}
        formControlName="marketProductType"
        w={220}
        options={datasetFileTypes}
        disabled={isFormDisabled}
      />

      <T>Years</T>

      <MultiSelectFormControl
        control={formControl}
        formControlName="years"
        w={220}
        rules={{ required: true }}
        options={allowedYears.map(year => ({ text: `${year}`, value: year }))}
        disabled={isFormDisabled}
      />

      <Button
        onClick={onSelectAllYearsClick}
        variant="secondaryDark"
        disabled={!allowedYears.length}>
        Select all
      </Button>

      <SwitchFormControl
        control={formControl}
        formControlName="isNonBusinessDay"
        labelLeft="Bussiness day"
        labelRight="Non-business day"
        disabled={isFormDisabled}
      />

      <SwitchFormControl
        control={formControl}
        formControlName="isDischarging"
        labelLeft="Charging"
        labelRight="Discharging"
        disabled={isFormDisabled}
      />
    </Box>
  )
}
