import { Fragment, useMemo } from 'react'
import { Box, Card, Divider, Grid, Heading, T } from '@veracity/vui'

import { TooltipIcon } from '@/components'
import { getDurationInYears } from '@/utils'
import { CalculationStates, ScenarioDetailed } from '@/interfaces'

interface Props {
  scenarioInfo?: ScenarioDetailed
}
export const ScenarioDetailsContentInfoBox = ({ scenarioInfo }: Props) => {
  const statusText = useMemo(() => {
    switch (scenarioInfo?.state) {
      case CalculationStates.Error:
        return (
          <Box gap={1} align="center">
            <T textAlign="center" size="lg">
              Failed
            </T>
            {!!scenarioInfo?.errors?.length && (
              <TooltipIcon.Error tooltipText={scenarioInfo.errors[0]} />
            )}
          </Box>
        )
      case CalculationStates.InProgress:
      case CalculationStates.Preprocessing:
        return <T>Pending</T>
      case CalculationStates.Finished:
        return <T>Completed</T>
      default:
        return <T>New</T>
    }
  }, [scenarioInfo?.errors, scenarioInfo?.state])

  const calculationPeriod =
    scenarioInfo?.startYear &&
    getDurationInYears(scenarioInfo.startYear, scenarioInfo?.endYear)

  const definitions = [
    { label: 'ISO', value: scenarioInfo?.isoBaName },
    {
      label: 'System Configuration',
      value: scenarioInfo?.systemConfigurationString
    },
    {
      label: 'Calculation period',
      value: calculationPeriod && `${calculationPeriod} year(s)`
    },
    { label: 'Status', value: statusText }
  ]

  return (
    <Card py={2} px={6}>
      <Grid gridTemplateColumns="1fr 1em 2fr 1em 1fr 1em 1fr" gap={2} w="100%">
        {definitions.map((item, index) => (
          <Fragment key={index}>
            <Box column alignItems="center" justify="space-between">
              <Heading color="sandstone.50" size="h6">
                {item.label}
              </Heading>
              {typeof item.value === 'string' ? (
                <T w="100%" isTruncated textAlign="center" size="lg">
                  {item.value}
                </T>
              ) : (
                item.value
              )}
            </Box>

            {index + 1 < definitions.length && <Divider isVertical />}
          </Fragment>
        ))}
      </Grid>
    </Card>
  )
}
