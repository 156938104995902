export const BatteryIcon = () => (
  <svg
    width="40"
    height="80"
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve">
    <g>
      <path
        transform="rotate(-90 20.0218 39.8385)"
        stroke="null"
        id="svg_1"
        d="m58.21886,31.39599l0,-0.042l-3.42628,0l0,-6.22c-0.01009,-2.587 -2.10863,-4.666 -4.71769,-4.675l-65.75205,0c-2.61007,0.009 -4.7076,2.088 -4.71567,4.675l0,29.409c0.00908,2.587 2.10561,4.667 4.71567,4.675l65.75104,0c2.60906,-0.008 4.7076,-2.088 4.71769,-4.675l0,-6.242l2.85927,0c0.05045,0.003 0.09686,0.015 0.1473,0.015s0.09686,-0.012 0.1473,-0.015l0.27342,0l0,-0.027c1.2561,-0.201 2.2176,-1.272 2.2176,-2.573l0,-11.732c0,-1.301 -0.9615,-2.372 -2.2176,-2.573zm-8.14397,23.147l-65.75205,0l0,-29.409l65.75104,0l0,29.409l0.00101,0z"
      />
      <path
        transform="rotate(-90 20.6497 43.7666)"
        id="svg_2"
        d="m50.23074,32.63055c0,-0.825 -0.675,-1.5 -1.5,-1.5l-7.079,0c-0.825,0 -1.5,0.675 -1.5,1.5l0,22.272c0,0.825 0.675,1.5 1.5,1.5l7.079,0c0.825,0 1.5,-0.675 1.5,-1.5l0,-22.272zm-12.27,0c0,-0.825 -0.675,-1.5 -1.5,-1.5l-7.08,0c-0.825,0 -1.5,0.675 -1.5,1.5l0,22.272c0,0.825 0.675,1.5 1.5,1.5l7.08,0c0.825,0 1.5,-0.675 1.5,-1.5l0,-22.272zm-12.272,0c0,-0.825 -0.675,-1.5 -1.5,-1.5l-7.079,0c-0.825,0 -1.5,0.675 -1.5,1.5l0,22.272c0,0.825 0.675,1.5 1.5,1.5l7.079,0c0.825,0 1.5,-0.675 1.5,-1.5l0,-22.272zm-12.27,0c0,-0.825 -0.675,-1.5 -1.5,-1.5l-7.079,0c-0.825,0 -1.5,0.675 -1.5,1.5l0,22.272c0,0.825 0.675,1.5 1.5,1.5l7.079,0c0.825,0 1.5,-0.675 1.5,-1.5l0,-22.272zm-12.271,0c0,-0.825 -0.675,-1.5 -1.5,-1.5l-7.079,0c-0.825,0 -1.5,0.675 -1.5,1.5l0,22.272c0,0.825 0.675,1.5 1.5,1.5l7.079,0c0.825,0 1.5,-0.675 1.5,-1.5l0,-22.272z"
      />
    </g>
  </svg>
)
