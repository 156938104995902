import { Box, Card, Grid, Icon, T } from '@veracity/vui'

import { useSystemDictionaries } from '@/hooks'
import { SystemConfigurations } from '@/interfaces'

interface Props {
  allowedSystemConfigurations: SystemConfigurations[]
}

export const LibraryDatasetConfigurationAlignment = ({
  allowedSystemConfigurations
}: Props) => {
  const { systemConfigurations } = useSystemDictionaries()

  const enabledSystemConfigurations = systemConfigurations
    .filter(x => !x.disabled)
    .map(x => ({
      isChecked: allowedSystemConfigurations.includes(+x.value),
      text: x.text
    }))

  return (
    <Card column p={2}>
      <Box column>
        <T variant="caption">Data provided in this dataset allows modeling:</T>

        <Grid pt={2} gridTemplateColumns={{ md: 2 }} rowGap={1}>
          {enabledSystemConfigurations.map((item, index) => (
            <Box gap={1} key={index}>
              <Icon
                pathFill="seaBlue.28"
                name={
                  item.isChecked
                    ? 'cuiCheckboxSelected'
                    : 'cuiCheckboxUnselected'
                }
              />
              <T>{item.text}</T>
            </Box>
          ))}
        </Grid>
      </Box>
    </Card>
  )
}
