import { Fragment } from 'react'
import { Box, Grid, T } from '@veracity/vui'

import { formatters } from '@/utils'
import { BorderedBox } from '@/components'
import { BessOperatingIncomeResult } from '@/interfaces'

interface Props {
  items: BessOperatingIncomeResult[]
}
export const ScenarioResultsSummaryTabAdjustmentTablePvBased = ({
  items
}: Props) => (
  <Box column>
    <Grid gridTemplateColumns="1fr 1fr 1fr 4fr 6fr">
      <BorderedBox />
      <BorderedBox p={1} bg="seaBlue.28" color="white" justify="center">
        <T>PV + ES</T>
      </BorderedBox>
      <BorderedBox p={1} bg="seaBlue.28" color="white" justify="center">
        <T>PV</T>
      </BorderedBox>
      <BorderedBox p={1} bg="seaBlue.28" color="white" justify="center">
        <T>BESS operating income summary</T>
      </BorderedBox>
      <BorderedBox p={1} bg="seaBlue.28" color="white" justify="center">
        <T>BESS operating income by market product</T>
      </BorderedBox>
    </Grid>
    <Grid gridTemplateColumns="repeat(13, 1fr)">
      {[
        'Year',
        'PV+ES Income ($)',
        'Income w/o ES ($)',
        'Usable Energy at POI (MWh)',
        'Equivalent Full Cycles',
        'Total ES Income ($)',
        'Total ES Income ($/kW-mo)',
        'PV Revenue Loss ($)',
        'DAM Energy ($)',
        'RTM Energy ($)',
        'DAM Reg Up ($)',
        'DAM Reg Down ($)',
        'DAM SR ($)'
      ].map(x => (
        <BorderedBox
          bg="skyBlue.77"
          justify="center"
          alignItems="center"
          key={x}>
          <T align="center" weight="medium">
            {x}
          </T>
        </BorderedBox>
      ))}

      {items
        .map(item => [
          item.year.toFixed(0),
          item.pvEsIncome === undefined
            ? '-'
            : formatters.number(item.pvEsIncome),
          item.pvEsIncomeWithoutEs === undefined
            ? '-'
            : formatters.number(item.pvEsIncomeWithoutEs),
          item.usableEnergyAtPoi === undefined
            ? '-'
            : formatters.number(item.usableEnergyAtPoi),
          item.equivalentFullCycles === undefined
            ? '-'
            : formatters.number(item.equivalentFullCycles),
          item.totalEsIncomeUsd === undefined
            ? '-'
            : formatters.number(item.totalEsIncomeUsd),
          item.totalEsIncomeKw === undefined
            ? '-'
            : formatters.number(item.totalEsIncomeKw),
          item.pvRevenueLoss === undefined
            ? '-'
            : formatters.number(item.pvRevenueLoss),
          item.damEnergy === undefined
            ? '-'
            : formatters.number(item.damEnergy),
          item.rtmEnergy === undefined
            ? '-'
            : formatters.number(item.rtmEnergy),
          item.damRegUp === undefined ? '-' : formatters.number(item.damRegUp),
          item.damRegDown === undefined
            ? '-'
            : formatters.number(item.damRegDown),
          item.damSr === undefined ? '-' : formatters.number(item.damSr)
        ])
        .map((items, index) => (
          <Fragment key={index}>
            {items.map((x, colIndex) => (
              <BorderedBox column align="flex-end" px={1} key={colIndex}>
                <T>{x}</T>
              </BorderedBox>
            ))}
          </Fragment>
        ))}
    </Grid>
  </Box>
)
