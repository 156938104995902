import { Link } from 'react-router-dom'
import { Grid } from '@veracity/vui'

import { ProjectSimple } from '@/interfaces'

import { ProjectsListCollectionGridCard } from './ProjectsListCollectionGridCard'

interface Props {
  projects?: ProjectSimple[]
}

export const ProjectsListCollectionGrid = ({ projects = [] }: Props) => (
  <Grid w="100%" gap={3} gridTemplateColumns={{ xl: 4, lg: 3, md: 2, sm: 1 }}>
    {projects?.map(project => (
      <Link
        key={project.id}
        to={`/projects/${project.id}`}
        className="text-decoration-none">
        <ProjectsListCollectionGridCard project={project} />
      </Link>
    ))}
  </Grid>
)
