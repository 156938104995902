import { useMutation, useQuery } from 'react-query'
import { scenarioComparisonClient } from '@/api'
import { downloadFromBlob } from '@/utils'

export const useScenarioComparison = (scenarioIds: number[]) => {
  const BASE_QUERY_KEY = [
    ...scenarioComparisonClient.QUERY_KEY,
    { scenarioIds }
  ]

  const comparisonQuery = useQuery(BASE_QUERY_KEY, () =>
    scenarioComparisonClient.getComparison(scenarioIds)
  )

  const detailedComparisonQuery = useQuery(
    [...BASE_QUERY_KEY, 'detailed'],
    () => scenarioComparisonClient.getDetailedComparison(scenarioIds)
  )

  const downloadComparisonMutation = useMutation({
    mutationFn: () => scenarioComparisonClient.downloadExportFile(scenarioIds),
    onSuccess: result =>
      downloadFromBlob(`ComparisonExport_${scenarioIds.join('_')}.xlsx`, result)
  })

  return {
    comparisonInfo: comparisonQuery.data || [],
    isFetching: comparisonQuery.isFetching,
    detailedComparisonInfo: detailedComparisonQuery.data,
    downloadComparison: downloadComparisonMutation.mutate,
    isDownloadComparisonInProgress: downloadComparisonMutation.isLoading
  }
}
