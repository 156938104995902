import { useToast } from '@veracity/vui'
import { useNavigate } from 'react-router-dom'
import { useMutation, useQuery, useQueryClient } from 'react-query'

import { config } from '@/config'
import { otherBessInputsLibraryClient } from '@/api'
import { UpdateOtherBessInputsDatasetRequest } from '@/interfaces'

const BASE_ROUTE = config.links.library.otherBessInputs

export const useOtherBessInputDatasetDetails = (datasetId: number) => {
  const { showSuccess } = useToast()
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const QUERY_KEY = [...otherBessInputsLibraryClient.QUERY_KEY, datasetId]

  const getDatasetQuery = useQuery(QUERY_KEY, () =>
    otherBessInputsLibraryClient.getDetails(datasetId)
  )

  const cloneDatasetMutation = useMutation({
    mutationFn: (datasetName: string) =>
      otherBessInputsLibraryClient.cloneDataset({
        datasetId,
        name: datasetName
      }),
    onSuccess: async clonedDatasetId => {
      showSuccess('Dataset successfully cloned')
      await queryClient.invalidateQueries(
        otherBessInputsLibraryClient.QUERY_KEY,
        { refetchInactive: true }
      )
      navigate(`${BASE_ROUTE}/${clonedDatasetId}`)
    }
  })

  const cloneDataset = (datasetName: string, onSuccess?: () => void) =>
    cloneDatasetMutation.mutate(datasetName, { onSuccess })

  const removeDatasetMutation = useMutation({
    mutationFn: () => otherBessInputsLibraryClient.removeDataset(datasetId),
    onSuccess: async () => {
      showSuccess('Dataset successfully removed')
      navigate(BASE_ROUTE)
      await queryClient.invalidateQueries(
        otherBessInputsLibraryClient.QUERY_KEY,
        { refetchInactive: true }
      )
    }
  })

  const lockDatasetMutation = useMutation({
    mutationFn: () => otherBessInputsLibraryClient.lockDataset(datasetId),
    onSuccess: async () => {
      showSuccess('Dataset successfully locked')
      await queryClient.invalidateQueries(
        otherBessInputsLibraryClient.QUERY_KEY,
        { refetchInactive: true }
      )
    }
  })

  const archiveDatasetMutation = useMutation({
    mutationFn: () => otherBessInputsLibraryClient.archiveDataset(datasetId),
    onSuccess: async () => {
      showSuccess('Dataset successfully archived')
      await queryClient.invalidateQueries(
        otherBessInputsLibraryClient.QUERY_KEY,
        { refetchInactive: true }
      )
    }
  })

  const unArchiveDatasetMutation = useMutation({
    mutationFn: () => otherBessInputsLibraryClient.unArchiveDataset(datasetId),
    onSuccess: async () => {
      showSuccess('Dataset successfully unarchived')
      await queryClient.invalidateQueries(
        otherBessInputsLibraryClient.QUERY_KEY,
        { refetchInactive: true }
      )
    }
  })

  const updateDatasetMutation = useMutation({
    mutationFn: (req: UpdateOtherBessInputsDatasetRequest) =>
      otherBessInputsLibraryClient.updateDataset(datasetId, req),
    onSuccess: async () => {
      showSuccess('Dataset successfully updated')
      await queryClient.invalidateQueries(QUERY_KEY)
    }
  })

  return {
    datasetInfo: getDatasetQuery.data,
    isLoadingDatasetInfo: getDatasetQuery.isFetching,
    cloneDataset: cloneDataset,
    isCloneDatasetInProgress: cloneDatasetMutation.isLoading,
    removeDataset: removeDatasetMutation.mutate,
    lockDataset: lockDatasetMutation.mutate,
    archiveDataset: archiveDatasetMutation.mutate,
    unArchiveDataset: unArchiveDatasetMutation.mutate,
    getDownloadFileUrl: (fileId?: number) =>
      !!fileId
        ? otherBessInputsLibraryClient.getDownloadFileUrl(fileId)
        : undefined,
    updateDataset: updateDatasetMutation.mutate,
    isUpdateDatasetInProgress: updateDatasetMutation.isLoading
  }
}
