import { useToast } from '@veracity/vui'
import { useMutation, useQuery, useQueryClient } from 'react-query'

import { apiKeyClient } from '@/api'

export const useHeroApiKey = () => {
  const { showSuccess } = useToast()
  const queryClient = useQueryClient()
  const { data: heroApiKeyInfo } = useQuery(
    apiKeyClient.QUERY_KEY,
    apiKeyClient.getApiKeyInfo
  )
  const { mutate: reactivateHeroApiKey } = useMutation({
    mutationFn: apiKeyClient.reactivateApiKey,
    onSuccess: async () => {
      await queryClient.invalidateQueries(apiKeyClient.QUERY_KEY)
      showSuccess('HERO API key generated')
    }
  })

  return { heroApiKeyInfo, reactivateHeroApiKey }
}
