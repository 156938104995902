import { ChangeEvent } from 'react'
import { Controller } from 'react-hook-form'
import { Box, Select, T } from '@veracity/vui'

import { SelectFormControlProps } from './SelectFormControl'

export type MultiSelectFormControlProps = SelectFormControlProps

export const MultiSelectFormControl = ({
  formControlName,
  control,
  label,
  rules,
  w,
  ...props
}: MultiSelectFormControlProps) => {
  const handleChange = (
    e: ChangeEvent<HTMLInputElement>,
    currentValue: any[] = []
  ) => {
    const { value } = e.target

    const newValues = currentValue.includes(value)
      ? currentValue.filter(v => v !== value)
      : [...currentValue, value]

    return { target: { value: newValues } }
  }

  return (
    <Controller
      name={formControlName}
      control={control}
      rules={rules}
      render={({ field, fieldState }) => (
        <Box column gap={1} w={w}>
          {!label ? null : <T>{label}</T>}
          <Select
            isMultiple
            value={field.value}
            onChange={ev => {
              field.onChange(handleChange(ev, field.value))
              field.onBlur()
            }}
            isInvalid={!!fieldState.error}
            {...props}
          />
        </Box>
      )}
    />
  )
}
