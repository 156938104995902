import { AxiosError } from 'axios'
import { PropsWithChildren } from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'

export const ReactQueryProvider = ({
  children
}: PropsWithChildren<unknown>) => {
  const errorHandling = (err: AxiosError) => {
    console.log(err, 'error')

    switch (err.response?.status) {
      case 400:
        const errorMessage = (err.response?.data as any)?.Error
        // showError(errorMessage || 'Something went wrong.') // TODO: Check why useToast() fails navigation
        break
      case 500:
        // showError('Something went wrong.')
        break
    }
  }

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        retry: false,
        retryOnMount: false
      },
      mutations: {
        onError: err => errorHandling(err as AxiosError)
      }
    }
  })

  return <QueryClientProvider client={queryClient} children={children} />
}
