import { Box, Divider, Heading, useDialog } from '@veracity/vui'

import { ExternalApiKeySimple, KeyTypes } from '@/interfaces'

import { ApiManagementOtherDnvSectionItem } from './ApiManagementOtherDnvSectionItem'
import { useOtherDnvApiKeys } from '../../hooks'

interface Props {
  externalApiKeys: ExternalApiKeySimple[]
  onCopyClicked: (key?: string) => void
}

export const ApiManagementOtherDnvSection = ({
  externalApiKeys,
  onCopyClicked
}: Props) => {
  const { showPrompt } = useDialog()
  const { addExternalApiKey, updateExternalApiKey } = useOtherDnvApiKeys()
  const srcApiKey = externalApiKeys.find(x => x.type === KeyTypes.Src)

  const solarFarmerApiKey = externalApiKeys.find(
    x => x.type === KeyTypes.SolarFarmer
  )

  const handleAddKeyClicked = async (keyType: KeyTypes) => {
    const result = await showPrompt('Add API Key')
    if (!!result)
      addExternalApiKey({ type: keyType, key: result, name: 'test' })
  }

  const handleEditKeyClicked = async (apiKey: ExternalApiKeySimple) => {
    const result = await showPrompt('Update API Key', apiKey.key)
    if (!!result) updateExternalApiKey({ id: apiKey.id, key: result })
  }

  return (
    <>
      <Heading size="h3" weight="medium">
        Other DNV API Keys
      </Heading>

      <Box column bg="white" p={2} gap={2}>
        <ApiManagementOtherDnvSectionItem
          keyType={KeyTypes.Src}
          externalApiKey={srcApiKey}
          onCopyToClipboard={onCopyClicked}
          onAddClicked={() => handleAddKeyClicked(KeyTypes.Src)}
          onEditClicked={handleEditKeyClicked}
        />
        <Divider />
        <ApiManagementOtherDnvSectionItem
          keyType={KeyTypes.SolarFarmer}
          externalApiKey={solarFarmerApiKey}
          onCopyToClipboard={onCopyClicked}
          onAddClicked={() => handleAddKeyClicked(KeyTypes.SolarFarmer)}
          onEditClicked={handleEditKeyClicked}
        />
      </Box>
    </>
  )
}
