import { useParams } from 'react-router-dom'
import { useToggle } from '@veracity/ui-utils'

import { PageContainer } from '@/components'

import { useScenarioComparison } from './hooks'
import {
  ScenarioComparisonOperations,
  ScenarioComparisonPreview
} from './components'

export const ScenarioComparisonPage = () => {
  const { joinedScenarioIds } = useParams()
  const scenarioIds = joinedScenarioIds?.split('+').map(x => +x) || []

  const [areAllRowsExpanded, toggleAreAllRowsExpanded] = useToggle(true)

  const {
    comparisonInfo,
    isFetching,
    detailedComparisonInfo,
    downloadComparison,
    isDownloadComparisonInProgress
  } = useScenarioComparison(scenarioIds)

  return (
    <PageContainer
      loading={isFetching}
      header={
        <PageContainer.Breadcrumbs
          items={[
            { title: 'Projects', path: '/projects' },
            { title: 'Scenario Comparison' }
          ]}
        />
      }
      menuBar={
        <ScenarioComparisonOperations
          selectedScenarioIds={scenarioIds}
          onExpandAllRows={toggleAreAllRowsExpanded.on}
          onCollapseAllRows={toggleAreAllRowsExpanded.off}
          onPrintComparison={() => window.print()}
          onDownloadExport={downloadComparison}
          isDownloadExportInProgress={isDownloadComparisonInProgress}
        />
      }>
      <ScenarioComparisonPreview
        areAllRowsExpanded={areAllRowsExpanded}
        comparisonInfo={comparisonInfo}
        detailedComparisonInfo={detailedComparisonInfo}
      />
    </PageContainer>
  )
}
