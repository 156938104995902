export const AboutPage = () => null

// (

// <Box column maxH="100%" overflowY="auto" p={2} bg="white" gap={4}>
//   <Heading size="h1">Hybrid Energy Resource Optimizer</Heading>
//
//   <AboutSection title="Planning for financial success of your hybrid or standalone battery project">
//     <P>
//       Built on unparalleled battery engineering, contract & regulatory
//       expertise, and advanced optimization modelling, DNV’s Hybrid Energy
//       Resource Optimizer platform provides reliable, realistic, and
//       transparent valuation of your storage or hybrid project. In an
//       increasingly competitive and fast-moving storage market, the Hybrid
//       Energy Resource Optimizer informs investment, strengthen your
//       portfolio’s strategic positioning, and provides certainty to investors.
//     </P>
//   </AboutSection>
//
//   <AboutSection title="An advanced valuation methodology">
//     <P>
//       The platform calculates dispatch on a 24-hour look-ahead basis at
//       15-minute intervals. It solves mixed integer linear programming problems
//       on that basis, evaluating an iterative bid strategy to maximize income
//       potential while complying with battery cycling and throughput
//       requirements. Operating income and charging cost at 15-minute intervals
//       are computed over project life based on the optimal dispatch strategy.
//       These results are adjusted according to DNV’s rich experience with
//       battery storage projects in operation to finetune perfect foresight
//       revenue in light of ground-reality estimates.
//     </P>
//     <Image src={heroComponentsImage} />
//     <P>
//       The Hybrid Energy Resource Optimizer explicitly considers a detailed
//       technical model, including technical and loss tree parameters of
//       renewable and storage systems. Renewable production profiles created by
//       off-the-shelf modeling tools can be used as direct inputs. ITC
//       requirements, interconnection constraints, battery warranty, and
//       capacity maintenance strategies, market price models are embedded and
//       configurable within the platform.
//     </P>
//   </AboutSection>
//
//   <AboutSection title="Market regulations model">
//     <P>
//       Each independent system operator (ISO) in the United States has
//       distinctive regulations governing battery resource bidding, market
//       clearing, dispatch, and settlement. The Hybrid Energy Resource Optimizer
//       embeds regulatory and participation mechanisms for each ISO.
//     </P>
//     <Grid gridTemplateColumns={2}>
//       <Box column>
//         <P> The following merchant markets are included in the platform: </P>
//         <List variant="unordered">
//           <List.Item>Energy Arbitrage Market</List.Item>
//
//           <List variant="unordered">
//             <List.Item>Day-ahead energy (hourly)</List.Item>
//             <List.Item>Real-time energy (15-min)</List.Item>
//           </List>
//
//           <List.Item>Ancillary Service Market</List.Item>
//
//           <List variant="unordered">
//             <List.Item>Regulation up and regulation down (hourly)</List.Item>
//             <List.Item>Spinning reserve (hourly)</List.Item>
//           </List>
//
//           <List.Item>Capacity Market</List.Item>
//         </List>
//       </Box>
//       <Image src={supportedIsoTypesImage} />
//     </Grid>
//   </AboutSection>
//
//   <AboutSection title="Market price library">
//     <P>
//       Users can utilize the Hybrid Energy Resource Optimizer as a library
//       management tool to manage different price forecast datasets under
//       different forecast assumptions, such as volatilities, demand growth,
//       fuel prices, carbon prices, and renewable portfolios.
//     </P>
//     <Image src={libraryPageImage} />
//   </AboutSection>
//
//   <AboutSection title="ITC eligibility requirement">
//     <P>
//       The tool accounts for minimum charging percentage from renewable output,
//       which is essential for auditing of investment tax credits (ITC).
//     </P>
//     <P>
//       Based on latest tax extenders bill that cleared Congress on Dec 21,
//       2020, solar projects that start construction in 2020, 2021, or 2022 will
//       qualify for a 26% investment tax credit. The tax credit drops to 22% for
//       projects starting construction in 2023. All projects must be placed in
//       service by the end of fourth year or 2025, otherwise will only be
//       qualified for 10% ITC. Onshore wind’s production tax credits are
//       extended to end of 2021. Offshore wind project on which construction
//       starts before 2026 will be qualified for a 30% ITC.
//     </P>
//   </AboutSection>
//
//   <AboutSection title="Renewable and storage technical model">
//     <P>
//       The Hybrid Energy Resource Optimizer embeds loss-trees in the
//       optimization formulation so that the decision to select a mode or to bid
//       into a market considers the economic cost of transacting energy in that
//       path. In the following DC-coupled solar plus storage example, the losses
//       and efficiencies shown in the chart are all considered in the HERO
//       model.
//     </P>
//     <Image src={technicalModelImage} />
//     <P>The losses are different for all four possible energy flow paths:</P>
//     <List
//       p={1}
//       variant="unordered"
//       items={[
//         { text: 'Solar photovoltaic (PV) export to grid' },
//         { text: 'Storage charging from PV on DC-bus' },
//         { text: 'Storage charging from grid' },
//         { text: 'Storage discharging to grid' }
//       ]}
//     />
//
//     <P>
//       The Hybrid Energy Resource Optimizer models loss-trees for six different
//       topologies:
//     </P>
//     <List
//       p={1}
//       variant="ordered"
//       items={[
//         { text: 'Stand-alone energy storage' },
//         { text: 'DC-coupled with solar PV' },
//         { text: 'AC-coupled with solar PV on shared bus (ACSB)' },
//         { text: 'AC-coupled with wind on shared bus (ACSB)' },
//         { text: 'DC-coupled with solar PV and AC-coupled with wind' },
//         { text: 'AC-coupled with solar PV and wind' }
//       ]}
//     />
//   </AboutSection>
//
//   <AboutSection title="Storage warranty and capacity strategy">
//     <P>
//       Storage vendor contracts often stipulate the maximum number of annual
//       cycles, storage depth of cycling, and preferred storage resting
//       state-of-charge. The tool implements these specific, warranty-oriented
//       parameters. Users can utilize the manufacture capacity retention curve
//       and augmentation plan as direct inputs.
//     </P>
//     <Grid gridTemplateColumns={2} gap={2}>
//       <Image src={report1Image} />
//       <Image src={report2Image} />
//     </Grid>
//   </AboutSection>
//
//   <AboutSection title="Finance library and cash-flow model">
//     <P>
//       DNV provides built-in industry-standard financial data as a sample in
//       its Hybrid Energy Resource Optimizer which users can use directly in
//       cash-flow projections. Users can also create and maintain their own
//       customized financial data sets to assess financial feasibility under
//       different assumptions for equipment cost, operating strategies, debt
//       amortization, tax rebates, and deductions.
//     </P>
//
//     <Grid gridTemplateColumns={2} gap={2}>
//       <Image src={financesLibraryPageImage} />
//       <Image src={financesReportImage} />
//     </Grid>
//   </AboutSection>
// </Box>
// )
