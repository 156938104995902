const APP_VERSION = '1.0.0'

const userAgentData = (navigator as any).userAgentData

const clientBrowserName = userAgentData?.brands?.slice(-1)?.[0]?.brand

const emails = {
  heroSupport: 'hero_support@dnv.com',
  heroContracts: 'hero_contracts@dnv.com',
  srcSupport: 'src_support@dnv.com',
  solarFarmerSupport: 'solarfarmer@dnv.com'
}

export const config = {
  appVersion: APP_VERSION,
  appFullName: 'Hybrid Energy Resource Optimizer',
  contactSupportLink: encodeURI(
    `mailto:${emails.heroSupport}?subject=Feedback/Support Request : DNV's Hybrid Energy Resource Optimizer&body=\n\nBrowser: ${clientBrowserName}\nOperating system: ${userAgentData?.platform}\nVersion: ${APP_VERSION}`
  ),
  emails,
  roles: {
    guest: 'Guest',
    viewer: 'Viewer',
    user: 'User',
    admin: 'Administrator',
    superAdmin: 'SuperAdministrator',
    developer: 'Developer'
  },
  requestIntervals: {
    userSession: 20 * 60 * 1_000, // 20 minutes
    scenarioCalculations: 10 * 1_000 // 10 seconds
  },
  externalLinks: {
    veracityTermsOfUse: 'https://id.veracity.com/terms-of-use',
    veracityTermsOfUseForHero:
      'https://store.veracity.com/terms/hybrid-energy-resource-storage',
    userGuide: 'https://dnv-opea.stoplight.io/docs/hero'
  },
  colors: {
    grayBg: '#f8f7f7'
  },
  links: {
    library: {
      wholesaleMarket: '/library/wholesale-market',
      finances: '/library/finances',
      incomeAdjustment: '/library/income-adjustment',
      lossFactors: '/library/loss-factors',
      productionProfiles: '/library/production-profiles',
      otherBessInputs: '/library/other-bess-inputs'
    }
  },
  mapboxStyle: 'mapbox://styles/mwojcik-dnv/cllezrdmm010d01pi6yy9fnie'
}
