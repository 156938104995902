import { Controller } from 'react-hook-form'
import { Box, Checkbox, T } from '@veracity/vui'

import { BaseFormItemProps } from './BaseFormItem'

export interface CheckboxGroupFormControlProps extends BaseFormItemProps {
  disabled?: boolean
  isHorizontal?: boolean
  items: { label: string; value: string | number }[]
}

export const CheckboxGroupFormControl = ({
  label,
  formControlName,
  control,
  rules,
  items,
  isHorizontal,
  disabled
}: CheckboxGroupFormControlProps) => {
  const getEventObject = (value: (string | number)[]) => ({ target: { value } })

  return (
    <Controller
      name={formControlName}
      control={control}
      rules={rules}
      render={({ field }) => (
        <Box column gap={1}>
          {!label ? null : <T>{label}</T>}

          <Box column={!isHorizontal} gap={1} flexWrap="wrap">
            {items.map(item => (
              <Checkbox
                key={item.value}
                label={item.label}
                disabled={disabled}
                checked={field.value?.includes(item.value) || false}
                onChange={ev =>
                  field.onChange(
                    getEventObject(
                      ev.target.checked
                        ? [...field.value, item.value]
                        : field.value.filter(
                            (x: string | number) => x !== item.value
                          )
                    )
                  )
                }
              />
            ))}
          </Box>
        </Box>
      )}
    />
  )
}
