import { Box, Card, Divider, formatDate, Heading, T } from '@veracity/vui'

import { OrganizationSimple } from '@/interfaces'

interface Props {
  organization?: OrganizationSimple
}

export const OrganizationInfoBox = ({ organization }: Props) => (
  <Card elevation={4} justify="space-around" alignItems="flex-start" p={3}>
    <Box column>
      <Box gap={2} alignItems="center">
        <T variant="caption" color="sandstone.55">
          Organization name
        </T>
        <Heading size="h3">{organization?.name}</Heading>
      </Box>
      <T variant="caption">
        {organization?.description ? `(${organization.description})` : null}
      </T>
    </Box>
    <Divider isVertical />
    <Box gap={2} alignItems="center">
      <T variant="caption" color="sandstone.55">
        Pricing plan
      </T>
      <Heading size="h3">{organization?.pricePlanName}</Heading>
    </Box>
    <Divider isVertical />
    <Box gap={2} alignItems="center">
      <T variant="caption" color="sandstone.55">
        Subscription exp. date
      </T>
      <Heading size="h3">
        {formatDate(organization?.subscriptionExpirationDate, 'DD MMM YYYY')}
      </Heading>
    </Box>
  </Card>
)
