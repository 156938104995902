import { CalculationStates, SystemConfigurations } from '@/interfaces'

export enum PortfolioDetailsPreviewType {
  GRID,
  TABLE,
  MAP
}

export interface PortfolioDetailsFilterValues {
  selectedCalculationState?: CalculationStates
  selectedSystemConfiguration?: SystemConfigurations
  selectedCalculationStartDate?: Date
}
