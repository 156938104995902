import axios from 'axios'
import {
  AddScenarioRequest,
  CloneScenarioRequest,
  GetCalculationProgressResponse,
  ScenarioDetailed,
  ScenarioTags,
  UpdatePropertyRequest
} from '@/interfaces'

const baseUrl = '/api/scenarios'

export const scenariosClient = {
  QUERY_KEY: (scenarioId: number) => ['scenarios', scenarioId],
  getById: (scenarioId: number) =>
    axios.get<ScenarioDetailed>(`${baseUrl}/${scenarioId}`).then(x => x.data),
  createScenario: (req: AddScenarioRequest) =>
    axios.post<number>(baseUrl, req).then(x => x.data),
  removeById: (scenarioId: number) =>
    axios.delete<void>(`${baseUrl}/${scenarioId}`).then(x => x.data),
  clone: (scenarioId: number, req: CloneScenarioRequest) =>
    axios.post(`${baseUrl}/CloneScenario/${scenarioId}`, req).then(x => x.data),
  updateName: (scenarioId: number, name: string) =>
    axios
      .patch<void>(`${baseUrl}/${scenarioId}`, {
        propertyName: 'name',
        propertyValue: name
      } as UpdatePropertyRequest)
      .then(x => x.data),
  updateTag: (scenarioId: number, tag: ScenarioTags) =>
    axios
      .patch<void>(`${baseUrl}/${scenarioId}`, {
        propertyName: 'tag',
        propertyValue: tag
      } as UpdatePropertyRequest)
      .then(x => x.data),
  patchScenarioField: (scenarioId: number, req: UpdatePropertyRequest) =>
    axios.patch<void>(`${baseUrl}/${scenarioId}`, req).then(x => x.data),
  runCalculation: (scenarioId: number) =>
    axios.post<void>(`${baseUrl}/run/${scenarioId}`).then(x => x.data),
  cancelCalculation: (scenarioId: number) =>
    axios
      .post<void>(`${baseUrl}/cancelCalculation/${scenarioId}`)
      .then(x => x.data),
  getCalculationProgress: (scenarioId: number) =>
    axios
      .get<GetCalculationProgressResponse>(
        `${baseUrl}/getCalculationProgress/${scenarioId}`
      )
      .then(x => x.data)
}
