export const DcAcInverterIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    fill="none"
    fillRule="evenodd"
    strokeLinecap="square"
    strokeMiterlimit="3"
    colorInterpolationFilters="sRGB"
    fontSize="12"
    height={52}
    viewBox="0 0 34.5313 32">
    <g transform="rotate(-180 17.2656 16)" id="svg_5">
      <path
        transform="rotate(-180 17.4063 16)"
        id="svg_1"
        d="m0.53125,0.25l33.75,0l0,31.5l-33.75,0l0,-31.5z"
        strokeWidth="0.25"
        strokeLinejoin="round"
        strokeLinecap="round"
        stroke="#000"
      />
      <path
        id="svg_2"
        d="m0.25002,31.74999l34.03009,-31.2177"
        strokeWidth="0.5"
        strokeLinejoin="round"
        strokeLinecap="round"
        stroke="#000"
      />
      <path
        id="svg_3"
        d="m4.46876,7.2813l13.5,0m-13.5,4.5l5.17,0m3.71754,0l5.17,0"
        strokeWidth="0.25"
        strokeLinejoin="round"
        strokeLinecap="round"
        stroke="#000"
      />
      <path
        id="svg_4"
        d="m16,22.75004c0.27,-0.73 0.69,-1.46 1.28,-2.02c0.92,-0.86 2.25,-1.27 3.07,-0.92c1.36,0.57 1.33,3.21 2.49,4.43c1.26,1.34 3.96,0.99 4.41,-1.49"
        strokeWidth="0.5"
        strokeLinejoin="round"
        strokeLinecap="round"
        stroke="#000"
      />
    </g>
  </svg>
)
