import { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import {
  Button,
  formatDate,
  Pagination,
  Progress,
  Table,
  Tbody,
  Td,
  Tfoot,
  Thead,
  Tr
} from '@veracity/vui'

import { orderByKey } from '@/utils'
import { OrganizationSimple } from '@/interfaces'
import { useTableSorting } from '@/hooks'

const PAGE_SIZE = 10

interface Props {
  organizations: OrganizationSimple[]
}

export const OrganizationsContainerTable = ({ organizations }: Props) => {
  const [pageNumber, setPageNumber] = useState(1)
  const { sort, handleSortChange } = useTableSorting<OrganizationSimple>({
    key: 'createdAt',
    order: 'desc'
  })

  useEffect(() => setPageNumber(1), [organizations])

  const pageData = useMemo(
    () =>
      (organizations || [])
        .sort(orderByKey(sort.key, sort.order))
        .slice((pageNumber - 1) * PAGE_SIZE, pageNumber * PAGE_SIZE),
    [pageNumber, organizations, sort]
  )

  return (
    <Table
      sort={sort}
      onSortChange={handleSortChange}
      columns={[
        { field: 'name', title: 'Organization name', isSortable: true },
        { field: 'pricePlanName', title: 'Pricing plan' },
        { field: 'createdAt', title: 'Created On', isSortable: true },
        {
          field: 'subscriptionExpirationDate',
          title: 'Subscription Exp. Date',
          isSortable: true
        },
        { field: 'usage', title: 'Usage' },
        { field: 'id', title: ' ' }
      ]}>
      <Thead bg="white" />
      <Tbody bg="white">
        {pageData.map(organization => (
          <Tr key={organization.id}>
            <Td>{organization.name}</Td>
            <Td>{organization.pricePlanName}</Td>
            <Td>{formatDate(organization.createdAt, 'DD MM YYYY')}</Td>
            <Td>
              {formatDate(
                organization.subscriptionExpirationDate,
                'DD MM YYYY'
              )}
            </Td>
            <Td>
              <Progress
                size="sm"
                showPercentage
                value={
                  (100 * organization.actuallyCalculationRequests) /
                  organization.maxCalculationRequests
                }
              />
            </Td>
            <Td>
              <Button
                as={Link}
                to={`/organizations/${organization.id}`}
                variant="tertiaryDark"
                icon="falArrowRight"
              />
            </Td>
          </Tr>
        ))}
      </Tbody>
      <Tfoot>
        <Tr>
          <Td colSpan={6}>
            <Pagination
              pageSize={PAGE_SIZE}
              count={organizations.length}
              page={pageNumber}
              onPageChange={setPageNumber}
            />
          </Td>
        </Tr>
      </Tfoot>
    </Table>
  )
}
