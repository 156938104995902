export const DcDcConverterIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    fill="none"
    fillRule="evenodd"
    strokeLinecap="square"
    strokeMiterlimit="3"
    colorInterpolationFilters="sRGB"
    fontSize="12"
    overflow="visible"
    height={55}
    viewBox="0 0 34.531 32">
    <g id="shape2-1" transform="translate(.531 -.25)">
      <path stroke="#000" strokeWidth=".25" d="M0 .5h33.75V32H0z" />
    </g>
    <g id="shape3-3" transform="rotate(-42.532 -.196 31.554)">
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth=".5"
        d="M0 32h46.18"
      />
    </g>
    <g id="shape4-6" transform="translate(4.469 -24.719)">
      <path
        d="M0 32h13.5"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.25}
      />
    </g>
    <g id="shape5-9" transform="translate(4.469 -20.219)">
      <path
        d="M0 32h5.17"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.25}
      />
    </g>
    <g id="shape6-12" transform="translate(13.356 -20.219)">
      <path
        d="M0 32h5.17"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.25}
      />
    </g>
    <g id="shape7-15" transform="translate(16 -10.769)">
      <path
        d="M0 32h13.5"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.25}
      />
    </g>
    <g id="shape8-18" transform="translate(16 -6.269)">
      <path
        d="M0 32h5.17"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.25}
      />
    </g>
    <g id="shape9-21" transform="translate(24.887 -6.269)">
      <path
        d="M0 32h5.17"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.25}
      />
    </g>
  </svg>
)
