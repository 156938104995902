import { useNavigate } from 'react-router-dom'
import { useToast } from '@veracity/vui'
import { useMutation, useQuery, useQueryClient } from 'react-query'

import { projectsClient, scenariosClient } from '@/api'
import {
  CloneScenarioRequest,
  ScenarioTags,
  UpdatePropertyRequest
} from '@/interfaces'

export const useScenarioDetails = (projectId: number, scenarioId: number) => {
  const QUERY_KEY = scenariosClient.QUERY_KEY(scenarioId)

  const navigate = useNavigate()
  const { showSuccess, showError } = useToast()
  const queryClient = useQueryClient()

  const scenarioQuery = useQuery(
    QUERY_KEY,
    () => scenariosClient.getById(scenarioId),
    {
      onSuccess: res => {
        if (res.projectId !== projectId) {
          showError('Scenario with given id not exists')
          navigate(`/projects/${projectId}`)
        }
      }
    }
  )

  const updateScenarioMutation = useMutation({
    mutationFn: (scenarioName: string) =>
      scenariosClient.updateName(scenarioId, scenarioName),
    onSuccess: async () => {
      showSuccess('Scenario successfully updated')
      await queryClient.invalidateQueries(QUERY_KEY)
    }
  })

  const removeScenarioMutation = useMutation({
    mutationFn: () => scenariosClient.removeById(scenarioId),
    onSuccess: async () => {
      showSuccess('Scenario successfully removed')
      navigate(`/projects/${scenarioQuery.data?.projectId}`)
      queryClient.removeQueries(QUERY_KEY)
      await queryClient.invalidateQueries(
        ['projects', scenarioQuery.data?.projectId],
        { refetchInactive: true }
      )
    }
  })

  const cloneScenarioMutation = useMutation({
    mutationFn: (req: CloneScenarioRequest) =>
      scenariosClient.clone(scenarioId, req),
    onSuccess: (clonedScenarioId: number) => {
      showSuccess('Scenario successfully cloned')
      navigate(
        `/projects/${scenarioQuery.data?.projectId}/scenarios/${clonedScenarioId}`
      )
      queryClient.removeQueries(QUERY_KEY)
    }
  })

  const updateTagMutation = useMutation({
    mutationFn: (tag: ScenarioTags) =>
      scenariosClient.updateTag(scenarioId, tag),
    onSuccess: async () => {
      showSuccess('Scenario successfully updated')
      await queryClient.invalidateQueries(QUERY_KEY)
    }
  })

  const updateScenarioFieldMutation = useMutation({
    mutationFn: (req: UpdatePropertyRequest) =>
      scenariosClient.patchScenarioField(scenarioId, req),
    onSuccess: async () => {
      showSuccess('Scenario successfully updated')
      await queryClient.invalidateQueries(QUERY_KEY)
    }
  })

  const runCalculationMutation = useMutation({
    mutationFn: () => scenariosClient.runCalculation(scenarioId),
    onSuccess: async () => {
      showSuccess('Calculation successfully started')
      await queryClient.invalidateQueries(projectsClient.QUERY_KEY, {
        refetchInactive: true
      })
      await queryClient.invalidateQueries(QUERY_KEY)
    },
    onError: error => {
      showError((error as any).message)
    }
  })

  const cancelCalculationMutation = useMutation({
    mutationFn: () => scenariosClient.cancelCalculation(scenarioId),
    onSuccess: async () => {
      showSuccess('Calculation successfully aborted')
      await queryClient.invalidateQueries(projectsClient.QUERY_KEY, {
        refetchInactive: true
      })
      await queryClient.invalidateQueries(QUERY_KEY)
    },
    onError: error => {
      console.log('cancel calculation error', error)
      showError((error as any).message)
    }
  })

  return {
    scenarioInfo: scenarioQuery.data,
    isFetching: scenarioQuery.isFetching && !scenarioQuery.isRefetching,
    updateScenarioName: (name: string, onSuccess: () => void) =>
      updateScenarioMutation.mutate(name, { onSuccess }),
    isUpdateScenarioNameInProgress: updateScenarioMutation.isLoading,
    removeScenario: removeScenarioMutation.mutate,
    isRemoveScenarioInProgress: removeScenarioMutation.isLoading,
    cloneScenario: (req: CloneScenarioRequest, onSuccess: () => void) =>
      cloneScenarioMutation.mutate(req, { onSuccess }),
    isCloneScenarioInProgress: cloneScenarioMutation.isLoading,
    updateScenarioTag: (tag: ScenarioTags, onSuccess: () => void) =>
      updateTagMutation.mutate(tag, { onSuccess }),
    isUpdateScenarioTagInProgress: updateTagMutation.isLoading,
    updateScenarioField: (fieldName: string, fieldValue: unknown) =>
      updateScenarioFieldMutation.mutate({
        propertyName: fieldName,
        propertyValue: fieldValue
      }),
    runCalculation: () => runCalculationMutation.mutate(),
    runCalculationInProgress: runCalculationMutation.isLoading,
    cancelCalculation: () => cancelCalculationMutation.mutate(),
    cancelCalculationInProgress: cancelCalculationMutation.isLoading
  }
}
