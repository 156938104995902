import { useEffect, useMemo, useState } from 'react'
import { formatDate, SelectOptionData } from '@veracity/vui'

import { PageFilterPanel, PageFilterPanelPreviewItem } from '@/components'
import { CalculationStates, SystemConfigurations } from '@/interfaces'

import { stateTitles } from '../../consts'
import { PortfolioDetailsFilterValues } from '../../types'
import { PortfolioDetailsFilterPanelInputs } from './PortfolioDetailsFilterPanelInputs'

interface Props {
  isFilterActive: boolean
  onFilterCloseClicked: () => void
  onFilterChange: (filterValues?: PortfolioDetailsFilterValues) => void
  availableSystemConfigurations: SelectOptionData[]
}

export const PortfolioDetailsFilterPanel = ({
  isFilterActive,
  onFilterCloseClicked,
  onFilterChange,
  availableSystemConfigurations
}: Props) => {
  const [selectedCalculationState, setSelectedCalculationState] =
    useState<CalculationStates>()
  const [selectedSystemConfiguration, setSelectedSystemConfiguration] =
    useState<SystemConfigurations>()
  const [selectedCalculationStartDate, setSelectedCalculationStartDate] =
    useState<Date>()

  useEffect(() => {
    if (
      selectedCalculationState === undefined &&
      selectedSystemConfiguration === undefined &&
      selectedCalculationStartDate === undefined
    )
      onFilterChange(undefined)
    else
      onFilterChange({
        selectedCalculationState,
        selectedSystemConfiguration,
        selectedCalculationStartDate
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedCalculationStartDate,
    selectedCalculationState,
    selectedSystemConfiguration
  ])

  const previewFilterItems = useMemo(() => {
    let items: PageFilterPanelPreviewItem[] = []

    if (selectedCalculationState !== undefined)
      items.push({
        label:
          stateTitles.find(x => x.state === selectedCalculationState)?.title ||
          '',
        onFilterClear: () => setSelectedCalculationState(undefined)
      })
    if (selectedSystemConfiguration !== undefined)
      items.push({
        label:
          availableSystemConfigurations.find(
            x => +x.value === selectedSystemConfiguration
          )?.text || '',
        onFilterClear: () => setSelectedSystemConfiguration(undefined)
      })
    if (selectedCalculationStartDate !== undefined)
      items.push({
        label: formatDate(selectedCalculationStartDate, 'DD MMM YYYY'),
        onFilterClear: () => setSelectedCalculationStartDate(undefined)
      })
    return items
  }, [
    selectedCalculationStartDate,
    selectedCalculationState,
    selectedSystemConfiguration,
    availableSystemConfigurations
  ])

  const handleClearAllFilters = () => {
    setSelectedCalculationState(undefined)
    setSelectedSystemConfiguration(undefined)
    setSelectedCalculationStartDate(undefined)
  }

  return (
    <PageFilterPanel
      isFilterPanelActive={isFilterActive}
      onCloseClick={onFilterCloseClicked}
      filterPreviewItems={previewFilterItems}
      onClearAllFiltersClicked={handleClearAllFilters}
      filtersSlot={
        <PortfolioDetailsFilterPanelInputs
          availableSystemConfigurations={availableSystemConfigurations}
          onCalculationStateChanged={setSelectedCalculationState}
          onSystemConfigurationChanged={setSelectedSystemConfiguration}
          onCalculationStartDateRangeChanged={setSelectedCalculationStartDate}
        />
      }
    />
  )
}
