import { useContext } from 'react'
import { SharedPlotLayout, SharedPlotLayoutContext } from '../contexts'

export const useSharedPlotLayout = () => {
  const { sharedConfig, sharedLayout, onLayoutChange } = useContext(
    SharedPlotLayoutContext
  )

  const handleLayoutChange = (change: SharedPlotLayout) => {
    const getXAxisElement = (index: 0 | 1) => change[`xaxis.range[${index}]`]

    const minX = getXAxisElement(0)
    const maxX = getXAxisElement(1)

    if (minX === undefined) return

    onLayoutChange({
      ...sharedLayout,
      xaxis: { ...sharedLayout.xaxis, range: [minX, maxX] }
    })
  }

  return { sharedConfig, sharedLayout, handleLayoutChange }
}
