import { useToast } from '@veracity/vui'
import { useMutation, useQuery, useQueryClient } from 'react-query'

import { userAdministrationClient } from '@/api'

const BASE_QUERY_KEY = userAdministrationClient.QUERY_KEY

export const useGuestUsers = () => {
  const queryClient = useQueryClient()
  const { showSuccess } = useToast()

  const getGuestUsersQuery = useQuery(
    BASE_QUERY_KEY,
    userAdministrationClient.getGuestUsers
  )

  const syncWithVeracityMutation = useMutation({
    mutationFn: userAdministrationClient.syncGuestUsersWithVeracity,
    onSuccess: async () => {
      showSuccess('Users synchronization succeed')
      await queryClient.invalidateQueries(BASE_QUERY_KEY)
    }
  })

  const removeUserMutation = useMutation({
    mutationFn: userAdministrationClient.removeUser,
    onSuccess: async () => {
      showSuccess('User successfully removed')
      await queryClient.invalidateQueries(BASE_QUERY_KEY)
    }
  })

  const attachToOrganizationMutation = useMutation({
    mutationFn: userAdministrationClient.attachToOrganization,
    onSuccess: async () => {
      showSuccess('User successfully attached to organization')
      await queryClient.invalidateQueries(BASE_QUERY_KEY)
    }
  })

  return {
    guestUsers: getGuestUsersQuery.data,
    syncWithVeracity: syncWithVeracityMutation.mutate,
    removeUser: removeUserMutation.mutate,
    attachToOrganization: attachToOrganizationMutation.mutate,
    isInProgress:
      getGuestUsersQuery.isFetching ||
      syncWithVeracityMutation.isLoading ||
      removeUserMutation.isLoading ||
      attachToOrganizationMutation.isLoading
  }
}
