import { useState } from 'react'
import { Box, Tag, useDialog } from '@veracity/vui'

import { useCurrentUser } from '@/hooks'
import {
  PageContainer,
  UserAdministrationPageMenu,
  UserAdministrationSection
} from '@/components'

import { useGuestUsers, useOrganizations } from './hooks'
import { GuestUsersContainer, OrganizationsContainer } from './components'

export const AdministrationPage = () => {
  const { showConfirm } = useDialog()
  const { isSuperAdmin, isAdmin } = useCurrentUser()
  const [isOrganizationsTabActive, setIsOrganizationsTabActive] = useState(true)
  const {
    guestUsers,
    syncWithVeracity,
    removeUser,
    attachToOrganization,
    isInProgress
  } = useGuestUsers()
  const { organizations } = useOrganizations()

  const handleAddUserToOrganization = (
    userId: string,
    organizationId: number
  ) => attachToOrganization({ userId, organizationId })

  const handleRemoveUser = async (userId: string) => {
    const result = await showConfirm(
      'Remove user',
      'This operation is irreversible. Are you sure?'
    )
    if (result) removeUser(userId)
  }

  return (
    <PageContainer
      header={
        <PageContainer.Breadcrumbs items={[{ title: 'Administration' }]} />
      }
      menuBar={
        <UserAdministrationPageMenu
          isAdmin={isAdmin}
          activeSection={UserAdministrationSection.ADMINISTRATION}
        />
      }
      secondMenuBar={
        <Box w="100%" justify="center" gap={2}>
          <Tag
            variant={!isOrganizationsTabActive ? 'subtleGrey' : 'subtleBlue'}
            onClick={() => setIsOrganizationsTabActive(true)}
            text="Organizations"
          />
          {isSuperAdmin && (
            <Tag
              variant={isOrganizationsTabActive ? 'subtleGrey' : 'subtleBlue'}
              onClick={() => setIsOrganizationsTabActive(false)}
              text="Guests"
            />
          )}
        </Box>
      }>
      {isOrganizationsTabActive && (
        <OrganizationsContainer organizations={organizations} />
      )}

      {!isOrganizationsTabActive && isSuperAdmin && (
        <GuestUsersContainer
          users={guestUsers}
          isInProgress={isInProgress}
          onSyncWithVeracityClicked={syncWithVeracity}
          isSyncButtonVisible={isSuperAdmin}
          allOrganizations={organizations}
          onAddToOrganizationClicked={handleAddUserToOrganization}
          onRemoveUserClicked={handleRemoveUser}
        />
      )}
    </PageContainer>
  )
}
