import { useState } from 'react'
import { Box, Button, Dialog, Spinner } from '@veracity/vui'

import { IsoBa, WholesaleMarketProviders } from '@/interfaces'
import {
  InputFormControl,
  MultiSelectFormControl,
  SelectFormControl
} from '@/components'

import { useWholesaleMarketDatasets, useWholesaleMarketForm } from '../hooks'

export const AddWholesaleMarketDatasetDialog = () => {
  const [isVisible, setIsVisible] = useState(false)
  const { wholesaleMarketDatasets, addDataset, isAddDatasetInProgress } =
    useWholesaleMarketDatasets()

  const {
    resetForm,
    formControl,
    handleSubmitForm,
    isDataPricesControlVisible,
    isOtherPriceProviderControlVisible,
    nameDuplicationValidation,
    isoBaOptions
  } = useWholesaleMarketForm(wholesaleMarketDatasets)

  const handleClose = () => {
    if (isAddDatasetInProgress) return
    resetForm()
    setIsVisible(false)
  }

  const handleSubmitClick = (formFields: any) => {
    addDataset(
      {
        name: formFields.name,
        isoBaItems: formFields.isoBaItems?.map((x: string) => +x as IsoBa),
        datasetProvider: formFields.datasetProvider,
        customPriceProviderName: formFields.customPriceProviderName
      },
      { onSuccess: handleClose }
    )
  }

  return (
    <>
      <Button size="lg" iconLeft="falPlus" onClick={() => setIsVisible(true)}>
        Add Dataset
      </Button>

      <Dialog
        isOpen={isVisible}
        title="Add Dataset"
        size="lg"
        onClose={handleClose}
        onSubmit={handleSubmitForm(handleSubmitClick)}
        cancelButton={{ text: 'Cancel', disabled: isAddDatasetInProgress }}
        submitButton={{
          text: 'Add',
          onClick: handleSubmitForm(handleSubmitClick),
          isLoading: isAddDatasetInProgress,
          disabled: isAddDatasetInProgress
        }}
        body={
          <Box column p={2} gap={2}>
            {isAddDatasetInProgress ? (
              <Spinner />
            ) : (
              <>
                <InputFormControl
                  control={formControl}
                  formControlName="name"
                  label="Name"
                  rules={{
                    required: true,
                    validate: nameDuplicationValidation
                  }}
                />

                <MultiSelectFormControl
                  control={formControl}
                  formControlName="isoBaItems"
                  label="ISO / BA"
                  options={isoBaOptions}
                  rules={{ required: true }}
                />

                {isDataPricesControlVisible && (
                  <SelectFormControl
                    control={formControl}
                    formControlName="datasetProvider"
                    label="Wholesale market data set prices provided by?"
                    options={[
                      { text: 'DNV', value: WholesaleMarketProviders.DNV },
                      { text: 'Other', value: WholesaleMarketProviders.Other }
                    ]}
                    rules={{ required: true }}
                  />
                )}

                {isOtherPriceProviderControlVisible && (
                  <InputFormControl
                    control={formControl}
                    formControlName="customPriceProviderName"
                    label="Custom Price Provider Name"
                    rules={{ required: true }}
                  />
                )}
              </>
            )}
          </Box>
        }
      />
    </>
  )
}
