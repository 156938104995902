import { useQuery } from 'react-query'
import { portfoliosClient } from '@/api'
import { SelectProps } from '@veracity/vui'

const BASE_QUERY_KEY = portfoliosClient.QUERY_KEY
export const usePortfoliosList = () => {
  const { data } = useQuery(BASE_QUERY_KEY, portfoliosClient.getAll)

  return data?.map(x => ({
    text: x.name || '',
    value: x.id
  })) as SelectProps['options']
}
