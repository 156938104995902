import { Box, Tag } from '@veracity/vui'

import { ScenarioResultsTabSectionType } from '../types'

interface Props {
  activeSection: ScenarioResultsTabSectionType
  onSectionChange: (sectionType: ScenarioResultsTabSectionType) => void
}

export const ScenarioResultsTabSection = ({
  activeSection,
  onSectionChange
}: Props) => {
  const sections = [
    { label: 'Summary', type: ScenarioResultsTabSectionType.SUMMARY },
    { label: 'Timeseries', type: ScenarioResultsTabSectionType.TIME_SERIES },
    { label: 'Aggregates', type: ScenarioResultsTabSectionType.AGGREGATES }
  ]

  return (
    <Box w="100%" justify="center" gap={2}>
      {sections.map(x => (
        <Tag
          key={x.label}
          isInteractive
          text={x.label}
          onClick={() => onSectionChange(x.type)}
          variant={activeSection === x.type ? 'subtleBlue' : 'subtleGrey'}
        />
      ))}
    </Box>
  )
}
