import { Box } from '@veracity/vui'

import { PageContainer, PageSearchInput } from '@/components'
import { useFilterDatasets } from '@/hooks'

import { useIncomeAdjustments } from './hooks'
import {
  AddIncomeAdjustmentDatasetDialog,
  IncomeAdjustmentDatasetsTable
} from './components'

export const IncomeAdjustmentPage = () => {
  const { incomeAdjustmentDatasets, isFetching } = useIncomeAdjustments()

  const { filteredDatasets, onFilterChange } = useFilterDatasets(
    incomeAdjustmentDatasets
  )

  return (
    <PageContainer
      loading={isFetching}
      header={
        <PageContainer.Breadcrumbs
          items={[
            { title: 'Library' },
            { title: 'Income Adjustment (Coming Soon)' }
          ]}
        />
      }
      menuBar={
        <Box justify="space-between" w="100%">
          <PageSearchInput onChange={onFilterChange} />
          <AddIncomeAdjustmentDatasetDialog />
        </Box>
      }>
      <IncomeAdjustmentDatasetsTable datasets={filteredDatasets} />
    </PageContainer>
  )
}
