import { PropsWithChildren } from 'react'
import { Navigate } from 'react-router-dom'

import { useCurrentUser } from '@/hooks'

interface Props {
  isAdminRequired?: boolean
}

export const ProtectedRoute = ({
  isAdminRequired,
  children
}: PropsWithChildren<Props>) => {
  const { currentUser, isAdmin } = useCurrentUser()

  if (isAdminRequired && !isAdmin) return <Navigate to="/" replace />

  if (!currentUser) return <Navigate to="/" replace />

  return <>{children}</>
}
