import { Accordion } from '@veracity/vui'

import { ReactMarkdownWrapper } from '@/components'

interface Props {
  items: { id: number; title: string; content: string }[]
  selectedItemId: number
  onSelectionChanged: (selectedItemId: number) => void
}

export const FaqPageAccordion = ({
  items,
  selectedItemId,
  onSelectionChanged
}: Props) => (
  <Accordion w="100%">
    {items.map(item => (
      <Accordion.Item
        key={item.id}
        id={item.id}
        title={item.title}
        collapsed={item.id !== selectedItemId}
        onToggle={() => onSelectionChanged(item.id)}>
        <ReactMarkdownWrapper content={item.content} />
      </Accordion.Item>
    ))}
  </Accordion>
)
