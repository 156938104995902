import { ProjectSimple } from '@/interfaces'

import { PortfolioDetailsPreviewType } from '../../types'
import { PortfolioDetailsCollectionPreviewGrid } from './PortfolioDetailsCollectionPreviewGrid'
import { PortfolioDetailsCollectionPreviewTable } from './PortfolioDetailsCollectionPreviewTable'

interface Props {
  selectedPreviewType: PortfolioDetailsPreviewType
  filteredProjects?: ProjectSimple[]
}

export const PortfolioDetailsCollectionPreview = ({
  selectedPreviewType,
  filteredProjects
}: Props) => {
  switch (selectedPreviewType) {
    case PortfolioDetailsPreviewType.GRID:
      return (
        <PortfolioDetailsCollectionPreviewGrid projects={filteredProjects} />
      )
    case PortfolioDetailsPreviewType.TABLE:
      return (
        <PortfolioDetailsCollectionPreviewTable projects={filteredProjects} />
      )
    default:
      return <div>Not implemented yet</div>
  }
}
