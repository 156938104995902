import { Box, Sidemenu } from '@veracity/vui'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { config } from '@/config'

import { SidebarChildItem } from './SidebarChildItem'
import { useState } from 'react'

const CustomSidebar = styled(Sidemenu)`
  height: calc(100vh - 75px);
  z-index: 1;
`

export const Sidebar = () => {
  const [isLibrarySectionExpanded, setIsLibrarySectionExpanded] =
    useState(false)
  const [isReferencesSectionExpanded, setIsReferencesSectionExpanded] =
    useState(false)
  const [isHelpSectionExpanded, setIsHelpSectionExpanded] = useState(false)

  const handleLibrarySectionExpandClick = () => {
    setIsLibrarySectionExpanded(true)
    setIsReferencesSectionExpanded(false)
    setIsHelpSectionExpanded(false)
  }

  const handleReferencesSectionExpandClick = () => {
    setIsLibrarySectionExpanded(false)
    setIsReferencesSectionExpanded(true)
    setIsHelpSectionExpanded(false)
  }

  const handleHelpSectionExpandClick = () => {
    setIsLibrarySectionExpanded(false)
    setIsReferencesSectionExpanded(false)
    setIsHelpSectionExpanded(true)
  }

  return (
    <CustomSidebar isExpanded={false}>
      <Sidemenu.Item
        as={Link}
        to="/projects"
        className="top-level-side-menu-item"
        title="Projects"
        icon="falProjectDiagram"
      />
      <Sidemenu.Item
        as={Link}
        to="/portfolios"
        className="top-level-side-menu-item"
        title="Portfolios"
        icon="falSuitcase"
      />
      <Sidemenu.Item
        as={Box}
        title="Library"
        icon="falBookOpen"
        isExpanded={isLibrarySectionExpanded}
        onExpand={handleLibrarySectionExpandClick}>
        <SidebarChildItem
          to={config.links.library.wholesaleMarket}
          title="Wholesale Market"
          icon="falCoins"
        />
        <SidebarChildItem
          to={config.links.library.finances}
          title="Finances"
          icon="falDollarSign"
        />
        <SidebarChildItem
          to={config.links.library.incomeAdjustment}
          title="Income Adjustment (Coming Soon)"
          icon="falPencilRuler"
        />
        <SidebarChildItem
          to={config.links.library.lossFactors}
          title="Loss Factors"
          icon="falChartLineDown"
        />
        <SidebarChildItem
          to={config.links.library.productionProfiles}
          title="Production Profiles"
          icon="falUserChart"
        />
        <SidebarChildItem
          to={config.links.library.otherBessInputs}
          title="Other BESS Inputs"
          icon="falPlug"
        />
      </Sidemenu.Item>
      <Sidemenu.Item as={Link} to="log" title="Log" icon="falAlignCenter" />
      <Sidemenu.Item
        as={Box}
        title="References"
        icon="cuiFileStandard"
        isExpanded={isReferencesSectionExpanded}
        onExpand={handleReferencesSectionExpandClick}>
        <SidebarChildItem
          to={config.externalLinks.userGuide}
          title="User Guide"
          icon="falPrint"
          isExternal
        />
        <SidebarChildItem
          to="/references/videos"
          title="Videos (Coming Soon)"
          icon="falPlayCircle"
        />

        <SidebarChildItem
          to="/references/faq"
          title="FAQ"
          icon="falUserHeadset"
        />

        <SidebarChildItem
          to="/references/release-notes"
          title="Release Notes"
          icon="falReceipt"
        />
      </Sidemenu.Item>
      <Sidemenu.Item
        as={Link}
        to="/profile"
        title="Profile"
        icon="falUserCog"
      />
      <Sidemenu.Item
        as={Box}
        title="Help"
        icon="falQuestionCircle"
        isExpanded={isHelpSectionExpanded}
        onExpand={handleHelpSectionExpandClick}>
        <SidebarChildItem
          to={config.contactSupportLink}
          title="Contact Support"
          icon="falComment"
        />
        <SidebarChildItem
          to="/help/privacy"
          title="Privacy"
          icon="falLockAlt"
        />
        <SidebarChildItem
          to="/help/terms-of-use"
          title="Terms of Use"
          icon="conCertificate"
        />
      </Sidemenu.Item>
    </CustomSidebar>
  )
}
