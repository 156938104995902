import { Box } from '@veracity/vui'

import { useFilterDatasets } from '@/hooks'
import { PageContainer, PageSearchInput } from '@/components'

import { useFinanceDatasets } from './hooks'
import { AddFinancesDatasetDialog, FinancesDatasetsTable } from './components'

export const FinancesPage = () => {
  const { financeDatasets, isFetching } = useFinanceDatasets()
  const { filteredDatasets, onFilterChange } =
    useFilterDatasets(financeDatasets)

  return (
    <PageContainer
      loading={isFetching}
      header={
        <PageContainer.Breadcrumbs
          items={[{ title: 'Library' }, { title: 'Finances' }]}
        />
      }
      menuBar={
        <Box justify="space-between" w="100%">
          <PageSearchInput onChange={onFilterChange} />
          <AddFinancesDatasetDialog />
        </Box>
      }>
      <FinancesDatasetsTable datasets={filteredDatasets} />
    </PageContainer>
  )
}
