import { useState } from 'react'
import { useToggle } from '@veracity/ui-utils'
import { Box } from '@veracity/vui'

import { useCurrentUser, usePricePlans } from '@/hooks'
import { OrganizationSimple } from '@/interfaces'
import { PageSearchInput, ToggleFilterButton } from '@/components'

import { OrganizationsContainerTable } from './OrganizationsContainerTable'
import { AddOrganizationDialog } from './AddOrganizationDialog'
import { OrganizationsFilterPanel } from './OrganizationsFilterPanel'
import { OrganizationsFilterValues } from '../../types'
import { useOrganizationsFilter } from '../../hooks'

interface Props {
  organizations: OrganizationSimple[]
}

export const OrganizationsContainer = ({ organizations }: Props) => {
  const [isFilterActive, toggleIsFilterActive] = useToggle()
  const [searchValue, setSearchValue] = useState<string>('')
  const [selectedFilters, setSelectedFilters] =
    useState<OrganizationsFilterValues>()

  const { isSuperAdmin } = useCurrentUser()
  const availablePricingPlans = usePricePlans()
  const filteredOrganizations = useOrganizationsFilter(
    organizations,
    searchValue,
    selectedFilters
  )

  return (
    <>
      <Box
        bg="white"
        justify="space-between"
        w="100%"
        wrap
        gap={1}
        p={2}
        mb={1}>
        <Box gap={1}>
          <PageSearchInput onChange={setSearchValue} />
          <ToggleFilterButton
            isActive={isFilterActive}
            onClick={toggleIsFilterActive}
          />
        </Box>

        {isSuperAdmin && (
          <AddOrganizationDialog availablePricePlans={availablePricingPlans} />
        )}
      </Box>

      <OrganizationsFilterPanel
        isFilterActive={isFilterActive}
        onFilterCloseClicked={toggleIsFilterActive.off}
        availablePricingPlans={availablePricingPlans}
        onFilterChange={setSelectedFilters}
      />

      <OrganizationsContainerTable organizations={filteredOrganizations} />
    </>
  )
}
