import axios from 'axios'

import {
  AddExternalApiKeyRequest,
  ExternalApiKeySimple,
  UpdatePropertyRequest
} from '@/interfaces'

const baseUrl = '/api/externalApiKey'

export const externalApiKeyClient = {
  QUERY_KEY: ['apiKey', 'external'],
  getAll: () => axios.get<ExternalApiKeySimple[]>(baseUrl).then(x => x.data),
  addNewApiKey: (request: AddExternalApiKeyRequest) =>
    axios.post<number>(baseUrl, request).then(x => x.data),
  updateApiKey: ({ id, key }: { id: number; key: string }) =>
    axios.patch(`${baseUrl}/${id}`, {
      propertyName: 'key',
      propertyValue: key
    } as UpdatePropertyRequest)
}
