import axios from 'axios'
import {
  AddWholesaleMarketDatasetRequest,
  CloneDatasetRequest,
  WholesaleMarketDatasetWithFilesSimple,
  WholesaleMarketSimple
} from '@/interfaces'

const baseUrl = '/api/library/wholesaleMarket'
export const wholesaleMarketClient = {
  QUERY_KEY: ['library', 'wholesaleMarket'],
  getAll: (lockedOnly?: boolean) =>
    axios
      .get<WholesaleMarketSimple[]>(baseUrl, { params: { locked: lockedOnly } })
      .then(x => x.data),
  getDetails: (datasetId: number) =>
    axios
      .get<WholesaleMarketDatasetWithFilesSimple>(`${baseUrl}/${datasetId}`)
      .then(x => x.data),
  addNewDataset: (req: AddWholesaleMarketDatasetRequest) =>
    axios.post<number>(baseUrl, req).then(x => x.data),
  removeDataset: (datasetId: number) =>
    axios.delete<number>(`${baseUrl}/${datasetId}`).then(x => x.data),
  lockDataset: (datasetId: number) =>
    axios.post<void>(`${baseUrl}/lockDataset/${datasetId}`).then(x => x.data),
  archiveDataset: (datasetId: number) =>
    axios
      .post<void>(`${baseUrl}/archiveDataset/${datasetId}`)
      .then(x => x.data),
  unArchiveDataset: (datasetId: number) =>
    axios
      .post<void>(`${baseUrl}/unArchiveDataset/${datasetId}`)
      .then(x => x.data),
  cloneDataset: ({
    datasetId,
    ...rest
  }: { datasetId: number } & CloneDatasetRequest) =>
    axios
      .post<number>(`${baseUrl}/cloneDataset/${datasetId}`, rest)
      .then(x => x.data),
  uploadDatasetFile: (req: {
    file: File
    fileType: string
    datasetId: number
  }) =>
    axios
      .postForm<void>(`${baseUrl}/file/${req.datasetId}/${req.fileType}`, {
        file: req.file
      })
      .then(x => x.data),
  removeDatasetFile: (datasetFileId: number) =>
    axios.delete<void>(`${baseUrl}/file/${datasetFileId}`),
  downloadWholesaleMarketFileUrl: (fileId: number) =>
    `${baseUrl}/File/${fileId}`
}
