import { Box, Grid, Heading } from '@veracity/vui'

import { TreeSelect, TreeSelectItemOption } from '@/components'
import { WholesaleMarketDatasetWithFilesSimple } from '@/interfaces'

import { WholesaleMarketDetailsPreviewCard } from './WholesaleMarketDetailsPreviewCard'
import { WholesaleMarketDetailsUploadSection } from './WholesaleMarketDetailsUploadSection'
import { MarketParticipationFilters } from '../types'

interface Props {
  datasetInfo?: WholesaleMarketDatasetWithFilesSimple
  isDatasetDisabled: boolean
  filters: MarketParticipationFilters
  treeSelectItems: TreeSelectItemOption[]
}

export const WholesaleMarketDetailsContent = ({
  datasetInfo,
  isDatasetDisabled,
  filters,
  treeSelectItems
}: Props) => (
  <Box column>
    <Heading size="h3">Market Participation Selection</Heading>

    <Grid pt={2} gap={5} gridTemplateColumns={{ md: '1fr 3fr', xs: '1fr' }}>
      <TreeSelect items={treeSelectItems} isDisabled={isDatasetDisabled} />
      <Box flex={3}>
        <WholesaleMarketDetailsUploadSection
          isDatasetLocked={isDatasetDisabled}
          filters={filters}
          datasetInfo={datasetInfo}
        />
      </Box>
    </Grid>

    <Box column pt={10}>
      <WholesaleMarketDetailsPreviewCard datasetInfo={datasetInfo} />
    </Box>
  </Box>
)
