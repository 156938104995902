import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import {
  ProductionProfileSimple,
  ProductionProfileTypes,
  SourceTypes,
  UpdateProductionProfileDatasetRequest
} from '@/interfaces'

export const useProductionProfileDetailsForm = (
  updateDataset: (req: UpdateProductionProfileDatasetRequest) => void,
  datasetInfo?: ProductionProfileSimple
) => {
  const { control, setValue, reset, formState, handleSubmit, watch } = useForm()
  const formValues = watch()

  useEffect(
    () =>
      reset({
        sourceType: datasetInfo?.sourceType || SourceTypes.Solar,
        productionProfileType:
          datasetInfo?.productionProfileType ||
          ProductionProfileTypes.AnnualProduction,
        annualDerating: datasetInfo?.annualDerating,
        pvProductionSoftwareType: datasetInfo?.pvProductionSoftwareType
      }),
    [reset, datasetInfo]
  )

  useEffect(() => {
    if (formValues.sourceTypeId === undefined) return
    if (formValues.sourceTypeId === SourceTypes.Solar) {
      setValue('windAnnualDeratingFile', '')
      setValue('windPTCPriceFile', '')
    } else {
      setValue('annualDerating', '')
      setValue('pvProductionSoftwareType', '')
    }
  }, [formValues.sourceTypeId, setValue])

  const handleSubmitForm = handleSubmit(data =>
    updateDataset({
      sourceType: data.sourceType,
      productionProfileType: data.productionProfileType,
      pvProductionSoftwareType: data.pvProductionSoftwareType,
      productionProfileFile: data.productionProfileFile,
      annualDerating: data.annualDerating,
      windPTCPriceFile: data.windPTCPriceFile,
      windAnnualDeratingFile: data.windAnnualDeratingFile
    })
  )

  return {
    formControl: control,
    isFormValid: formState.isValid,
    isFormTouched: formState.isDirty,
    handleSubmitForm,
    formValues
  }
}
