import { Link } from 'react-router-dom'
import { Breadcrumbs } from '@veracity/vui'

interface BreadcrumbItem {
  path?: string
  title: string
}

interface Props {
  items: BreadcrumbItem[]
}

export const BreadcrumbContainer = ({ items }: Props) => (
  <Breadcrumbs>
    {items.map((item, index) => (
      <Breadcrumbs.Item key={index}>
        {item.path ? (
          <Link to={item.path}>{item.title}</Link>
        ) : (
          <>{item.title}</>
        )}
      </Breadcrumbs.Item>
    ))}
  </Breadcrumbs>
)
