import { useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { SelectOptionData } from '@veracity/vui'
import {
  IsoBa,
  WholesaleMarketSimple,
  WholesaleMarketProviders
} from '@/interfaces'
import { useSystemDictionaries } from '@/hooks'

export const useWholesaleMarketForm = (
  allWholesaleMarketDatasets: WholesaleMarketSimple[]
) => {
  const { isoBaList } = useSystemDictionaries()
  const { control, resetField, watch, handleSubmit, reset } = useForm({
    defaultValues: {
      name: '',
      isoBaItems: [] as IsoBa[],
      datasetProvider: undefined as WholesaleMarketProviders | undefined,
      customPriceProviderName: undefined as string | undefined
    }
  })

  const selectedIsoBaItems = watch('isoBaItems')

  const isDataPricesControlVisible = selectedIsoBaItems.includes(
    IsoBa.ES_PENINSULA
  )

  const isOtherPriceProviderControlVisible =
    watch('datasetProvider') === WholesaleMarketProviders.Other

  useEffect(() => {
    if (!isDataPricesControlVisible) {
      resetField('datasetProvider')
      resetField('customPriceProviderName')
    }
    // eslint-disable-next-line
  }, [isDataPricesControlVisible])

  useEffect(() => {
    if (!isOtherPriceProviderControlVisible) {
      resetField('customPriceProviderName')
    }
    // eslint-disable-next-line
  }, [isOtherPriceProviderControlVisible])

  const nameDuplicationValidation = (value?: string) =>
    !allWholesaleMarketDatasets.some(
      p => p.name?.toLocaleLowerCase() === value?.toLocaleLowerCase()
    )

  const isoBaOptions = useMemo(() => {
    if (selectedIsoBaItems.includes(IsoBa.ES_PENINSULA))
      return isoBaList.map(
        x =>
          ({
            ...x,
            disabled: x.value !== +IsoBa.ES_PENINSULA
          } as SelectOptionData)
      )
    else if (selectedIsoBaItems.length > 0)
      return isoBaList.map(
        x =>
          ({
            ...x,
            disabled: x.disabled || x.value === +IsoBa.ES_PENINSULA
          } as SelectOptionData)
      )
    else return isoBaList
  }, [selectedIsoBaItems, isoBaList])

  return {
    formControl: control,
    resetForm: reset,
    handleSubmitForm: handleSubmit,
    isDataPricesControlVisible,
    isOtherPriceProviderControlVisible,
    nameDuplicationValidation,
    isoBaOptions: isoBaOptions
  }
}
