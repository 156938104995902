import { useQuery } from 'react-query'

import { releaseNotesClient } from '@/api'

const BASE_QUERY_KEY = releaseNotesClient.QUERY_KEY

export const useReleaseNotes = () => {
  const releaseNotesQuery = useQuery(
    BASE_QUERY_KEY,
    releaseNotesClient.getReleaseNotes
  )

  return {
    releaseNotes: releaseNotesQuery.data,
    isFetching: releaseNotesQuery.isFetching
  }
}
