import { Button, ButtonToggleGroup } from '@veracity/vui'

import { PortfolioDetailsPreviewType } from '../types'

interface Props {
  value: PortfolioDetailsPreviewType
  onChange: (value: PortfolioDetailsPreviewType) => void
}

export const PortfolioDetailsPreviewSelector = ({ value, onChange }: Props) => (
  <ButtonToggleGroup outline="none">
    {/*<Button icon="falMapMarked" isActive={value === ProjectsPreviewType.MAP} onClick={() => onChange(ProjectsPreviewType.MAP)} />*/}
    <Button
      size="lg"
      icon="falGrid"
      isActive={value === PortfolioDetailsPreviewType.GRID}
      onClick={() => onChange(PortfolioDetailsPreviewType.GRID)}
    />
    <Button
      size="lg"
      icon="falList"
      isActive={value === PortfolioDetailsPreviewType.TABLE}
      onClick={() => onChange(PortfolioDetailsPreviewType.TABLE)}
    />
  </ButtonToggleGroup>
)
