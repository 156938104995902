import { useToast } from '@veracity/vui'
import { useNavigate } from 'react-router-dom'
import { useMutation, useQuery, useQueryClient } from 'react-query'

import { projectsClient, scenariosClient } from '@/api'
import { AddScenarioRequest } from '@/interfaces'

const baseRoute = '/projects'
const BASE_QUERY_KEY = projectsClient.QUERY_KEY

export const useProjectsDetails = (projectId: number) => {
  const QUERY_KEY = [...BASE_QUERY_KEY, projectId]

  const { showSuccess } = useToast()
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const projectInfoQuery = useQuery(QUERY_KEY, () =>
    projectsClient.getById(projectId)
  )

  const updateProjectMutation = useMutation({
    mutationFn: (updatedName: string) =>
      projectsClient.updateProjectName(projectId, updatedName),
    onSuccess: async () => {
      showSuccess('Project successfully updated')
      await queryClient.invalidateQueries(BASE_QUERY_KEY)
    }
  })

  const removeProjectMutation = useMutation({
    mutationFn: () => projectsClient.removeById(projectId),
    onSuccess: async () => {
      showSuccess('Project successfully removed')
      navigate(baseRoute)
      queryClient.removeQueries(QUERY_KEY)
      await queryClient.invalidateQueries(BASE_QUERY_KEY, {
        refetchInactive: true
      })
    }
  })

  const addScenarioMutation = useMutation({
    mutationFn: (req: Omit<AddScenarioRequest, 'projectId'>) =>
      scenariosClient.createScenario({ projectId, ...req }),
    onSuccess: async (newScenarioId: number) => {
      showSuccess('Scenario successfully created')
      await queryClient.invalidateQueries(BASE_QUERY_KEY)
      navigate(`${baseRoute}/${projectId}/scenarios/${newScenarioId}`)
    }
  })

  return {
    projectInfo: projectInfoQuery.data,
    isFetching: projectInfoQuery.isFetching,
    updateProject: (projectName: string, onSuccess: () => void) =>
      updateProjectMutation.mutate(projectName, { onSuccess }),
    isUpdateProjectInProgress: updateProjectMutation.isLoading,
    removeProject: removeProjectMutation.mutate,
    addScenario: (
      req: Omit<AddScenarioRequest, 'projectId'>,
      onSuccess: () => void
    ) => addScenarioMutation.mutate(req, { onSuccess }),
    isAddScenarioInProgress: addScenarioMutation.isLoading
  }
}
