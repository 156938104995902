import { ProjectSimple } from '@/interfaces'

import { ProjectsPreviewType } from '../../types'
import { ProjectsListCollectionGrid } from './ProjectsListCollectionGrid'
import { ProjectsListCollectionTable } from './ProjectsListCollectionTable'

interface Props {
  selectedPreviewType: ProjectsPreviewType
  filteredProjects?: ProjectSimple[]
}

export const ProjectsListCollectionPreview = ({
  selectedPreviewType,
  filteredProjects
}: Props) => {
  switch (selectedPreviewType) {
    case ProjectsPreviewType.GRID:
      return <ProjectsListCollectionGrid projects={filteredProjects} />
    case ProjectsPreviewType.TABLE:
      return <ProjectsListCollectionTable projects={filteredProjects} />
    default:
      return <div>Not implemented yet</div>
  }
}
