import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import {
  Accordion,
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  Dialog,
  Message
} from '@veracity/vui'

import { projectsClient } from '@/api'
import { PageLoader } from '@/components'

interface Props {
  selectedProjectId?: number
  selectedScenariosIds?: number[]
}

export const ScenarioComparisonDialog = ({
  selectedProjectId,
  selectedScenariosIds
}: Props) => {
  const navigate = useNavigate()
  const [isVisible, setIsVisible] = useState(false)
  const [currentSelectedIds, setCurrentSelectedIds] = useState<number[]>([])

  useEffect(
    () => setCurrentSelectedIds(selectedScenariosIds || []),
    [selectedScenariosIds]
  )

  const { data: projects, isFetching } = useQuery(
    [...projectsClient.QUERY_KEY, { onlyCompletedScenarios: true }],
    projectsClient.getAllWithCompletedScenarios
  )

  const isFormValid =
    currentSelectedIds.length >= 2 && currentSelectedIds.length <= 4

  const handleSubmitClick = () => {
    handleClose()
    navigate(`/projects/scenarios/comparison/${currentSelectedIds.join('+')}`)
  }

  const handleClose = () => {
    setIsVisible(false)
    setCurrentSelectedIds(selectedScenariosIds || [])
  }

  const handleChange = (isChecked: boolean, value: number) => {
    const filteredValues = currentSelectedIds.filter(x => x !== value)
    setCurrentSelectedIds(
      isChecked ? [...filteredValues, value] : filteredValues
    )
  }

  return (
    <>
      <Button
        icon="falBalanceScale"
        size="lg"
        variant="tertiaryDark"
        onClick={() => setIsVisible(true)}
      />

      <Dialog
        isOpen={isVisible}
        title="Compare Scenarios"
        size="lg"
        onClose={handleClose}
        cancelButton={{ text: 'Cancel' }}
        submitButton={{
          text: 'Compare',
          onClick: handleSubmitClick,
          disabled: !isFormValid
        }}
        body={
          isFetching ? (
            <PageLoader />
          ) : (
            <>
              <Box column p={2} gap={1} overflowY="auto">
                <Accordion>
                  {projects?.map(project => (
                    <Accordion.Item
                      key={project.projectId}
                      id={project.projectId}
                      title={project.projectName}
                      collapsed={
                        project.projectId !== selectedProjectId &&
                        !project.scenarios?.some(x =>
                          selectedScenariosIds?.includes(x.id)
                        )
                      }>
                      <CheckboxGroup
                        gap={1}
                        onChange={ev =>
                          handleChange(ev.target.checked, +ev.target.value)
                        }>
                        {project.scenarios?.map(x => (
                          <Checkbox
                            key={x.id}
                            label={x.name}
                            name={`test-${x.name}`}
                            value={x.id}
                            checked={currentSelectedIds.includes(x.id)}
                          />
                        ))}
                      </CheckboxGroup>
                    </Accordion.Item>
                  ))}
                </Accordion>
              </Box>
              <Box p={2}>
                {!isFormValid && currentSelectedIds.length < 2 && (
                  <Message
                    variant="info"
                    text="Minimum number of scenarios to compare: 2"
                  />
                )}
                {!isFormValid && currentSelectedIds.length > 4 && (
                  <Message
                    variant="error"
                    text="Maximum number of scenarios to compare: 4"
                  />
                )}
              </Box>
            </>
          )
        }
      />
    </>
  )
}
