import { Link } from 'react-router-dom'
import {
  Box,
  Button,
  Card,
  Definition,
  Divider,
  formatDate,
  Grid,
  Heading,
  useDialog
} from '@veracity/vui'

import { formatters } from '@/utils'
import { ScenarioSimple } from '@/interfaces'

import { ProjectDetailsScenarioCardStatus } from './ProjectDetailsScenarioCardStatus'
import { ProjectDetailsScenarioCardScenarioTag } from './ProjectDetailsScenarioCardScenarioTag'
import { ProjectDetailsScenarioCardEditTagDialog } from './ProjectDetailsScenarioCardEditTagDialog'
import { ProjectDetailsScenarioCardCloneDialog } from './ProjectDetailsScenarioCardCloneDialog'
import { useProjectScenario } from '../../hooks'

interface Props {
  scenarioInfo: ScenarioSimple
  validateNameDuplication: (val: string) => boolean
}

export const ProjectDetailsScenarioCard = ({
  scenarioInfo,
  validateNameDuplication
}: Props) => {
  const { showConfirm } = useDialog()
  const {
    removeScenario,
    isRemoveScenarioInProgress,
    cloneScenario,
    isCloneScenarioInProgress,
    updateScenarioTag,
    isUpdateScenarioTagInProgress
  } = useProjectScenario(scenarioInfo.projectId, scenarioInfo.id)

  const handleRemoveScenario = async () => {
    const result = await showConfirm(
      'Remove scenario',
      'This operation is irreversible. Are you sure?'
    )
    if (result) removeScenario()
  }

  return (
    <Card column p={3} gap={2} isInteractive>
      <Grid gridTemplateColumns="2fr 1fr 5fr 4fr" gap={2}>
        <Heading size="h5">{scenarioInfo.name}</Heading>
        <ProjectDetailsScenarioCardScenarioTag scenarioTag={scenarioInfo.tag} />

        <Box justify="center">
          <ProjectDetailsScenarioCardStatus status={scenarioInfo.state} />
        </Box>

        <Box gap={2} justify="right">
          <ProjectDetailsScenarioCardCloneDialog
            baseScenarioName={scenarioInfo.name}
            isCloneInProgress={isCloneScenarioInProgress}
            onCloneScenario={cloneScenario}
            validateNameDuplication={validateNameDuplication}
          />
          <Button
            icon="falTrashAlt"
            variant="tertiaryDark"
            onClick={handleRemoveScenario}
            disabled={isRemoveScenarioInProgress}
            isLoading={isRemoveScenarioInProgress}
          />
          <ProjectDetailsScenarioCardEditTagDialog
            currentValue={scenarioInfo.tag}
            onTagUpdate={updateScenarioTag}
            isTagUpdateInProgress={isUpdateScenarioTagInProgress}
          />
          <Button
            as={Link}
            to={`/projects/${scenarioInfo.projectId}/scenarios/${scenarioInfo.id}`}
            icon="falArrowRight"
            variant="tertiaryDark"
          />
        </Box>
      </Grid>

      <Divider />

      <Box column alignItems="center">
        <Definition
          w="60vw"
          orientation="vertical"
          size="sm"
          isLabelBold
          gridTemplateColumns={6}
          items={[
            {
              key: 'System Configuration',
              value: scenarioInfo.systemConfigurationString || '-'
            },
            {
              key: 'IRR',
              value:
                (scenarioInfo.result?.irr &&
                  formatters.percentage(scenarioInfo.result.irr)) ||
                '-'
            },
            {
              key: 'Average SOC',
              value:
                (scenarioInfo.result?.esAverageSoc &&
                  formatters.percentage(scenarioInfo.result.esAverageSoc)) ||
                '-'
            },
            {
              key: 'Average Cycles',
              value: scenarioInfo.result?.esAverageCycles || '-'
            },
            { key: 'Author', value: scenarioInfo.createdBy || '-' },
            {
              key: 'Last Modified',
              value: formatDate(scenarioInfo.modifiedAt, 'DD MMM YYYY')
            }
          ]}
        />
      </Box>
    </Card>
  )
}
