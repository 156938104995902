import { useContext } from 'react'
import { CurrentUserContext } from '@/contexts'
import { config } from '@/config'

export const useCurrentUser = () => {
  const { currentUser, isLoading } = useContext(CurrentUserContext)

  const userRoles = currentUser?.roles?.map(x => x.name) || []

  const isSuperAdmin = userRoles.includes(config.roles.superAdmin)

  const isAdmin = isSuperAdmin || userRoles.includes(config.roles.admin)

  const isUser = isAdmin || userRoles.includes(config.roles.user)

  const isGuest = !isUser

  const isInternal = !!currentUser?.email?.endsWith('@dnv.com')

  return {
    currentUser,
    isLoading,
    isSuperAdmin,
    isAdmin,
    isUser,
    isGuest,
    isInternal
  }
}
