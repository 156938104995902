import { PageContainer } from '@/components'

import { useReleaseNotes } from './hooks'
import { ReleaseNotesAccordion } from './components'

export const ReleaseNotesPage = () => {
  const { releaseNotes, isFetching } = useReleaseNotes()

  return (
    <PageContainer
      loading={isFetching}
      header={
        <PageContainer.Breadcrumbs items={[{ title: 'Release Notes' }]} />
      }>
      <ReleaseNotesAccordion items={releaseNotes} />
    </PageContainer>
  )
}
