import { useEffect } from 'react'
import { useForm } from 'react-hook-form'

import { CalculationStates, ScenarioDetailed } from '@/interfaces'

import { ScenarioDetailsFormValues } from '../types'
import { convertToPercentage } from '@/utils'

export const useScenarioDetailsForm = (
  scenarioInfo?: ScenarioDetailed,
  onFieldUpdate?: (fieldName: string, fieldValue: unknown) => void
) => {
  const { control, reset, trigger, formState, watch } =
    useForm<ScenarioDetailsFormValues>({ mode: 'onBlur' })

  useEffect(() => {
    const { unsubscribe } = watch(async (value, info) => {
      if (!info.name || !(await trigger(info.name))) return
      const currentValue = (value as any)[info.name]

      const percentageFields = [
        'acMainTransformerEfficiency',
        'auxiliaryPowerLoss',
        'acLineEfficiency',
        'acMediumVoltageTransformerEfficiency',
        'dCACInverterEfficiency',
        'dCDCConverterEfficiency',
        'windTotalEfficiency',
        'dcLineEfficiency'
      ]
      if (percentageFields.includes(info.name))
        onFieldUpdate?.(info.name, +currentValue / 100)
      else if (info.name === 'selectedRunYears')
        onFieldUpdate?.(info.name, currentValue)
      else onFieldUpdate?.(info.name, +currentValue)
    })
    return unsubscribe
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch, trigger])

  useEffect(() => {
    reset({
      // param fields
      startYear: scenarioInfo?.startYear,
      endYear: scenarioInfo?.endYear,
      systemConfiguration: scenarioInfo?.systemConfiguration,
      yearsToRun: scenarioInfo?.yearsToRun,
      selectedRunYears: scenarioInfo?.selectedRunYears,
      interconnectionCapacity: scenarioInfo?.interconnectionCapacity,

      // datasets fields
      wholesaleMarketPriceId: scenarioInfo?.wholesaleMarketPriceId,
      finansesDatasetId: scenarioInfo?.finansesDatasetId,
      incomeAdjustmentsId: scenarioInfo?.incomeAdjustmentsId,
      lossFactorsId: scenarioInfo?.lossFactorsId,
      pvProductionProfileId: scenarioInfo?.pvProductionProfileId,
      windProductionProfileId: scenarioInfo?.windProductionProfileId,
      otherBessInputId: scenarioInfo?.otherBessInputId,

      // diagram fields
      acLineEfficiency: convertToPercentage(scenarioInfo?.acLineEfficiency),
      acMainTransformerEfficiency: convertToPercentage(
        scenarioInfo?.acMainTransformerEfficiency
      ),
      acMediumVoltageTransformerEfficiency: convertToPercentage(
        scenarioInfo?.acMediumVoltageTransformerEfficiency
      ),
      energyCapacityAtPoi: scenarioInfo?.energyCapacityAtPoi,
      auxiliaryPowerLoss: convertToPercentage(
        scenarioInfo?.storage?.auxiliaryPowerLoss
      ),
      dcLineEfficiency: convertToPercentage(
        scenarioInfo?.storage?.dcLineEfficiency
      ),
      installedEnergyCapacity: scenarioInfo?.storage?.installedEnergyCapacity,
      installedPowerCapacity: scenarioInfo?.installedPowerCapacity,
      dCDCConverterEfficiency: convertToPercentage(
        scenarioInfo?.storage?.dcdcConverterEfficiency
      ),
      dCACInverterEfficiency: convertToPercentage(
        scenarioInfo?.storage?.dcacInverterEfficiency
      ),
      pvInstalledDcCapacity: scenarioInfo?.photovoltaic?.dcCapacity,
      pvInstalledAcCapacity: scenarioInfo?.photovoltaic?.acCapacity,

      windTotalEfficiency: convertToPercentage(
        scenarioInfo?.wind?.totalEfficiency
      ),
      windInstalledAcCapacity: scenarioInfo?.wind?.acCapacity
    })
  }, [reset, scenarioInfo])

  const isFormDisabled =
    scenarioInfo?.state === undefined ||
    ![CalculationStates.NotSet, CalculationStates.Error].includes(
      scenarioInfo.state
    )

  return {
    formControl: control,
    isFormDisabled: isFormDisabled,
    isFormValid: formState.isValid
  }
}
