import { useQuery } from 'react-query'

import { useDebouncedValue } from '@/hooks'
import { convertDateToLocal } from '@/utils'
import { scenarioResultsClient } from '@/api'

export const useTimeSeriesChartData = (
  scenarioId: number,
  startDate?: Date
) => {
  const applyIncomeAdjustment = true
  const BASE_QUERY_KEY = [
    ...scenarioResultsClient.QUERY_KEY(scenarioId),
    'summary',
    { applyIncomeAdjustment }
  ]

  const scenarioQuery = useQuery(BASE_QUERY_KEY, () =>
    scenarioResultsClient.getResultsSummary(scenarioId, applyIncomeAdjustment)
  )

  const debouncedDate = useDebouncedValue<Date>(convertDateToLocal(startDate))

  const query = useQuery(
    [...BASE_QUERY_KEY, 'chartData', { debouncedDate }],
    () => scenarioResultsClient.getChartData(scenarioId, debouncedDate!),
    { enabled: !!debouncedDate }
  )

  return {
    isFetching: query.isFetching,
    timeSeriesData: query.data || [],
    scenarioConfiguration: scenarioQuery.data?.systemConfiguration
  }
}
