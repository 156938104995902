import { Box, Button, formatDate, Grid, Heading } from '@veracity/vui'

import { ApiKeySimple } from '@/interfaces'

import { ApiManagementKeyValuePair } from './ApiManagementKeyValuePair'

interface Props {
  value?: ApiKeySimple
  onRegenerateClicked: () => void
  onCopyKeyClicked: () => void
}

export const ApiManagementHeroSection = ({
  value,
  onRegenerateClicked,
  onCopyKeyClicked
}: Props) => (
  <>
    <Heading size="h3" weight="medium">
      HERO API Keys
    </Heading>
    <Box column bg="white" p={2}>
      <Box justifyContent="flex-end" gap={2} w="100%">
        <Button
          variant="secondaryDark"
          iconLeft="falSync"
          onClick={onRegenerateClicked}>
          Regenerate
        </Button>
        <Button
          variant="secondaryDark"
          iconLeft="falCopy"
          onClick={onCopyKeyClicked}>
          Copy
        </Button>
      </Box>
      <Grid gap={2} gridTemplateColumns={4}>
        <ApiManagementKeyValuePair
          label="Key"
          value={value?.key || ''}
          isSecret
        />
        <ApiManagementKeyValuePair
          label="Active"
          value={value?.isActive ? 'Yes' : 'No'}
        />
        <ApiManagementKeyValuePair
          label="Expires"
          value={formatDate(value?.expirationDate, 'DD MMM YYYY')}
        />
      </Grid>
    </Box>
  </>
)
