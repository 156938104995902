import { useMemo } from 'react'
import { Box, Card, Grid, Icon, List, T } from '@veracity/vui'

import { WholesaleMarketDatasetWithFilesSimple } from '@/interfaces'
import { useSystemDictionaries } from '@/hooks'

import { useIsSpainDataset } from '../hooks'

interface Props {
  datasetInfo?: WholesaleMarketDatasetWithFilesSimple
}

export const WholesaleMarketDetailsPreviewCard = ({ datasetInfo }: Props) => {
  const { isoBaList } = useSystemDictionaries()

  const isSpainDataset = useIsSpainDataset(datasetInfo)

  const uploadedDatasetsListItems = useMemo(() => {
    return isSpainDataset
      ? [
          {
            text: 'Day-Ahead Energy',
            isUploaded: !!datasetInfo?.dayAheadEnergyPricesFile?.id
          },
          {
            text: 'Energy Down (aFRR)',
            isUploaded: !!datasetInfo?.afrrEnergyDownFile?.id
          },
          {
            text: 'Energy Up (aFRR)',
            isUploaded: !!datasetInfo?.afrrEnergyUpFile?.id
          },
          {
            text: 'Power (aFRR)',
            isUploaded: !!datasetInfo?.afrrPowerFile?.id
          },
          {
            text: 'Energy Down (mFRR)',
            isUploaded: !!datasetInfo?.mfrrEnergyDownFile?.id
          },
          {
            text: 'Energy Up (mFRR)',
            isUploaded: !!datasetInfo?.mfrrEnergyUpFile?.id
          }
        ]
      : [
          {
            text: 'Day-Ahead Energy',
            isUploaded: !!datasetInfo?.dayAheadEnergyPricesFile?.id
          },
          {
            text: 'Real-Time Energy',
            isUploaded: !!datasetInfo?.realTimeEnergyPriceFile?.id
          },
          {
            text: 'Regulation Up',
            isUploaded: !!datasetInfo?.regUpPricesFile?.id
          },
          {
            text: 'Regulation Down',
            isUploaded: !!datasetInfo?.regDownPricesFile?.id
          },
          {
            text: 'Spinning Reserve',
            isUploaded: !!datasetInfo?.spinningReservePricesFile?.id
          },
          {
            text: 'Regulation Mileage',
            isUploaded: !!datasetInfo?.regulationMileagePriceFile?.id
          },
          {
            text: 'Capacity Price',
            isUploaded: !!datasetInfo?.capacityMarketPriceFile?.id
          }
        ]
  }, [datasetInfo, isSpainDataset])

  const selectedIsoList = isoBaList.filter(x =>
    datasetInfo?.isoBaItems?.includes(+x.value)
  )

  return (
    <Card column p={2}>
      <Grid gap={5} gridTemplateColumns={{ md: 2, xs: 1 }} elevation={4}>
        <Box column>
          <T variant="caption">
            Data provided in this dataset allows participation in:
          </T>

          <List>
            {uploadedDatasetsListItems.map((item, index) => (
              <List.Item gap={1} key={index}>
                <Icon
                  pathFill="seaBlue.28"
                  name={
                    item.isUploaded
                      ? 'cuiCheckboxSelected'
                      : 'cuiCheckboxUnselected'
                  }
                />
                <T>{item.text}</T>
              </List.Item>
            ))}
          </List>
        </Box>

        <Box column>
          <T variant="caption">
            This dataset can also be used in the following ISOs:
          </T>

          <List>
            {selectedIsoList.map((item, index) => (
              <List.Item gap={1} key={index}>
                <Icon pathFill="seaBlue.28" name="cuiCheckboxSelected" />
                <T>{item.text}</T>
              </List.Item>
            ))}
          </List>
        </Box>
      </Grid>
    </Card>
  )
}
