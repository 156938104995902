import { Box, Card, formatDate, Heading, P, Tag } from '@veracity/vui'

import { CalculationStates, ProjectSimple } from '@/interfaces'

interface Props {
  project: ProjectSimple
}
export const PortfolioDetailsCollectionPreviewGridCard = ({
  project
}: Props) => {
  const subtitleText = [
    project.createdBy,
    project.company,
    formatDate(project.createdAt, 'DD MM YYYY')
  ]
    .filter(Boolean)
    .join(', ')

  const inProgressCount =
    project.scenarios?.filter(x =>
      [
        CalculationStates.NotSet,
        CalculationStates.InProgress,
        CalculationStates.Preprocessing
      ].includes(x.state)
    )?.length || 0

  const failedCount =
    project.scenarios?.filter(x => x.state === CalculationStates.Error)
      ?.length || 0

  const passedCount =
    project.scenarios?.filter(x => x.state === CalculationStates.Finished)
      ?.length || 0

  return (
    <Card column p={1} h="100%" isInteractive justify="space-between">
      <Box column gap={1}>
        <Box w="100%" gap={1} justify="space-between">
          <Heading w="70%" isTruncated size="h5" title={project.name}>
            {project.name}
          </Heading>
          <Tag isRound text={project.isoBaName} />
        </Box>
        <P>{subtitleText}</P>
      </Box>
      <Box gap={1} h={30}>
        {!!inProgressCount && (
          <Tag
            isRound
            text={inProgressCount}
            title="Calculations in progress"
          />
        )}
        {!!failedCount && (
          <Tag
            isRound
            variant="subtleRed"
            text={failedCount}
            title="Failed calculation"
          />
        )}
        {!!passedCount && (
          <Tag
            isRound
            variant="subtleGreen"
            text={passedCount}
            title="Passed calculations"
          />
        )}
      </Box>
    </Card>
  )
}
