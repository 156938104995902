import { Box, Button } from '@veracity/vui'
import { ScenarioComparisonDialog } from '@/components'

interface Props {
  selectedScenarioIds: number[]
  onExpandAllRows: () => void
  onCollapseAllRows: () => void
  onPrintComparison: () => void
  onDownloadExport: () => void
  isDownloadExportInProgress: boolean
}

export const ScenarioComparisonOperations = ({
  selectedScenarioIds,
  onPrintComparison,
  onExpandAllRows,
  onCollapseAllRows,
  isDownloadExportInProgress,
  onDownloadExport
}: Props) => {
  return (
    <Box justifyContent="flex-end" gap={1} w="100%">
      <Button
        icon="falExpandAlt"
        size="lg"
        variant="tertiaryDark"
        onClick={onExpandAllRows}
      />
      <Button
        icon="falCompressAlt"
        size="lg"
        variant="tertiaryDark"
        onClick={onCollapseAllRows}
      />

      <ScenarioComparisonDialog selectedScenariosIds={selectedScenarioIds} />

      <Button
        icon="falFileExcel"
        size="lg"
        variant="tertiaryDark"
        disabled={isDownloadExportInProgress}
        isLoading={isDownloadExportInProgress}
        onClick={onDownloadExport}
      />

      <Button
        icon="falPrint"
        size="lg"
        variant="tertiaryDark"
        onClick={onPrintComparison}
      />
    </Box>
  )
}
