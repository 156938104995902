import { Box, Button, DatePicker, Heading, Menu } from '@veracity/vui'

import { PricePlanSimple } from '@/interfaces'

interface Props {
  onCloseClicked: () => void
  availablePricingPlans: PricePlanSimple[]
  onPricePlanFilterChanged: (pricePlanId: number) => void
  onCreateDateFilterChanged: (date?: Date) => void
  onSubscriptionExpirationDateFilterChanged: (date?: Date) => void
  onClearAllFiltersClicked: () => void
}

export const OrganizationsFilterPanelInputs = ({
  onCloseClicked,
  availablePricingPlans,
  onPricePlanFilterChanged,
  onCreateDateFilterChanged,
  onSubscriptionExpirationDateFilterChanged,
  onClearAllFiltersClicked
}: Props) => {
  return (
    <>
      <Box justify="space-between">
        <Heading size="h3">Filter</Heading>
        <Button
          size="lg"
          variant="tertiaryDark"
          icon="falTimes"
          onClick={onCloseClicked}
        />
      </Box>

      <Box gap={2}>
        <Menu>
          <Menu.Button isDropDown>Pricing plan</Menu.Button>
          <Menu.List>
            {availablePricingPlans.map(x => (
              <Menu.Item
                key={x.id}
                onClick={() => onPricePlanFilterChanged(x.id)}>
                {x.name}
              </Menu.Item>
            ))}
          </Menu.List>
        </Menu>

        <DatePicker onSelected={onCreateDateFilterChanged}>
          <Button variant="tertiaryDark" iconRight="falAngleDown">
            Date created
          </Button>
        </DatePicker>

        <DatePicker onSelected={onSubscriptionExpirationDateFilterChanged}>
          <Button variant="tertiaryDark" iconRight="falAngleDown">
            Sub exp date
          </Button>
        </DatePicker>

        <Button
          ml={3}
          variant="tertiaryDark"
          iconRight="falTimes"
          onClick={onClearAllFiltersClicked}>
          Clear all
        </Button>
      </Box>
    </>
  )
}
