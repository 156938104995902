import { Controller } from 'react-hook-form'
import { Switch, SwitchProps } from '@veracity/vui'
import { BaseFormItemProps } from './BaseFormItem'

interface Props extends Omit<BaseFormItemProps, 'label'>, SwitchProps {}

export const SwitchFormControl = ({
  formControlName,
  control,
  rules,
  ...props
}: Props) => (
  <Controller
    name={formControlName}
    control={control}
    rules={rules}
    render={({ field }) => (
      <Switch checked={!!field.value} onChange={field.onChange} {...props} />
    )}
  />
)
