import { useToast } from '@veracity/vui'
import { useNavigate } from 'react-router-dom'
import { useMutation, useQuery, useQueryClient } from 'react-query'

import { config } from '@/config'
import { productionProfilesClient } from '@/api'

const BASE_ROUTE = config.links.library.productionProfiles
const BASE_QUERY_KEY = productionProfilesClient.QUERY_KEY

export const useProductionProfiles = () => {
  const { showSuccess } = useToast()
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const productionProfilesQuery = useQuery(
    [...BASE_QUERY_KEY, { lockedOnly: false }],
    () => productionProfilesClient.getAll()
  )

  const addDatasetMutation = useMutation({
    mutationFn: productionProfilesClient.addDataset,
    onSuccess: async (datasetId: number) => {
      showSuccess('Dataset successfully added')
      await queryClient.invalidateQueries(BASE_QUERY_KEY)
      navigate(`${BASE_ROUTE}/${datasetId}`)
    }
  })

  const cloneDatasetMutation = useMutation({
    mutationFn: productionProfilesClient.cloneDataset,
    onSuccess: async (datasetId: number) => {
      showSuccess('Dataset successfully cloned')
      await queryClient.invalidateQueries(BASE_QUERY_KEY)
      navigate(`${BASE_ROUTE}/${datasetId}`)
    }
  })

  const removeDatasetMutation = useMutation({
    mutationFn: productionProfilesClient.removeDataset,
    onSuccess: async () => {
      showSuccess('Dataset successfully removed')
      await queryClient.invalidateQueries(BASE_QUERY_KEY)
    }
  })

  return {
    productionProfilesDatasets: productionProfilesQuery.data || [],
    isFetching:
      productionProfilesQuery.isFetching &&
      !productionProfilesQuery.isRefetching,
    addDataset: addDatasetMutation.mutate,
    isAddDatasetInProgress: addDatasetMutation.isLoading,
    cloneDataset: cloneDatasetMutation.mutate,
    isCloneDatasetInProgress: cloneDatasetMutation.isLoading,
    removeDataset: removeDatasetMutation.mutate
  }
}
