import axios from 'axios'

import {
  AddOrganizationRequest,
  OrganizationSimple,
  UpdateOrganizationRequest
} from '@/interfaces'

const baseUrl = '/api/organizations'

export const organizationClient = {
  QUERY_KEY: ['organizations'],
  getOrganizations: () =>
    axios.get<OrganizationSimple[]>(baseUrl).then(x => x.data),
  getOrganizationInfo: (organizationId: number) =>
    axios
      .get<OrganizationSimple>(`${baseUrl}/${organizationId}`)
      .then(x => x.data),
  addOrganization: (payload: AddOrganizationRequest) =>
    axios.post<void>(baseUrl, payload).then(x => x.data),
  removeOrganization: (organizationId: number) =>
    axios.delete<void>(`${baseUrl}/${organizationId}`).then(x => x.data),
  updateOrganization: (
    organizationId: number,
    request: UpdateOrganizationRequest
  ) => axios.put(`${baseUrl}/${organizationId}`, request).then(x => x.data)
}
