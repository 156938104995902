import { cloneElement, isValidElement, PropsWithChildren } from 'react'
import { Control, Controller, ControllerProps } from 'react-hook-form'
import { Box, T } from '@veracity/vui'

export interface BaseFormItemProps extends PropsWithChildren {
  label?: string
  control: Control<any>
  formControlName: string
  rules?: ControllerProps['rules']
  useOnBlurAsChange?: boolean
}

export const BaseFormItem = ({
  control,
  label,
  rules,
  formControlName,
  useOnBlurAsChange,
  children
}: BaseFormItemProps) => (
  <Controller
    name={formControlName}
    control={control}
    rules={rules}
    render={({ field, fieldState }) => (
      <Box column gap={1}>
        {!label ? null : <T> {label} </T>}
        {isValidElement(children)
          ? cloneElement(children, {
              value: field.value,
              onChange: (ev: any) => {
                if (!useOnBlurAsChange) field.onChange(ev)
              },
              onBlur: (ev: any) => {
                if (useOnBlurAsChange) field.onChange(ev)
                field.onBlur()
              },
              variant: !!fieldState.error ? 'red' : undefined
            } as any)
          : children}
      </Box>
    )}
  />
)
