import { Box } from '@veracity/vui'

import { useFilterDatasets } from '@/hooks'
import { PageContainer, PageSearchInput } from '@/components'

import { useLossFactors } from './hooks'
import {
  AddLossFactorsDatasetDialog,
  LossFactorsDatasetsTable
} from './components'

export const LossFactorsPage = () => {
  const { lossFactorsDatasets, isFetching } = useLossFactors()

  const { filteredDatasets, onFilterChange } =
    useFilterDatasets(lossFactorsDatasets)

  return (
    <PageContainer
      loading={isFetching}
      header={
        <PageContainer.Breadcrumbs
          items={[{ title: 'Library' }, { title: 'Loss Factors' }]}
        />
      }
      menuBar={
        <Box justify="space-between" w="100%">
          <PageSearchInput onChange={onFilterChange} />
          <AddLossFactorsDatasetDialog />
        </Box>
      }>
      <LossFactorsDatasetsTable datasets={filteredDatasets} />
    </PageContainer>
  )
}
