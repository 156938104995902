import { Link } from 'react-router-dom'
import { Box, Tag } from '@veracity/vui'

export enum UserAdministrationSection {
  USER_PROFILE,
  API_KEY_MANAGEMENT,
  ADMINISTRATION
}

interface Props {
  activeSection: UserAdministrationSection
  isAdmin: boolean
}

export const UserAdministrationPageMenu = ({
  activeSection,
  isAdmin
}: Props) => (
  <Box w="100%" justify="center" gap={2}>
    <Tag
      as={Link}
      to="/profile"
      variant={
        activeSection === UserAdministrationSection.USER_PROFILE
          ? 'subtleBlue'
          : 'subtleGrey'
      }
      text="User Profile"
    />

    <Tag
      as={Link}
      to="/api-management"
      variant={
        activeSection === UserAdministrationSection.API_KEY_MANAGEMENT
          ? 'subtleBlue'
          : 'subtleGrey'
      }
      text="API Key Management"
    />

    {!isAdmin ? null : (
      <Tag
        as={Link}
        to="/administration"
        variant={
          activeSection === UserAdministrationSection.ADMINISTRATION
            ? 'subtleBlue'
            : 'subtleGrey'
        }
        text="Admin"
      />
    )}
  </Box>
)
