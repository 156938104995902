import { UserSimple } from '@/interfaces'

export const useFilterOrganizationUsers = (
  users: UserSimple[],
  searchValue: string
) => {
  const normalizedSearchValue = searchValue.toLocaleLowerCase()
  return users.filter(
    x =>
      x.firstName?.toLocaleLowerCase().includes(normalizedSearchValue) ||
      x.lastName?.toLocaleLowerCase().includes(normalizedSearchValue) ||
      x.email?.toLocaleLowerCase().includes(normalizedSearchValue)
  )
}
