import { Box, useToast } from '@veracity/vui'
import { copyToClipboard } from '@veracity/ui-utils'

import {
  PageContainer,
  UserAdministrationPageMenu,
  UserAdministrationSection
} from '@/components'
import { useCurrentUser } from '@/hooks'

import {
  ApiManagementHeroSection,
  ApiManagementOtherDnvSection
} from './components'
import { useHeroApiKey, useOtherDnvApiKeys } from './hooks'

export const ApiManagementPage = () => {
  const { showInfo } = useToast()
  const { heroApiKeyInfo, reactivateHeroApiKey } = useHeroApiKey()
  const { otherDnvApiKeys } = useOtherDnvApiKeys()
  const { currentUser, isAdmin } = useCurrentUser()

  const handleCopyHeroApiKey = async () => {
    await copyToClipboard(heroApiKeyInfo?.key || '')
    showInfo('HERO API Key successfully copied.')
  }

  const handleCopyOtherApiKey = async (apiKey?: string) => {
    if (!apiKey) return
    await copyToClipboard(apiKey)
    showInfo('Api Key successfully copied')
  }

  return (
    <PageContainer
      header={
        <PageContainer.Breadcrumbs items={[{ title: 'Api Key Management' }]} />
      }
      menuBar={
        <UserAdministrationPageMenu
          isAdmin={isAdmin}
          activeSection={UserAdministrationSection.API_KEY_MANAGEMENT}
        />
      }>
      <Box column gap={2}>
        {!!currentUser?.organization?.hasAccessToApi && (
          <ApiManagementHeroSection
            value={heroApiKeyInfo}
            onCopyKeyClicked={handleCopyHeroApiKey}
            onRegenerateClicked={reactivateHeroApiKey}
          />
        )}

        <ApiManagementOtherDnvSection
          externalApiKeys={otherDnvApiKeys}
          onCopyClicked={handleCopyOtherApiKey}
        />
      </Box>
    </PageContainer>
  )
}
