import { Box } from '@veracity/vui'

import { useFilterDatasets } from '@/hooks'
import { PageContainer, PageSearchInput } from '@/components'

import { useProductionProfiles } from './hooks'
import {
  AddProductionProfilesDatasetDialog,
  ProductionProfilesDatasetsTable
} from './components'

export const ProductionProfilesPage = () => {
  const { productionProfilesDatasets, isFetching } = useProductionProfiles()

  const { filteredDatasets, onFilterChange } = useFilterDatasets(
    productionProfilesDatasets
  )

  return (
    <PageContainer
      loading={isFetching}
      header={
        <PageContainer.Breadcrumbs
          items={[{ title: 'Library' }, { title: 'Production Profiles' }]}
        />
      }
      menuBar={
        <Box justify="space-between" w="100%">
          <PageSearchInput onChange={onFilterChange} />
          <AddProductionProfilesDatasetDialog />
        </Box>
      }>
      <ProductionProfilesDatasetsTable datasets={filteredDatasets} />
    </PageContainer>
  )
}
