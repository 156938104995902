import { Box, T } from '@veracity/vui'
import { Control } from 'react-hook-form'

import {
  FileUploadFormControl,
  InputFormControl,
  InputPercentageFormControl,
  SelectFormControl
} from '@/components'
import { FinancesSimple } from '@/interfaces'

import { FinancesDetailsFormSection } from './FinancesDetailsFormSection'
import { useSystemDictionaries } from '@/hooks'

interface Props {
  datasetInfo?: FinancesSimple
  formControl: Control
  isSubmitInProgress: boolean
  getDownloadFileUrl: (datasetFileId?: number) => string | undefined
}

export const FinancesDetailsForm = ({
  datasetInfo,
  formControl,
  isSubmitInProgress,
  getDownloadFileUrl
}: Props) => {
  const { taxDepreciationTypes } = useSystemDictionaries()

  const isFormDisabled =
    datasetInfo?.isLocked ||
    datasetInfo?.isDefault ||
    datasetInfo?.isArchive ||
    isSubmitInProgress

  return (
    <Box column w={{ xxl: '80%', _: '100%' }}>
      <FinancesDetailsFormSection title="Capacity Maintenance Cost/ Tax Depreciation">
        <T>Capacity Augmentation/Replacement Cost</T>
        <FileUploadFormControl
          control={formControl}
          formControlName="capacityAugmentationFile"
          uploadedFileName={datasetInfo?.capacityAugmentationFileName}
          downloadFileUrl={getDownloadFileUrl(
            datasetInfo?.capacityAugmentationFileId
          )}
          rules={{ required: !datasetInfo?.capacityAugmentationFileId }}
          disabled={isFormDisabled}
        />

        <T>Tax Depreciation</T>
        <SelectFormControl
          control={formControl}
          formControlName="taxDepreciationType"
          options={taxDepreciationTypes}
          rules={{ required: true }}
          disabled={isFormDisabled}
        />
      </FinancesDetailsFormSection>

      <FinancesDetailsFormSection title="ES Capital Cost">
        <T>DC Side</T>
        <InputFormControl
          control={formControl}
          formControlName="dcSide"
          size="md"
          readOnly={isFormDisabled}
          suffix="$/kWh"
          type="number"
          min={0}
          rules={{ required: true, min: 0 }}
        />

        <T>Power Conversion System</T>
        <InputFormControl
          control={formControl}
          formControlName="powerConversionSystem"
          size="md"
          readOnly={isFormDisabled}
          suffix="$/kWh"
          type="number"
          min={0}
          rules={{ required: true, min: 0 }}
        />

        <T>Grid Management System</T>
        <InputFormControl
          control={formControl}
          formControlName="gridManagementSystem"
          size="md"
          readOnly={isFormDisabled}
          suffix="$/kWh"
          type="number"
          min={0}
          rules={{ required: true, min: 0 }}
        />

        <T>LV Xmer and Switchgear</T>
        <InputFormControl
          control={formControl}
          formControlName="lvXmerAndSwitchgear"
          size="md"
          readOnly={isFormDisabled}
          suffix="$/kW-ac"
          type="number"
          min={0}
          rules={{ required: true, min: 0 }}
        />
      </FinancesDetailsFormSection>

      <FinancesDetailsFormSection title="ES O&M Cost">
        <T>Preventative Maintenance & Dispatch Cost</T>
        <InputFormControl
          control={formControl}
          formControlName="preventativeMaintenanceAndDispatchCost"
          size="md"
          readOnly={isFormDisabled}
          suffix="$/kW-year"
          type="number"
          min={0}
          rules={{ required: true, min: 0 }}
        />

        <T>Warranty/LTSA Cost</T>
        <InputFormControl
          control={formControl}
          formControlName="capacityLongTermServiceAgreement"
          size="md"
          readOnly={isFormDisabled}
          suffix="$/kWh-year"
          type="number"
          min={0}
          rules={{ required: true, min: 0 }}
        />
      </FinancesDetailsFormSection>

      <FinancesDetailsFormSection title="Escalation Rate">
        <T>O&M Cost Escalation Rates</T>
        <InputPercentageFormControl
          control={formControl}
          formControlName="eSOMCostEscalationRate"
          size="md"
          readOnly={isFormDisabled}
          suffix="%/year"
          rules={{ required: true, min: 0, max: 100 }}
        />
      </FinancesDetailsFormSection>

      <FinancesDetailsFormSection title="PV Capital Cost">
        <T>PV Module Equipment</T>
        <InputFormControl
          control={formControl}
          formControlName="pVModuleEquipment"
          size="md"
          readOnly={isFormDisabled}
          suffix="$/kW-dc"
          type="number"
          min={0}
          rules={{ required: true, min: 0 }}
        />

        <T>PCS/Inverters and Associated Controls</T>
        <InputFormControl
          control={formControl}
          formControlName="pCSInvertersAndAssociatedControls"
          size="md"
          readOnly={isFormDisabled}
          suffix="$/kW-ac"
          type="number"
          min={0}
          rules={{ required: true, min: 0 }}
        />

        <T>PV Equipment Engg and Construction</T>
        <InputFormControl
          control={formControl}
          formControlName="pVEquipmentEnggAndConstruction"
          size="md"
          readOnly={isFormDisabled}
          suffix="$/kW-dc"
          type="number"
          min={0}
          rules={{ required: true, min: 0 }}
        />

        <T>PV LV Transformer</T>
        <InputFormControl
          control={formControl}
          formControlName="pVLVTransformer"
          size="md"
          readOnly={isFormDisabled}
          suffix="$/kW-ac"
          type="number"
          min={0}
          rules={{ required: true, min: 0 }}
        />
      </FinancesDetailsFormSection>

      <FinancesDetailsFormSection title="PV O&M Cost">
        <T>PV Fixed O&M Cost</T>
        <InputFormControl
          control={formControl}
          formControlName="pVFixedOMCost"
          size="md"
          readOnly={isFormDisabled}
          suffix="$/kw-dc-year"
          type="number"
          min={0}
          rules={{ required: true, min: 0 }}
        />

        <T>O&M Cost Escalation Rates</T>
        <InputPercentageFormControl
          control={formControl}
          formControlName="pVOMCostEscalationRate"
          size="md"
          readOnly={isFormDisabled}
          suffix="%/year"
          rules={{ required: true, min: 0, max: 100 }}
        />
      </FinancesDetailsFormSection>

      <FinancesDetailsFormSection title="Wind Capital Cost">
        <T>WTG Foundations</T>
        <InputFormControl
          control={formControl}
          formControlName="wTGFoundations"
          size="md"
          readOnly={isFormDisabled}
          suffix="$/kW-ac"
          type="number"
          min={0}
          rules={{ required: true, min: 0 }}
        />

        <T>WTG Supply and Options</T>
        <InputFormControl
          control={formControl}
          formControlName="wTGSupplyAndOptions"
          size="md"
          readOnly={isFormDisabled}
          suffix="$/kW-ac"
          type="number"
          min={0}
          rules={{ required: true, min: 0 }}
        />

        <T>WTG Installation</T>
        <InputFormControl
          control={formControl}
          formControlName="wTGInstallation"
          size="md"
          readOnly={isFormDisabled}
          suffix="$/kW-ac"
          type="number"
          min={0}
          rules={{ required: true, min: 0 }}
        />

        <T>Collection System</T>
        <InputFormControl
          control={formControl}
          formControlName="collectionSystem"
          size="md"
          readOnly={isFormDisabled}
          suffix="$/kW-ac"
          type="number"
          min={0}
          rules={{ required: true, min: 0 }}
        />

        <T>Control System</T>
        <InputFormControl
          control={formControl}
          formControlName="controlSystem"
          size="md"
          readOnly={isFormDisabled}
          suffix="$/kW-ac"
          type="number"
          min={0}
          rules={{ required: true, min: 0 }}
        />

        <T>Wind LV Xmers</T>
        <InputFormControl
          control={formControl}
          formControlName="windLVXmers"
          size="md"
          readOnly={isFormDisabled}
          suffix="$/kW-ac"
          type="number"
          min={0}
          rules={{ required: true, min: 0 }}
        />
      </FinancesDetailsFormSection>

      <FinancesDetailsFormSection title="Wind O&M Cost">
        <T>Wind Fixed O&M Cost</T>
        <InputFormControl
          control={formControl}
          formControlName="windFixedOMCost"
          size="md"
          readOnly={isFormDisabled}
          suffix="$/kw-ac-year"
          type="number"
          min={0}
          rules={{ required: true, min: 0 }}
        />

        <T>O&M Cost Escalation Rates</T>
        <InputPercentageFormControl
          control={formControl}
          formControlName="windOMCostEscalationRates"
          size="md"
          readOnly={isFormDisabled}
          suffix="%/year"
          rules={{ required: true, min: 0, max: 100 }}
        />
      </FinancesDetailsFormSection>

      <FinancesDetailsFormSection title="Site Infrastructure">
        <T>Land Lease and Acquisition</T>
        <InputFormControl
          control={formControl}
          formControlName="landLeaseAndAcquisition"
          size="md"
          readOnly={isFormDisabled}
          suffix="$"
          type="number"
          min={0}
          rules={{ required: true, min: 0 }}
        />

        <T>Site and Public Roads</T>
        <InputFormControl
          control={formControl}
          formControlName="siteAndPublicRoads"
          size="md"
          readOnly={isFormDisabled}
          suffix="$"
          type="number"
          min={0}
          rules={{ required: true, min: 0 }}
        />

        <T>Buildings</T>
        <InputFormControl
          control={formControl}
          formControlName="buildings"
          size="md"
          readOnly={isFormDisabled}
          itemRight={<T px={1}>$</T>}
          suffix="$"
          type="number"
          min={0}
          rules={{ required: true, min: 0 }}
        />
      </FinancesDetailsFormSection>

      <FinancesDetailsFormSection title="Offsite">
        <T>Direct Interconnection Cost</T>
        <InputFormControl
          control={formControl}
          formControlName="directInterconnectionCost"
          size="md"
          readOnly={isFormDisabled}
          suffix="$"
          type="number"
          min={0}
          rules={{ required: true, min: 0 }}
        />

        <T>System Upgrades</T>
        <InputFormControl
          control={formControl}
          formControlName="systemUpgrades"
          size="md"
          readOnly={isFormDisabled}
          suffix="$"
          type="number"
          min={0}
          rules={{ required: true, min: 0 }}
        />
      </FinancesDetailsFormSection>

      <FinancesDetailsFormSection title="Owner's Cost">
        <T>Project Development</T>
        <InputFormControl
          control={formControl}
          formControlName="projectDevelopment"
          size="md"
          readOnly={isFormDisabled}
          suffix="$/kW"
          type="number"
          min={0}
          rules={{ required: true, min: 0 }}
        />

        <T>Project Management</T>
        <InputFormControl
          control={formControl}
          formControlName="projectManagement"
          size="md"
          readOnly={isFormDisabled}
          suffix="$/kW"
          type="number"
          min={0}
          rules={{ required: true, min: 0 }}
        />

        <T>Engineering</T>
        <InputFormControl
          control={formControl}
          formControlName="contingencyAndOverhead"
          size="md"
          readOnly={isFormDisabled}
          suffix="$/kW"
          type="number"
          min={0}
          rules={{ required: true, min: 0 }}
        />

        <T>Construction Management</T>
        <InputFormControl
          control={formControl}
          formControlName="constructionManagementAndEngg"
          size="md"
          readOnly={isFormDisabled}
          suffix="$/kW"
          type="number"
          min={0}
          rules={{ required: true, min: 0 }}
        />
      </FinancesDetailsFormSection>

      <FinancesDetailsFormSection title="Tax and Financial">
        <T>Federal ITC Rate as % of Installed Cost</T>
        <InputPercentageFormControl
          control={formControl}
          formControlName="federalITCRateOfInstalledCost"
          size="md"
          readOnly={isFormDisabled}
          rules={{ required: true, min: 0, max: 100 }}
        />

        <T>Tax Rate (Federal + State)</T>
        <InputPercentageFormControl
          control={formControl}
          formControlName="taxRate"
          size="md"
          readOnly={isFormDisabled}
          rules={{ required: true, min: 0, max: 100 }}
        />

        <T>Sales Tax Rate</T>
        <InputPercentageFormControl
          control={formControl}
          formControlName="salesTaxRate"
          size="md"
          readOnly={isFormDisabled}
          rules={{ required: true, min: 0, max: 100 }}
        />

        <T>Debt % for BESS Financing</T>
        <InputPercentageFormControl
          control={formControl}
          formControlName="debtForBESSFinancing"
          size="md"
          readOnly={isFormDisabled}
          rules={{ required: true, min: 0, max: 100 }}
        />

        <T>Debt Financing Rate</T>
        <InputPercentageFormControl
          control={formControl}
          formControlName="debtFinancingRate"
          size="md"
          readOnly={isFormDisabled}
          rules={{ required: true, min: 0, max: 100 }}
        />

        <T>Debt Financing Term</T>
        <InputPercentageFormControl
          control={formControl}
          formControlName="debtFinancingTerm"
          size="md"
          readOnly={isFormDisabled}
          rules={{ required: true, min: 0, max: 100 }}
        />

        <T>Discount Rate</T>
        <InputPercentageFormControl
          control={formControl}
          formControlName="discountRate"
          size="md"
          readOnly={isFormDisabled}
          rules={{ required: true, min: 0, max: 100 }}
        />

        <T>Equipment Insurance Fee</T>
        <InputPercentageFormControl
          control={formControl}
          formControlName="equipmentInsuranceFee"
          size="md"
          readOnly={isFormDisabled}
          rules={{ required: true, min: 0, max: 100 }}
        />
      </FinancesDetailsFormSection>
    </Box>
  )
}
