import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Box, Button, Dialog, formatDate, Spinner } from '@veracity/vui'

import { usePricePlans } from '@/hooks'
import { OrganizationSimple } from '@/interfaces'
import { InputFormControl, SelectFormControl } from '@/components'

import { useOrganizationInfo } from '../hooks'

interface Props {
  organization: OrganizationSimple
}

export const OrganizationManagementEditDialog = ({ organization }: Props) => {
  const [isOpen, setIsOpen] = useState(false)

  const { updateOrganization, isUpdateInProgress } = useOrganizationInfo(
    organization.id
  )
  const availablePricePlans = usePricePlans()

  const { control, handleSubmit, setValue } = useForm()

  useEffect(() => {
    setValue('name', organization.name)
    setValue('description', organization.description)
    setValue('pricePlanId', organization.pricePlanId)
    setValue(
      'expirationDate',
      formatDate(organization.subscriptionExpirationDate, 'YYYY-MM-DD')
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organization])

  const handleSubmitClick = (formValues: any) => {
    updateOrganization(
      {
        name: formValues.name,
        description: formValues.description,
        pricePlanId: formValues.pricePlanId,
        expirationDate: formValues.expirationDate
      },
      {
        onSuccess: () => setIsOpen(false)
      }
    )
  }

  const handleCloseClick = () => {
    if (!isUpdateInProgress) setIsOpen(false)
  }

  return (
    <>
      <Button
        variant="tertiaryDark"
        icon="falPen"
        size="lg"
        onClick={() => setIsOpen(true)}
      />

      <Dialog
        isOpen={isOpen}
        size="lg"
        title="Edit Organization"
        onClose={handleCloseClick}
        cancelButton={{ text: 'Cancel', disabled: isUpdateInProgress }}
        submitButton={{
          text: 'Save',
          onClick: handleSubmit(handleSubmitClick),
          isLoading: isUpdateInProgress,
          disabled: isUpdateInProgress
        }}
        body={
          isUpdateInProgress ? (
            <Box column p={2}>
              <Spinner />
            </Box>
          ) : (
            <Box column p={2} gap={2}>
              <InputFormControl
                control={control}
                formControlName="name"
                label="Name"
                rules={{ required: true }}
              />

              <InputFormControl
                control={control}
                formControlName="description"
                label="Description"
              />

              <SelectFormControl
                control={control}
                formControlName="pricePlanId"
                label="Price Plan"
                rules={{ required: true }}
                options={availablePricePlans.map(x => ({
                  text: x.name!,
                  value: x.id
                }))}
              />

              <InputFormControl
                control={control}
                formControlName="expirationDate"
                type="date"
                label="Expiration Date"
                rules={{ required: true }}
              />
            </Box>
          )
        }
      />
    </>
  )
}
