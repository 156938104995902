import { useToast } from '@veracity/vui'
import { useNavigate } from 'react-router-dom'
import { useMutation, useQuery, useQueryClient } from 'react-query'

import { config } from '@/config'
import { wholesaleMarketClient } from '@/api'

const BASE_ROUTE = config.links.library.wholesaleMarket

export const useWholesaleDataset = (datasetId: number) => {
  const { showSuccess } = useToast()
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const QUERY_KEY = [...wholesaleMarketClient.QUERY_KEY, datasetId]

  const getDatasetQuery = useQuery(QUERY_KEY, () =>
    wholesaleMarketClient.getDetails(datasetId)
  )

  const uploadDatasetFileMutation = useMutation({
    mutationFn: ({ fileType, file }: { fileType: string; file: File }) =>
      wholesaleMarketClient.uploadDatasetFile({
        datasetId: datasetId,
        fileType,
        file
      }),
    onSuccess: async (_, variables) => {
      showSuccess(`File ${variables.file.name} successfully uploaded`)
      await queryClient.invalidateQueries(QUERY_KEY)
    }
  })

  const removeDatasetFileMutation = useMutation({
    mutationFn: wholesaleMarketClient.removeDatasetFile,
    onSuccess: async () => {
      showSuccess(`Dataset file successfully removed`)
      await queryClient.invalidateQueries(wholesaleMarketClient.QUERY_KEY, {
        refetchInactive: true
      })
    }
  })

  const cloneDatasetMutation = useMutation({
    mutationFn: (datasetName: string) =>
      wholesaleMarketClient.cloneDataset({ datasetId, name: datasetName }),
    onSuccess: async clonedDatasetId => {
      showSuccess('Dataset successfully cloned')
      await queryClient.invalidateQueries(wholesaleMarketClient.QUERY_KEY, {
        refetchInactive: true
      })
      navigate(`${BASE_ROUTE}/${clonedDatasetId}`)
    }
  })

  const cloneDataset = (datasetName: string, onSuccess?: () => void) =>
    cloneDatasetMutation.mutate(datasetName, { onSuccess })

  const removeDatasetMutation = useMutation({
    mutationFn: () => wholesaleMarketClient.removeDataset(datasetId),
    onSuccess: async () => {
      showSuccess('Dataset successfully removed')
      navigate(BASE_ROUTE)
      await queryClient.invalidateQueries(wholesaleMarketClient.QUERY_KEY, {
        refetchInactive: true
      })
    }
  })

  const lockDatasetMutation = useMutation({
    mutationFn: () => wholesaleMarketClient.lockDataset(datasetId),
    onSuccess: async () => {
      showSuccess('Dataset successfully locked')
      await queryClient.invalidateQueries(wholesaleMarketClient.QUERY_KEY, {
        refetchInactive: true
      })
    }
  })

  const archiveDatasetMutation = useMutation({
    mutationFn: () => wholesaleMarketClient.archiveDataset(datasetId),
    onSuccess: async () => {
      showSuccess('Dataset successfully archived')
      await queryClient.invalidateQueries(wholesaleMarketClient.QUERY_KEY, {
        refetchInactive: true
      })
    }
  })

  const unArchiveDatasetMutation = useMutation({
    mutationFn: () => wholesaleMarketClient.unArchiveDataset(datasetId),
    onSuccess: async () => {
      showSuccess('Dataset successfully unarchived')
      await queryClient.invalidateQueries(wholesaleMarketClient.QUERY_KEY, {
        refetchInactive: true
      })
    }
  })

  return {
    datasetInfo: getDatasetQuery.data,
    isFetching: getDatasetQuery.isFetching && !getDatasetQuery.isRefetching,
    uploadDatasetFile: uploadDatasetFileMutation.mutate,
    isDatasetFileUploading: uploadDatasetFileMutation.isLoading,
    removeDatasetFile: removeDatasetFileMutation.mutate,
    cloneDataset: cloneDataset,
    isCloneDatasetInProgress: cloneDatasetMutation.isLoading,
    removeDataset: removeDatasetMutation.mutate,
    lockDataset: lockDatasetMutation.mutate,
    archiveDataset: archiveDatasetMutation.mutate,
    unArchiveDataset: unArchiveDatasetMutation.mutate,
    getDownloadFileUrl: (fileId: number | undefined) =>
      !fileId
        ? undefined
        : wholesaleMarketClient.downloadWholesaleMarketFileUrl(fileId)
  }
}
