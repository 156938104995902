export const convertDateToLocal = (date?: Date) => {
  if (!date) return undefined

  const selectedDate = new Date(date?.toDateString())

  selectedDate.setMinutes(
    selectedDate.getMinutes() - selectedDate.getTimezoneOffset()
  )

  return selectedDate
}
