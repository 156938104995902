import { useEffect } from 'react'
import { useForm } from 'react-hook-form'

import {
  LossFactorsSimple,
  UpdateLossFactorsDatasetRequest
} from '@/interfaces'

export const useLossFactorsDetailsForm = (
  updateDataset: (req: UpdateLossFactorsDatasetRequest) => void,
  datasetInfo?: LossFactorsSimple
) => {
  const { control, handleSubmit, formState, reset, watch } = useForm()
  useEffect(() => {
    reset({
      acLineEfficiency: datasetInfo?.acLineEfficiency,
      acMainTransformerEfficiency: datasetInfo?.acMainTransformerEfficiency,
      storageChargeDischargeEfficiency:
        datasetInfo?.storageChargeDischargeEfficiency,
      dcLineEfficiency: datasetInfo?.dcLineEfficiency,
      dcDcConverterEfficiency: datasetInfo?.dcdcConverterEfficiency,
      dcAcInverterEfficiency: datasetInfo?.dcacInverterEfficiency,
      auxiliaryPowerLoss: datasetInfo?.auxiliaryPowerLoss,
      initialStateOfCharge: datasetInfo?.initialStateOfCharge,
      minimumStateOfCharge: datasetInfo?.minimumStateOfCharge,
      maximumStateOfCharge: datasetInfo?.maximumStateOfCharge,
      pvDCACInverterEfficiency: datasetInfo?.pvDCACInverterEfficiency,
      pvAnnualDerating: datasetInfo?.pvAnnualDerating,
      windTotalEfficiency: datasetInfo?.windTotalEfficiency,
      windAnnualDerating: datasetInfo?.windAnnualDerating
    })
  }, [datasetInfo, reset])

  const formValues = watch()

  const handleSubmitForm = handleSubmit(data =>
    updateDataset({
      acLineEfficiency: data.acLineEfficiency,
      acMainTransformerEfficiency: data.acMainTransformerEfficiency,
      storageChargeDischargeEfficiency: data.storageChargeDischargeEfficiency,
      dcLineEfficiency: data.dcLineEfficiency,
      dcdcConverterEfficiency: data.dcDcConverterEfficiency,
      dcacInverterEfficiency: data.dcAcInverterEfficiency,
      auxiliaryPowerLoss: data.auxiliaryPowerLoss,
      initialStateOfCharge: data.initialStateOfCharge,
      minimumStateOfCharge: data.minimumStateOfCharge,
      maximumStateOfCharge: data.maximumStateOfCharge,
      pvDCACInverterEfficiency:
        data.pvDCACInverterEfficiency === ''
          ? undefined
          : data.pvDCACInverterEfficiency,
      pvAnnualDerating:
        data.pvAnnualDerating === '' ? undefined : data.pvAnnualDerating,
      windTotalEfficiency:
        data.windTotalEfficiency === '' ? undefined : data.windTotalEfficiency,
      windAnnualDerating:
        data.windAnnualDerating === '' ? undefined : data.windAnnualDerating
    })
  )

  return {
    formControl: control,
    isFormValid: formState.isValid,
    isFormTouched: formState.isDirty,
    handleSubmitForm,
    formValues
  }
}
