export const AcLineEfficiencyIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={74}
    height={55}
    fill="none"
    fillRule="evenodd"
    strokeLinecap="square"
    strokeMiterlimit={3}
    colorInterpolationFilters="sRGB"
    fontSize={12}>
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M4.573 11.132a10.42 10.42 0 0 1 3.75 3.63c1.617 2.631 2.372 6.4 1.737 8.737-1.066 3.878-5.99 3.784-8.266 7.086C-.697 34.2-.038 41.862 4.574 43.14"
    />
    <path
      d="m37.182 44.855-.228 1.169-10.709-4.034 10.41-3.536.215 1.168-7.139 2.43 7.45 2.803zm-.827-12.849-.203 1.184-10.003-3.411 10.074-3.427.204 1.184-6.612 2.243 6.54 2.227zm.072-12.32-.216 1.184-10.074-3.458 10.65-3.582.203 1.168-7.187 2.43 6.624 2.258zm7.81 16.214-8.145-2.741.203-1.169 11.643 3.91-11.643 3.925-.203-1.168 8.145-2.757zm-8.014-15.03.204-1.184 11.667 3.925-11.75 3.94-.204-1.168 8.24-2.772-8.157-2.741zm.156-12.382.216-1.184 11.487 3.925-11.212 3.816-.215-1.184 7.762-2.632-8.038-2.741z"
      className="st3"
      stroke="null"
      fill="#000"
    />
    <path
      d="M36.323 8.495V.23h.877v8.265h-.877zm0 46.246v-9.884h.877v9.884h-.877z"
      className="st3"
      fill="#000"
      stroke="null"
    />
  </svg>
)
