import { Box } from '@veracity/vui'

import { TreeSelectItem } from './TreeSelectItem'
import { TreeSelectItemOption } from './types'

interface Props {
  items?: TreeSelectItemOption[]
  isDisabled?: boolean
}

export const TreeSelect = ({ items, isDisabled }: Props) => (
  <Box column gap={2}>
    {items?.map((item, index) => (
      <TreeSelectItem key={index} item={item} isDisabled={isDisabled} />
    ))}
  </Box>
)
