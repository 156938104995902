import { Tag } from '@veracity/vui'

import { CalculationStates } from '@/interfaces'

interface Props {
  status: CalculationStates
}

export const LogItemStatusBadge = ({ status }: Props) => {
  switch (status) {
    case CalculationStates.Error:
      return <Tag variant="subtleRed" text="Failed" />
    case CalculationStates.Finished:
      return <Tag variant="subtleGreen" text="Completed" />
    default:
      return <Tag text="In Progress" />
  }
}
