import { Box, Button, Dialog, Grid } from '@veracity/vui'
import { useToggle } from '@veracity/ui-utils'

import {
  InputFormControl,
  IsoRegionsMap,
  MultiSelectFormControl,
  SelectFormControl
} from '@/components'
import { useForm } from 'react-hook-form'
import { usePortfoliosList, useProjectsList } from '../hooks'
import { useSystemDictionaries } from '@/hooks'
import { IsoBa, RevenueType } from '@/interfaces'

export const ProjectsListAddProjectDialog = () => {
  const [isVisible, toggleIsVisible] = useToggle()
  const { isoBaList } = useSystemDictionaries()
  const { projects, addProject, isAddProjectInProgress } = useProjectsList()
  const portfoliosList = usePortfoliosList()
  const { reset, handleSubmit, control, watch } = useForm({
    defaultValues: {
      name: '',
      revenueType: '',
      isoBa: '',
      portfolioIds: []
    }
  })

  const selectedIso = watch('isoBa')

  const handleClose = () => {
    if (isAddProjectInProgress) return
    reset()
    toggleIsVisible.off()
  }

  const handleSubmitClick = (values: any) => {
    addProject({
      projectName: values.name,
      isoBa: values.isoBa
      // revenueType: values.revenueType,
      // portfolioIds: values.portfolioIds
    })
  }

  const validateNameDuplication = (value?: string) =>
    !projects.some(
      p => p.name?.toLocaleLowerCase() === value?.toLocaleLowerCase()
    )

  return (
    <>
      <Button
        size="lg"
        iconLeft="falPlus"
        text="Add project"
        onClick={toggleIsVisible}
      />

      <Dialog
        isOpen={isVisible}
        title="Add Project"
        size="fullWidth"
        maxW={1200}
        onClose={handleClose}
        onSubmit={handleSubmit(handleSubmitClick)}
        cancelButton={{ text: 'Cancel', disabled: isAddProjectInProgress }}
        submitButton={{
          text: 'Add',
          onClick: handleSubmit(handleSubmitClick),
          isLoading: isAddProjectInProgress,
          disabled: isAddProjectInProgress
        }}
        body={
          <Box column>
            <Grid gridTemplateColumns={3} gap={2} p={2}>
              <InputFormControl
                control={control}
                formControlName="name"
                label="Project Name"
                size="md"
                rules={{ required: true, validate: validateNameDuplication }}
              />

              <SelectFormControl
                control={control}
                formControlName="revenueType"
                label="Revenue Type"
                options={[
                  { text: 'Merchant', value: RevenueType.MERCHANT },
                  {
                    text: 'Contract/PPA',
                    value: RevenueType.CONTRACT_PPA,
                    disabled: true
                  },
                  {
                    text: 'Combination',
                    value: RevenueType.COMBINE,
                    disabled: true
                  }
                ]}
                rules={{ required: true }}
              />

              <SelectFormControl
                control={control}
                formControlName="isoBa"
                label="Country-ISO"
                options={isoBaList}
                rules={{ required: true }}
              />

              <MultiSelectFormControl
                control={control}
                formControlName="portfolioIds"
                label="Portfolio (Optional)"
                options={portfoliosList}
              />
            </Grid>
            <IsoRegionsMap selectedISO={+selectedIso as IsoBa} />
          </Box>
        }
      />
    </>
  )
}
