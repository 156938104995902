import { Outlet } from 'react-router-dom'

import { useCurrentUser } from '@/hooks'
import { Header } from './Header'
import { Sidebar } from './Sidebar'
import { PageContent, PageContentContainer } from './styled'

export const BaseLayout = () => {
  const { currentUser } = useCurrentUser()

  return (
    <main>
      <Header currentUser={currentUser} />
      <PageContent>
        {!!currentUser && <Sidebar />}
        <PageContentContainer>
          <Outlet />
        </PageContentContainer>
      </PageContent>
    </main>
  )
}
