import { Link } from 'react-router-dom'
import { Grid } from '@veracity/vui'
import { PortfolioSimple } from '@/interfaces'

import { PortfoliosListPreviewCard } from './PortfoliosListPreviewCard'

interface Props {
  portfolios: PortfolioSimple[]
}

export const PortfoliosListPreview = ({ portfolios }: Props) => (
  <Grid gridTemplateColumns={{ xs: 1, md: 2, xl: 3, xxl: 4 }} gap={2}>
    {portfolios.map(portfolio => (
      <Link to={`/portfolios/${portfolio.id}`} key={portfolio.id}>
        <PortfoliosListPreviewCard portfolio={portfolio} />
      </Link>
    ))}
  </Grid>
)
