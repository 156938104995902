import { Box, Card, Grid } from '@veracity/vui'
import { Control } from 'react-hook-form'

import { ScenarioDetailed } from '@/interfaces'

import { ScenarioDetailsContentInfoBox } from './ScenarioDetailsContentInfoBox'
import { ScenarioDetailsContentParametersForm } from './ScenarioDetailsContentParametersForm'
import { ScenarioDetailsContentDatasetsForm } from './ScenarioDetailsContentDatasetsForm'
import { ScenarioDetailsContentDiagramForm } from './ScenarioDetailsContentDiagramForm'
import { ScenarioDetailsFormValues } from '../../types'

interface Props {
  scenarioInfo?: ScenarioDetailed
  formControl: Control<ScenarioDetailsFormValues>
  isFormDisabled: boolean
}

export const ScenarioDetailsContent = ({
  scenarioInfo,
  formControl,
  isFormDisabled
}: Props) => (
  <Box column gap={1}>
    <ScenarioDetailsContentInfoBox scenarioInfo={scenarioInfo} />
    <Grid gap={1} gridTemplateColumns={{ xl: '1fr 3fr' }}>
      <Box column gap={1}>
        <ScenarioDetailsContentParametersForm
          scenarioInfo={scenarioInfo}
          formControl={formControl}
          isFormDisabled={isFormDisabled}
        />
        <ScenarioDetailsContentDatasetsForm
          scenarioInfo={scenarioInfo}
          formControl={formControl}
          isFormDisabled={isFormDisabled}
        />
      </Box>

      <Card column p={2} gap={2}>
        <ScenarioDetailsContentDiagramForm
          systemConfiguration={scenarioInfo?.systemConfiguration}
          formControl={formControl}
          isFormDisabled={isFormDisabled}
          scenarioId={scenarioInfo?.id || 0}
        />
      </Card>
    </Grid>
  </Box>
)
