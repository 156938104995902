import { Box, P } from '@veracity/vui'

import { ContactSupport, PageContainer } from '@/components'

export const PrivacyPage = () => (
  <PageContainer
    header={<PageContainer.Breadcrumbs items={[{ title: 'Privacy' }]} />}>
    <Box column bg="white" p={2}>
      <P>
        Hybrid Energy Resource Optimizer is provided on the DNV Veracity data
        platform.
      </P>
      <P>
        The Veracity platform Privacy Statement defines personal data and the
        steps we take to protect your personal data.
      </P>

      <ContactSupport />
    </Box>
  </PageContainer>
)
