import { StrictMode } from 'react'
import ReactDOM from 'react-dom/client'
import { LinksProvider, VuiProvider } from '@veracity/vui'

import { App } from './App'
import { ReactQueryProvider } from '@/contexts'

import './styles/main.css'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <StrictMode>
    <VuiProvider>
      <LinksProvider>
        <ReactQueryProvider>
          <App />
        </ReactQueryProvider>
      </LinksProvider>
    </VuiProvider>
  </StrictMode>
)
