import axios from 'axios'
import {
  AddDatasetRequest,
  CloneDatasetRequest,
  DatasetSimple,
  ProductionProfileSimple,
  SourceTypes,
  UpdateProductionProfileDatasetRequest
} from '@/interfaces'

const baseUrl = '/api/library/ProductionProfiles'

export const productionProfilesClient = {
  QUERY_KEY: ['library', 'productionProfiles'],
  getAll: (lockedOnly?: boolean, sourceType?: SourceTypes) =>
    axios
      .get<DatasetSimple[]>(baseUrl, {
        params: { locked: lockedOnly, type: sourceType }
      })
      .then(x => x.data),
  getById: (datasetId: number) =>
    axios
      .get<ProductionProfileSimple>(`${baseUrl}/${datasetId}`)
      .then(x => x.data),
  addDataset: (req: AddDatasetRequest) =>
    axios.post<number>(baseUrl, req).then(x => x.data),
  cloneDataset: ({
    datasetId,
    name
  }: { datasetId: number } & CloneDatasetRequest) =>
    axios
      .post<number>(`${baseUrl}/cloneDataset/${datasetId}`, { name })
      .then(x => x.data),
  updateDataset: (
    datasetId: number,
    req: UpdateProductionProfileDatasetRequest
  ) => axios.putForm<void>(`${baseUrl}/${datasetId}`, req).then(x => x.data),
  removeDataset: (datasetId: number) =>
    axios.delete<number>(`${baseUrl}/${datasetId}`).then(x => x.data),
  lockDataset: (datasetId: number) =>
    axios.post<void>(`${baseUrl}/lockDataset/${datasetId}`).then(x => x.data),
  archiveDataset: (datasetId: number) =>
    axios
      .post<void>(`${baseUrl}/archiveDataset/${datasetId}`)
      .then(x => x.data),
  unArchiveDataset: (datasetId: number) =>
    axios
      .post<void>(`${baseUrl}/unArchiveDataset/${datasetId}`)
      .then(x => x.data),
  getDownloadFileUrl: (datasetFileId?: number) =>
    !datasetFileId ? undefined : `${baseUrl}/File/${datasetFileId}`
}
