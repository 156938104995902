import styled from 'styled-components'
import { Box } from '@veracity/vui'

export const SliderBase = styled('input')`
  writing-mode: bt-lr; /* IE */
  -webkit-appearance: slider-vertical; /* Chromium */
  width: 8px;
  height: 100%;
  padding: 0 5px;
  transform: rotate(180deg);
`

export const SliderTooltip = styled('output')`
  background: rgb(0, 52, 143);
  color: white;
  padding: 4px 12px;
  position: absolute;
  border-radius: 4px;
  //left: 50%;
  //transform: translateX(-50%);
  left: 2em;
  width: 8em;
`

interface Props {
  year: number
  value?: Date
  onChange: (newValue: Date) => void
}

export const Slider = ({ value, onChange, year }: Props) => {
  const min = new Date(year, 0, 1).getTime() / 1000
  const max = new Date(year, 11, 31, 23, 59, 59).getTime() / 1000
  const step = 24 * 60 * 60

  const currentValue = value ? (value?.getTime() || 0) / 1000 : undefined

  const handleChange = (val: number) => onChange(new Date(val * 1_000))

  return (
    <Box position="relative" pl={2} h="100%">
      <SliderBase
        type="range"
        value={currentValue}
        step={step}
        min={min}
        max={max}
        onChange={ev => handleChange(+ev.target.value)}
      />
      {/*<SliderTooltip>{formatDate(value, 'DD MMM YYYY')}</SliderTooltip>*/}
    </Box>
  )
}
