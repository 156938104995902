import { useParams } from 'react-router-dom'

import { config } from '@/config'
import { DatasetDetailsOperationBar, PageContainer } from '@/components'

import { ProductionProfilesDetailsForm } from './components'
import {
  useProductionProfileDetails,
  useProductionProfileDetailsForm
} from './hooks'

export const ProductionProfilesDetailsPage = () => {
  const { id } = useParams()

  const {
    datasetInfo,
    getDownloadFileUrl,
    updateDataset,
    archiveDataset,
    unArchiveDataset,
    removeDataset,
    lockDataset,
    cloneDataset,
    isUpdateDatasetInProgress,
    isCloneDatasetInProgress,
    allDatasetNames,
    isFetching
  } = useProductionProfileDetails(+(id || ''))

  const {
    handleSubmitForm,
    formControl,
    isFormValid,
    isFormTouched,
    formValues
  } = useProductionProfileDetailsForm(updateDataset, datasetInfo)

  return (
    <PageContainer
      loading={isFetching}
      header={
        <PageContainer.Breadcrumbs
          items={[
            { title: 'Library' },
            {
              title: 'Production Profiles',
              path: config.links.library.productionProfiles
            },
            { title: datasetInfo?.name || '' }
          ]}
        />
      }
      menuBar={
        !datasetInfo ? null : (
          <DatasetDetailsOperationBar
            datasetInfo={datasetInfo}
            onArchiveDataset={archiveDataset}
            onUnArchiveDataset={unArchiveDataset}
            onLockDataset={lockDataset}
            onRemoveDataset={removeDataset}
            onCloneDataset={cloneDataset}
            isCloneDatasetInProgress={isCloneDatasetInProgress}
            allDatasetNames={allDatasetNames}
            isLockDisabled={!isFormValid || isFormTouched}
            saveButton={{
              onClick: handleSubmitForm,
              isDisabled:
                !isFormTouched || !isFormValid || isUpdateDatasetInProgress,
              isLoading: isUpdateDatasetInProgress
            }}
          />
        )
      }>
      <ProductionProfilesDetailsForm
        datasetInfo={datasetInfo}
        formControl={formControl}
        formValues={formValues}
        isSubmitInProgress={isUpdateDatasetInProgress}
        getDownloadFileUrl={getDownloadFileUrl}
      />
    </PageContainer>
  )
}
