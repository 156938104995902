import { useState } from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { Box, Button, useDialog } from '@veracity/vui'

import { useCurrentUser } from '@/hooks'
import { userAdministrationClient } from '@/api'
import { PageContainer, PageSearchInput } from '@/components'

import {
  OrganizationInfoBox,
  OrganizationManagementEditDialog,
  OrganizationManagementUsersTable
} from './components'
import {
  useFilterOrganizationUsers,
  useOrganizationInfo,
  useOrganizationUsers
} from './hooks'

export const OrganizationManagementPage = () => {
  const { id } = useParams()
  const organizationId = +(id || '')

  const { showConfirm } = useDialog()

  const [searchValue, setSearchValue] = useState('')

  const { data: allowedRoles } = useQuery(
    ['userRoles'],
    userAdministrationClient.getAvailableRoles
  )
  const { isSuperAdmin } = useCurrentUser()
  const { organizationInfo, removeOrganization } =
    useOrganizationInfo(organizationId)

  const {
    users,
    isFetching,
    updateUserRole,
    updateUserStatus,
    detachUserFromOrganization
  } = useOrganizationUsers(organizationId)

  const filteredOrganizationUsers = useFilterOrganizationUsers(
    users,
    searchValue
  )

  const handleRemoveOrganizationClicked = async () => {
    const isConfirmed = await showConfirm(
      'Remove organization',
      'This operation is irreversible. Are you sure?'
    )
    if (isConfirmed) removeOrganization()
  }

  return (
    <PageContainer
      loading={isFetching}
      header={
        <PageContainer.Breadcrumbs
          items={[
            { title: 'Organizations', path: '/administration' },
            { title: organizationInfo?.name || 'Organization' }
          ]}
        />
      }
      menuBar={
        <Box justify="space-between" w="100%">
          <PageSearchInput onChange={setSearchValue} />

          {!!organizationInfo && isSuperAdmin && (
            <Box gap={2}>
              <OrganizationManagementEditDialog
                organization={organizationInfo}
              />

              <Button
                variant="tertiaryDark"
                icon="falTrashAlt"
                size="lg"
                onClick={handleRemoveOrganizationClicked}
              />
            </Box>
          )}
        </Box>
      }>
      <Box column gap={2}>
        <OrganizationInfoBox organization={organizationInfo} />
        <OrganizationManagementUsersTable
          users={filteredOrganizationUsers}
          allowedRoles={allowedRoles}
          onDetachUserClicked={detachUserFromOrganization}
          onUserStatusChanged={updateUserStatus}
          onUserRoleChanged={updateUserRole}
        />
      </Box>
    </PageContainer>
  )
}
