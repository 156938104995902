import { Box, Icon, Tooltip } from '@veracity/vui'

import { DatasetSimple } from '@/interfaces'

interface Props {
  dataset: DatasetSimple
}

export const LibraryDatasetTableTypeIcon = ({ dataset }: Props) => {
  if (dataset.isDefault)
    return (
      <Tooltip text="Default">
        <Icon name="cubDNV" />
      </Tooltip>
    )

  return (
    <Box gap={1}>
      {dataset.isArchive && (
        <Tooltip text="Archived">
          <Icon name="falFileArchive" />
        </Tooltip>
      )}

      {dataset.isLocked && (
        <Tooltip text="Locked">
          <Icon name="falLockAlt" />
        </Tooltip>
      )}
    </Box>
  )
}
