import { Box, Card, Divider, Grid, Heading } from '@veracity/vui'

import { ProjectSimple } from '@/interfaces'

interface Props {
  projectInfo?: ProjectSimple
}

export const ProjectDetailsInfoBox = ({ projectInfo }: Props) => (
  <Card py={2} px={6}>
    <Grid gridTemplateColumns="2fr 1fr 2fr 1fr 2fr" w="100%">
      <Box gap={2} alignItems="center">
        <Heading color="sandstone.50" size="h5">
          Project name
        </Heading>
        <Heading size="h2" isTruncated title={projectInfo?.name}>
          {projectInfo?.name}
        </Heading>
      </Box>
      <Box justify="center">
        <Divider isVertical />
      </Box>

      <Box gap={2} alignItems="center">
        <Heading color="sandstone.50" size="h5">
          ISO
        </Heading>
        <Heading size="h2">{projectInfo?.isoBaName}</Heading>
      </Box>

      <Box justify="center">
        <Divider isVertical />
      </Box>

      <Box gap={2} alignItems="center">
        <Heading color="sandstone.50" size="h5">
          Author
        </Heading>
        <Heading size="h2" isTruncated title={projectInfo?.createdBy}>
          {projectInfo?.createdBy}
        </Heading>
      </Box>
    </Grid>
  </Card>
)
