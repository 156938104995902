import { Control } from 'react-hook-form'
import { Box, Card, Grid, Heading, T } from '@veracity/vui'

import { generateRange } from '@/utils'
import { useSystemDictionaries } from '@/hooks'
import { RunOptions, ScenarioDetailed } from '@/interfaces'
import {
  CheckboxGroupFormControl,
  InputFormControl,
  SelectFormControl,
  TooltipIcon
} from '@/components'

import { ScenarioDetailsFormValues } from '../../types'

const MIN_START_YEAR = 2017
const MAX_END_YEAR = 2050

interface Props {
  scenarioInfo?: ScenarioDetailed
  formControl: Control<ScenarioDetailsFormValues>
  isFormDisabled: boolean
}

export const ScenarioDetailsContentParametersForm = ({
  scenarioInfo,
  formControl,
  isFormDisabled
}: Props) => {
  const { systemConfigurations, runOptionsList } = useSystemDictionaries()

  const isSelectedYearsCheckboxVisible =
    !!scenarioInfo?.startYear &&
    !!scenarioInfo?.endYear &&
    scenarioInfo.yearsToRun === RunOptions.CustomerSelectedYears

  return (
    <Card column gap={2} p={2}>
      <Heading size="h4">Scenario Parameters</Heading>

      <Grid gridTemplateColumns={{ xs: 2, md: 3, xl: 1 }} gap={2}>
        <Box column gap={2}>
          <Box gap={1}>
            <T>Calculation start year</T>
            <TooltipIcon.Info tooltipText="HERO optimization starts on Jan 1 of calculations start year." />
          </Box>

          <InputFormControl
            control={formControl}
            formControlName="startYear"
            type="number"
            size="md"
            min={MIN_START_YEAR}
            max={MAX_END_YEAR}
            disabled={isFormDisabled}
            rules={{
              required: true,
              min: MIN_START_YEAR,
              max: scenarioInfo?.endYear
            }}
            useOnBlurAsChange
          />
        </Box>

        <Box column gap={2}>
          <Box gap={1}>
            <T>Calculation end year</T>
            <TooltipIcon.Info tooltipText="HERO optimization ends on Dec 31 of calculations end year." />
          </Box>
          <InputFormControl
            control={formControl}
            formControlName="endYear"
            type="number"
            size="md"
            min={MIN_START_YEAR}
            max={MAX_END_YEAR}
            disabled={isFormDisabled}
            rules={{
              required: true,
              min: scenarioInfo?.startYear,
              max: MAX_END_YEAR
            }}
            useOnBlurAsChange
          />
        </Box>

        <Box column gap={2}>
          <T>System configuration</T>
          <SelectFormControl
            control={formControl}
            formControlName="systemConfiguration"
            disabled={isFormDisabled}
            options={systemConfigurations}
            rules={{ required: true }}
          />
        </Box>

        <Box column gap={2}>
          <Box gap={1}>
            <T>Run options</T>
            <TooltipIcon.Info tooltipText="You can select which year(s) you want to run." />
          </Box>
          <SelectFormControl
            control={formControl}
            formControlName="yearsToRun"
            disabled={isFormDisabled}
            options={runOptionsList}
            rules={{ required: true }}
          />
        </Box>

        <Box column gap={2}>
          <T>Interconnection capacity</T>
          <InputFormControl
            control={formControl}
            formControlName="interconnectionCapacity"
            type="number"
            size="md"
            min={0}
            suffix="MW"
            disabled={isFormDisabled}
            rules={{ required: true, min: 0, max: 1_000 }}
            useOnBlurAsChange
          />
        </Box>
      </Grid>
      {isSelectedYearsCheckboxVisible && (
        <CheckboxGroupFormControl
          control={formControl}
          formControlName="selectedRunYears"
          disabled={isFormDisabled}
          items={generateRange(
            scenarioInfo.startYear,
            scenarioInfo.endYear || scenarioInfo?.startYear
          ).map(year => ({ label: `${year}`, value: year }))}
          isHorizontal
          rules={{ required: true, min: 1 }}
        />
      )}
    </Card>
  )
}
