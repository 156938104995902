import { useMemo } from 'react'
import moment from 'moment'

import { OrganizationSimple } from '@/interfaces'

import { OrganizationsFilterValues } from '../types'

export const useOrganizationsFilter = (
  organizations: OrganizationSimple[],
  searchValue?: string,
  filtersValues?: OrganizationsFilterValues
) => {
  const filterByText = (base: OrganizationSimple[], query: string) =>
    base.filter(x =>
      x.name?.toLocaleLowerCase()?.includes(query.toLocaleLowerCase())
    )

  const filterByValues = (
    base: OrganizationSimple[],
    filters: OrganizationsFilterValues
  ) =>
    base
      .filter(
        x =>
          filters.selectedPricePlanId === undefined ||
          filters.selectedPricePlanId === x.pricePlanId
      )
      .filter(
        x =>
          filters.selectedCreationDate === undefined ||
          moment(filters.selectedCreationDate).isBefore(x.createdAt)
      )
      .filter(
        x =>
          filters.selectedSubscriptionExpirationDate === undefined ||
          moment(filters.selectedSubscriptionExpirationDate).isBefore(
            x.subscriptionExpirationDate
          )
      )

  return useMemo(() => {
    let results = organizations

    if (!!searchValue) results = filterByText(results, searchValue)

    if (!!filtersValues) results = filterByValues(results, filtersValues)

    return results
  }, [filtersValues, organizations, searchValue])
}
