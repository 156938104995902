export const DcLineEfficiencyIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    colorInterpolationFilters="sRGB"
    height={55}
    style={{ transform: 'translateX(-1px)' }}
    viewBox="0 0 46 34">
    <path d="m24.3642 28.08236-.18.73-8.73-2.53 8.48-2.21.17.73-5.8 1.52 6.06 1.76zm-.67-8.05-.17.74-8.14-2.14 8.2-2.14.17.74-5.38 1.41 5.32 1.39zm.06-7.71-.17.74-8.21-2.16 8.67-2.25.17.73-5.85 1.52 5.39 1.42zm6.36 10.15-6.64-1.71.17-.74 9.48 2.45-9.48 2.46-.17-.74 6.64-1.72zm-6.53-9.41.17-.74 9.5 2.45-9.57 2.48-.17-.74 6.72-1.74-6.65-1.71zm.13-7.76.17-.73 9.35 2.45-9.13 2.39-.17-.74 6.32-1.65-6.54-1.72z" />
    <path d="M23.6659 5.31056V.1345h.71445v5.17606zm0 28.9609V28.0814h.71445v6.19006z" />
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth=".25"
      d="M6.95537 25.1102v-17.86m-6.8274 17.86v-17.86"
    />
  </svg>
)
