import { Controller } from 'react-hook-form'
import { Box, Select, SelectProps, T } from '@veracity/vui'

import { BaseFormItemProps } from './BaseFormItem'

export interface SelectFormControlProps
  extends Omit<Omit<SelectProps, 'isMultiple'>, 'children'>,
    BaseFormItemProps {
  w?: number | string
}

export const SelectFormControl = ({
  formControlName,
  control,
  label,
  rules,
  w,
  ...props
}: SelectFormControlProps) => (
  <Controller
    name={formControlName}
    control={control}
    rules={rules}
    render={({ field, fieldState }) => (
      <Box column gap={1} w={w}>
        {!label ? null : <T>{label}</T>}
        <Select
          value={field.value}
          onChange={(ev: any) => {
            field.onChange(ev)
            field.onBlur()
          }}
          isInvalid={!!fieldState.error}
          {...props}
        />
      </Box>
    )}
  />
)
