import { createContext } from 'react'
import { UserResponse } from '@/interfaces'

interface CurrentUserContextState {
  currentUser?: UserResponse
  isLoading: boolean
}

export const CurrentUserContext = createContext<CurrentUserContextState>({
  currentUser: undefined,
  isLoading: false
})
