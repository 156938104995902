import {
  createBrowserRouter,
  Navigate,
  Outlet,
  RouterProvider
} from 'react-router-dom'

import { useCurrentUser } from '@/hooks'
import { BaseLayout, ProtectedRoute } from '@/components'
import {
  AboutPage,
  AdministrationPage,
  ApiManagementPage,
  FaqPage,
  FinancesDetailsPage,
  FinancesPage,
  IncomeAdjustmentDetailsPage,
  IncomeAdjustmentPage,
  LogsPage,
  LossFactorsDetailsPage,
  LossFactorsPage,
  OrganizationManagementPage,
  OtherBessInputsDetailsPage,
  OtherBessInputsPage,
  PortfolioDetailsPage,
  PortfoliosListPage,
  PrivacyPage,
  ProductionProfilesDetailsPage,
  ProductionProfilesPage,
  ProfilePage,
  ProjectDetailsPage,
  ProjectsListPage,
  ReleaseNotesPage,
  ScenarioComparisonPage,
  ScenarioDetailsPage,
  ScenarioResultsPage,
  TermsOfUsePage,
  WholesaleMarketDetailsPage,
  WholesaleMarketPage
} from '@/pages'
import { config } from '@/config'

export const AppRouting = () => {
  const { currentUser } = useCurrentUser()
  const routes = createBrowserRouter([
    {
      path: '/',
      element: <BaseLayout />,
      children: [
        {
          path: '/',
          element: <Navigate to={!!currentUser ? '/projects' : '/about'} />
        },
        { path: 'about', element: <AboutPage /> },
        {
          path: 'log',
          element: (
            <ProtectedRoute>
              <LogsPage />
            </ProtectedRoute>
          )
        },
        {
          path: 'portfolios',
          element: (
            <ProtectedRoute>
              <Outlet />
            </ProtectedRoute>
          ),
          children: [
            { path: '', element: <PortfoliosListPage /> },
            { path: ':portfolioId', element: <PortfolioDetailsPage /> }
          ]
        },
        {
          path: 'projects',
          element: (
            <ProtectedRoute>
              <Outlet />
            </ProtectedRoute>
          ),
          children: [
            { path: '', element: <ProjectsListPage /> },
            {
              path: ':projectId',
              children: [
                { path: '', element: <ProjectDetailsPage /> },
                {
                  path: 'scenarios',
                  children: [
                    { path: '', element: null },
                    {
                      path: ':scenarioId',
                      children: [
                        { path: '', element: <ScenarioDetailsPage /> },
                        { path: 'results', element: <ScenarioResultsPage /> }
                      ]
                    }
                  ]
                }
              ]
            },
            {
              path: 'scenarios/comparison/:joinedScenarioIds',
              element: <ScenarioComparisonPage />
            }
          ]
        },
        {
          path: 'references',
          element: (
            <ProtectedRoute>
              <Outlet />
            </ProtectedRoute>
          ),
          children: [
            { path: 'faq', element: <FaqPage /> },
            { path: 'release-notes', element: <ReleaseNotesPage /> }
          ]
        },
        {
          path: 'help',
          element: (
            <ProtectedRoute>
              <Outlet />
            </ProtectedRoute>
          ),
          children: [
            { path: 'privacy', element: <PrivacyPage /> },
            { path: 'terms-of-use', element: <TermsOfUsePage /> }
          ]
        },
        {
          path: 'library',
          element: (
            <ProtectedRoute>
              <Outlet />
            </ProtectedRoute>
          ),
          children: [
            {
              path: '',
              element: (
                <Navigate to={config.links.library.wholesaleMarket} replace />
              )
            },
            {
              path: 'wholesale-market',
              children: [
                { path: '', element: <WholesaleMarketPage /> },
                { path: ':id', element: <WholesaleMarketDetailsPage /> }
              ]
            },
            {
              path: 'finances',
              children: [
                { path: '', element: <FinancesPage /> },
                { path: ':id', element: <FinancesDetailsPage /> }
              ]
            },
            {
              path: 'income-adjustment',
              children: [
                { path: '', element: <IncomeAdjustmentPage /> },
                { path: ':id', element: <IncomeAdjustmentDetailsPage /> }
              ]
            },
            {
              path: 'loss-factors',
              children: [
                { path: '', element: <LossFactorsPage /> },
                { path: ':id', element: <LossFactorsDetailsPage /> }
              ]
            },
            {
              path: 'production-profiles',
              children: [
                { path: '', element: <ProductionProfilesPage /> },
                { path: ':id', element: <ProductionProfilesDetailsPage /> }
              ]
            },
            {
              path: 'other-bess-inputs',
              children: [
                { path: '', element: <OtherBessInputsPage /> },
                { path: ':id', element: <OtherBessInputsDetailsPage /> }
              ]
            }
          ]
        },
        {
          path: 'profile',
          element: (
            <ProtectedRoute>
              <ProfilePage />
            </ProtectedRoute>
          )
        },
        {
          path: 'api-management',
          element: (
            <ProtectedRoute>
              <ApiManagementPage />
            </ProtectedRoute>
          )
        },
        {
          path: 'administration',
          element: (
            <ProtectedRoute isAdminRequired>
              <AdministrationPage />
            </ProtectedRoute>
          )
        },
        {
          path: 'organizations/:id',
          element: (
            <ProtectedRoute>
              <OrganizationManagementPage />
            </ProtectedRoute>
          )
        }
      ]
    },
    { path: '*', element: <Navigate to="/" replace /> }
  ])
  return <RouterProvider router={routes} />
}
