import { Button, ButtonToggleGroup } from '@veracity/vui'

import { ProjectsPreviewType } from '../types'

interface Props {
  value: ProjectsPreviewType
  onChange: (value: ProjectsPreviewType) => void
}

export const ProjectsListPreviewSelector = ({ value, onChange }: Props) => (
  <ButtonToggleGroup outline="none">
    {/*<Button icon="falMapMarked" isActive={value === ProjectsPreviewType.MAP} onClick={() => onChange(ProjectsPreviewType.MAP)} />*/}
    <Button
      size="lg"
      icon="falGrid"
      isActive={value === ProjectsPreviewType.GRID}
      onClick={() => onChange(ProjectsPreviewType.GRID)}
      data-qa="projects-preview-selector-grid"
    />
    <Button
      size="lg"
      icon="falList"
      isActive={value === ProjectsPreviewType.TABLE}
      onClick={() => onChange(ProjectsPreviewType.TABLE)}
      data-qa="projects-preview-selector-table"
    />
  </ButtonToggleGroup>
)
