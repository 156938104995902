import { Box, Button, Icon, Menu } from '@veracity/vui'

import { OrganizationSimple } from '@/interfaces'

interface Props {
  userId: string
  allOrganizations: OrganizationSimple[]
  onAddToOrganizationClicked: (userId: string, organizationId: number) => void
  onRemoveUserClicked: (userId: string) => void
}

export const GuestUsersTableActionColumn = ({
  userId,
  allOrganizations,
  onAddToOrganizationClicked,
  onRemoveUserClicked
}: Props) => {
  return (
    <Box gap={2}>
      <Menu>
        <Menu.Button icon="falUserPlus" isDropDown></Menu.Button>
        <Menu.List>
          {allOrganizations.map(organization => (
            <Menu.Item
              key={organization.id}
              onClick={() =>
                onAddToOrganizationClicked(userId, organization.id)
              }>
              {organization.name}
            </Menu.Item>
          ))}
        </Menu.List>
      </Menu>
      <Button
        variant="tertiaryDark"
        onClick={() => onRemoveUserClicked(userId)}>
        <Icon name="falUserTimes" pathFill="energyRed.main" />
      </Button>
    </Box>
  )
}
