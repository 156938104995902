import { PropsWithChildren, ReactNode } from 'react'
import { Box } from '@veracity/vui'
import { BreadcrumbContainer } from './BreadcrumbContainer'
import { PageLoader } from '../PageLoader'

interface Props {
  header: ReactNode
  menuBar?: ReactNode
  secondMenuBar?: ReactNode
  loading?: boolean
}

const PageContainerBase = ({
  header,
  menuBar,
  secondMenuBar,
  loading,
  children
}: PropsWithChildren<Props>) => (
  <Box column h="100%" overflowY="auto" pb={2}>
    <Box mx={5} my={4}>
      {header}
    </Box>

    {!menuBar ? null : (
      <Box px={5} py={2} bg="white">
        {menuBar}
      </Box>
    )}

    {!secondMenuBar ? null : (
      <Box mt={1} px={5} py={2} bg="white">
        {secondMenuBar}
      </Box>
    )}

    <Box column px={5} pt={3} w="100%">
      {loading ? <PageLoader /> : children}
    </Box>
  </Box>
)

export const PageContainer = PageContainerBase as typeof PageContainerBase & {
  Breadcrumbs: typeof BreadcrumbContainer
}

PageContainer.Breadcrumbs = BreadcrumbContainer
