import { Box, Card, Divider, Grid, T } from '@veracity/vui'
import { Control } from 'react-hook-form'

import {
  InputFormControl,
  InputPercentageFormControl,
  TooltipIcon
} from '@/components'

import {
  AcLineEfficiencyIcon,
  BatteryIcon,
  DcAcInverterIcon,
  DcLineEfficiencyIcon,
  PowerLossIcon,
  TransformerIcon
} from './icons'
import { ScenarioDetailsFormValues } from '../../../types'
import { ScenarioDetailsContentDiagramFormLabel } from './ScenarioDetailsContentDiagramFormLabel'

interface Props {
  scenarioId: number
  formControl: Control<ScenarioDetailsFormValues>
  isFormDisabled: boolean
}

export const ScenarioDetailsContentDiagramFormStandalone = ({
  scenarioId,
  formControl,
  isFormDisabled
}: Props) => (
  <Box column>
    <Grid
      gridTemplateColumns="6fr 3fr 3em 13fr"
      gridTemplateRows="1.5rem 5px 1.5rem">
      <Card
        p={2}
        bg="darkBlue.main"
        justifyContent="center"
        gridArea="1 / 1 / 4 / 2">
        <T variant="label" color="white">
          POI Bus
        </T>
      </Card>

      <Divider width={3} borderColor="sandstone.5" gridArea="2 / 2 / 3 / 5" />

      <Box column align="center" gridArea="3 / 3 / 4 / 4">
        <Divider isVertical width={2} borderColor="sandstone.5" />
      </Box>
    </Grid>

    <Grid gridTemplateColumns="9fr 3em 13fr">
      <div />

      <Box column align="center">
        <Divider isVertical width={2} borderColor="sandstone.5" />
      </Box>

      <Box column pt={1} w="100%" pl={2}>
        <T>Energy Capacity at POI</T>
        <InputFormControl
          useOnBlurAsChange
          control={formControl}
          formControlName="energyCapacityAtPoi"
          type="number"
          size="md"
          suffix="MWh"
          min={0}
          max={10_000}
          w="14rem"
          maxW="100%"
          disabled={isFormDisabled}
          rules={{
            required: true,
            min: 0,
            max: 10_000
          }}
        />
      </Box>
    </Grid>

    <Grid gridTemplateColumns="9fr 3em 13fr">
      <div />

      <Box column align="center">
        <Divider isVertical width={2} borderColor="sandstone.5" />
      </Box>

      <Box column pt={1} w="100%" pl={2}>
        <T>Power Capacity at POI</T>
        <InputFormControl
          useOnBlurAsChange
          control={formControl}
          formControlName="installedPowerCapacity"
          type="number"
          size="md"
          suffix="MW"
          min={0}
          max={1_000}
          w="14rem"
          maxW="100%"
          disabled={isFormDisabled}
          rules={{
            required: true,
            min: 0,
            max: 1_000
          }}
        />
      </Box>
    </Grid>

    <Grid gridTemplateColumns="9fr 3em 13fr">
      <div />

      <Box column align="center" justifyContent="center">
        <TransformerIcon />
      </Box>

      <Box column pt={1} w="100%" pl={2}>
        <ScenarioDetailsContentDiagramFormLabel
          scenarioId={scenarioId}
          currentValueKey="acMainTransformerEfficiency"
          originalValueKey="defaultAcMainTransformerEfficiency"
          formControl={formControl}
          isPercentage>
          <T>AC Main Transformer Efficiency</T>
        </ScenarioDetailsContentDiagramFormLabel>

        <InputPercentageFormControl
          useOnBlurAsChange
          control={formControl}
          formControlName="acMainTransformerEfficiency"
          size="md"
          w="14rem"
          maxW="100%"
          disabled={isFormDisabled}
          rules={{
            required: true,
            min: 0,
            max: 100
          }}
        />
      </Box>
    </Grid>

    <Grid
      gridTemplateColumns={{
        xs: '1fr 3fr 5fr 3em 9fr 4fr',
        xl: '2fr 2fr 5fr 3em 6fr 7fr'
      }}>
      <div />
      <Box column align="center" justifyContent="center" alignItems="flex-end">
        <PowerLossIcon />
      </Box>

      <Box column justify="center" mr="-20px">
        <Divider width={2} borderColor="earth.50" />
      </Box>

      <Box column align="center">
        <Divider isVertical width={2} borderColor="sandstone.5" />
      </Box>
    </Grid>

    <Grid
      gridTemplateColumns={{
        xs: '0fr 8fr 1fr 3em 13fr',
        lg: '1fr 7fr 1fr 3em 13fr'
      }}>
      <div />
      <Box column pt={1} w="100%" pl={2}>
        <ScenarioDetailsContentDiagramFormLabel
          scenarioId={scenarioId}
          currentValueKey="auxiliaryPowerLoss"
          originalValueKey="defaultAuxiliaryPowerLoss"
          formControl={formControl}
          isPercentage>
          <T isTruncated>ES Auxiliary Power Loss</T>
          <TooltipIcon.Info tooltipText="BESS Auxiliary supply may be separately fed" />
        </ScenarioDetailsContentDiagramFormLabel>

        <InputPercentageFormControl
          useOnBlurAsChange
          control={formControl}
          formControlName="auxiliaryPowerLoss"
          size="md"
          w="14rem"
          maxW="100%"
          disabled={isFormDisabled}
          rules={{
            required: true,
            min: 0,
            max: 100
          }}
        />
      </Box>
      <div />
      <Box column align="center" justifyContent="center">
        <AcLineEfficiencyIcon />
      </Box>
      <Box column pt={1} w="100%" pl={2}>
        <ScenarioDetailsContentDiagramFormLabel
          scenarioId={scenarioId}
          currentValueKey="acLineEfficiency"
          originalValueKey="defaultAcLineEfficiency"
          formControl={formControl}
          isPercentage>
          <T>AC Line Efficiency</T>
        </ScenarioDetailsContentDiagramFormLabel>

        <InputPercentageFormControl
          useOnBlurAsChange
          control={formControl}
          formControlName="acLineEfficiency"
          size="md"
          w="14rem"
          maxW="100%"
          disabled={isFormDisabled}
          rules={{
            required: true,
            min: 0,
            max: 100
          }}
        />
      </Box>
    </Grid>

    {/*Separator*/}
    <Grid gridTemplateColumns="9fr 3em 13fr" h="1.5rem">
      <div />
      <Box column align="center">
        <Divider isVertical width={2} borderColor="sandstone.5" />
      </Box>
    </Grid>

    <Grid gridTemplateColumns="9fr 3em 13fr">
      <div />

      <Box column align="center" justifyContent="center">
        <TransformerIcon />
      </Box>

      {/*<Box column pt={1} w="100%" pl={2}>*/}
      {/*  <T isTruncated title="AC Medium Voltage Transformer Efficiency">*/}
      {/*    AC Medium Voltage Transformer Efficiency*/}
      {/*  </T>*/}

      {/*  <InputPercentageFormControl*/}
      {/*    useOnBlurAsChange*/}
      {/*    control={formControl}*/}
      {/*    formControlName="acMediumVoltageTransformerEfficiency"*/}
      {/*    size="md"*/}
      {/*    w="14rem"*/}
      {/*    maxW="100%"*/}
      {/*    disabled={isFormDisabled}*/}
      {/*    rules={{*/}
      {/*      required: true,*/}
      {/*      min: 0,*/}
      {/*      max: 100*/}
      {/*    }}*/}
      {/*  />*/}
      {/*</Box>*/}
      {/*TODO: Remove below div when uncomment */}
      <div />
    </Grid>

    {/*Separator*/}
    <Grid gridTemplateColumns="9fr 3em 13fr" h="1.5rem">
      <div />
      <Box column align="center">
        <Divider isVertical width={2} borderColor="sandstone.5" />
      </Box>
    </Grid>

    <Grid gridTemplateColumns="9fr 3em 13fr">
      <div />

      <Box column align="center" justifyContent="center">
        <DcAcInverterIcon />
      </Box>

      <Box column pt={1} w="100%" pl={2}>
        <ScenarioDetailsContentDiagramFormLabel
          scenarioId={scenarioId}
          currentValueKey="dCACInverterEfficiency"
          originalValueKey="defaultDCACInverterEfficiency"
          formControl={formControl}
          isPercentage>
          <T>ES DC-AC Inverter Efficiency</T>
        </ScenarioDetailsContentDiagramFormLabel>

        <InputPercentageFormControl
          useOnBlurAsChange
          control={formControl}
          formControlName="dCACInverterEfficiency"
          size="md"
          w="14rem"
          maxW="100%"
          disabled={isFormDisabled}
          rules={{
            required: true,
            min: 0,
            max: 100
          }}
        />
      </Box>
    </Grid>

    {/*Separator*/}
    <Grid gridTemplateColumns="9fr 3em 13fr" h="1.5rem">
      <div />
      <Box column align="center">
        <Divider isVertical width={2} borderColor="sandstone.5" />
      </Box>
    </Grid>

    <Grid gridTemplateColumns="9fr 3em 13fr">
      <div />

      <Box column align="center" justifyContent="center">
        <DcLineEfficiencyIcon />
      </Box>

      <Box column pt={1} w="100%" pl={2}>
        <ScenarioDetailsContentDiagramFormLabel
          scenarioId={scenarioId}
          currentValueKey="dcLineEfficiency"
          originalValueKey="defaultDCLineEfficiency"
          formControl={formControl}
          isPercentage>
          <T>ES DC one-way efficiency</T>
        </ScenarioDetailsContentDiagramFormLabel>

        <InputPercentageFormControl
          useOnBlurAsChange
          control={formControl}
          formControlName="dcLineEfficiency"
          size="md"
          w="14rem"
          maxW="100%"
          disabled={isFormDisabled}
          rules={{
            required: true,
            min: 0,
            max: 100
          }}
        />
      </Box>
    </Grid>

    {/*Separator*/}
    <Grid gridTemplateColumns="9fr 3em 13fr" h="1.5rem">
      <div />
      <Box column align="center">
        <Divider isVertical width={2} borderColor="sandstone.5" />
      </Box>
    </Grid>

    <Grid gridTemplateColumns="9fr 3em 13fr">
      <div />

      <Box column align="center">
        <Divider isVertical width={2} borderColor="sandstone.5" />
      </Box>

      <Box column pt={1} w="100%" pl={2}>
        <T>Installed Energy Capacity</T>
        <InputFormControl
          useOnBlurAsChange
          control={formControl}
          formControlName="installedEnergyCapacity"
          type="number"
          size="md"
          suffix="MWh"
          min={0}
          max={10_000}
          w="14rem"
          maxW="100%"
          disabled={isFormDisabled}
          rules={{
            required: true,
            min: 0,
            max: 10_000
          }}
        />
      </Box>
    </Grid>

    <Grid gridTemplateColumns="9fr 3em 13fr">
      <div />

      <Box column align="center" justifyContent="center">
        <BatteryIcon />
      </Box>

      <Box column pt={1} w="100%" pl={2}>
        <T>Installed Power Capacity</T>

        <InputFormControl
          useOnBlurAsChange
          control={formControl}
          formControlName="installedPowerCapacity"
          type="number"
          size="md"
          suffix="MW"
          min={0}
          max={1_000}
          w="14rem"
          maxW="100%"
          disabled={isFormDisabled}
          rules={{
            required: true,
            min: 0,
            max: 1_000
          }}
        />
      </Box>
    </Grid>
  </Box>
)
