import { useToast } from '@veracity/vui'
import { useMutation, useQuery, useQueryClient } from 'react-query'

import { portfoliosClient, projectsClient } from '@/api'
import { UpdateLinkedProjectsRequest } from '@/interfaces'
import { useNavigate } from 'react-router-dom'

const BASE_QUERY_KEY = portfoliosClient.QUERY_KEY
const BASE_ROUTE = '/portfolios'
export const usePortfolioDetails = (portfolioId: number) => {
  const QUERY_KEY = [...portfoliosClient.QUERY_KEY, portfolioId]
  const navigate = useNavigate()
  const { showSuccess } = useToast()
  const queryClient = useQueryClient()

  const { data, isFetching } = useQuery(
    QUERY_KEY,
    () => portfoliosClient.getById(portfolioId || 0),
    { enabled: !!portfolioId }
  )

  const projectsQuery = useQuery(
    projectsClient.QUERY_KEY,
    projectsClient.getAll
  )

  const linkProjectsMutation = useMutation({
    mutationFn: (req: UpdateLinkedProjectsRequest) =>
      portfoliosClient.updateLinkedProjects(portfolioId || 0, req),
    onSuccess: async () => {
      showSuccess('Portfolio successfully updated')
      await queryClient.invalidateQueries(BASE_QUERY_KEY, {
        refetchInactive: true
      })
    }
  })

  const removePortfolioMutation = useMutation({
    mutationFn: () => portfoliosClient.removeById(portfolioId),
    onSuccess: async () => {
      showSuccess('Portfolio successfully removed')
      navigate(BASE_ROUTE)
      await queryClient.invalidateQueries(BASE_QUERY_KEY, {
        refetchInactive: true
      })
    }
  })

  return {
    portfolioDetails: data,
    isFetching: isFetching,
    linkProjects: linkProjectsMutation.mutate,
    isLinkProjectsInProgress: linkProjectsMutation.isLoading,
    linkedProjectsIds: data?.projects?.map(x => x.id),
    allProjects: projectsQuery.data || [],
    removePortfolio: removePortfolioMutation.mutate,
    isRemovePortfolioInProgress: removePortfolioMutation.isLoading
  }
}
