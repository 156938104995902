import { ReactNode } from 'react'
import { Box, Button, Heading, T, Tag } from '@veracity/vui'

export interface PageFilterPanelPreviewItem {
  label: string
  onFilterClear: () => void
}

interface Props {
  isFilterPanelActive: boolean
  onCloseClick: () => void
  filtersSlot: ReactNode
  onClearAllFiltersClicked: () => void
  filterPreviewItems: PageFilterPanelPreviewItem[]
}
export const PageFilterPanel = ({
  isFilterPanelActive,
  onCloseClick,
  onClearAllFiltersClicked,
  filtersSlot,
  filterPreviewItems
}: Props) => (
  <Box column pb={3}>
    {isFilterPanelActive && (
      <Box column p={1}>
        <Box justify="space-between">
          <Heading size="h3">Filter</Heading>
          <Button
            size="lg"
            variant="tertiaryDark"
            icon="falTimes"
            onClick={onCloseClick}
          />
        </Box>

        <Box gap={2}>
          {filtersSlot}

          <Button
            ml={3}
            variant="tertiaryDark"
            iconRight="falTimes"
            onClick={onClearAllFiltersClicked}>
            Clear all
          </Button>
        </Box>
      </Box>
    )}

    {!!filterPreviewItems.length && (
      <Box py={2} gap={2} alignItems="center">
        <T variant="label">Applied filters:</T>

        {filterPreviewItems.map((item, index) => (
          <Tag key={index} text={item.label} onDelete={item.onFilterClear} />
        ))}
      </Box>
    )}
  </Box>
)
