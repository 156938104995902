import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Box, Button, useDialog } from '@veracity/vui'
import { useToggle } from '@veracity/ui-utils'

import {
  PageContainer,
  PageSearchInput,
  ScenarioComparisonDialog,
  ToggleFilterButton
} from '@/components'

import { useProjectScenarioFilter, useProjectsDetails } from './hooks'
import { ProjectScenarioFilterValues } from './types'
import {
  ProjectDetailsAddScenarioDialog,
  ProjectDetailsInfoBox,
  ProjectDetailsScenariosFilterPanel,
  ProjectDetailsUpdateDialog,
  ProjectListScenariosCollection
} from './components'

export const ProjectDetailsPage = () => {
  const navigate = useNavigate()
  const { showConfirm } = useDialog()
  const { projectId: projectIdString } = useParams()
  const projectId = +(projectIdString || '')

  const {
    projectInfo,
    isFetching,
    removeProject,
    addScenario,
    isAddScenarioInProgress,
    updateProject,
    isUpdateProjectInProgress
  } = useProjectsDetails(projectId)

  const [searchValue, setSearchValue] = useState('')
  const [scenarioFilterValues, setScenarioFilterValues] =
    useState<ProjectScenarioFilterValues>()

  const [isFilterActive, toggleIsFilterActive] = useToggle(false)

  const filteredScenarios = useProjectScenarioFilter(
    projectInfo?.scenarios || [],
    searchValue,
    scenarioFilterValues
  )
  const handleRemoveProject = async () => {
    const result = await showConfirm(
      'Remove project',
      'This operation is irreversible. Are you sure?'
    )
    if (result) removeProject()
  }

  const handleBatchDesignerClick = () => {
    navigate(`/projects/${projectId}/batch-wizard`)
  }

  const validateProjectScenarioName = (val: string) =>
    !projectInfo?.scenarios?.some(
      x => x.name?.toLocaleLowerCase() === val.toLocaleLowerCase()
    )

  return (
    <PageContainer
      loading={isFetching}
      header={
        <PageContainer.Breadcrumbs
          items={[
            { title: 'Projects', path: '/projects' },
            { title: projectInfo?.name || '' }
          ]}
        />
      }
      menuBar={
        <Box justify="space-between" w="100%">
          <Box gap={2}>
            <PageSearchInput onChange={setSearchValue} />
            <ToggleFilterButton
              isActive={isFilterActive}
              onClick={toggleIsFilterActive}
            />
          </Box>
          <Box gap={2}>
            <ProjectDetailsUpdateDialog
              currentName={projectInfo?.name || ''}
              onUpdateProject={updateProject}
              isUpdateProjectInProgress={isUpdateProjectInProgress}
            />
            <Button
              icon="falTrashAlt"
              size="lg"
              variant="tertiaryDark"
              onClick={handleRemoveProject}
            />
            <ScenarioComparisonDialog selectedProjectId={projectId} />
            <ProjectDetailsAddScenarioDialog
              onAddScenario={addScenario}
              isAddScenarioInProgress={isAddScenarioInProgress}
              validateScenarioName={validateProjectScenarioName}
              onBatchDesignerClicked={handleBatchDesignerClick}
            />
          </Box>
        </Box>
      }>
      <ProjectDetailsScenariosFilterPanel
        isFilterActive={isFilterActive}
        onFilterCloseClicked={toggleIsFilterActive.off}
        onFilterChange={setScenarioFilterValues}
      />

      <ProjectDetailsInfoBox projectInfo={projectInfo} />

      <ProjectListScenariosCollection
        scenarios={filteredScenarios}
        validateNameDuplication={validateProjectScenarioName}
      />
    </PageContainer>
  )
}
