import { Box, Message, Progress } from '@veracity/vui'

import { useScenarioCalculationProgress } from '../../hooks'

interface Props {
  scenarioId: number
}

export const ScenarioDetailsMenuBarCalculationProgress = ({
  scenarioId
}: Props) => {
  const { data } = useScenarioCalculationProgress(scenarioId)

  const isPreProcessing = !!data?.preprocessingProgress

  const progressValue = isPreProcessing
    ? data?.preprocessingProgress
    : data?.progress

  return (
    <Box column gap={2} p={1} pt={4}>
      <Progress
        size="lg"
        showPercentage
        value={progressValue}
        variant={isPreProcessing ? 'green' : 'primary'}
      />

      {!!data?.message && <Message>{data?.message}</Message>}
    </Box>
  )
}
