import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useToast } from '@veracity/vui'

import { organizationClient } from '@/api'

const BASE_QUERY_KEY = organizationClient.QUERY_KEY

export const useOrganizations = () => {
  const queryClient = useQueryClient()
  const { showSuccess } = useToast()

  const getOrganizationsQuery = useQuery(
    BASE_QUERY_KEY,
    organizationClient.getOrganizations
  )

  const addOrganizationMutation = useMutation({
    mutationFn: organizationClient.addOrganization,
    onSuccess: async () => {
      await queryClient.invalidateQueries(BASE_QUERY_KEY)
      showSuccess('Organization successfully created')
    }
  })

  return {
    organizations: getOrganizationsQuery.data || [],
    isLoadingOrganizations: getOrganizationsQuery.isLoading,
    addOrganization: addOrganizationMutation.mutate,
    isAddOrganizationInProgress: addOrganizationMutation.isLoading
  }
}
