import { useQuery } from 'react-query'

import { otherBessInputsLibraryClient } from '@/api'

export const useOtherBessInputsDatasetNames = () => {
  const { data } = useQuery(
    [...otherBessInputsLibraryClient.QUERY_KEY, { lockedOnly: false }],
    () => otherBessInputsLibraryClient.getAll()
  )

  return data?.map(x => x.name || '').filter(Boolean) || []
}
