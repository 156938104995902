import { useEffect, useState } from 'react'
import { FieldValues, useForm } from 'react-hook-form'
import { Box, Button, Dialog } from '@veracity/vui'

import { InputFormControl } from '@/components'
import { CloneScenarioRequest } from '@/interfaces'

interface Props {
  baseScenarioName?: string
  isCloneInProgress: boolean
  onClone: (req: CloneScenarioRequest, onSuccess: () => void) => void
}

export const ScenarioDetailsMenuBarCloneDialog = ({
  baseScenarioName,
  onClone,
  isCloneInProgress
}: Props) => {
  const [isVisible, setIsVisible] = useState(false)
  const { control, reset, handleSubmit } = useForm({
    defaultValues: { name: '' }
  })

  useEffect(
    () => reset({ name: `${baseScenarioName} - Clone` }),
    [baseScenarioName, reset]
  )

  const handleSubmitClick = (val: FieldValues) =>
    onClone({ name: val.name }, handleClose)

  const handleClose = () => {
    if (isCloneInProgress) return
    setIsVisible(false)
    reset()
  }

  return (
    <>
      <Button
        icon="falCopy"
        size="lg"
        variant="tertiaryDark"
        disabled={isCloneInProgress}
        isLoading={isCloneInProgress}
        onClick={() => setIsVisible(true)}
      />

      <Dialog
        isOpen={isVisible}
        title="Clone Scenario"
        size="lg"
        onClose={handleClose}
        cancelButton={{ text: 'Cancel', disabled: isCloneInProgress }}
        submitButton={{
          text: 'Clone',
          onClick: handleSubmit(handleSubmitClick),
          isLoading: isCloneInProgress,
          disabled: isCloneInProgress
        }}
        body={
          <Box column p={2}>
            <InputFormControl
              control={control}
              formControlName="name"
              label="Name"
              disabled={isCloneInProgress}
              rules={{ required: true }}
            />
          </Box>
        }
      />
    </>
  )
}
