import { useState } from 'react'
import { useToggle } from '@veracity/ui-utils'
import { Box, Button, P, useDialog } from '@veracity/vui'
import { useRouteIdParam, useSystemDictionaries } from '@/hooks'
import {
  PageContainer,
  PageSearchInput,
  ToggleFilterButton
} from '@/components'

import { usePortfolioDetails, useProjectsListFilter } from './hooks'
import {
  PortfolioDetailsFilterValues,
  PortfolioDetailsPreviewType
} from './types'
import {
  PortfolioDetailsCollectionPreview,
  PortfolioDetailsFilterPanel,
  PortfolioDetailsLinkProjectDialog,
  PortfolioDetailsPreviewSelector
} from './components'

export const PortfolioDetailsPage = () => {
  const portfolioId = useRouteIdParam('portfolioId') || 0
  const { showConfirm } = useDialog()
  const { portfolioDetails, isFetching, removePortfolio } =
    usePortfolioDetails(portfolioId)
  const { systemConfigurations } = useSystemDictionaries()

  const [isFilterActive, toggleIsFilterActive] = useToggle()
  const [searchValue, setSearchValue] = useState<string>('')
  const [selectedPreviewType, setSelectedPreviewType] = useState(
    PortfolioDetailsPreviewType.GRID
  )
  const [filterValues, setFilterValues] =
    useState<PortfolioDetailsFilterValues>()

  const filteredProjects = useProjectsListFilter(
    portfolioDetails?.projects || [],
    searchValue,
    filterValues
  )

  const handleDeletePortfolio = async () => {
    const result = await showConfirm(
      'Remove portfolio',
      'This operation is irreversible. Are you sure?'
    )
    if (result) removePortfolio()
  }

  return (
    <PageContainer
      loading={isFetching}
      header={
        <PageContainer.Breadcrumbs
          items={[
            { title: 'Portfolios', path: '/portfolios' },
            { title: portfolioDetails?.name || '' }
          ]}
        />
      }
      menuBar={
        <Box justify="space-between" w="100%" wrap gap={1}>
          <Box gap={1}>
            <PageSearchInput onChange={setSearchValue} />
            <ToggleFilterButton
              isActive={isFilterActive}
              onClick={toggleIsFilterActive}
            />
          </Box>
          <Box gap={1}>
            <Button
              icon="falTrashAlt"
              size="lg"
              variant="tertiaryDark"
              onClick={handleDeletePortfolio}
            />
            <PortfolioDetailsPreviewSelector
              value={selectedPreviewType}
              onChange={setSelectedPreviewType}
            />
            <PortfolioDetailsLinkProjectDialog portfolioId={portfolioId} />
          </Box>
        </Box>
      }>
      <P w="45rem" maxLines={3}>
        {portfolioDetails?.description}
      </P>

      <PortfolioDetailsFilterPanel
        isFilterActive={isFilterActive}
        onFilterCloseClicked={toggleIsFilterActive.off}
        onFilterChange={setFilterValues}
        availableSystemConfigurations={systemConfigurations}
      />

      <PortfolioDetailsCollectionPreview
        selectedPreviewType={selectedPreviewType}
        filteredProjects={filteredProjects}
      />
    </PageContainer>
  )
}
