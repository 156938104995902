import { Box } from '@veracity/vui'

import { useFilterDatasets } from '@/hooks'
import { PageContainer, PageSearchInput } from '@/components'

import { useWholesaleMarketDatasets } from './hooks'
import {
  AddWholesaleMarketDatasetDialog,
  WholesaleMarketDatasetsTable
} from './components'

export const WholesaleMarketPage = () => {
  const { wholesaleMarketDatasets, isFetching } = useWholesaleMarketDatasets()

  const { filteredDatasets, onFilterChange } = useFilterDatasets(
    wholesaleMarketDatasets
  )

  return (
    <PageContainer
      loading={isFetching}
      header={
        <PageContainer.Breadcrumbs
          items={[{ title: 'Library' }, { title: 'Wholesale market' }]}
        />
      }
      menuBar={
        <Box justify="space-between" w="100%">
          <PageSearchInput onChange={onFilterChange} />
          <AddWholesaleMarketDatasetDialog />
        </Box>
      }>
      <WholesaleMarketDatasetsTable datasets={filteredDatasets} />
    </PageContainer>
  )
}
