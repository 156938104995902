import axios from 'axios'

import { UpdatePropertyRequest, UserRoleSimple, UserSimple } from '@/interfaces'

const baseUrl = '/api/userAdministration'

const patchUser = (
  userId: string,
  propertyName: keyof UserSimple,
  propertyValue: any
) =>
  axios.patch<void>(`${baseUrl}/user/${userId}`, {
    propertyName: propertyName,
    propertyValue: propertyValue
  } as UpdatePropertyRequest)

export const userAdministrationClient = {
  QUERY_KEY: ['user', 'administration'],
  getGuestUsers: () =>
    axios
      .get<UserSimple[]>(`${baseUrl}/usersWithoutOrganization`)
      .then(x => x.data),
  removeUser: (userId: string) =>
    axios.delete<void>(`${baseUrl}/user/${userId}`).then(x => x.data),
  syncGuestUsersWithVeracity: () =>
    axios
      .post<void>(`${baseUrl}/synchronizeUsersWithVeracity`)
      .then(x => x.data),
  attachToOrganization: ({
    userId,
    organizationId
  }: {
    userId: string
    organizationId: number
  }) => patchUser(userId, 'employerId', organizationId).then(x => x.data),
  getOrganizationUsers: (organizationId: number) =>
    axios
      .get<UserSimple[]>(`${baseUrl}/UsersInOrganization/${organizationId}`)
      .then(x => x.data),
  getAvailableRoles: () =>
    axios.get<UserRoleSimple[]>(`${baseUrl}/Roles`).then(x => x.data),
  setUserStatus: ({
    userId,
    isEnabled
  }: {
    userId: string
    isEnabled: boolean
  }) => patchUser(userId, 'isEnabled', isEnabled).then(x => x.data),
  detachUserFromOrganization: (userId: string) =>
    patchUser(userId, 'employerId', null),
  setUserRole: ({ userId, roleId }: { userId: string; roleId: number }) =>
    axios.put<void>(`${baseUrl}/User/${userId}/Role/${roleId}`)
}
