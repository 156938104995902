import { Box } from '@veracity/vui'
import { useFilterDatasets } from '@/hooks'
import { PageContainer, PageSearchInput } from '@/components'

import { useOtherBessInputs } from './hooks'
import {
  AddOtherBessInputsDatasetDialog,
  OtherBessInputsDatasetsTable
} from './components'

export const OtherBessInputsPage = () => {
  const { datasets, isFetching } = useOtherBessInputs()
  const { filteredDatasets, onFilterChange } = useFilterDatasets(datasets)

  return (
    <PageContainer
      loading={isFetching}
      header={
        <PageContainer.Breadcrumbs
          items={[{ title: 'Library' }, { title: 'Other BESS Inputs' }]}
        />
      }
      menuBar={
        <Box justify="space-between" w="100%">
          <PageSearchInput onChange={onFilterChange} />
          <AddOtherBessInputsDatasetDialog />
        </Box>
      }>
      <OtherBessInputsDatasetsTable datasets={filteredDatasets} />
    </PageContainer>
  )
}
