import { Notification } from '@veracity/vui'

import { BessOperatingIncomeResult, SystemConfigurations } from '@/interfaces'

import { ScenarioResultsSummaryTabAdjustmentTableStandalone } from './ScenarioResultsSummaryTabAdjustmentTableStandalone'
import { ScenarioResultsSummaryTabAdjustmentTablePvBased } from './ScenarioResultsSummaryTabAdjustmentTablePvBased'

interface Props {
  systemConfiguration?: SystemConfigurations
  summaryResults: BessOperatingIncomeResult[]
}
export const ScenarioResultsSummaryTabAdjustmentTable = ({
  systemConfiguration,
  summaryResults
}: Props) => {
  if (!summaryResults.length)
    return (
      <Notification
        status="warning"
        title="Data Unavailable"
        text="Apologies, results are unavailable due to backward incompatibility."
        w="100%"
      />
    )

  switch (systemConfiguration) {
    case SystemConfigurations.Standalone:
      return (
        <ScenarioResultsSummaryTabAdjustmentTableStandalone
          items={summaryResults}
        />
      )
    case SystemConfigurations.SolarPV:
    case SystemConfigurations.WindOnShareBus:
    case SystemConfigurations.SolarPVOnSharedBus:
      return (
        <ScenarioResultsSummaryTabAdjustmentTablePvBased
          items={summaryResults}
        />
      )
    case SystemConfigurations.None:
    case SystemConfigurations.SolarPVAndAcCoupledWithWind:
    case SystemConfigurations.SolarPVAndWind:
    default:
      return null
  }
}
