import { useQuery } from 'react-query'
import { scenarioResultsClient } from '@/api'

export const useScenarioResultsSummary = (
  scenarioId: number,
  applyIncomeAdjustment: boolean
) => {
  const QUERY_KEY = [
    ...scenarioResultsClient.QUERY_KEY(scenarioId),
    'summary',
    { applyIncomeAdjustment }
  ]
  const query = useQuery(QUERY_KEY, () =>
    scenarioResultsClient.getResultsSummary(scenarioId, applyIncomeAdjustment)
  )

  return {
    summaryInfo: query.data,
    isFetching: query.isFetching && !query.isRefetching,
    isRefetching: query.isRefetching
  }
}
