import { useEffect, useState } from 'react'
import { Box, Button, Dialog } from '@veracity/vui'
import { FieldValues, useForm } from 'react-hook-form'

import { InputFormControl } from '@/components'

interface Props {
  isDisabled?: boolean
  baseScenarioName?: string
  isEditInProgress: boolean
  onEdit: (scenarioName: string, onSuccess: () => void) => void
}

export const ScenarioDetailsMenuBarEditDialog = ({
  isDisabled,
  isEditInProgress,
  onEdit,
  baseScenarioName
}: Props) => {
  const [isVisible, setIsVisible] = useState(false)
  const { control, reset, handleSubmit } = useForm({
    defaultValues: { name: '' }
  })

  useEffect(() => reset({ name: baseScenarioName }), [baseScenarioName, reset])

  const handleSubmitClick = (val: FieldValues) => onEdit(val.name, handleClose)

  const handleClose = () => {
    setIsVisible(false)
    reset()
  }

  return (
    <>
      <Button
        icon="falPen"
        size="lg"
        variant="tertiaryDark"
        disabled={isDisabled}
        onClick={() => setIsVisible(true)}
      />

      <Dialog
        isOpen={isVisible}
        title="Edit Scenario"
        size="lg"
        onClose={handleClose}
        cancelButton={{ text: 'Cancel', disabled: isEditInProgress }}
        submitButton={{
          text: 'Save',
          onClick: handleSubmit(handleSubmitClick),
          isLoading: isEditInProgress,
          disabled: isEditInProgress
        }}
        body={
          <Box column p={2}>
            <InputFormControl
              control={control}
              formControlName="name"
              label="Name"
              disabled={isEditInProgress}
              rules={{ required: true }}
            />
          </Box>
        }
      />
    </>
  )
}
