import { useQuery } from 'react-query'
import { SelectProps } from '@veracity/vui'

import { configurationClient } from '@/api'
import { DatasetFileTypes, SelectListItem } from '@/interfaces'

export const useSystemDictionaries = () => {
  const { data } = useQuery(
    [...configurationClient.QUERY_KEY, 'dictionaries'],
    configurationClient.getAllDictionaries,
    { retry: 0, refetchOnMount: false }
  )

  const mapSelectOptions = (selectItems?: SelectListItem[]) =>
    (selectItems?.map(item => ({
      text: item.text || '',
      value: +(item.value || ''),
      disabled: item.disabled
    })) as SelectProps['options']) || []

  const datasetFileTypes = [
    {
      text: 'Day-Ahead Energy',
      value: DatasetFileTypes.DayAheadEnergyPrices
    },
    {
      text: 'Real-Time Energy',
      value: DatasetFileTypes.RealTimeEnergyPrice
    },
    {
      text: 'Regulation Up',
      value: DatasetFileTypes.RegUpPrices
    },
    {
      text: 'Regulation Down',
      value: DatasetFileTypes.RegDownPrices
    },
    {
      text: 'Spinning Reserves',
      value: DatasetFileTypes.SpinningReservePrices
    }
  ]

  return {
    systemConfigurations: mapSelectOptions(data?.systemConfigurationsList),
    isoBaList: mapSelectOptions(data?.isoBaList),
    pvProductionSoftwareTypes: mapSelectOptions(
      data?.pvProductionSoftwareTypes
    ),
    taxDepreciationTypes: mapSelectOptions(data?.taxDepreciationTypes),
    runOptionsList: mapSelectOptions(data?.runOptionsList),
    datasetFileTypes
  }
}
