import { useState } from 'react'

import { PageContainer, PageSearchInput } from '@/components'

import { useFilterFaqItems } from './hooks'
import { FaqPageAccordion } from './components'

export const FaqPage = () => {
  const [searchValue, setSearchValue] = useState('')
  const [selectedItemId, setSelectedItemId] = useState(0)

  const items = useFilterFaqItems(searchValue)

  return (
    <PageContainer
      header={
        <PageContainer.Breadcrumbs
          items={[{ title: 'Frequently Asked Questions' }]}
        />
      }
      menuBar={<PageSearchInput onChange={setSearchValue} />}>
      <FaqPageAccordion
        items={items}
        selectedItemId={selectedItemId}
        onSelectionChanged={setSelectedItemId}
      />
    </PageContainer>
  )
}
