import { Grid, T } from '@veracity/vui'
import { Control, FieldValues } from 'react-hook-form'

import {
  ProductionProfileSimple,
  ProductionProfileTypes,
  SourceTypes
} from '@/interfaces'
import { useSystemDictionaries } from '@/hooks'
import {
  FileUploadFormControl,
  InputPercentageFormControl,
  SelectFormControl
} from '@/components'

interface Props {
  datasetInfo?: ProductionProfileSimple
  formControl: Control
  isSubmitInProgress: boolean
  formValues: FieldValues
  getDownloadFileUrl: (datasetFileId?: number) => string | undefined
}

export const ProductionProfilesDetailsForm = ({
  datasetInfo,
  formControl,
  isSubmitInProgress,
  formValues,
  getDownloadFileUrl
}: Props) => {
  const { pvProductionSoftwareTypes } = useSystemDictionaries()
  const isFormDisabled =
    datasetInfo?.isLocked ||
    datasetInfo?.isDefault ||
    datasetInfo?.isArchive ||
    isSubmitInProgress

  const isAnnualDeratingEnabled =
    formValues.productionProfileType === ProductionProfileTypes.AnnualProduction

  const isWindSelected = formValues.sourceType === SourceTypes.Wind

  return (
    <Grid gap={5} py={4} gridTemplateColumns="4fr 3fr" maxW={800}>
      <T>Renewable Source</T>
      <SelectFormControl
        control={formControl}
        formControlName="sourceType"
        size="md"
        readOnly={isFormDisabled}
        rules={{ required: true }}
        options={[
          { text: 'Solar PV', value: SourceTypes.Solar },
          { text: 'Wind', value: SourceTypes.Wind }
        ]}
      />

      <T>Production Profile Type</T>
      <SelectFormControl
        control={formControl}
        formControlName="productionProfileType"
        size="md"
        readOnly={isFormDisabled}
        rules={{ required: true }}
        options={[
          { text: 'Lifetime', value: ProductionProfileTypes.Lifetime },
          { text: 'Annual', value: ProductionProfileTypes.AnnualProduction }
        ]}
      />

      <T>Annual Derating</T>

      {isWindSelected ? (
        <FileUploadFormControl
          control={formControl}
          formControlName="windAnnualDeratingFile"
          downloadFileUrl={getDownloadFileUrl(
            datasetInfo?.windAnnualDeratingFileId
          )}
          uploadedFileName={datasetInfo?.windAnnualDeratingFileName}
          disabled={isFormDisabled}
          rules={{ required: !datasetInfo?.windAnnualDeratingFileId }}
        />
      ) : (
        <InputPercentageFormControl
          control={formControl}
          formControlName="annualDerating"
          size="md"
          disabled={!isAnnualDeratingEnabled}
          readOnly={isFormDisabled}
          rules={{
            required: isAnnualDeratingEnabled,
            min: 0,
            max: 100
          }}
        />
      )}

      {!isWindSelected && (
        <>
          <T>Production Software Type</T>
          <SelectFormControl
            control={formControl}
            formControlName="pvProductionSoftwareType"
            size="md"
            readOnly={isFormDisabled}
            rules={{ required: true }}
            options={pvProductionSoftwareTypes}
          />
        </>
      )}

      <T>
        {isAnnualDeratingEnabled
          ? 'Annual Production Profile'
          : 'Lifetime Production Profile'}
      </T>

      <FileUploadFormControl
        control={formControl}
        formControlName="productionProfileFile"
        downloadFileUrl={getDownloadFileUrl(
          datasetInfo?.productionProfileFileId
        )}
        uploadedFileName={datasetInfo?.productionProfileFileName}
        disabled={isFormDisabled}
        rules={{ required: !datasetInfo?.productionProfileFileId }}
      />

      {isWindSelected && (
        <>
          <T>PTC Price</T>

          <FileUploadFormControl
            control={formControl}
            formControlName="windPTCPriceFile"
            downloadFileUrl={getDownloadFileUrl(
              datasetInfo?.windPTCPriceFileId
            )}
            uploadedFileName={datasetInfo?.windPTCPriceFileName}
            disabled={isFormDisabled}
            rules={{ required: !datasetInfo?.windPTCPriceFileId }}
          />
        </>
      )}
    </Grid>
  )
}
