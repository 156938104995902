import { Box, Grid, Heading, T } from '@veracity/vui'
import { Control, FieldValues } from 'react-hook-form'

import { InputPercentageFormControl } from '@/components'
import { LossFactorsSimple } from '@/interfaces'

import { LossFactorsDetailsPreviewCard } from './LossFactorsDetailsPreviewCard'

interface Props {
  datasetInfo?: LossFactorsSimple
  formControl: Control
  formValues: FieldValues
  isSubmitInProgress: boolean
}

export const LossFactorsDetailsForm = ({
  datasetInfo,
  formControl,
  formValues,
  isSubmitInProgress
}: Props) => {
  const isFormDisabled =
    datasetInfo?.isLocked ||
    datasetInfo?.isDefault ||
    datasetInfo?.isArchive ||
    isSubmitInProgress

  return (
    <Box column w={{ xxl: '80%', _: '100%' }}>
      <Heading size="h3" weight="medium">
        System Level Losses
      </Heading>

      <Grid
        gap={5}
        py={4}
        gridTemplateColumns={{
          xs: 'repeat(1, 4fr 3fr)',
          sm: 'repeat(2, 4fr 3fr)'
        }}>
        <T>Ac Line Efficiency</T>
        <InputPercentageFormControl
          control={formControl}
          formControlName="acLineEfficiency"
          size="md"
          readOnly={isFormDisabled}
          rules={{ required: true, min: 0, max: 100 }}
        />
        <T>AC Main Transformer Efficiency</T>
        <InputPercentageFormControl
          control={formControl}
          formControlName="acMainTransformerEfficiency"
          size="md"
          readOnly={isFormDisabled}
          rules={{ required: true, min: 0, max: 100 }}
        />
      </Grid>

      <Heading size="h3" weight="medium">
        Battery Energy Storage System (BESS)
      </Heading>

      <Grid
        gap={5}
        py={4}
        gridTemplateColumns={{
          xs: '1fr 1fr',
          lg: '4fr 3fr 4fr 3fr'
        }}>
        <T>Charge/Discharge Efficiency</T>
        <InputPercentageFormControl
          control={formControl}
          formControlName="storageChargeDischargeEfficiency"
          size="md"
          rules={{ required: true, min: 0, max: 100 }}
          readOnly={isFormDisabled}
        />

        <T>ES DC one-way efficiency</T>
        <InputPercentageFormControl
          control={formControl}
          formControlName="dcLineEfficiency"
          size="md"
          rules={{ required: true, min: 0, max: 100 }}
          readOnly={isFormDisabled}
        />

        <T>DC-DC Converter Efficiency</T>
        <InputPercentageFormControl
          control={formControl}
          formControlName="dcDcConverterEfficiency"
          size="md"
          rules={{ required: true, min: 0, max: 100 }}
          readOnly={isFormDisabled}
        />

        <T>DC-AC Inverter Efficiency</T>
        <InputPercentageFormControl
          control={formControl}
          formControlName="dcAcInverterEfficiency"
          size="md"
          rules={{ required: true, min: 0, max: 100 }}
          readOnly={isFormDisabled}
        />

        <T>Auxiliary Power Loss</T>
        <InputPercentageFormControl
          control={formControl}
          formControlName="auxiliaryPowerLoss"
          size="md"
          rules={{ required: true, min: 0, max: 100 }}
          readOnly={isFormDisabled}
        />

        <T>Initial State of Charge</T>
        <InputPercentageFormControl
          control={formControl}
          formControlName="initialStateOfCharge"
          size="md"
          rules={{ required: true, min: 0, max: 100 }}
          readOnly={isFormDisabled}
        />

        <T>Minimum State of Charge</T>
        <InputPercentageFormControl
          control={formControl}
          formControlName="minimumStateOfCharge"
          size="md"
          rules={{ required: true, min: 0, max: 100 }}
          readOnly={isFormDisabled}
        />

        <T>Maximum State of Charge</T>
        <InputPercentageFormControl
          control={formControl}
          formControlName="maximumStateOfCharge"
          size="md"
          rules={{ required: true, min: 0, max: 100 }}
          readOnly={isFormDisabled}
        />
      </Grid>

      <Heading size="h3" weight="medium">
        Solar (PV)
      </Heading>

      <Grid
        gap={5}
        py={4}
        gridTemplateColumns={{
          xs: '4fr 3fr',
          sm: '4fr 3fr 4fr 3fr'
        }}>
        <T>DC-AC Inverter Efficiency</T>
        <InputPercentageFormControl
          control={formControl}
          formControlName="pvDCACInverterEfficiency"
          size="md"
          rules={{ min: 0, max: 100 }}
          readOnly={isFormDisabled}
        />

        <T>Annual Derating</T>
        <InputPercentageFormControl
          control={formControl}
          formControlName="pvAnnualDerating"
          size="md"
          rules={{ min: 0, max: 100 }}
          readOnly={isFormDisabled}
        />
      </Grid>

      <Heading size="h3" weight="medium">
        Wind
      </Heading>

      <Grid
        gap={5}
        py={4}
        gridTemplateColumns={{
          xs: '4fr 3fr',
          sm: '4fr 3fr 4fr 3fr'
        }}>
        <T>DC-AC Inverter Efficiency</T>
        <InputPercentageFormControl
          control={formControl}
          formControlName="windTotalEfficiency"
          size="md"
          rules={{ min: 0, max: 100 }}
          readOnly={isFormDisabled}
        />

        <T>Annual Derating</T>
        <InputPercentageFormControl
          control={formControl}
          formControlName="windAnnualDerating"
          size="md"
          rules={{ min: 0, max: 100 }}
          readOnly={isFormDisabled}
        />
      </Grid>

      <LossFactorsDetailsPreviewCard formValues={formValues} />
    </Box>
  )
}
