export const TransformerIcon = () => (
  <svg
    width="36"
    height="54"
    xmlns="http://www.w3.org/2000/svg"
    colorInterpolationFilters="sRGB">
    <g stroke="null" id="group12-1">
      <g stroke="null" id="shape13-2">
        <path
          stroke="#000"
          id="svg_1"
          strokeWidth="0.25"
          fill="none"
          className="st1"
          d="m2.59409,26.99226a17.76562,17.62628 -180 1 0 30.65557,0a17.76079,17.62146 -180 1 0 -30.65557,0z"
        />
      </g>
      <g stroke="null" id="shape14-4">
        <path
          stroke="#000"
          id="svg_2"
          strokeWidth="0.25"
          fill="none"
          className="st1"
          d="m2.48704,26.99265a17.82257,17.68278 -180 0 0 30.87271,0a17.82257,17.68278 -180 0 0 -30.87271,0z"
        />
      </g>
    </g>
  </svg>
)
