import { Control } from 'react-hook-form'
import { Card, Grid, Heading } from '@veracity/vui'

import { SelectFormControl } from '@/components'
import { ScenarioDetailed, SystemConfigurations } from '@/interfaces'

import { ScenarioDetailsFormValues } from '../../types'
import { useScenarioLibrariesDictionaries } from '../../hooks'

interface Props {
  scenarioInfo?: ScenarioDetailed
  formControl: Control<ScenarioDetailsFormValues>
  isFormDisabled: boolean
}

export const ScenarioDetailsContentDatasetsForm = ({
  scenarioInfo,
  formControl,
  isFormDisabled
}: Props) => {
  const {
    wholesaleMarket,
    lossFactors,
    incomeAdjustment,
    finances,
    productionProfilesWind,
    productionProfilesPv,
    otherBessInputs
  } = useScenarioLibrariesDictionaries()

  const isPvProductionProfilesVisible =
    !!scenarioInfo &&
    [
      SystemConfigurations.SolarPV,
      SystemConfigurations.SolarPVAndWind,
      SystemConfigurations.SolarPVOnSharedBus,
      SystemConfigurations.SolarPVAndAcCoupledWithWind
    ].includes(scenarioInfo.systemConfiguration)

  const isWindProductionProfilesVisible =
    !!scenarioInfo &&
    [
      SystemConfigurations.WindOnShareBus,
      SystemConfigurations.SolarPVAndWind,
      SystemConfigurations.SolarPVAndAcCoupledWithWind
    ].includes(scenarioInfo?.systemConfiguration)

  return (
    <Card column p={2} gap={2} h="100%">
      <Heading size="h4">Input Datasets</Heading>

      <Grid gap={2} gridTemplateColumns={{ xs: 2, md: 3, xl: 1, xxl: 2 }}>
        <SelectFormControl
          label="Wholesale Market"
          control={formControl}
          formControlName="wholesaleMarketPriceId"
          options={wholesaleMarket}
          disabled={isFormDisabled}
        />

        <SelectFormControl
          label="Finances"
          control={formControl}
          formControlName="finansesDatasetId"
          options={finances}
          disabled={isFormDisabled}
        />

        <SelectFormControl
          label="Income Adjustment"
          control={formControl}
          formControlName="incomeAdjustmentsId"
          options={incomeAdjustment}
          disabled={isFormDisabled || true /* TODO: Temporary disabled */}
        />

        <SelectFormControl
          label="Loss Factors"
          control={formControl}
          formControlName="lossFactorsId"
          options={lossFactors}
          disabled={isFormDisabled}
        />

        {isPvProductionProfilesVisible && (
          <SelectFormControl
            label="PV Production Profiles"
            control={formControl}
            formControlName="pvProductionProfileId"
            options={productionProfilesPv}
            disabled={isFormDisabled}
          />
        )}

        {isWindProductionProfilesVisible && (
          <SelectFormControl
            label="Wind Production Profiles"
            control={formControl}
            formControlName="windProductionProfileId"
            options={productionProfilesWind}
            disabled={isFormDisabled}
          />
        )}

        <SelectFormControl
          label="Other BESS Inputs"
          control={formControl}
          formControlName="otherBessInputId"
          options={otherBessInputs}
          disabled={isFormDisabled}
        />
      </Grid>
    </Card>
  )
}
