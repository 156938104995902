import { Button, ButtonToggleGroup } from '@veracity/vui'

interface RadioButtonItem<T> {
  label: string
  value: T
}

interface Props<T> {
  items: RadioButtonItem<T>[]
  value: T
  onChange: (val: T) => void
}

export const RadioButtons = <T extends string | number | boolean>({
  items,
  value,
  onChange
}: Props<T>) => (
  <ButtonToggleGroup>
    {items.map((x, index) => (
      <Button
        key={index}
        isActive={value === x.value}
        onClick={() => onChange(x.value)}>
        {x.label}
      </Button>
    ))}
  </ButtonToggleGroup>
)
