import { Grid, T } from '@veracity/vui'

import { BorderedBox } from '@/components'

interface Props {
  label: string
  values?: (string | number | undefined | null)[]
}

export const ScenarioResultsAggregatesTabSection = ({
  label,
  values
}: Props) => {
  const columnsCount = values?.length || 0
  return (
    <Grid
      gridTemplateColumns={`minmax(20rem, 3fr) repeat(${columnsCount}, minmax(8rem, auto))`}>
      <BorderedBox>
        <T p={1}>{label}</T>
      </BorderedBox>
      {values?.map((value, index) => (
        <BorderedBox key={index} justify="right">
          <T weight="medium" p={1}>
            {value}
          </T>
        </BorderedBox>
      ))}
    </Grid>
  )
}
