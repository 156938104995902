import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { Box, Button, Dialog } from '@veracity/vui'
import { useToggle } from '@veracity/ui-utils'

import { CheckboxGroupFormControl } from '@/components'
import { usePortfolioDetails } from '../hooks'

interface Props {
  portfolioId: number
}

export const PortfolioDetailsLinkProjectDialog = ({ portfolioId }: Props) => {
  const [isVisible, toggleIsVisible] = useToggle()

  const {
    linkProjects,
    isLinkProjectsInProgress,
    linkedProjectsIds,
    allProjects
  } = usePortfolioDetails(portfolioId)

  const { reset, setValue, handleSubmit, control } = useForm({
    defaultValues: { projectsIds: [] as number[] }
  })

  useEffect(
    () => setValue('projectsIds', linkedProjectsIds || []),
    [linkedProjectsIds, setValue]
  )

  const handleClose = () => {
    if (isLinkProjectsInProgress) return
    reset()
    toggleIsVisible.off()
  }

  const handleSubmitClick = (values: any) => {
    linkProjects(
      { projectsIds: values.projectsIds },
      { onSuccess: handleClose }
    )
  }

  return (
    <>
      <Button
        size="lg"
        iconLeft="falPaperclip"
        text="Link projects"
        onClick={toggleIsVisible}
      />

      <Dialog
        isOpen={isVisible}
        title="Link projects"
        onClose={handleClose}
        onSubmit={handleSubmit(handleSubmitClick)}
        cancelButton={{ text: 'Cancel', disabled: isLinkProjectsInProgress }}
        submitButton={{
          text: 'Save',
          onClick: handleSubmit(handleSubmitClick),
          isLoading: isLinkProjectsInProgress,
          disabled: isLinkProjectsInProgress
        }}
        body={
          <Box m={2} overflowY="auto" h="50vh">
            <Box p={2}>
              <CheckboxGroupFormControl
                control={control}
                formControlName="projectsIds"
                items={allProjects.map(x => ({
                  label: x.name || '',
                  value: x.id
                }))}
              />
            </Box>
          </Box>
        }
      />
    </>
  )
}
